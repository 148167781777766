import * as React from "react";

import { Box } from "@mui/material";

import {
  MainContainer,
  paperContainerBcgProps,
  SecondaryContainer,
  ImageProps,
  TextContainerProps,
  MainTitle,
  ButtonTitle,
} from "../../styledComponents/homePage/secOneStyledCmps";

export default function SecOne({ openModal }) {
  return (
    <MainContainer>
      <Box component="div" sx={{ ...paperContainerBcgProps }}>
        <Box
          component="img"
          src={require("../../assets/img/rectangle-sec1.png")}
          alt="group-frame"
          sx={{
            position: "absolute",
            bottom: "0",
            width: "100%",
            height: "158px",
            display: { sm: "none", xs: "block" },
          }}
        />
        <SecondaryContainer>
          <Box
            alt="group-frame"
            sx={{ ...ImageProps}}
          >
            <picture>
              <source srcSet={require("../../assets/img/group-frame-sec1.webp")} type="image/webp" />
              <source srcSet={require("../../assets/img/group-frame-sec1-lq.png")} type="image/png" />
              <img alt="user dashboard and data sources presentation" src={require("../../assets/img/group-frame-sec1-mobile.png")} style={{width: "100%", height: "100%"}}  />
            </picture>
          </Box>

          <Box sx={{ ...TextContainerProps }}>
            <MainTitle>
              שירות חכם ופשוט לניהול כלל המכרזים וההצעות בעסק
            </MainTitle>

            <ButtonTitle variant="contained" onClick={() => openModal()}>
              התחל בחינם
            </ButtonTitle>
          </Box>
        </SecondaryContainer>
      </Box>
    </MainContainer>
  );
}
