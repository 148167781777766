import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useState } from "react";

import { StyledTableCell } from "../../../styledComponents/dashboard/user";
import { Button, Checkbox, Link, List, Tooltip } from "@mui/material";
import { userService } from "../../../services/userService.service";
import { offerService } from "../../../services/offerService.service";
import { AppwideValues } from "../../../env";

const themeColors = AppwideValues.themeColors;

export function BodyPreview(props)
{
  const {offer, openBody} = props;
  if(offer.body)
  return (
      <><Typography sx={{ textAlign: "center", fontWeight: "600" }}>
        גוף ההצעה
      </Typography>
        <Button variant="text" onClick={() => openBody(offer)}>
          {offer.body.substr(0, 20)}...
        </Button>
      </>)
  return ''
}

function Row(props)
{
  const { row, resort, setSelected, openBody, clickableRows } = props;
  const [open, setOpen] = useState(false);
  const [hidden, setHidden] = useState(
    offerService.UserOffer.getState(row.id, "hidden")
  );
  const [favorit, setFavorit] = useState(
    offerService.UserOffer.getState(row.id, "favorites")
  );
  // const [showDetails, setShowDetails] = useState(false);
  // const [selected, setSelected] = useState(null);

  const handleOpen = (evt) =>
  {
    setOpen(!open);
    evt.stopPropagation();
  };
  const handleClick = (type) =>
  {
    let val = type === "favorites" ? favorit : hidden;
    const setter = type === "favorites" ? setFavorit : setHidden;
    offerService.UserOffer.set({ id: row.id, type, val: !val, notify: setter });

    if (type === "favorites")
    {
      offerService.addFavorite(row.id);
    }
    // switch (val)
    // {
    //   case false:
    //     val = 'changing';
    //     break;
    //   case 'changing':
    //     val = true;
    //     break;
    //   case true:
    //     val = false;
    //     break;
    //   default: return;
    // }
    //setter(val);
  };
  const buttonHeights = { xl: "35px", md: "30px", sm: "28px" };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset", fontSize: "16px" },
          "&:hover": clickableRows?{
            backgroundColor: "rgba(0,0,100,0.2)",
          }:undefined,
          width: "100%",
          cursor: clickableRows?"pointer":"unset",
        }}
        onClick={() =>
        {
          setSelected(row);
        }}
      >
        <TableCell
          sx={{
            paddingInline: { lg: "20px", md: "10px", sm: "5px" },
            paddingInlineEnd: { lg: "20px", md: "10px" },
            textAlign: "center",
            border: "none",
          }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpen}
            sx={{ backgroundColor: open ? "#F1F7FF" : "inherit" }}
          >
            <ArrowForwardIosSharpIcon
              fontSize="small"
              sx={{ transform: open ? "rotate(270deg)" : "rotate(90deg)" }}
            />
          </IconButton>
        </TableCell>

        {/* aid name */}
        <StyledTableCell
          align="right"
          title={row.name}
          sx={{
            paddingInlineStart: { md: "10px", xs: "2px" },
            paddingInlineEnd: { md: "30px", sm: "7px" },
            maxWidth: { xl: "640px", lg: "390px" },
            fontWeight: "600",
            "&.MuiTableCell-body": {
              maxWidth: { lg: "420px", md: "230px", sm: "200px" },
              whiteSpace: "nowrap",
            },
          }}
        >
          {row.name}
        </StyledTableCell>

        {/* authority name */}
        <StyledTableCell
          align="right"
          sx={{
            paddingInlineStart: { md: "40px", xs: "0px" },
            paddingInlineEnd: { lg: "40px" },
            "&.MuiTableCell-body": {
              maxWidth: { lg: "150px", sm: "120px" },
              paddingInline: { sm: "16px" },
            },
          }}
        >
          {row.offerGiverName}
        </StyledTableCell>

        {/* aid number, noow status */}
        <StyledTableCell
          align="right"
          sx={{
            paddingInlineStart: "0px",
            paddingInlineEnd: { xl: "32px", lg: "0px" },
            width: { xl: "109px", lg: "109px" },
            display: { md: "table-cell", sm: "none" },
          }}
        >
          {/* on size "sm": will be shown when the row is open */}
          {/* {row.originalId} */}
          {statusCircles(row)}
        </StyledTableCell>

        {/* last date to submit */}
        <StyledTableCell
          align="right"
          sx={{
            paddingInlineStart: "0px",
            //paddingInlineEnd: { xl: "34px", lg: "15px", md: "10px" },
            paddingInlineEnd: 0, //{ lg: "15px", md: "10px" },
            width: { xl: "106px", lg: "103px" },
            minWidth: "64px",
          }}
        >
          {row.lastDateToSubmit}
        </StyledTableCell>

        {/* action buttons */}
        <StyledTableCell
          align="center"
          sx={{
            padding: "0px",
            width: { xl: "237px" },
          }}
        >
          <TableButtons
            id={row.id}
            resort={resort} />
        </StyledTableCell>
      </TableRow>

      {/* expanded row */}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: "0.5px solid rgba(28, 124, 255, 0.25)",
            borderTop: open ? "0.5px solid rgba(28, 124, 255, 0.25)" : "",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 0,
                display: "flex",
                justifyContent: "space-between",
                direction: "rtl",
                paddingBlock: "12px",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      marginInlineEnd: "5px",
                    }}
                  >
                    שם המכרז:
                  </span>
                  {row.name}
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      marginInlineEnd: "5px",
                    }}
                  >
                    גורם מפרסם:
                  </span>
                  {row.offerGiverName}
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      marginInlineEnd: "5px",
                    }}
                  >
                    אזור:
                  </span>
                  {row.region}
                </Typography>
                <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      marginInlineEnd: "5px",
                    }}
                  >
                    מס' מכרז:
                  </span>
                  {row.originalId}
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  <span
                    style={{
                      fontWeight: "600",
                      textDecoration: "underline",
                      marginInlineEnd: "5px",
                    }}
                  >
                    תאריך יעד:
                  </span>
                  {row.lastDateToSubmit}
                </Typography>
              </Box>

              <Box
                sx={{
                  textAlign: "right",
                  paddingInlineStart: "50px",
                  maxWidth: { lg: "500px", md: "430px" },
                }}
              >
                <BodyPreview offer={row} openBody={openBody}/>
                <Typography sx={{ textAlign: "center", fontWeight: "600" }}>
                  קישורים נוספים
                </Typography>
                <List
                  sx={{
                    direction: 'ltr',
                    textAlign: 'start',
                  }}>
                  {offerService.registrationLinksFromOffer(row).map((link, index) => (
                    <>
                      <Link
                        key={index}
                        href={link}
                        target="_blank"
                        underline="none"
                      >
                        {link}
                      </Link><br />
                    </>
                  ))}
                </List>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function statusCircles(row)
{
  return (
    <>
      <Tooltip title="תקף">
        <Box
          sx={{
            //green circle
            height: { lg: "14px", xs: "7px" },
            width: { lg: "14px", xs: "7px" },
            backgroundColor:
              row.lastDateToSubmit &&
                new Date(row.lastDateToSubmit) > new Date()
                ? themeColors.green
                : "lightgray",
            borderRadius: "50%",
            mr: { lg: "7px", xs: "3.5px" },
            position: "relative",
            top: { lg: "4px", xs: "0px" },
            display: "inline-block",
            tooltip: "תקף",
          }}
        />
      </Tooltip>
      <Tooltip title="חדש">
        <Box
          sx={{
            //yello circle
            height: { lg: "14px", xs: "7px" },
            width: { lg: "14px", xs: "7px" },
            backgroundColor: row.isNew ? themeColors.yellow : "lightgray",
            borderRadius: "50%",
            mr: { lg: "7px", xs: "3.5px" },
            position: "relative",
            top: { lg: "4px", xs: "0px" },
            display: "inline-block",
            title: "",
          }}
        />
      </Tooltip>
      <Tooltip title="מעודכן">
        <Box
          sx={{
            //blue circle
            height: { lg: "14px", xs: "7px" },
            width: { lg: "14px", xs: "7px" },
            backgroundColor: row.isUpdated ? themeColors.blue : "lightgray",
            borderRadius: "50%",
            mr: { lg: "7px", xs: "3.5px" },
            position: "relative",
            top: { lg: "4px", xs: "0px" },
            display: "inline-block",
          }}
        />
      </Tooltip>
    </>
  );
}

export function TableButtons({ id, resort })
{
  const [hidden, setHidden] = useState(
    offerService.UserOffer.getState(id, "hidden")
  );
  const [favorit, setFavorit] = useState(
    offerService.UserOffer.getState(id, "favorites")
  );
  const buttonHeights = { xl: "35px", md: "30px", sm: "28px" };
  const handleClick = (type, evt) =>
  {
    evt.stopPropagation();
    let val = type === "favorites" ? favorit : hidden;
    const setter =
      type === "favorites"
        ? (v) =>
        {
          setFavorit(v);
          resort();
        }
        : (v) =>
        {
          setHidden(v);
          resort();
        };
    offerService.UserOffer.set({ id, type, val: !val, notify: setter });
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "space-around" }}>
      <Checkbox
        checkedIcon={
          <Box
            component="img"
            src={require("../../../assets/icons/star-full.png")}
            sx={{ height: buttonHeights }}
          />
        }
        icon={
          <Box
            component="img"
            src={require("../../../assets/icons/star-empty.png")}
            sx={{ height: buttonHeights }}
          />
        }
        indeterminateIcon={
          <Box
            component="img"
            src={require("../../../assets/icons/star-full.png")}
            sx={{
              height: buttonHeights,
              filter: "grayscale(100%)",
            }}
          />
        }
        checked={favorit === true}
        indeterminate={favorit === "changing"}
        onClick={(e) => handleClick("favorites", e)}
      ></Checkbox>

      <Checkbox
        checkedIcon={
          <Box
            component="img"
            src={require("../../../assets/icons/bin.png")}
            sx={{
              height: buttonHeights,
              filter: "hue-rotate(140deg)",
            }}
          />
        }
        icon={
          <Box
            component="img"
            src={require("../../../assets/icons/bin.png")}
            sx={{ height: buttonHeights }}
          />
        }
        indeterminateIcon={
          <Box
            component="img"
            src={require("../../../assets/icons/bin.png")}
            sx={{
              height: buttonHeights,
              filter: "grayscale(100%)",
            }}
          />
        }
        checked={hidden === true}
        indeterminate={hidden === "changing"}
        onClick={(e) => handleClick("hidden", e)}
      ></Checkbox>

      {/* <IconButton>
        <Box
          component="img"
          src={require("../../../assets/icons/share.png")}
          sx={{ height: buttonHeights }}
        />
      </IconButton> */}
    </Box>
  );
}

export default function CollapsibleTable({
  aidsForDisplay,
  height,
  width,
  resort,
  setSelected,
  setBodyModelOffer,
  clickableRows,
})
{
  return (
    <TableContainer
      component={Paper}
      sx={{
        display: { sm: "block", xs: "none" },
        borderRadius: "0px",
        height: "100%",
        direction: "rtl",
        boxShadow: "none",
        "&::-webkit-scrollbar-button": {
          backgroundColor: "black",
          display: "none",
        },
        "&::-webkit-scrollbar": {
          display: "block",
          width: "12px",
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "inherit",
          borderRadius: "20px",
          mt: "38px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1C7CFF",
          borderRight: "none",
          borderLeft: "none",
          borderRadius: "20px",
          border: "4px solid #fff",
        },
      }}
    >
      <Table
        stickyHeader
        aria-label="collapsible table"
        sx={{ overflowX: "hidden" }}
      >
        <TableHead
          sx={{
            "& .MuiTableCell-stickyHeader": {
              backgroundColor: "#00357D",
            },
          }}
        >
          <TableRow
            sx={{
              width: "100%",
              height: "39px",
              maxWidth: "100px",
              "& .MuiTableCell-root": {
                border: "none",
                height: "39px",
              },
            }}
          >
            {/* icon */}
            <StyledTableCell
              align="right"
              sx={{
                paddingInlineEnd: { lg: "16px", xs: "10px" },
                paddingInlineStart: { md: "16px", xs: "5px" },
              }}
            >
              לפרטים
            </StyledTableCell>

            {/* aid name */}
            <StyledTableCell
              align="right"
              sx={{
                paddingInlineStart: { md: "10px", xs: "5px" },
                width: { xl: "728px" },
              }}
            >
              שם המכרז
            </StyledTableCell>

            {/* authority name  */}
            <StyledTableCell
              align="right"
              sx={{
                paddingInlineStart: { md: "20px", sm: "16px", xs: "0px" },
                width: { xl: "338px" },
              }}
            >
              גורם מפרסם
            </StyledTableCell>

            {/* aid number */}
            <StyledTableCell
              align="right"
              sx={{
                paddingInlineStart: "0px",
                paddingInlineEnd: "8px",
                width: { xl: "109px", md: "72px" },
                display: { md: "table-cell", sm: "none" },
              }}
            >
              סטטוס
            </StyledTableCell>

            {/* last date to submit */}
            <StyledTableCell
              align="right"
              sx={{
                paddingInlineStart: "0px",
                paddingInlineEnd: { lg: "0px", md: "0" },
                width: { xl: "106px", md: "75px", sm: "64px" },
              }}
            >
              תאריך יעד
            </StyledTableCell>

            {/* actions */}
            <StyledTableCell
              sx={{
                paddingInlineStart: "0px",
                width: { xl: "237px" },
                minWidth: "56px",
              }}
            />
          </TableRow>
        </TableHead>

        <TableBody sx={{ overflow: "auto" }}>
          {aidsForDisplay.items?.map((row, index) => (
            <Row key={row.id} row={row} resort={resort} setSelected={setSelected} openBody={setBodyModelOffer} clickableRows={clickableRows} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
