import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { regNextStep } from "../store/actions/userActions";

import { Box, IconButton, Modal } from "@mui/material";

import { MainContainer } from "../styledComponents/signup";
import { userService } from "../services/userService.service";

// pages
import PageOne from "../components/signup/PageOne";
import PageTwo from "../components/signup/PageTwo";
import PageThree from "../components/signup/PageThree";
import PageFour from "../components/signup/PageFour";
import { useNavigate } from "react-router-dom";

export default function Signup({ isOpen, closeModal, isNoBack })
{
  const [step, setStep] = useState(
    userService.RegistrationController.getRegState());
  const stepsData = [];
  const nav = useNavigate();
  const navAndClose = (address) => { nav(address); closeModal(); }

  const { regData } = useSelector((state) => state.userModule);
  const dispatch = useDispatch();

  const back = (data, pageNum) =>
  {
    userService.RegistrationController.save(data, pageNum);
    const num = pageNum - 1
    setStep((prevStep) => num);
    userService.RegistrationController.setRegState(num);

  };
  const forward = async (pageNum, data) =>
  {
    if (pageNum === 4)
    {
      nav("/login");
      closeModal();
      setStep(1);
      userService.RegistrationController.setRegState(1);
      return;
    }
    await dispatch(regNextStep({ data, pageNum }))
    setStep((prevStep) => pageNum + 1);
    userService.RegistrationController.setRegState(pageNum + 1);
  }
  // const forward = async (pageNum, data) => {
  //   try {
  //     if (pageNum === 2 || pageNum === 3) {
  //       data.email = regData.reg1.email;
  //     } else if (pageNum === 4) {
  //       nav("/login");
  //       closeModal();
  //       setStep(1);
  //       return;
  //     }
  //     try {
  //       const affArrStr = localStorage.getItem('affiliate')
  //       if(affArrStr)
  //       {
  //         data.affiliate = JSON.stringify(affArrStr);
  //       }
  //     } catch (error) {

  //     }
  //     await dispatch(regNextStep({ data, pageNum }));
  //     setStep((prevStep) => pageNum + 1);
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  return (
    <div>
      <Modal
        keepMounted
        sx={{ backgroundColor: "rgba(3, 44, 101, 0.8)", overflowY: "auto" }}
        open={isOpen}
        onClose={closeModal}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <>
          <IconButton
            aria-label="Close"
            onClick={() =>
            {
              if (step === 4)
              {
                setStep(1);
              }
              else
              {
                userService.RegistrationController.save(stepsData[step], step);
              }
              closeModal();
            }}
            sx={{
              width: "min-content",
              marginBlock: { xl: "15px", md: "10px" },
              display: "flex",
              marginInline: "auto",
            }}
          >
            <Box
              component="img"
              src={require("../assets/icons/close-circle.png")}
              sx={{
                height: { md: "30px", xs: "30px" },
                width: { md: "30px", xs: "30px" },
                marginBlock: { md: 0, xs: 1 },
              }}
            />
          </IconButton>
          <MainContainer
            sx={{
              maxWidth: {
                xl: step === 3 || step === 4 ? "1000px" : "970px",
                lg: step === 3 || step === 4 ? "1000px" : "840px",
              },
            }}
          >
            {step === 1 && (
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "none",
                    maxWidth: "354px",
                    width: "100%",
                  },
                }}
              >
                <Box
                  component="img"
                  src={require("../assets/img/frame-reg3.png")}
                  sx={{ width: "100%" }}
                  />
                <Box
                  component="img"
                  src={require("../assets/img/benefits-reg2.png")}
                  sx={{ width: "100%", marginBlock: 2 }}
                />
              </Box>
            )}
            {step === 1 && <PageOne forward={forward} nav={navAndClose} stepsData={stepsData} />}
            {step === 2 && <PageTwo forward={forward} back={back} stepsData={stepsData} />}
            {step === 3 && <PageThree forward={forward} back={back} stepsData={stepsData} isNoBack={isNoBack} nav={navAndClose} />}
            {step === 4 && <PageFour forward={forward} back={back} />}
            {step === 1 && (
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "flex",
                    maxWidth: "100%",
                    width: "100%",
                  },
                  flexDirection:{
                    sm: 'column-reverse',
                    md: 'row'
                  },
                  alignItems: {
                    sm:'center',
                    md:'start'
                  },
                  gap:'16px',
                  marginBlock:'16px',
                }}
              >
                <Box
                  component="img"
                  src={require("../assets/img/benefits-reg2.png")}
                  sx={{
                    flex: '464 464',
                    minWidth: 0,
                   }}
                />
                <Box
                  component="img"
                  src={require("../assets/img/frame-reg3.png")}
                  sx={{
                    flex: '575 575',
                    minWidth: 0,
                  }}
                />
              </Box>
            )}
          </MainContainer>
        </>
      </Modal>
    </div>
  );
}
