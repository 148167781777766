import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button, AppBar, Toolbar, Typography, Modal, IconButton } from "@mui/material";

import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

import Pagination from "./pagination";
import { StyledInput } from "../../../styledComponents/signup";
import FilterDropdown from "./FilterDropdown";
import CollapsibleTable from "./Table";
import Swipeable from "../Swipeable";
import AidList from "./AidList";
//import { setFilter } from "../../../store/actions/aidActions";
import Lottie from "react-lottie";
import Loader from "../../../assets/animations/loader.json";
import { offerService } from "../../../services/offerService.service";
import { AppwideValues } from "../../../env";
import { CheckBox } from "@mui/icons-material";
import { useStableState } from "../../../util/StableState";

const themeColors = AppwideValues.themeColors

const regionNames = [
  { title: "ירושלים", value: "ירושלים" },
  { title: "השרון", value: "השרון" },
  { title: "דרום ", value: "דרום" },
  { title: "מרכז", value: "מרכז" },
  { title: "צפון", value: "צפון" },
];

const types = [
  { title: "מכרזים", value: "AUCTION" },
  { title: "מאגרים", value: "REPOSITORY" },
  { title: "הצעות אחרות", value: "OTHER" },
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

function UserDashboard(props)
{
  const dispatch = useDispatch();
  const [_swipeableOpen, _setSwipeableOpen] = useOutletContext();
  const [, , _user, _offers,  _filters, resort] = useOutletContext();
  const isSubElement = props.offers ? true : false;
  const user = props.user ?? _user;
  const offers = props.offers ?? _offers;
  const swipeableOpen = props.swipeableOpen ?? _swipeableOpen;
  const setSwipeableOpen = props.setSwipeableOpen ?? _setSwipeableOpen;
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const /** @type {{
    display: {
      text: string,
      region: string[],
      subscription: string[],
      type: string[]
    },
    set:(
      value: {
      text: string,
      region: string[],
      subscription: string[],
      type: string[]
      }) => void,
    get: () => {
      text: string,
    region: string[],
    subscription: string[],
      type: string[]
  }}} */  filters = _filters;  //fing JSDoc workaround
  const repositoryOnly = useStableState(false);
  const offerRef = useRef(offers);


  const [bodyModelOffer, setBodyModelOffer] = useState(null);

  const setSelected = props.setSelected ?? (() => { });
  const clickableRows = (!!props.setSelected);
  //const { /*aids,*/ filterBy } = useSelector((state) => state.aidModule);

  console.log("user", user);

  const filtersData = [
    {
      values: types,
      field: "type",
      summaryTitle: ":סוגי הצעות",
      all: {
        title: "הכל",
        value: ["AUCTION", "REPOSITORY", "OTHER"],
      },
    },
    {
      values: regionNames,
      field: "region",
      summaryTitle: ":אזור",
      all: {
        title: "כל הארץ",
        value: ["השרון", "דרום", "מרכז", "צפון", "ירושלים"],
      },
    },
    {
      values: [...user.subscriptions],
      field: "subscription",
      summaryTitle: ":קטגוריה",
      all: {
        title: "הכל",
        value: [...user.subscriptions],
      },
    },
  ];

  // {
  //   startIdx: 0,
  //   endIdx: 50,
  //   items: [],
  // });

  const [mounted, setMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(50);

  const handleFilter = ({ target }, val, field) =>
  {

    let newfilters= filters.get();
    if (field === "text")
    {
      const value = target.value;
      //setFilter({ ...filterBy, [field]: value });
      //filterBy = { ...filters.value, text: value };
      newfilters.text=value;
    }
    else
    {
      const value = check(field, val);
      //dispatch(setFilter({ ...filterBy, [field]: [...value] }));
      // filterBy = { ...filters.value, [field]: [...value] };
      // filters.set(filterBy);
      newfilters[field] = [...value];
    }
    filters.set(newfilters);
    processFilters();
  };

  const check = (field, value) => 
  {
    const filterBy = filters.get();
    const newArr = [...filterBy[field]];
    const filter = filtersData.find((filter) => filter.field === field);
    if (Array.isArray(value))
    {
      if (newArr.length < filter.values.length)
      {
        return [...value];
      } else return [];
    } else if (!newArr.includes(value))
    {
      newArr.push(value);
    } else
    {
      const idx = newArr.findIndex((item) => item === value);
      newArr.splice(idx, 1);
    }
    return newArr;
  };

  useEffect(() =>
  {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const filterAids = (data, filterBy) => 
  {
    let filtered = data;
    //const filterBy = filters.value;

    // filter by region
    if (filterBy.region.length > 0)
    {
      filtered = filtered.filter((aid) => filterBy.region.includes(aid.region));
    }

    // filter by type
    if (filterBy.type.length > 0)
    {
      console.log("filtered", filtered);
      filtered = filtered.filter((aid) => filterBy.type.includes(aid.type));

      if (filterBy.type.includes("OTHER"))
      {
        const otherAids = data.filter(
          (aid) => aid.type !== "REPOSITORY" && aid.type !== "AUCTION"
        );
        filtered = [...filtered, ...otherAids];
      }
    }

    // filter by category
    if (filterBy.subscription.length > 0)
    {
      filtered = filtered.filter((aid) =>
      {
        if (
          aid.categories.some((category) =>
            filterBy.subscription.includes(category)
          )
        )
        {
          return aid;
        }
      });
    }

    // filter by text
    if (filterBy.text)
    {
      const regex = new RegExp(filterBy.text, "i");
      filtered = filtered.filter((aid) => regex.test(aid.name));
    }
    if (repositoryOnly.get())
    {
      filtered = filtered.filter((aid) =>
      {
        return aid.categories.includes('מאגרים') || aid.type === 'REPOSITORY';
      });
    }

    //setFilteredAids((prevState) => [...filtered]);
    return filtered;
  };

  const paginate = (filtered, page) =>
  {
    if (!mounted)
    {
      setMounted(true);
    }
    if (page * perPage > filtered.length)
    {
      page = Math.floor((filtered.length - 1) / perPage);
      setPage(page);
    }

    const startIdx = page * perPage;
    const endIdx =
      (page + 1) * perPage > filtered.length
        ? filtered.length - 1
        : (page + 1) * perPage - 1;

    const items = filtered.slice(startIdx, endIdx);

    return {
      startIdx: startIdx + 1,
      endIdx: endIdx + 1,
      items: [...items],
      totalLength: filtered.length,
    };
  };

  const handlePagination = (newPage) => 
  {
    setPage(newPage);
    aidsForDisplay.set(paginate(filteredAids.get(), newPage));
  };

  const updateDimensions = () =>
  {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  const filteredAids = useStableState(() =>
    filterAids(offers, filters.get())
  );
  const aidsForDisplay = useStableState(() =>
    paginate(filteredAids.get(), page)
  );
  if (offers != offerRef.current)
  {
    offerRef.current = offers;
    processFilters();
  }

  if (offerService.getServiceState() !== "loaded")
  {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          component="img"
          src={require("../../../assets/img/logo-loader.png")}
          sx={{ maxWidth: "fit-content" }}
        />
        <Lottie
          height={400}
          width={400}
          options={defaultOptions}
          style={{ maxWidth: "600px" }}
        />
      </Box>
    );
  }

  console.log("offers", offers, aidsForDisplay.get());

  return (
    <>
      <Modal
        open={bodyModelOffer}
        sx={{
          backgroundColor: "rgba(3, 44, 101, 0.8)",
          overflowY: "auto",
          fontFamily: 'Assistant,sans-serif',
          direction: 'rtl',
        }}
        onClose={() => setBodyModelOffer(false)}
      >
        <>
          <IconButton
            aria-label="Close"
            onClick={() => setBodyModelOffer(false)}
            sx={{
              width: "min-content",
              marginBlock: { xl: "15px", md: "10px" },
              display: "flex",
              marginInline: "auto",
            }}
          >
            <Box
              component="img"
              src={require("../../../assets/icons/close-circle.png")}
              sx={{
                height: { md: "30px", xs: "30px" },
                width: { md: "30px", xs: "30px" },
                marginBlock: { md: 0, xs: 1 },
              }}
            />
          </IconButton>
          <Box
            sx={{
              maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
              height: "fit-content",
              width: "fit-content",
              borderRadius: "30px",
              marginInline: 'auto',
              mb: 5,
              backgroundColor: 'white',
              textAlign: 'center',
              padding: '30px',
              overflow: 'auto',
            }}

          >

            {(bodyModelOffer?.body)?.split('\n').map((txt, index) =>
              <>
                {index && <br />}
                {txt}
              </>)}
          </Box>
        </>
      </Modal>
      <Box className="UserDashboard" sx={{ flex: 1, height: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            height: {
              // xl: height > 750 ? "85vh" : "81vh",
              // md: height > 750 ? "87vh" : "84vh",
              // sm: height > 750 ? "84vh" : "79.5vh",
              sm: isSubElement ? '100%' : '93%',
              xs: "100%",
            },
            maxWidth: { xl: "1920px", xs: "100%" },
            marginInline: "auto",
            "& .PrivateSwipeArea-root": {
              display: "none",
            },
          }}
        >
          <Toolbar
            sx={{
              //display: "flex",
              backgroundColor: { sm: "#F3F3F3", xs: "transparent" },
              minHeight: {
                xl: "64px",
                lg: width <= 1290 ? "96px" : "64px",
                sm: width < 900 ? "96px" : "112px",
                xs: "148px",
              },
              //"&.MuiToolbar-root": { paddingLeft: "8px", paddingRight: "10px" },
              "&.MuiToolbar-root": { paddingLeft: "0", paddingRight: "0" },
              justifyContent: {
                xl: "space-between",
                sm: "space-around",
              },
              flexDirection: {
                xl: "row",
                lg: width <= 1290 ? "column-reverse" : "row",
                sm: "column-reverse",
                xs: "column",
              },
              mb: { sm: "0", xs: "19px" },
              //maxWidth: { sm: "-webkit-fill-available", xs: "354px" },
              width: { sm: "100%", xs: 'auto' },
              margin: { sm: "0", xs: "auto" },
            }}
          >


          {/* pagination and search */}
          <Box
            sx={{
              display: "flex",
              width: { lg: "fit-content", xs: "100%" },
              justifyContent: "center",
            }}
          >
            <Pagination
              aidsForDisplay={aidsForDisplay.display}
              setPage={setPage}
              handlePagination={handlePagination}
              page={page}
              perPage={perPage}
            />
            <Box
              sx={{ display: { sm: "flex", xs: "none" } }}
            >
              <Legend />
            </Box>
            
            <Box
              sx={{
                position: "relative",
                maxWidth: { md: "230px" },
                width: "100%",
                display: { lg: "none", md: "block", xs: "none" },
                ml: { xs: "10px" },
              }}
            >
              <Box
                component="img"
                src={require("../../../assets/icons/search-input.png")}
                sx={{
                  position: "absolute",
                  zIndex: "1",
                  right: { lg: "16px", md: "15px" },
                  top: "12px",
                  height: { lg: "24px", md: "20px" },
                  display: {
                    lg: width <= 1290 ? "none" : "block",
                    xs: "none",
                  },
                }}
              />
              
              <StyledInput
                id="outlined-search"
                type="search"
                size="small"
                label="חפשו חופשי"
                onChange={(ev) =>
                {
                  handleFilter(ev, "", "text");
                }}
                sx={{
                  width: "100%",
                  fontSize: { lg: "16px", md: "14px" },
                  "& .MuiInputBase-formControl": {
                    justifyContent: "end",
                    display: { md: "inline-flex" },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 8px",
                    width: { lg: width <= 1290 ? "87%" : "78%" },

                      fontSize: { lg: "16px", md: "14px" },
                    },
                    "& .MuiInputLabel-root": {
                      left: "auto",
                      right: {
                        lg: width <= 1290 ? "30px" : "60px",
                        md: "30px",
                      },
                      top: "3px",
                      fontSize: { lg: "16px", md: "14px" },
                      "&.Mui-focused": {
                        right: "13px",
                      },
                      "&.MuiInputLabel-shrink": {
                        right: { lg: "10px", md: "15px" },
                      },
                    },
                  }}
                />
              </Box>
            </Box>

          {/* search and dropdown */}
          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              alignItems: "center",
              justifyContent: {
                lg: width <= 1290 ? "center" : "center",
                md: "center",
              },
              width: "100%",
              height: "45px",
              maxWidth: { lg: "955px", md: "860px" },
            }}
          >
            {/* only repositories */}
            <Box
              sx={{
                width: "100%",
                backgroundColor: { md: "#FFCC32", xs: "#F5F9FF" },
                color: '#00357D',
                boxShadow: "",
                border: {
                  md: "1px solid rgba(28, 124, 255, 0.25)",
                  xs: " 1px solid rgba(28, 124, 255, 0.25)",
                },
                borderRadius: '50px',
                width: 'fit-content',

                mb: { md: "0px", xs: "30px" },
              }}
            >
              <Button
                disableRipple={true}
                endIcon={
                  <Box
                    component="img"
                    src={require(`../../../assets/icons/dashboard-${repositoryOnly.display
                      ? "check"
                      : "uncheck"
                      }.png`)}
                    sx={{ height: { md: "16px", xs: "17px" } }}
                  />
                }
                sx={{
                  //border: "1px solid #1C7CFF",
                  paddingBlock: "11px",
                  borderRadius: "0",
                  justifyContent: "center",
                  borderRadius: '50px',
                  height:'100%',
                  paddingInline:'23px',
                }}
                onClick={(ev) =>
                {
                  repositoryOnly.set(!repositoryOnly.get());
                  processFilters();
                }}
              >
                <Typography sx={{
                  color: '#00357D',
                }}>{'רק מאגרים'}</Typography>
              </Button>
            </Box>
            {filtersData.map((filter, index) => (
              <Box
                key={index}
                sx={{
                  maxWidth: {
                    md: filter.field === "region" ? "178px" : "225px",
                  },
                  height: "47px",
                  mr: 0.8,
                  mb: { md: "0", xs: "30px" },
                  "&:last-child": { mb: { md: "0", xs: "40px" } },
                }}
              >
                <FilterDropdown
                  key={filter.field}
                  filter={filter}
                  filterBy={filters.display}
                  handleFilter={handleFilter}
                />
              </Box>
            ))}            
            <Box
              sx={{
                position: "relative",
                maxWidth: { lg: "230px" },
                width: "100%",
                display: { lg: "block", xs: "none" },
              }}
            >
              <Box
                component="img"
                src={require("../../../assets/icons/search-input.png")}
                sx={{
                  position: "absolute",
                  zIndex: "1",
                  right: { lg: "16px", md: "15px" },
                  top: "12px",
                  height: { lg: "24px", md: "20px" },
                  display: {
                    lg: width <= 1290 ? "none" : "block",
                    xs: "none",
                  },
                }}
              />
              <StyledInput
                id="outlined-search"
                type="search"
                size="small"
                label="חפשו חופשי"
                onChange={(ev) =>
                {
                  handleFilter(ev, "", "text");
                }}
                sx={{
                  width: "100%",
                  fontSize: { lg: "16px", md: "14px" },
                  "& .MuiInputBase-formControl": {
                    justifyContent: "end",
                    display: { md: "inline-flex" },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 8px",
                    width: { lg: width <= 1290 ? "87%" : "78%" },

                      fontSize: { lg: "16px", md: "14px" },
                    },
                    "& .MuiInputLabel-root": {
                      left: "auto",
                      right: {
                        lg: width <= 1290 ? "30px" : "60px",
                        md: "30px",
                      },
                      top: "3px",
                      fontSize: { lg: "16px", md: "14px" },
                      "&.Mui-focused": {
                        right: "13px",
                      },
                      "&.MuiInputLabel-shrink": {
                        right: { lg: "10px", md: "15px" },
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: { md: "none", xs: "flex" },
                width: "100%",
                maxWidth: { sm: "375px", xs: "100%" },
                justifyContent: "space-between",
                mt: { sm: "20px", xs: "11px" },
                mb: { sm: 0, xs: 0 },
              }}
            >
              <Button
                onClick={() =>
                {
                  setSwipeableOpen(!swipeableOpen);
                }}
                variant="contained"
                endIcon={
                  <Box
                    component="img"
                    src={require("../../../assets/icons/filter.png")}
                  />
                }
                sx={{
                  borderRadius: "30px",
                  paddingBlock: "5px",
                  width: "100%",
                  maxWidth: "137px",
                  fontSize: "17px",
                  fontWeight: "400",
                }}
              >
                סננו תוצאות
              </Button>
              <Box
                sx={{
                  position: "relative",
                  maxWidth: "210px",
                  width: "100%",
                }}
              >
                <Box
                  component="img"
                  src={require("../../../assets/icons/search-input.png")}
                  sx={{
                    position: "absolute",
                    zIndex: "1",
                    right: "15px",
                    top: "11px",
                    height: "18px",
                  }}
                />
                <StyledInput
                  id="outlined-search"
                  type="search"
                  size="small"
                  label="חפשו חופשי"
                  onChange={(ev) =>
                  {
                    handleFilter(ev, "", "text");
                  }}
                  sx={{
                    width: "100%",
                    maxWidth: "210px",
                    fontSize: { lg: "16px", md: "14px" },
                    "& .MuiOutlinedInput-input": {
                      padding: "9px 8px",
                      paddingInlineStart: "35px",
                      fontSize: "17px",
                    },
                    "& .MuiInputLabel-root": {
                      left: "auto",
                      right: "50px",
                      top: "-1px",
                      fontSize: "17px",
                      "&.Mui-focused": {
                        right: "13px",
                      },
                      "&.MuiInputLabel-shrink": {
                        right: "13px",
                        top: "1px",
                        fontSize: "15px",
                      },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{ display: { sm: "none", xs: "flex" } }}
            >
              <Legend />
            </Box>
          </Toolbar>

          <Box
            component="main"
            sx={{
              width: "100%",
              padding: 0,
              overflow: "auto",
              position: "relative",
            }}
          >
            {/* <Box
            sx={{
              position: "absolute",
              zIndex: "1",
              backgroundColor: "#00357D",
              height: "39px",
              width: "12px",
              display: { sm: "block", xs: "none" },
            }}
          /> */}

            <CollapsibleTable
              aidsForDisplay={aidsForDisplay.display}
              user={user}
              height={height}
              width={width}
              resort={resort}
              setSelected={setSelected}
              setBodyModelOffer={setBodyModelOffer}
              clickableRows={clickableRows}
            />
            <AidList
              user={user}
              aidsForDisplay={aidsForDisplay.display}
              resort={resort}
              setSelected={setSelected}
              setBodyModelOffer={setBodyModelOffer}
              clickableRows={clickableRows}
            />
          </Box>
          <Swipeable
            width={width}
            swipeableOpen={swipeableOpen}
            setSwipeableOpen={setSwipeableOpen}
            filters={filtersData}
            filterBy={filters.display}
            handleFilter={handleFilter}
          repositoryOnly={repositoryOnly}
          processFilters={processFilters}
          />
        </Box >
      </Box >
    </>
  );

  function processFilters()
  {
    filteredAids.set(filterAids(offers, filters.get()));
    aidsForDisplay.set(paginate(filteredAids.get(), page));
  }
}

UserDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default UserDashboard;
function Legend()
{
  const typograpySx = {
    fontFamily: 'Assistant',
    fontSize: '17px',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#1C7CFF',
  }

  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      // alignItems: 'center',
      //padding: '12px 20px 12px 50px',
      //padding: '6px 12px 0px 12px',
      gap: '7px',
      position: 'relative',
      width: '200px',
      height: '25px',
      marginBlockStart: '7px',
      marginInline: '16px',
    }}>
     {/* <Box
        sx={circleCss({ backgroundColor: themeColors.green })} /> */}
    <Typography
      sx={typograpySx}
    >
      עודכן
      </Typography>
      <Box
        sx={circleCss({ backgroundColor: themeColors.blue })} />
    <Typography
      sx={typograpySx}
      >
      חדש
    </Typography>
      <Box
        sx={circleCss({ backgroundColor: themeColors.yellow })} />
    <Typography
      sx={typograpySx}
    >
      תקף
    </Typography>
      <Box
        sx={circleCss({ backgroundColor: themeColors.green })} />
    </Box>

  function circleCss(override)
  {
    return {
      ...{
        height: { lg: "14px", xs: '7px' },
        width: { lg: "14px", xs: '7px' },
        backgroundColor: themeColors.green,
        borderRadius: "50%",
        marginInline: { lg: "1px", xs: '6px' },
        position: "relative",
        top: { lg: "4px", xs: '0px' },
        display: "inline-block",
        title: '',
      }, ...override
    };
  }
}
