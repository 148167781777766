// import { userService } from "../../services/userService.service"
import { offerService } from "../../services/offerService.service";

export function setFilter(filterBy) {
    return async (dispatch) => {
        try {
            dispatch({ type: "SET_FILTER", filterBy })
        } catch (err) {
            console.log(err);
        }
    }
}
export function loadAllAids() {
    return async (dispatch) => {
        try {

            const data = await offerService.loadAllOffers()
            // console.log(data);

            dispatch({ type: "SET_AIDS", data })
        } catch (err) {
            console.log(err);
        }
    }
}
export function iframeData(sid) {
    return async (dispatch) => {
        try {

            const data = await offerService.fetchIframeData(sid)
            // console.log("data",data);
            return data
        } catch (err) {
            console.log(err);
        }
    }
}
