import * as React from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import palette from './palette';
import typography from './typography';
import shadows, { customShadows } from './shadows';

const themeOptions ={
    palette,
    typography,
    // shadows,
    //   customShadows,
}

const theme = createTheme(themeOptions)

export default theme