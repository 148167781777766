import { Box, styled } from "@mui/material";
import { experimental_sx as sx } from '@mui/system'


export const MiddleWave = styled((props) => (
    <Box component="img"
        src={require("../../assets/img/middle-wave.png")}
        {...props}
    />
))(({ theme }) => (sx({
    maxHeight: "1662px",
    width: '100%',
    position: 'absolute',
    top: '2000px'
})))


export const BottomWave = styled((props) => (
    <Box component="img"
        src={require("../../assets/img/bottom-wave.png")}
        {...props}
    />
))(({ theme }) => (sx({
    maxHeight: { xl: "1200px", sm: "1662px" },
    width: '100%',
    position: 'absolute',
    bottom: { xl: "1000px", md: '950px', sm: "670px" }
})))