import { Box, List } from "@mui/material";
import React from "react";
import AidListItem from "./AidListItem";

export default function AidList({ aidsForDisplay, resort, setSelected, setBodyModelOffer, clickableRows }) {
  const { items } = aidsForDisplay;

  if (!items || !items.length) return;

  return (
    <Box
      sx={{
        display: { sm: "none", xs: "block" },
        width: "100%",
        position: "relative",
        zIndex: "1200",
        //height: "calc(86% - 70px)",
        direction: "rtl",
      }}
    >
      <List
        sx={{
          width: "100%",
          height: "100%",
          margin: "0",
          overflow: "overlay",
          pt: "20px",
          "&::-webkit-scrollbar": {
            width: "15px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#1C7CFF",
            borderRight: "none",
            borderLeft: "none",
            borderRadius: "20px",
            border: "5px solid #edf3fd",
          },
        }}
      >
        {items.map((aid, index) => (
          <AidListItem key={aid.id} aid={aid} resort={resort} setSelected={setSelected} openBody={setBodyModelOffer} clickableRows={clickableRows} />
        ))}
      </List>
    </Box>
  );
}
