import { Typography } from "@mui/material";
import React from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ListTitle,
} from "../../styledComponents/homePage/accordion";

export default function Question({ question }) {
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        boxShadow: expanded ? "0px 8px 48px rgba(161, 194, 244, 0.68)" : "",
        mb: { md: 3, sm: 2, xs: 1 },
        width: "100%",
        maxWidth: {
          xs: "354px",
          sm: "590px",
          md: "850px",
          lg: "1050px",
          xl: "1400px",
        },
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          borderRadius: expanded ? "20px 20px 0 0 " : "100px",
          border: !expanded ? "1px solid rgba(28, 124, 255, 0.25)" : "",
        }}
      >
        <ListTitle
          sx={{
            fontSize: {
              xl: "25px",
              md: "21px",
              sm: "19px",
              xs: "16px",
            },
          }}
        >
          {question.question}
        </ListTitle>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          backgroundColor: "#F5F9FF",
          borderBottomRightRadius: "20px",
          borderBottomLeftRadius: "20px",
          justifyContent: "flex-end",
          paddingBlockStart: { md: "16px", xs: "10px" },
        }}
      >
        <Typography
          sx={{
            unicodeBidi: "plaintext",
            fontSize: {
              xl: "20px",
              md: "18px",
              sm: "17px",
              xs: "15px",
            },
          }}
        >
          {question.answer}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
