import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { BoxForm, MediaButton, StyledInput } from "../styledComponents/signup";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useDispatch } from "react-redux";
import { forgot, login } from "../store/actions/userActions";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { userService } from "../services/userService.service";
import MessagePopup from "../components/MessagePopup";
import { useCallback } from "react";

export default function LoginPage({ openModal }) {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [isForgot, setIsForgot] = useState(false);
  const [data, setData] = useState({ username: "", password: "" });
  const [recoverDetails, setRecoverDetails] = useState({
    username: "",
    // password: "",
  });
  const [errorContent, setErrorContent] = useState('');
  const [isError, setIsError] = useState(false);
  // const [errorFuncs, setErrorFuncs] = useState({});
  // const setErrorFuncsProp = useCallback(
  //   (v)=>setErrorFuncs(v)
  // );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = ({ target }) => {
    const field = target.name;
    const value = target.value;
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleRecover = ({ target }) => {
    const field = target.name;
    const value = target.value;
    setRecoverDetails((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const doLogin = async () => {
    try {
      const userInfo = await dispatch(
        login({
          email: data.username,
          password: data.password,
        })
      );
      nav("/dashboard/user");
    } catch (err) {
      console.log("failed to connect");
    }
  };

  const doRecover = async () => {
    //const reg = new RegExp(/[a-zA-z0-9.-]+@[a-zA-z-]+\.[a-z]/, "gi");

    //if (recoverDetails.username.match(reg)) {
    if (userService.RegistrationController.valid('email', recoverDetails.username))
    {
      console.log("recover pass", recoverDetails);
      const res = await dispatch(forgot(recoverDetails.username));

      console.log("res forgot", res);
      if(res.status===201)
      {
        setErrorContent(
          <Box
          sx={{
            padding: '60px'
          }}
          >
            הודעה עם קישור להתחברות נשלחה לכתובת הדוא"ל שלך
           <br/>
            <Button
              variant="text"
              sx={{
                borderRadius: "30px",
                alignItems: "center",
                margineBlockStart: '30px',
                //mb: "10px",
                "&.MuiButton-root": {
                  fontSize: "14px",
                  //paddingBlock: "12px",

                },
              }}
              onClick={()=>{
                setIsError(false);
                setIsForgot(false);
              }}
            >
              חזור
              </Button>
          </Box>
        );

        setIsError(true);
      }
    }
    else
    {
      setErrorContent('כתובת הדוא"ל אינה תקינה');
      setIsError(true);
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        overflowY: "overlay",
        overflowX: "hidden",
        position: "relative",
        direction: "rtl",

        "&::-webkit-scrollbar": {
          display: "block",
          width: "12px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "20px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1C7CFF",
          borderRight: "none",
          borderLeft: "none",
          borderRadius: "20px",
          border: "4px solid #E5E5E5",
        },
      }}
    >
      <Header openModal={openModal} sx={{ direction: "ltr" }} />
      {/* forgot password */}
      <Box
        component="main"
        sx={{
          display: isForgot ? "block" : "none",
          height: { md: "100%", xs: "fit-content" },
          maxHeight: { md: "744px" },
          backgroundColor: { md: "#EBF3FF" },
          direction: "ltr",
        }}
      >
        <Modal
          sx={{ backgroundColor: "rgba(3, 44, 101, 0.8)", overflowY: "auto" }}
          open={isError}
          onClose={()=>setIsError(false)}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <>
            <IconButton
              aria-label="Close"
              onClick={() => setIsError(false)}
              sx={{
                width: "min-content",
                marginBlock: { xl: "15px", md: "10px" },
                display: "flex",
                marginInline: "auto",
              }}
            >
              <Box
                component="img"
                src={require("../assets/icons/close-circle.png")}
                sx={{
                  height: { md: "30px", xs: "30px" },
                  width: { md: "30px", xs: "30px" },
                  marginBlock: { md: 0, xs: 1 },
                }}
              />
            </IconButton>
            <Box
              sx={{
                maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
                height: "fit-content",
                width: "fit-content",
                borderRadius: "30px",
                marginInline: 'auto',
                mb: 5,
                backgroundColor: 'white',
                textAlign: 'center',
              }}

            >
              {errorContent}
            </Box>
          </>
        </Modal>
        <Box
          component="img"
          src={require("../assets/img/login-wave.png")}
          sx={{
            display: { md: "block", xs: "none" },
            position: "absolute",
            height: "100%",
            maxHeight: "744px",
            maxWidth: { lg: "1199px", md: "1000px" },
            left: "0",
          }}
        />

        <Box sx={{ position: "relative", height: "100%" }}>
          <Typography
            sx={{
              display: { xl: "block", md: "none" },
              fontSize: { md: "48px", sm: "38px", xs: "24px" },
              fontWeight: { md: "400", xs: "600" },
              textAlign: { xl: "end", xs: "center" },
              position: "relative",
              top: { xl: "50px" },
              right: { xl: "340px" },
              mt: { md: "0px", xs: "25px" },
            }}
          >
            התחברות
          </Typography>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: { md: "center" },
              flexDirection: { md: "row", xs: "column" },
              marginBlockStart: { xl: "-45px" },
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/login-img.png")}
              sx={{
                height: "100%",
                maxHeight: {
                  lg: "675px",
                  md: "520px",
                  sm: "450px",
                  xs: "260px",
                },
              }}
            />

            <Box
              component="div"
              sx={{
                width: "100%",
                maxWidth: { md: "370px", sm: "380px", xs: "333px" },
                display: "flex",
                flexDirection: "column",
                mr: { lg: 0, md: "10px" },
              }}
            >
              <Typography
                sx={{
                  display: { xl: "none", md: "block", xs: "none" },
                  fontSize: "48px",
                  textAlign: "center",
                  position: "relative",
                  top: "-15px",
                }}
              >
                התחברות
              </Typography>
              <BoxForm
                sx={{
                  maxWidth: { md: "370px", sm: "380px", xs: "333px" },
                  justifyContent: "flex-start",
                  marginBlockEnd: { md: "59px" },
                  height: "fit-content",
                }}
              >
                <>
                  <Typography
                    sx={{
                      color: "#00357D",
                      fontSize: { lg: "22px", sm: "20px", xs: "18px" },
                      mb: { md: "20px", xs: "12px" },
                    }}
                  >
                    שיחזור סיסמה
                  </Typography>
                  <StyledInput
                    size="small"
                    label="מייל"
                    type="email"
                    name="username"
                    onChange={handleRecover}
                    sx={{
                      "&.MuiTextField-root": {
                        maxWidth: "100%",
                        marginBlockEnd: "10px",
                        marginBlockStart: 0,
                        label: {
                          fontSize: "14px",
                          right: "30px",
                          top: { lg: "3px" },
                        },

                        "& .MuiInputLabel-root": {
                          "&.MuiInputLabel-shrink": {
                            right: { md: "25px" },
                          },
                        },
                      },
                    }}
                  />
                  {/* <StyledInput
                    size="small"
                    label="סיסמה חדשה"
                    type="password"
                    name="password"
                    onChange={handleRecover}
                    sx={{
                      "&.MuiTextField-root": {
                        maxWidth: "100%",
                        marginBlockEnd: "10px",
                        marginBlockStart: 0,
                        label: {
                          fontSize: "14px",
                          right: "35px",
                          top: { lg: "3px" },
                        },
                        "& .MuiInputLabel-root": {
                          "&.MuiInputLabel-shrink": {
                            right: { md: "20px" },
                          },
                        },
                      },
                    }}
                  /> */}

                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "30px",
                      width: "100%",
                      alignItems: "center",
                      mb: "10px",
                      "&.MuiButton-root": {
                        fontSize: "14px",
                        paddingBlock: "12px",
                      },
                    }}
                    onClick={doRecover}
                  >
                    שחזר סיסמה
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "30px",
                      alignItems: "center",
                      mb: "10px",
                      "&.MuiButton-root": {
                        fontSize: "14px",
                        paddingBlock: "12px",
                      },
                    }}
                    onClick={() => {
                      setIsForgot(false);
                      setRecoverDetails({
                        username: data.username ? data.username : "",
                        password: "",
                      });
                    }}
                  >
                    חזור
                  </Button>
                </>
              </BoxForm>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* login page */}
      <Box
        component="main"
        sx={{
          display: isForgot ? "none" : "block",
          height: { md: "100%", xs: "fit-content" },
          maxHeight: { md: "744px" },
          backgroundColor: { md: "#EBF3FF" },
          direction: "ltr",
        }}
      >
        <Box
          component="img"
          src={require("../assets/img/login-wave.png")}
          sx={{
            display: { md: "block", xs: "none" },
            position: "absolute",
            height: "100%",
            maxHeight: "744px",
            maxWidth: { lg: "1199px", md: "1000px" },
            left: "0",
          }}
        />

        <Box sx={{ position: "relative", height: "100%" }}>
          <Typography
            sx={{
              display: { xl: "block", md: "none" },
              fontSize: { md: "48px", sm: "38px", xs: "24px" },
              fontWeight: { md: "400", xs: "600" },
              textAlign: { xl: "end", xs: "center" },
              position: "relative",
              top: { xl: "50px" },
              right: { xl: "340px" },
              mt: { md: "0px", xs: "25px" },
            }}
          >
            התחברות
          </Typography>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: { md: "center" },
              flexDirection: { md: "row", xs: "column" },
              marginBlockStart: { xl: "-45px" },
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/login-img.png")}
              sx={{
                height: "100%",
                maxHeight: {
                  lg: "675px",
                  md: "520px",
                  sm: "450px",
                  xs: "260px",
                },
              }}
            />

            <Box
              component="div"
              sx={{
                width: "100%",
                maxWidth: { md: "370px", sm: "380px", xs: "333px" },
                display: "flex",
                flexDirection: "column",
                mr: { lg: 0, md: "10px" },
              }}
            >
              <Typography
                sx={{
                  display: { xl: "none", md: "block", xs: "none" },
                  fontSize: "48px",
                  textAlign: "center",
                  position: "relative",
                  top: "-15px",
                }}
              >
                התחברות
              </Typography>
              <BoxForm
                sx={{
                  maxWidth: { md: "370px", sm: "380px", xs: "333px" },
                  justifyContent: "flex-start",
                  marginBlockEnd: { md: "59px" },
                  height: "fit-content",
                }}
              >
                <>
                  <Typography
                    sx={{
                      color: "#00357D",
                      fontSize: { lg: "22px", sm: "20px", xs: "18px" },
                      mb: { md: "20px", xs: "12px" },
                    }}
                  >
                    התחברו עם שם משתמש וססמה
                  </Typography>
                  <StyledInput
                    size="small"
                    label="שם משתמש"
                    type="text"
                    name="username"
                    onChange={handleChange}
                    sx={{
                      "&.MuiTextField-root": {
                        maxWidth: "100%",
                        marginBlockEnd: "10px",
                        marginBlockStart: 0,
                        label: {
                          fontSize: "14px",
                          right: "35px",
                          top: { lg: "3px" },
                        },

                        "& .MuiInputLabel-root": {
                          "&.MuiInputLabel-shrink": {
                            right: { md: "15px" },
                          },
                        },
                      },
                    }}
                  />
                  <StyledInput
                    size="small"
                    label="ססמה"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    sx={{
                      "&.MuiTextField-root": {
                        maxWidth: "100%",
                        marginBlockEnd: "10px",
                        marginBlockStart: 0,
                        label: {
                          fontSize: "14px",
                          right: "35px",
                          top: { lg: "3px" },
                        },
                        "& .MuiInputLabel-root": {
                          "&.MuiInputLabel-shrink": {
                            right: { md: "20px" },
                          },
                        },
                      },
                    }}
                  />

                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: "30px",
                      width: "100%",
                      alignItems: "center",
                      mb: "10px",
                      "&.MuiButton-root": {
                        fontSize: "14px",
                        paddingBlock: "12px",
                      },
                    }}
                    onClick={doLogin}
                  >
                    <ChevronLeftRoundedIcon
                      fontSize="small"
                      sx={{ position: "relative", top: "1px", right: "10px" }}
                    />
                    הכניסו אותי
                  </Button>

                  <Typography
                    sx={{ fontSize: "14px", unicodeBidi: "plaintext" }}
                  >
                    לא רשומים? התחילו בחינם עוד היום!
                  </Typography>
                </>
              </BoxForm>

              <Box
                component="div"
                sx={{
                  textAlign: "center",
                  maxWidth: { lg: "300px", md: "260px" },
                  mb: 1.3,
                  marginInline: { sm: "auto" },
                }}
              >
                {/* <Typography
                  sx={{
                    color: "#00357D",
                    fontSize: { lg: "22px", md: "20px", xs: "18px" },
                    mb: "11px",
                    textAlign: "center",
                  }}
                >
                  אפשר גם להתחבר באמצעות
                </Typography>
                <Container
                  sx={{
                    display: "flex",
                    "&.MuiContainer-root": { padding: 0 },
                    mb: "13px",
                    justifyContent: "space-between",
                  }}
                >
                  <MediaButton
                    content="Facebook"
                    icon="facebook"
                    sx={{
                      img: {
                        bottom: { md: "2px", xs: "3px" },
                        left: { md: "5px", xs: "3px" },
                        height: { lg: "26px", sm: "23px", xs: "26px" },
                        width: { lg: "26px", sm: "23px", xs: "26px" },
                      },
                      mr: { lg: 0, sm: 3 },
                      paddingInlineStart: { md: "8px", xs: "4px" },
                    }}
                  />
                  <MediaButton
                    content="Google"
                    icon="google"
                    sx={{ ml: { lg: 2 }, color: "#484848" }}
                  />
                </Container> */}
                <Button
                  onClick={() => {
                    setIsForgot(true);
                  }}
                  sx={{
                    "&.MuiButton-root": {
                      borderRadius: "30px",
                      fontWeight: "400",
                      textDecoration: "underline",
                      fontSize: { lg: "14px", sm: "13px" },
                    },
                  }}
                >
                  שכחתי סיסמה
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer sx={{ direction: "ltr" }} openModal={openModal} />
    </Box>
  );
}
