import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { experimental_sx as sx } from "@mui/system";
import { useState } from "react";
import FilterDropdown from "./user/FilterDropdown";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) =>
  sx({
    // height: "100%",
    // zIndex: "1500",
  })
);

const Puller = styled(Box)(({ theme }) => ({
  width: "54px",
  height: 2,
  backgroundColor: "#1C7CFF",
  borderRadius: 2,
  marginInline: "auto",
  marginTop: "19px",
  marginBottom: "22px",
}));

export default function Swipeable({
  width,
  swipeableOpen,
  setSwipeableOpen,
  filters,
  filterBy,
  handleFilter,
  repositoryOnly,
  processFilters,
})
{

  const toggleDrawer = (newOpen) => () =>
  {
    setSwipeableOpen(newOpen);
  };

  return (
    <Root>
      <SwipeableDrawer
        anchor="bottom"
        open={swipeableOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiBackdrop-root": {
            backgroundColor: "inherit",
          },
          "&.MuiDrawer-root > .MuiPaper-root": {
            boxShadow: "-1px -8px 39px rgba(28, 124, 255, 0.39)",
            overflow: "visible",
            display: { md: "none", xs: "block" },
            borderRadius: "40px 40px 0 0",
          },
        }}
      >
        <Puller />
        {/* only repositories */}
        <Box
          sx={{
            width: "100%",
            backgroundColor: { md: "#FFCC32", xs: "#F5F9FF" },
            color: '#00357D',
            boxShadow: "",
            border: {
              md: "1px solid rgba(28, 124, 255, 0.25)",
              xs: " 1px solid rgba(28, 124, 255, 0.25)",
            },
            borderRadius: '50px',
            width: 'fit-content',            

            mb: { md: "0px", xs: "30px" },
            width: "100%", maxWidth: "309px", marginInline: "auto",
            direction:"rtl",
          }}
        >
          <Button
            disableRipple={true}
            startIcon={
              <Box
                component="img"
                src={require(`../../assets/icons/dashboard-${repositoryOnly.display
                  ? "check"
                  : "uncheck"
                  }.png`)}
                sx={{ height: { md: "16px", xs: "17px" } }}
              />
            }
            sx={{
              //border: "1px solid #1C7CFF",
              paddingBlock: "11px",
              borderRadius: "0",
              justifyContent: "right",
              borderRadius: '50px',
              height: '100%',
              width: '100%',
              paddingInline: '10px',
            }}
            onClick={(ev) =>
            {
              repositoryOnly.set(!repositoryOnly.get());
              processFilters();
            }}
          >
            <Typography sx={{
              color: '#00357D',
              marginInline:'12px',
            }}>{'רק מאגרים'}</Typography>
          </Button>
        </Box>
        <Box sx={{ width: "100%", maxWidth: "309px", marginInline: "auto" }}>
          {filters.map((filter) => (
            <FilterDropdown
              key={filter.field}
              filter={filter}
              filterBy={filterBy}
              handleFilter={handleFilter}
            />
          ))}
        </Box>
      </SwipeableDrawer>
    </Root>
  );
}

Swipeable.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
