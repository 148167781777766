import { styled, Box, Typography, Button } from "@mui/material";
import { experimental_sx as sx } from '@mui/system'


export const TextContainerProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    mb:{md:'0px', xs:8}
};

export const MainContainer = styled(Box)(({ theme }) => (sx({
    mb: {md:20, xs:15},
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection:{md:'row', xs:'column-reverse'}
})))

export const MainTitle = styled(Typography)(({ theme }) => (sx({
    fontWeight: "400",
    textAlign: 'center',
    fontSize: {
        xl: "45px",
        lg: "41px",
        md: "37px",
        sm: "32px",
        xs: "28px",
    },
    mb: { xl: 4, lg: 6, xs: 4 },
})))

export const SubTitle = styled(Typography)(({ theme }) => (sx({
    fontWeight: "400",
    textAlign: 'center',
    fontSize: {
        xl: "28px",
        lg: "23px",
        md: "22px",
        sm: "20px",
        xs: "18px",
    },
    mb: { xl: 5, lg: 6, xs: 5 },
})))

export const Btn = styled(Button)(({ theme }) => (sx({
    "&.MuiButton-contained": {
        fontWeight:"700",
        borderRadius: "50px",
        fontSize: {
            xl: "25px",
            lg: "23px",
            md: "21px",
            xs: "17px",
        },
        padding: { xl: '12px 104px', md: '12px 80px', xs:'10px 60px' }
    },
})))