import React from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";

import { Outlet } from "react-router-dom";

import {
  MiddleWave,
  BottomWave,
} from "../styledComponents/homePage/wavesBackground";

// main sections
import { Box, Container } from "@mui/material";
import SecOne from "../components/home page main/SecOne";
import CategDropdownList from "../components/home page main/CategDropdownList";
import SecThird from "../components/home page main/SecThird";
import Iframe from "../components/home page main/IFrame";
import SecFive from "../components/home page main/SecFive";
import SecSix from "../components/home page main/SecSix";
import SecSeven from "../components/home page main/SecSeven";
import Carousel from "../components/home page main/Carousel";
import Questions from "../components/home page main/Questions";
import { useEffect } from "react";

export default function HomePage({ openModal }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section style={{ position: "relative" }}>
      <Header openModal={openModal} />
      <Container
        component="div"
        sx={{
          maxWidth: { xl: "1920px", xs: "100%" },
          "&.MuiContainer-root": { width: "100%", padding: { xs: "0px" } },
          position: "relative",
        }}
      >
        <BottomWave sx={{ display: { sm: "block", xs: "none" } }} />
        <MiddleWave sx={{ display: { sm: "block", xs: "none" } }} />
        <main style={{ position: "relative", overflow: "hidden" }}>
          <SecOne openModal={openModal} />
          <CategDropdownList />
          <SecThird />
          <Iframe openModal={openModal} />
          <SecFive />
          <SecSix />
          <SecSeven />
          <Carousel />
          <Questions />
        </main>
      </Container>
      <Footer openModal={openModal} />
    </section>
  );
}
