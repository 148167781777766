import { Box, Button, IconButton, List, ListItemButton, Modal, Typography } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppwideValues } from "../env";
import { Store, useStore } from "../util/store";

/**
* @typedef {Object} PromiseControl
* @property {function} resolve - Promise resolve function
* @property {function} reject - Promise reject function
*/
/**
 * @typedef {object} PopupData - popup data
 * @property {string|React.JSX.Element} content - The content of the message popup
 * @property {("error"|"warning"|"info"|null)} type - The type of the message popup
 * @property {string|null} title - The title of the message popup
 * @property {string|null} color - The color of the message popup
 * @property {PromiseControl} promiseControl - The promise control object
 */
class MessagePopupManager
{
  store = new Store({
    open: false,
    /** @type {PopupData[]} */
    messageQueue: [],
  });
  /**
 *
 * @function pushMessage
 * @param {Object} props
 * @param {string|React.JSX.Element} props.content - The content of the message popup
 * @param {("error"|"warning"|"info"|null)} props.type - The type of the message popup
 * @param {string|null} props.title - The title of the message popup
 * @param {string|null} props.color - The color of the message popup
 * @returns {Promise} - A promise that resolves when the message popup is closed
 */
  pushMessage({ content, type, title, color })
  {
    const store = this.store;
    const ctx = store.value;


    /** @type {PromiseControl} */
    let promiseControl;
    function onClose()
    {
      const ctx = store.value;
      const idx = ctx.messageQueue.findIndex(data => data == popupData);
      ctx.messageQueue.splice(idx, 1);
      if (!ctx.messageQueue.length)
      {
        ctx.open = false;
      }
      store.value = ctx;
    }
    const promise = new Promise((resolve, reject) =>
      promiseControl = {
        resolve: (ret) => { onClose(); resolve(ret); },
        reject: (ret) => { onClose(); reject(ret); }
      });
    /** @type {PopupData} */
    const popupData = { content, type, title, color, promiseControl };
    ctx.messageQueue.push(popupData);
    if (!ctx.open)
    {
      ctx.open = true;
    }
    this.store.value = ctx;
    return promise;
  }
}
export const messagePopupManager = new MessagePopupManager();
// class MessagePopupState
// {
//   isShowing = false;
//   title = '';
//   message = '';
//   color = 'red';
//   states = {
//     error: {
//       color: AppwideValues.themeColors.red,
//       title: 'שגיאה'
//     },
//     warning: {
//       color: AppwideValues.themeColors.yellow,
//       title: 'אזהרה'
//     },
//     info: {
//       color: AppwideValues.themeColors.blue,
//       title: 'שים לב'
//     },
//   }

//   setState(state)
//   {
//     this.color = this.states[state].color
//     this.title = this.states[state].title
//   }
//   setError()
//   {
//     this.setState(this.states.error)
//   }
//   setWarning()
//   {
//     this.setState(this.states.warning)
//   }
//   setInfo()
//   {
//     this.setState(this.states.info)
//   }
// }
const states = {
  error: {
    color: AppwideValues.themeColors.red,
    title: 'שגיאה'
  },
  warning: {
    color: AppwideValues.themeColors.yellow,
    title: 'אזהרה'
  },
  info: {
    color: AppwideValues.themeColors.blue,
    title: 'שים לב'
  },
};

/**
 * MessagePopup component.
 *
 * @export
 * @param {Object} props
 * @param {string|React.JSX.Element} props.Content - The content of the message popup
 * @param {("error"|"warning"|"info"|null)} props.type - The type of the message popup
 * @param {string|null} props.title - The title of the message popup
 * @param {string|null} props.color - The color of the message popup
 * @param {PromiseControl} props.promiseControl - The promise control object
 * @returns {React.JSX.Element}
 */
export function MessagePopup({ content: Content, type, title, color, promiseControl }) {
  const dTitle = (type && states[type].title) || title || states.error.title;
  const dColor = (type && states[type].color) || color || states.error.color;
  const ctx = useStore(messagePopupManager.store);

  return (
    <Modal
      sx={{ backgroundColor: "rgba(3, 44, 101, 0.8)", overflowY: "auto" }}
      open={ctx.open}
      onClose={() => {
        promiseControl.reject(null);
      }}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <>
        <IconButton
          aria-label="Close"
          onClick={() => {
            promiseControl.reject(null);
          }}
          sx={{
            width: "min-content",
            marginBlock: { xl: "15px", md: "10px" },
            display: "flex",
            marginInline: "auto",
          }}
        >
          <Box
            component="img"
            src={require("../assets/icons/close-circle.png")}
            sx={{
              height: { md: "30px", xs: "30px" },
              width: { md: "30px", xs: "30px" },
              marginBlock: { md: 0, xs: 1 },
            }}
          />
        </IconButton>
        <Box
          sx={{
            maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
            height: "fit-content",
            width: "fit-content",
            borderRadius: "30px",
            marginInline: "auto",
            mb: 5,
            backgroundColor: "white",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: dColor,
            }}
          >
            <Typography variant="h5">{dTitle}</Typography>
          </Box>
          <Box sx={{ paddingInline: { md: "20px", xs: "10px" } }}>
            {Content instanceof String ? (
              <>
                {Content}
                <br />
                <Button
                variant="contained"
                onClick={() =>
                {
                  promiseControl.resolve("close");
                }}
                sx={{
                  "&.MuiButton-root": { borderRadius: "30px" },
                  mb: 1.5,
                }}
          >
                  סגור
              </Button>
              </>
            ) : (
              <Content promiseControl={promiseControl} />
            )}
          </Box>
        </Box>
      </>
    </Modal>
  );
}
export function MessagePopupModal()
{
  const ctx = useStore(messagePopupManager.store);

  return (
    <Modal
      sx={{ backgroundColor: "rgba(3, 44, 101, 0.8)", overflowY: "auto" }}
      open={ctx.open}
      onClose={() =>
      {
        ctx.messageQueue[0].promiseControl.reject();
      }
      }
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <>
        <IconButton
          aria-label="Close"
          onClick={() =>
          {
            ctx.messageQueue[0].promiseControl.reject();
          }
          }
          sx={{
            width: "min-content",
            marginBlock: { xl: "15px", md: "10px" },
            display: "flex",
            marginInline: "auto",
          }}
        >
          <Box
            component="img"
            src={require("../assets/icons/close-circle.png")}
            sx={{
              height: { md: "30px", xs: "30px" },
              width: { md: "30px", xs: "30px" },
              marginBlock: { md: 0, xs: 1 },
            }}
          />
        </IconButton>
        {
          ctx.messageQueue[0] && <MessagePopup {...ctx.messageQueue[0]} />
        }


      </>
    </Modal >
  );
}
