import React, { useState } from "react";
import
  {
    Button,
    Checkbox,
    Grid,
    IconButton,
    Stack,
    Typography,
  } from "@mui/material";
import { Box } from "@mui/system";
import TaskCard from "./TaskCard";
import UploadCard from "../../UploadCard";
import DetailComponent from "./DetailComponent";
import EditTask from "./EditTask";
import { useEffect, useSyncExternalStore } from "react";
import { userService } from "../../../services/userService.service";
import { offerService } from "../../../services/offerService.service";
import { useStore } from "../../../util/store";
const noLogo = require("../../../assets/img/close-x-drawer.png");

const buttonHeights = { xl: "25px", md: "25px", sm: "20px" };

const NewTable = ({ data, aidsForDisplay, setEditOpen, setTaskForEdit, addTask, setSelectedEditOfferId, setSelectedOffer, offerGiverToLogo }) =>
{
  const [showDetails, setShowDetails] = useState(false);
  const [open, setOpen] = useState(false);
  // const [editOpen, setEditOpen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedEdit, setSelectedEdit] = useState(null);
  // const [favourites, setFavourites] = useState([]);
  // const [tasks, setTasks] = useState([]);
  // const [taskToOfferId, setTaskToOfferId] = useState([]);
  // const favoriteOffers = useSyncExternalStore((f) => offerService.UserOffer.favoriteOffers.subscribe(f), () => offerService.UserOffer.favoriteOffers.value);
  const favoriteOffers = useStore(offerService.UserOffer.allTasksOffers);
  const favoritesList = Object.keys(favoriteOffers);
  // const { tasks, taskToOfferId } = useSyncExternalStore((f) => offerService.UserOffer.allTasksOffers.subscribe(f), () => offerService.UserOffer.allTasksOffers.value);

  /**
   * @typedef {import('../../../util/store').Store} Store
   */
  const { tasks, taskToOfferId } = useStore(offerService.UserOffer.allTasksOffers);

  function _setSelectedEdit(task)
  {
    const offerId = taskToOfferId[task]
    setSelectedEditOfferId(offerId);
    setSelectedOffer(favoriteOffers[offerId])
    setSelectedEdit(task);
    setTaskForEdit(task);
  }


  // useEffect(() => {
  //   console.log("i am running");
  //   const allFav = [];
  //   const allTask = [];

  //   data?.favorites?.forEach((v) => {
  //     let filtered = aidsForDisplay.items.filter((item) => item.id === v);

  //     allFav.push(...filtered);
  //   });
  //   setFavourites(allFav);
  //   const { tasks, taskToOfferId }=offerService.UserOffer.getAllTasks()
  //   setTasks(tasks);
  //   setTaskToOfferId(taskToOfferId)

  // }, [data, aidsForDisplay, data?.offerData]);
  
  console.log("tasks", tasks);

  if (favoriteOffers == null) return <>No Data Found</>;

  return (
    <>
      {/* <EditTask
        open={editOpen}
        onClose={(task) => handleEdit(task)}
        task={selectedEdit}
        offerId={selectedEditOfferId?.[selectedEdit]}
      /> */}
      <Box
        sx={{
          overflow: { sm: "auto", xs: "unset" },
          direction: "rtl",
          px: "1rem",
          pb: "1rem",
          backgroundColor: "#F3F3F3",
        }}
      >
        <Grid container spacing={2}>
          {/* table Start */}
          <Grid
            item
            xs={12}
            lg={showDetails ? 6 : 12}
            sx={{ display: `${showDetails ? "none" : "block"}` }}
          >
            <Box
              sx={{
                boxShadow: "0px 0px 4px #1c7cff38",
                overflow: "auto",
              }}
            ></Box>
          </Grid>
          {/* table End */}
          {/* Details Start */}
          <Grid
            item
            xs={12}
            lg={showDetails ? 6 : 12}
            sx={{ display: `${showDetails ? "block" : "none"}` }}
          >
            <Box
              component="ul"
              sx={{
                margin: 0,
                padding: 0,
                height: "100%",
                backgroundColor: "#fff",
                boxShadow: "-2px 0px 27px #00000021",
              }}
            >

              {favoritesList.map((detailList) => (
                <DetailComponent key={detailList} data={detailList} logo={offerGiverToLogo?.[detailList] ?? noLogo} />
              ))}
            </Box>
          </Grid>
          {/* Details End */}
          {/* upload + tasks Start */}
          <Grid item xs={12} lg={showDetails ? 6 : 12}>
            <Grid container spacing={2}
              sx={{
                justifyContent: "center",
              }}>
              <Grid
                item
                xs={12}
                lg={showDetails ? 12 : 6}
                sx={{ display: `${showDetails ? "block" : "none"}` }}
              >
                <div>
                  <Stack
                    justifyContent="space-between"
                    alignItems="center"
                    direction={{ xs: "row", md: "row" }}
                  >
                    <div>
                      <Typography
                        component="h4"
                        sx={{
                          direction: "rtl",
                          fontWeight: 700,
                          fontSize: "1.125rem",
                          fontFamily: "Assistant",
                          mb: "1rem",
                        }}
                      >
                        גורם מפרסם
                      </Typography>
                    </div>
                    <Stack
                      alignItems="center"
                      direction={{ xs: "row", md: "row" }}
                      gap="1rem"
                      sx={{
                        mb: "1rem",
                      }}
                    >
                      <Typography
                        component="h4"
                        sx={{
                          direction: "rtl",
                          fontWeight: 400,
                          fontSize: "1.125rem",
                          fontFamily: "Assistant",
                        }}
                      >
                        תאריך יעד: <b>22.5.2024</b>
                      </Typography>
                      <Box
                        component="div"
                        sx={{
                          display: "block",
                          height: "4rem",
                          minWidth: "4rem",
                          borderRadius: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        <img
                          style={{
                            display: "block",
                            height: "100%",
                            width: "100%",
                            borderRadius: "100%",
                          }}
                          // src={offerGiverToLogo?.[selectedOffer.offerGiverName] ?? noLogo}
                          alt="..."
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Typography
                    component="h5"
                    sx={{
                      direction: "rtl",
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      color: "#646464",
                      py: "1rem",
                      borderTop: "0.25px solid #00357D",
                      borderBottom: "0.25px solid #00357D",
                    }}
                  >
                    לורם איפסום דולור סיט אמט, קונסקטורר אדיפיסינג אלית לורם
                    איפסום ...אמט, קונסקטורר אדיפיסינג אלית לורם איפסום ...
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={12} lg={showDetails ? 12 : 6}>
                <Typography
                  component="h4"
                  sx={{
                    direction: "rtl",
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    fontFamily: "Assistant",
                    mb: "1rem",
                  }}
                >
                  משימות
                </Typography>
                <Stack direction="column" gap="0.5rem">
                  {tasks.map((task) => (
                    <TaskCard
                      task={task}
                      setOpen={setEditOpen}
                      setSelected={_setSelectedEdit}
                    />
                  ))}
                  <Stack
                    direction="row"
                    gap="1rem"
                    sx={{ display: `${showDetails ? "flex" : "none"}` }}
                  >
                    <Button
                      type="button"
                      sx={{
                        backgroundColor: "#fff",
                        border: "1px solid #1c7cff3f",
                        borderRadius: "50px",
                        flex: 1,
                        justifyContent: "space-between",
                        px: "1rem",
                      }}
                    >
                      <span>*******</span>
                      <Box
                        component="img"
                        src={require("../../../assets/icons/arrow-left-chosen.png")}
                        sx={{ height: "16px", width: "16px" }}
                      />
                    </Button>
                    <Button
                      onClick={() => setOpen(true)}
                      type="button"
                      sx={{
                        backgroundColor: "#fff",
                        border: "1px dashed #1C7CFF",
                        borderRadius: "50px",
                        flex: 1,
                        justifyContent: "space-between",
                        px: "1rem",
                      }}
                    >
                      <span>הוסף משימה</span>
                      <Box
                        component="img"
                        src={require("../../../assets/icons/add-circle.png")}
                        sx={{ height: "16px", width: "16px" }}
                      />
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
              {/* <Grid item xs={12} lg={showDetails ? 12 : 6}>
                <div>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      padding: "1.25rem",
                      borderRadius: "15px",
                      boxShadow: "2px 4px 4px #1c7cff38",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        direction: "rtl",
                        fontWeight: 700,
                        fontSize: "1.125rem",
                        fontFamily: "Assistant",
                        mb: "1rem",
                        pb: "1rem",
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      צרף קבצים
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        {uploadList.map((uploadList) => (
                          <Grid item xs={6} md={4} xl={4}>
                            <UploadCard />
                          </Grid>
                        ))}
                        <Grid item xs={6} md={4} xl={4}>
                          <Box
                            as="label"
                            htmlFor="upload"
                            sx={{
                              backgroundColor: "#F5F9FF",
                              border: "2px dashed #00357D",
                              borderRadius: "0 0 2.375rem 0",
                              height: "9.3125rem",

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",

                              direction: "ltr",
                            }}
                          >
                            <span>
                              <img src="/assets/add-square.svg" alt="..." />
                            </span>
                            <input
                              type="file"
                              name="upload"
                              id="upload"
                              style={{ display: "none" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </div>
              </Grid> */}
            </Grid>
            {/* === */}
          </Grid>
          {/* upload + tasks End */}
        </Grid>
      </Box>
      {/* footer spacer */}
      <Box
        sx={{
          height: "7vh",
        }}></Box>
    </>
  );
};

export default NewTable;
