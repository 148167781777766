import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const items = [
  {
    title: 'מבוא: ',

    paragraphs: [
      'ברוכים הבאים לפלטפורמה "קשר- עסקים ומכרזים בע"מ" (ח"פ: 516697505) (להלן: "האתר" ו/או "קשר-עסקים" ו/או "אסי.בי.סי מכרזים ו/או  kesher – business) האתר מספק שירותים עסקיים לעסקים ומערכת איתור וניהול מכרזים. ',
      'בהיכנסך לאתר, הנך מתבקש לקרוא בעיון את תנאי השימוש באתר כמפורט להלן:',

    ],
    list: [
      { listTitle: 'תנאי השימוש באתר "קשר - עסקים ומכרזים"', listText: ' תנאי השימוש באתר מנוסחים בלשון זכר מטעמי נוחות בלבד, והם מתייחסים לשני המינים.', },
      { listTitle: '', listText: 'האמור בתקנון זה בלשון זכר, הינו לשם הנוחות בלבד והתקנון מתייחס לבני שני המינים באופן שווה.', },
      { listTitle: '', listText: 'אנא קרא את תנאי השימוש בעיון ובקפידה, שכן תחילת השימוש באתר מעידה כי הנך מצהיר כי קראת, הבנת ואישרת את תנאי השימוש, וכי לא תהא לך או למי מטעמך כל טענה ו/או דרישה ו/או תביעה כנגד החברה ו/או מי מטעמה בכל הקשור לתנאי שימוש אלה. אם אינך מסכים לתנאי השימוש אלה, כולם או כל חלק מהם, אינך רשאי לעשות שימוש באתר זה לכל מטרה.', },
      { listTitle: '', listText: 'מפעילת האתר זוקפת לזכותה את הזכות לשנות את תנאי השימוש באתר ללא כל הודעה מוקדמת לגולשי האתר ולא יהיה בכך בכדי לגרוע מתוקפו של התקנון ותחולתו.', },
    ],
  },
  {
    title: 'שימוש באתר',

    paragraphs: [
      'על כתובתו המדויקת של דף האינטרנט באתר "קשר - עסקים ומכרזים" להופיע במקום הרגיל המיועד לכך בממשק המשתמש, לדוגמא: בשורת הכתובת (Address bar) בדפדפן של המשתמש. אין לשנות, לסלף או להסתיר כתובת זו ואין להחליפה בכל כתובת אחרת.',
    ],
  },
  {
    title: 'הסכמה לתקנון',

    paragraphs: [
      'השימוש באתר ובתכניו, לרבות השימוש באתר באמצעות מכשירי קצה שונים - (כולל מסירת כל מידע לפרסום באתר זה וקריאת הודעות המתפרסמות בו), מעיד על הסכמתו של המשתמש ואישורו לאמור בתנאי השימוש, ההתניות וכל יתר ההנחיות שבהסכם זה, כפי שיתעדכנו על ידי "קשר - עסקים ומכרזים" מעת לעת.',
      'הכול כמפורט בתקנון זה, וכפוף ומותנה בכך שהמשתמש יקרא תנאים אלו בעיון ובקפידה בטרם רישום לאתר, ואכן יעמוד בתנאים ובמגבלות המפורטים בתקנון זה, ויקיים אותם. תקנון זה יהא מחייב ויפעל לטובת הצדדים, נציגיהם, יורשיהם וכל נציג מורשה אחר שלהם.',
      'התקנון נועד להסדיר את היחסים המשפטיים בין כל מי שגולש באתר לבין "קשר - עסקים ומכרזים", ולכן הסכם זה מהווה את הבסיס המשפטי הבלעדי לכל התדיינות משפטית בנושא השימוש באתר בין המשתמש לבין "קשר - עסקים ומכרזים" או למי מטעמה. השימוש באתר מעיד על כך שהמשתמש קרא, הבין והסכים לכל התנאים שלהלן במלואם ללא סייג, וכן מאשר כי נהג וינהג על פי הכתוב בתקנון.',
    ],
  },
  {
    title: 'זכות שימוש',

    paragraphs: [
      'מפעילת האתר אינה בודקת את זכותם של המשתמשים באתר לפרסם מידע באתר. הקפדה על מסירת פרטים נכונים המשתמש מתבקש לנהוג בקפידה במסירת הפרטים ולמסור פרטים נכונים, מדויקים, עדכניים ומלאים לגבי זהותו.',
    ],
  },
  {
    title: 'חובת הודעה על שימוש בלתי מורשה',

    paragraphs: [
      'חל איסור מוחלט לעשות שימוש באתר לצורך הבעת או הפצת מידע או פרטים שהם בלתי חוקיים, טורדניים, מאיימים, ו/או שיש בהם משום עידוד לביצוע דבר אלימות, מוציאים לשון הרע, פוגעים בפרטיות או מפרים את החוק בכל דרך שהיא. מובהר בזאת כי "קשר - עסקים ומכרזים" שומרת לעצמה את שיקול הדעת ואת הזכות הבלעדית להסיר מידע, למנוע ו/או להפסיק שימוש באתר, בכל מקרה בו ייעשה שימוש בלתי ראוי באתר בהתאם לשיקול דעתה הבלעדי.',
    ],
  },
  {
    title: 'שליטה בפרסומים ואחריות עליהם',

    paragraphs: [
      '"קשר - עסקים ומכרזים" או מי מטעמה לא תישא באחריות לכל תוכן פרסומי או מידע מסחרי אחר שיפורסם באתר. הפרסום באתר אינו משום הצעה ו/או המלצה ו/או חוות דעת או הבעת דעה או עידוד, מצד "קשר – עסקים ומכרזים" לרכוש את המוצר המוצג בפרסום/בפרסומת ו/או לעשות שימוש בשירות או מידע המוצג בפרסום/בפרסומת. האחריות הבלעדית לכל תוכן המידע המפורסם באתר ולעצם פרסומו באתר, חלה על מפרסמי ההודעות/הפרסומים בלבד, שכן תכנים המוצגים באתר אשר מקורם בצדדים שלישיים הינם באחריותם הבלעדית של אותם צדדים שלישיים.',
      '"קשר - עסקים ומכרזים" אינה מתחייבת כי כל הקישורים ודרכי יצירת הקשר שימצאו באתר יהיו תקינים.',
      'כל הסתמכות של משתמש באתר על כל תוכן או על כל מידע המוצגים ומפורסמים באתר ו/או באתרים אליהם קיימת אפשרות הפנייה, לרבות במידע, תוכן, דעות ועמדות המפורסמות שם, נעשית על פי שיקול דעת המשתמש ועל אחריותו הבלעדית.',
    ],
  },
  {
    title: 'פרסום מסחרי ב"קשר - עסקים ומכרזים"',

    paragraphs: [
      '"קשר - עסקים ומכרזים" בלבד רשאית לפרסם במסגרת אתר "קשר - עסקים ומכרזים" מסרים מסחריים, מודעות או פרסומות מסחריות- ישירות ו/או באמצעות צד שלישי. פרסומות ומסרים אלה ישולבו ככל האפשר באופן שהמשתמש יבחין ביניהם לבין תוכן הודעות האתר. שירותי האתר עומדים לרשות המשתמש בין היתר בתנאי שיסכים לצפות בפרסומות ובמסרים מסחריים. מסיבה זאת נאסר עליו להתערב בפרסומות ובמסרים או להסירם.',
    ],
  },
  {
    title: 'שינויים כלליים',

    paragraphs: [
      'ל"קשר - עסקים ומכרזים" או למי מטעמה, שמורה הזכות לשנות את תקנון האתר מעת לעת לפי שיקול דעתה הבלעדי ותפעל ככל האפשר לעדכון המשתמש אודות השינויים בתנאי השימוש, וזאת באמצעות פרסום הודעה ב"קשר - עסקים ומכרזים" שתכלול את נוסח התנאים המתוקנים, או שתפנה אליהם. באחריותם הבלעדית של משתמשי האתר לבדוק ולהתעדכן מעת לעת האם חלו באתר ובתנאיו שינויים, והאם שינויים אלה רלוונטיים לגביהם. אם המשתמש מעוניין להכניס עקב השינוי באתר שינויים בתוכן המידע שמסר לפרסום באתר, באחריותו הבלעדית לבקש זאת בכתב מ"קשר - עסקים ומכרזים" לעשות כן. למשתמש לא תהיה כל טענה, תביעה ו/או דרישה כלפי "קשר - עסקים ומכרזים" ו/או מי מטעמה, בגין ביצוע שינויים כאמור ו/או תקלות שיתרחשו (אם יתרחשו) אגב ביצועם.',
    ],
  },
  {
    title: 'אתרים מקושרים',

    paragraphs: [
      'באתר עשויים להופיע קישורים לאתרים אחרים. אתרים אלה לא נתונים לשליטתו של האתר והוא אינו נושא בשום אחריות לתכנים המופיעים בהם.',
    ],
  },
  {
    title: 'זמינות האתר',

    paragraphs: [
      'האתר אינו מתחייב שהשירות הניתן באתר לא יופרע, ושיינתן כסדרו ללא הפרעות. המשתמש מסכים לכך שהאתר לא יהא אחראי לכל נזק, ישיר או עקיף, שייגרם למשתמש עקב כך.',
    ],
  },
  {
    title: 'הבהרות תקלות במידע ונכונותו',

    paragraphs: [
      'המידע באתר מגיע מגורמים שלישיים שלקשר אין שליטה על פרסומיהם, האתר לא יקח אחריות עבור "שגיאות" שהופיעו במקור.',
      'עם זאת, האתר הינו מערכת דיגיטלית אשר מבצעת פעולות מורכבות ע"מ "לכרות" ולנתח את המידע המוצע, בהבנת הצדדים - יתכנו שגיאות, בגים וקריסות שהינן חלק מתפעול מערכת דיגיטלית, מקרים אלה לא יהוו עילה לזיכוי הלקוח או הפרה כפי שמתפרש מהסכם זה באם נפתרו בפרקי הזמן הבאים:',

    ],
    list: [
      { listTitle: 'תקלה ירוקה(קלה)', listText: ' - פתרון יום עסקים אחד.', },
      { listTitle: 'תקלה אדומה(קשה) ', listText: '- פתרון תוך שלושה ימי עסקים.', },
      { listTitle: 'תקלה חריגה', listText: ' - שפתרונה מעבר ל3 ימים – הלקוח יהיה זכאי לחודש נוסף ללא עלות או בזיכוי עבור התקופת הנוכחית בה התרחשה התקלה ונמנע מן הלקוח שימוש במערכת.', },
      { listTitle: 'כיצד יש לחשב את הזיכוי', listText: ' - עלות חישוב הזיכוי תתבצע ע"י עלות המנוי החודשית חלקי שלושים כפול מספר הימים בהם נמנע השימוש מהלקוח.', },
      { listTitle: '', listText: 'סטטוס תקלה יקבע מרגע גילוי התקלה ע"י מנהל הטכנולוגיה במיזם.', },
    ],
  },
  {
    title: 'זמני הספקה',

    paragraphs: [
      'לתשלום באמצעות מוקד הרישומים של האתר Kesher-business יערך עד כ 60 דקות מבקשת הלקוח ומסירת פרטי התשלום.',
      '',
      'לתשלום באופן דיגיטלי באמצעות רכישת מנוי און-ליין דרך אתר האינטרנט האספקה תהיה מיידית.',
    ],
  },
  {
    title: 'קניין רוחני',

    paragraphs: [
      'כל זכויות הקניין הרוחני בתכנים המופיעים באתר, לרבות עיצוב האתר, תמונות, קבצים גרפיים, יישומים, קוד מחשב, טקסט ו/או כל חומר אחר, שייכות לאתר או לצד שלישי שהתיר לאתר לעשות בו שימוש. אין להעתיק באופן מלא או חלקי, להציג בפומבי, להפיץ, לבצע בפומבי, להעביר לרשות הציבור, לשנות, לעבד או ליצור יצירות נגזרות, למכור או להשכיר כל חלק מהתכנים הנ"ל, בכל אמצעי ומדיה שהיא, בלי הסכמה בכתב ומראש מצד בעלי הזכויות.',
      'חל איסור על כל שימוש בזכויות יוצרים של "קשר - עסקים ומכרזים" ו/או של המפרסמים, בסימני מסחר המופיעים באתר ובלוגו האתר, בין אם באופן ישיר ובין אם באופן עקיף, בין אם בתמורה ובין אם שלא בתמורה, ללא הרשאה מבעלי האתר ו/או מהמפרסמים בעלי הזכויות- בכתב ומראש.',
      '"קשר - עסקים ומכרזים" מעניקה ללקוח בזה, כמשתמש, אישור/רישיון אישי שאינו ניתן להעברה, להשתמש בתוכנה לצורך הזנת פרטיו וכן לתצוגה ולקבלת מידע - וזאת בתנאי שיקפיד על קיום כללי זכויות היוצרים וכל ההתניות האחרות שבתקנון.',
      'המשתמש לא רוכש זכויות כלשהן בתוכנה ו/או בזכויות היוצרים ו/או בזכויות אחרות הטמונות ו/או קשורות בה, למעט זכות השימוש בה, כמפורט בהסכם זה.',
      'המשתמש אינו רשאי לעשות כל שימוש באתר "קשר - עסקים ומכרזים" וביישומיו מעבר לשימוש לצרכים שלו בלבד, וכן אינו רשאי לדרוש שתכנים יפורסמו או שיוסיפו להתפרסם.',
      'החיבור לאתר עם שם המשתמש והסיסמא ינתנו למשתמש לאחר התשלום.',
      'למען הסר ספק- אין להפעיל או לאפשר להפעיל כל יישום מחשב או כל אמצעי אחר, לרבות תוכנות מסוג Crawlers, Robots וכדומה, לשם חיפוש, סריקה, העתקה או אחזור אוטומטי של תכנים מתוך אתר- "קשר - עסקים ומכרזים" ובכלל זה, אין ליצור ואין להשתמש באמצעים כאמור לשם יצירת לקט, אוסף או מאגר שיכילו תכנים מאתר "קשר - עסקים ומכרזים". בכלל זה ומבלי לגרוע מכלליות האמור לעיל- חל איסור קצירה , כך שהמשתמש, אינו רשאי להפעיל מערכות לקצירת כתובות דואר אלקטרוני מעמודי האתר, ולא ישתמש באתר על מנת לאסוף מידע על כל אדם אחר..',
      'מודגש כי חדירה למערכת האתר מהווה עבירה פלילית על פי הדין החל בישראל.',
      'כל שימוש הפוגע בזכויות היוצרים ו/או בקניין רוחני כמפורט לעיל, ישמש עילה לסגירת חשבון המשתמש ללא כל הודעה מוקדמת, והמשתמש יישא בכל ההוצאות אשר יגרמו ל"קשר - עסקים ומכרזים" ו/או ללקוחות "קשר - עסקים ומכרזים" ו/או למשתמש עצמו בגין שימוש זה, ו/או בגין סגירת חשבון המשתמש, וזאת - מבלי לגרוע מכל סעד אחר המגיע ל"קשר - עסקים ומכרזים" על פי ההסכם ועל פי הדין.',
    ],
  },
  {
    title: 'שימושים אסורים',

    paragraphs: [
      'המשתמש מסכים שלא לעשות באתר כל שימוש שאינו חוקי וכן כל שימוש בניגוד לתנאים שלהלן, ובכלל זאת שימוש שעלול להביא לפגיעה או השבתה של האתר או לפגיעה בחוויית המשתמש של משתמשים אחרים באתר. כמו כן, המשתמש מסכים שלא להשיג או לנסות להשיג מידע או חומר הכלול באתר בכל אמצעי אחר מלבד האמצעים שמספק האתר למשתמשיו, וכן שלא לאסוף כל מידע על משתמשים אחרים ללא הסכמתם. המשתמש אינו רשאי להמחות, להעניק ברישיון משנה או להעביר בדרך אחרת או בכל אופן שהוא, זכות כלשהי מזכויותיו או מהתחייבויותיו על פי הסכם זה ביוזמתו, אלא באישור מראש ובכתב של "קשר - עסקים ומכרזים".',
    ],
  },
  {
    title: 'הגבלת אחריות',

    paragraphs: [
      '"קשר - עסקים ומכרזים" משקיעה מאמצים רבים על מנת לספק למשתמשי האתר תוכן אמין ומדויק. עם זאת, יש לציין כי ייתכן שבתהליך עיבוד ופרסום תכני האתר תיפולנה טעויות. האתר אינו אחראי לשום נזק שייגרם כתוצאה מהסתמכות על תוכן זה וכל הסתמכות עליו הינה על אחריות המשתמש בלבד. המשתמש מוותר על כל טענה או תביעה כלפי האתר הנובעת משימוש בתוכן האתר.',
      'תוצאות שירותי החיפוש של האתר מסופקים ומוצגים כמו שהם (As is) כפי שנתקבלו מצדדים שלישיים, ללא אחריות כלשהי של "קשר - עסקים ומכרזים" או מי מטעמה. "קשר - עסקים ומכרזים" או מי מטעמה אינה אחראית לאי התאמות כלשהם בנתונים המתקבלים מהצדדים השלישיים ו/או להפרה של זכות קניין הנובעת מנתונים שמקורם בצדדים שלישיים. בהצגתם של הנתונים אין משום המלצה ו/או הבעת דעה ו/או חוות דעת ביחס לאתר מכירות של צד שלישי כלשהו.',
      '"קשר - עסקים ומכרזים" או מי מטעמה לא תהא אחראית לכל נזק, אבדן, הפסד או הוצאה שיגרמו למשתמש ו/או לצד שלישי כלשהו בקשר עם כל מעשה ו/או מחדל שמקורו בצד שלישי כלשהו. כמו כן ומבלי לגרוע באמור, "קשר - עסקים ומכרזים" או מי מטעמה לא תהא אחראית לטיב ו/או לאיכות המוצרים והשירותים הניתנים והמוצעים למשתמש באתר על ידי צד שלישי, לרבות לא לתנאי הרכישה, להליכי הרכישה ו/או לאספקתם, לאיכותם ו/או לזמינותם של המוצרים ו/או השירותים כאמור.',
    ],
  },
  {
    title: 'דיוור ישיר אלקטרוני',

    paragraphs: [
      'ייתכן כי בכוונת מפעילת האתר לשלוח אליך מזמן לזמן בדואר אלקטרוני מידע בדבר שירותיה וכן מידע שיווקי ופרסומי.',
    ],
    list: [
      { listTitle: '', listText: 'המשתמש מצהיר בזאת כי הוא מודע ומסכים לכך שעם הרשמתו לאתר יישלח אליו חומר שיווקי, ובכל עת יוכל לבטל את הסכמתך ולחדול מקבלתו .', },
      { listTitle: '', listText: 'המשתמש מסכים בזאת ומאשר כי לא תהיה לו כל תביעה או טענה כלפי האתר בגין שימוש בפרטיו בהתאם לאמור לעיל.', },
    ],
  },
  {
    title: 'ביטול עסקה',

    paragraphs: [
      'השימוש בחלק משירותי האתר ותכניו כרוך בתשלום, בהתאם לשיקול דעתה של הנהלת האתר. משך השימוש בשירות הינו לתקופה קצובה בהתאם לחבילה אשר רכש המשתמש ו/או בהתאם לחבילה אשר הותאמה לו על ידי צוות האתר. רכישת המינוי יכולה להיעשות על ידי טופס רישום לאתר Kesher-business או על ידי פניה צוות האתר כמפורט מטה. שם המשתמש באתר הינו אישי ולא ניתן להעברה.',
      'התשלום בעבור המנוי יהיה באמצעות כרטיס אשראי. פעולת החיוב באמצעות כרטיס אשראי מתבצעת באופן מיידי לאחר השלמת רכישת המינוי.',
      'כאשר לקוח נרשם באמצעות כרטיס חיוב מידי (דירקט, דיינרס, ישראCash, וכו\') החיוב יבוצע מידית.במידה והלקוח ביטל את המנוי לפני תום תקופת הניסיון, כספו יוחזר לו ע"י חברת האשראי לפי מדיניות ההחזרים של חברת האשראי. יש לשים לב שבמידה והביטול הוא לאחר תום תקופת הניסיון, הלקוח לא זכאי להחזר.',
      'ברכישת מנוי, אתר "קשר - עסקים ומכרזים" מאפשר למשתמשיו גישה מקצועית למאגרי מידע המכילים את פירוט של המכרזים במשק, לרבות מכרזי ממשלה, מכרזים של מוסדות פרטיים, מכרזים של נכסים וכדומה. Kesher-business  הוא אתר מקצועי ומהימן, המידע בו מתעדכן על בסיס יומי, וכך מאפשר למשתמשיו להגיש הצעות למכרזים השונים בזמן אמת.',
      'כאמור לעיל, "קשר - עסקים ומכרזים" מעניקה ללקוח בזה, כמשתמש, אישור/רישיון אישי שאינו ניתן להעברה, להשתמש בתוכנה לצורך הזנת פרטיו וכן לתצוגה ולקבלת מידע, וזאת בתנאי שיקפיד על קיום כל זכויות היוצרים וכל ההתניות האחרות שבתקנון.',
      'בשל אופי אתר "קשר - עסקים ומכרזים" ותכניו, ובהתאם לחריג בחוק הגנת הצרכן, התשמ"א 98 לפיו לא תעמוד לצרכן זכות ביטול בטובין הניתנים להקלטה, לשעתוק או לשכפול, כל רכישת מנוי הינה סופית והמנוי אינו ניתן לביטול מרגע הינתן הגישה לתכני האתר.',
      'בהתאם לכך, בעת רכישת מנוי באתר המשתמש מסכים ומאשר בזאת כי הוא מודע לכך שהמנוי אינו ניתן לביטול ולא עומדת לו כל זכות לקבלת החזר כספי. כמו כן, המשתמש מסכים ומאשר כי הוא מודע לכך שלא תעמוד לו זכות להחזר כספי במידה ולא ניצל את יתרת זכותו במינוי. כמו כן, לא ניתן להשהות את מימוש המינוי.',
      'בבחירת מנוי לתקופה מתחדשת, המשתמש מסכים ומאשר בזאת כי בגמר תקופת המנוי הראשוני, הנהלת Kesher-business תהא רשאית לחדש את תשלום המינוי באופן אוטומטי מידי חודש בחודשו, לפי המחיר המובטח בעת רכישת המינוי החודשי המתחדש.',
      'במקרים חריגים בלבד, צוות האתר שומר לעצמו את הזכות להפסיק מנוי טרם תום תקופת סיומו, זאת בהתאם לשיקול דעתו הבלעדית.',
      'מקרים חריגים שיבדקו בנפרד- באם לקוח סיים תקופת מנוי חינמית (תחת מבצע או הטבה) והיה מעוניין להפסיק את המנוי אך לא ביצע "ביטול מנוי" בתקופת הזמן החינמית ונאלץ לשלם תשלום ראשון עקב טעות זו, ובמסגרת התקופה שבין התשלום הראשון לשני ביקש לסיים את המנוי בטענה זו, באישור הנהלה הלקוח יזוכה.',
      'על אף האמור לעיל, מקום בו רכש המשתמש מנוי במבצע, וקיבל את התמורה הייחודית שבמבצע, בין כהטבה בדמי המנוי ובין באופן אחר, לא יינתן כל החזר כספי על אף ביטול העסקה.',
      'סיום מנוי ביוזמת הלקוח טרם תום תקופתו – על-פי חוק הגנת הצרכן, התשמ"א-98, לא ניתן לבטל עסקת מכר מרחוק של טובין הניתנים להקלטה, לשעתוק או לשכפול, ומשכך לא ניתן לבטל עסקת רכישה של מנוי באתר, מכל סיבה שהיא, עד לתום תקופת המנוי שנרכשה. צפייה בתוכן כלשהו שבאתר למשך חמש דקות או יותר, תהא חזקה לשימוש באתר, ורישום במערכת "קשר - עסקים ומכרזים" יהווה ראיה חלוטה לעניין זה, אלא אם יוכיח הלקוח, ואתר "קשר - עסקים ומכרזים" תאשר, כי הצפייה בפריט התוכן לא התאפשרה כלל (דהיינו, לאחר לפחות חמישה ניסיונות בזמנים שונים) בשל סיבות הקשורות עם "קשר – עסקים ומכרזים" בלבד ולאחר שהלקוח התריע על כך בכתב לחברת "קשר - עסקים ומכרזים" ואיפשר לה לתקן את התקלה. יובהר, כי באחריות המנוי לבדוק את אופי התכנים ואיכותם טרם התקשרותו עם "קשר - עסקים ומכרזים" (באמצעות התכנים המוצעים לצפייה באתר בטרם רכישת המנוי), ולא תישמע טענת ביטול בגין אופי ו/או איכות התכנים, ובכל זאת לפנים משורת הדין חברת "קשר - עסקים ומכרזים" תאפשר ללקוח שרוצה לבטל באותו היום לשלם עבור מנוי ליום אחד. ',
      'וכעת יפורטו הלוחות והמחירים ליום שימוש :',
      'לוח מכרזים 100 ש״ח ליום שימוש אחד',
    ],
  },
  {
    title: 'הבהרות לעניין מנויים משלמים ("לקוחות פעילים") -',

    paragraphs: [
      'כפי שצוין לעיל - השימוש בשירותים המוצעים באתר זה, חלקם או כולם, כרוך בתשלום מסוגים שונים ובתעריפים שונים, שיקבעו על פי שיקול דעתה של המפעילה, ויפורסמו באתר מעת לעת. מבלי לגרוע מכלליות האמור:',
      'לקוחות בשירות "סוכן חכם" - יחוייבו בדמי השירות החודשיים אשר ייגבו מראש מדי חודש בחודשו, בהתאם לאמצעי התשלום הנבחר, כל עוד לא בוטל השירות, הופסק,  או הוקפא כאמור ותשלומם מאפשר גישה למאגר המכרזים בפרופיל העסקי שלהם.',
    ],
  },
  {
    title: 'ביטול, הפסקה או הקפאה:',

    paragraphs: [
      'לקוח בשירות "סוכן חכם" רשאי להודיע בכל עת בה יחפוץ על ביטול השירות לאתר, באמצעות אחת הדרכים הבאות: בפניה למוקד שירות הלקוחות של המפעילה בטלפון 055-5528310; באמצעות משלוח דואר אלקטרוני לכתובת',
      ' info@kesher-business.co.il  - ',
      ' באתר (באמצעות לחיצה על כפתור- "ביטול מנוי", המופיע בעמוד הגדרות הפרופיל. מרגע אישור האתר לבקשת הביטול - המפעילה תחדל לחייב את לקוח בדמי השירות החודשיים לאתר , לפי העניין, בתוך שלושה ימי עסקים מיום מסירת הודעת הביטול, ובמידה והודעת הביטול נמסרה בדואר רשום - בתוך שישה ימי עסקים מיום מסירת הודעת הביטול למשלוח ("הביטול"). אם בוטל השירות הרלוונטי במהלך החודש שעבורו שולמו דמי השירות, יינתן ללקוח  החזר יחסי של דמי המנוי, בגין הימים שממועד הביטול בפועל ועד לתום התקופה המשולמת (להלן: "תקופת ההחזר"), בתוך 14 ימים מיום מסירת הודעת הביטול, בניכוי דמי ביטול. דמי הביטול יהיו בגובה 50% ממחיר דמי המנוי בגין תקופת ההחזר. לא יינתן החזר כספי בגין התקופה בה נעשה שימוש בשירותים המוצעים באתר בתשלום ואשר קדמה למועד הביטול.',
      'לקוח  רשאי להודיע בכל עת בה יחפוץ על רצונו להפסיק את השירות, כך שהוא יוכל להמשיך ולעשות שימוש בשירותים עד תום אותו חודש קלנדרי ומהחודש העוקב לא יחויב בגין השירות, וזאת באמצעות פנייה לעמוד "ההגדרות שלי" או באמצעות פניה למוקד שירות הלקוחות בטלפון 055-5528310.',
      'לקוח  רשאי להודיע בכל עת בה יחפוץ על רצונו להקפיא את השירות, באמצעות פניה למוקד שירות הלקוחות בטלפון 055-5528310. במקרה של הקפאה, יישמרו ללקוח  מספר הימים הנותרים ממועד הודעת ההקפאה ועד לתום חודש השירות אשר שולם, לשימוש עתידי, בכל עת. הפשרת ימים מוקפאים תתאפשר רק ברצף אחד ובאופן חד פעמי. לא ניתן להקפיא שנית ימים שהופשרו.',
      'השירות כרוך בתשלום, כפי שיקבע על ידי המפעילה מעת לעת.',
      'המפעילה רשאית להעניק ללקוחותיה הטבות לתקופה מוגבלת בזמן במהלכן יקבלו הלקוחות עדכון על משרות באמצעות מסרונים שיתקבלו במכשירם, אך זאת ללא תשלום או בעלות מופחתת מעלות השירות בדרך כלל, הכל בהתאם לשיקול דעתה הבלעדי של מפעילת השירות.',
    ],
  },
  {
    title: 'פרשנות לתנאי השימוש',

    paragraphs: [
      'המשתמש מסכים כי אין לפרש את תנאי השימוש האלו באופן מצמצם כנגד האתר, וזאת לנוכח המדיום המשתנה במהירות של האינטרנט . כל זכות שלא הוקנתה במפורש לגולש שמורה לאתר. כל ויתור, דחייה, ארכה או הקלה מצד האתר כלפי המשתמש לא יהוו תקדים, לא יפעלו לחובת האתר, ולא ייחשבו כוויתור על זכויותיו על פי תנאי השימוש, למעט במקרה של ויתור מפורש בכתב, על הוראות תנאי השימוש.',
    ],
  },
  {
    title: 'שיפוי',

    paragraphs: [
      'המשתמש באתר מתחייב לשפות את האתר, בעליו, מנהליו או מי מטעמו בגין כל נזק, אובדן-רווח או הוצאה שייגרמו להם עקב הפרת תנאי שימוש אלה על ידו, לרבות הוצאות משפטיות. אין בשיפוי כדי לגרוע מכל סעד שהאתר זכאי לו על פי דין. המשתמש יפצה וישפה את "קשר- עסקים מכרזים" ו/או את ספקי המידע שלה, נושאי המשרה שלה, מנהליה, שליחיה/סוכניה, מורשיה, עמיתיה, שותפיה האחרים, עובדיה ונציגיה, בגין כל טענה, תביעה ו/או דרישה ו/או נזק ו/או הפסד, אובדן רווח, תשלום או הוצאה, עלויות וחבויות, לרבות תשלומי ריבית ותשלום שכר טרחה סביר לעורכי דין והוצאות משפט , אשר יגרמו ל"קשר - עסקים ומכרזים" ו/או למי מטעמה על ידי המשתמש כתוצאה מכך שהמשתמש לא יקיים הוראות תקנון זה ו/או יפר הוראות דין כלשהן ו/או זכויות צד ג\' כלשהן, ו / או כתוצאה מפרטים, מידע או קבצים שהמשתמש מסר לפרסום, ו / או כתוצאה ממחדליו של המשתמש, כפי שבאו לידי ביטוי באופן ישיר ו / או באופן עקיף.',
      'המשתמש נותן בזאת את הסכמתו כי התחייבותו זו לשיפוי תחול בין אם "קשר - עסקים ומכרזים" או מי מטעמה הינו צד רשמי לתובענה משפטית ובין אם לא. מבלי לפגוע בכלליות האמור לעיל, ישלם המשתמש את סכומי הנזק הנובעים מאחריות, כאמור לעיל, מיד עם הידרשו לכך בכתב על ידי "קשר - עסקים ומכרזים" דרישת תשלום כנ"ל של "קשר - עסקים ומכרזים" תשמש כראיה מכרעת שאין אחריה ולא כלום לגבי הצורך בשיפויה של "קשר - עסקים ומכרזים" בגין הנזק שנגרם לה באותו עניין.',
      'המשתמש יהיה מנוע מלהעלות כל טענה ו/או דרישה כלפי ""קשר - עסקים ומכרזים" ו/או מי מטעמה, בכל הקשור לגבולות האחריות של המשתמש לנזק כאמור.',
      '',
      'על התקנון יחולו דיני מדינת ישראל',
    ],
  },
  {
    title: 'ישוב חילוקי דעות, דין ומקום השיפוט',

    paragraphs: [
      'הצדדים מסכימים לנסות וליישב כל מחלוקת שתתגלה ביניהם לרבות מחלוקת בקשר עם השימוש באתר "קשר - עסקים ומכרזים", ו/או בקשר עם תנאי שימוש אלה, וכן לרבות בקשר עם השירותים המסופקים על ידי "קשר - עסקים ומכרזים", בדרך של משא ומתן ישיר, ואם לא יעלה בידם ליישב את חלוקי הדעות בדרך זו, יפנו לבורר, אשר יהיה עו"ד הבקיא בתחום הסחר האלקטרוני והאינטרנט, ושזהותו תיקבע על ידי יו"ר לשכת עורכי הדין בישראל לבקשת מי מהצדדים.',
      'עובר לתחילת הבוררות ימסור הבורר לצדדים את כל המידע הרלוונטי ביחס לקשריו הישירים והעקיפים למי מן הצדדים ו/או לבעלי העניין בצדדים ו/או לגופים קשורים של הצדדים, ככל שישנם, ולאחר שישמע את השגות הצדדים בנוגע לקשרים אלה ויכולתו להכריע באובייקטיביות ביחס לנושא הבוררות, יהיה הבורר בעל סמכות ההכרעה בשאלה האם להמשיך לכהן כבורר אם לאו.',
      'הבורר יהיה רשאי לתת סעדים זמניים וצווי ביניים. מקום קיום הבוררות יהיה בעיר באר שבע. הבורר לא יהיה כבול לסדרי הדין האזרחיים, אך יהיה כבול לדיני הראיות ולדין המהותי. הבורר יהיה חייב בנימוק החלטתו.',
      'סעיף זה מהווה הסכם בוררות בין הצדדים לכל דבר ועניין. בכפוף ומבלי לגרוע מהאמור, בכל עניין שאינו בגדר סמכות הבורר, או בכל עניין שבו לא תהיה תחולה להסכם בוררות זה, מכל סיבה שהיא, תהיה סמכות השיפוט הבלעדית בכל הקשור לתקפותו, פרשנותו ו/או הפרתו של הסכם זה נתונה לבית-המשפט המוסמך בבאר שבע בלבד, בהתאם להוראות חוק הבוררות, תשכ"ח-968.',
      'המשתמש מסכים במפורש לסמכות השיפוט של בית המשפט כאמור. ומוותר בזה על כל זכות להתנגד למקום השיפוט ו/או לסמכות השיפוט בטענה של אי-נוחות מקום השיפוט, פורום בלתי נאות, או מכל סיבה שהיא.',
      'היה ו"קשר - עסקים ומכרזים" תתבע על ידי צד שלישי, בכל הליך משפטי שהוא, הנובע מכל מעשה או מחדל של המשתמש, היא תהיה רשאית לצרף את המשתמש כצד לאותו הליך שיפוטי, ובמקרה זה לא תהיה תחולה להסכם הבוררות הנ"ל. רישומי המחשב של החברה בדבר הפעולות המתבצעות דרך אתר "קשר - עסקים ומכרזים" יהוו ראיה לנכונות הפעולות.',
      'השימוש באתר אינטרנט זה אסור באזורי שיפוט שאינם מקנים תוקף לכל האמור בתנאים ובהתניות הכלולים במסמך זה.',
      'קבילות בהליכים משפטיים',
      'גרסה מודפסת של תקנון זה ושל כל הודעה שהועברה במתכונת אלקטרונית יהוו ראיה קבילה בהליכים משפטיים או מנהליים, המבוססים או הקשורים לתקנון זה במידה שווה ובאותם תנאים החלים על שאר הרשומות והמסמכים העסקיים, שהופקו ונשמרו במקור במתכונת מודפסת.',
    ],
  },
  {
    title: 'התיישנות',

    paragraphs: [
      'על פי האמור בסעיף 9 לחוק ההתיישנות, התשי"ח-958, וכחוזה נפרד בכתב, הגולש מסכים בזאת על תקופת התיישנות מוסכמת של 6 חודשים ממועד ביצוע הפרסום נשוא פנייתו לאתר, כמועד סופי שבו יוכל הגולש לפנות/להפנות אל האתר בכל טענה ו/או דרישה ו/או תביעה. במקרה של פרסום חוזר, יתחיל מועד תקופת ההתיישנות ממועד הפרסום המקורי. הגולש מתחייב שלא להפנות אל האתר כל טענה ו/או דרישה ו/או תביעה לאחר תקופת ההתיישנות המוסכמת. פעולה של המשתמש בניגוד להתחייבותו ו/או התייחסות של האתר לפעולתו של המשתמש, לא יהא בהם כדי לגרוע מטענת ההתיישנות של האתר. המשתמש מסכים כי הוראת סעיף זה תגבר על כל הוראת דין אחרת.',
      'כתובות הצדדים בכל הנוגע להסכם זה הן כמצוין בטופס הזמנת השירותים המקוון/הידני ו/או ב"הסכם ההתקשרות"- (במקרים בהם נחתם). כל הודעה שתישלח לפי כתובות אלה ב\'דואר רשום\' תחשב כאילו התקבלה על ידי הצד השני כעבור שבוע מעת שנשלחה.',
      'הדין החל וסמכות שיפוט התיישנות ותנאים נוספים',
      'על הסכם זה יחולו אך ורק דיני מדינת ישראל. מקום השיפוט הבלעדי לכל עניין הנוגע להסכם זה הינו בבתי המשפט המוסמכים באזור באר שבע. הגולש מסכים לכך כי לא תהא תחולה להוראות ו/או לכללי ברירת דין בינלאומיים, ולפיכך לא יתקיים הליך משפטי מחוץ לישראל.',
      'תקנות אבטחה לדף התשלום מאובטחת ברמה Pci-Dss Level .',
    ],
  },
  {
    title: 'COOKIES',

    paragraphs: [
      'המשתמש מצהיר כי הוא יודע שמפעילת האתר משתמשת ב"עוגיות" (Cookies) לצורך תפעולו השוטף והתקין, ובכלל זה כדי לאסוף נתונים סטטיסטיים אודות השימוש באתר, לאימות פרטים, כדי להתאים את האתר להעדפותיו האישיות ולצורכי אבטחת מידע.',
      '',

    ],
    list: [
      { listTitle: '', listText: 'יש באפשרות המשתמש להימנע מקבלת Cookies בדפדפן אשר בו אתה משתמש, אם המשתמש אינו יודע כיצד לעשות זאת, עליו לבדוק בקובץ העזרה של הדפדפן.', },
    ],
  },
  {
    title: 'מדיניות שמירה על פרטיות',

    paragraphs: [
      'האתר מציע אפשרות לקבלת פרטים ועדכונים אודות המוצרים והשירותים המוצגים בו, וכן לקבלת תכנים שיווקיים אחרים מטעמו, וזאת באמצעות רשימת תפוצה. ההרשמה לרשימת התפוצה מתבצעת באמצעות מסירת שם וכתובת הדואר האלקטרוני של המשתמש. מסירת מידע כנ"ל על ידי המשתמש נעשית מרצונו החופשי ועל פי בחירתו, וזאת כדי להשתמש בשירותיו של האתר. מובהר כי על המשתמש למסור פרטים אמיתיים, וכי פעולות התחזות הינן אסורות על פי הדין, וייתכן שיש בפעולות אלה אף עבירות פליליות. מובהר כי בעת השימוש באתר, ייאסף מידע אודות הדפים בהם ביקר המשתמש, החיפושים אשר ערך באתר ומשך זמן שהייתו באתר.',
      'האתר רשאי לעשות שימוש בשליחה של קובץ זיהוי אל הגולש, באופן שיאפשר לזהות את המחשב ממנו הגולש עושה שימוש (Cookie) וכן להשתמש במידע המופיע בקובץ זה. המשתמש יוכל להימנע מקבלת קבצים אלה באמצעות שינוי הגדרות הדפדפן, אך חסימתם עלולה לגרום לכך שחלק מתכני האתר לא יהיו זמינים עבור אותו משתמש.',
      'אתר "קשר - עסקים ומכרזים" פועל עפ"י חוק הגנת הפרטיות התשמ"א .98',
    ],
  },
  {
    title: 'שמירת המידע והשימוש בו',

    paragraphs: [
      'האתר רשאי לשמור במאגריו מידע זה, בין היתר לשם ביצוע פעולות אלו: סטטיסטיקה, פרסום, שיווק וקידום מכירות, מתן שירותי דיוור וביצוע כל שימוש חוקי במידע בהתאם לכל דין. האתר רשאי לשלוח אל המשתמש דואר אלקטרוני הכולל גם מידע שיווקי ופרסומי, בכפוף להסכמת המשתמש. המשתמש מסכים בזאת ומאשר כי לא תהיה לו כל תביעה או טענה כלפי האתר בגין שימוש בפרטיו בהתאם לאמור לעיל.',
    ],
  },
  {
    title: 'אבטחת פרטי המשתמשים',

    paragraphs: [
      'האתר מכבד את פרטיות המשתמשים, ומפעיל מערכות ייעודיות לשם אבטחת המידע שנמסר על ידם ובכלל זאת לשמירה על סודיות המידע הנוגע לאמצעי התשלום. האתר מתחייב לא לעשות שימוש בפרטי המשתמשים אלא לפי תנאי שימוש אלה. למרות מאמצי אבטחת המידע אין ודאות מוחלטת כי המידע לא ייחשף במקרה של תקלה, פריצה למערכות האתר, או לשרתי האתר, או כל מקרה שאינו בשליטת האתר ו/או נובע מכוח עליון.',
      'המשתמש מסכים כי האתר לא יישא בשום אחריות לחשיפת המידע בכל מקרה של פריצה למערכות האתר או לשרתיו. האתר לא יהא אחראי לכל נזק מכל סוג שהוא, עקיף או ישיר שייגרם ללקוח או למי מטעמו, אם מידע זה יאבד או יגיע לצד שלישי ו/או יעשה בו שימוש שלא בהרשאה.',
      'העברת המידע לצדדים שלישיים',
      'מנהלי האתר לא יעבירו לצדדים שלישיים את המידע שנמסר על ידי המשתמש אלא במקרים הבאים:',
      'במקרה שיתקבל צו שיפוטי המורה למנהלי האתר למסור את המידע לצד שלישי.',
      'במקרה של כל טענה, תביעה, דרישה או הליכים משפטיים, אם יהיו, בין המשתמש לבין אתר "קשר - עסקים ומכרזים" או מי מטעמו.',
      'במקרה שהמשתמש הפר את תנאי השימוש באתר.',
      'הסרת המידע ממאגר האתר',
      'אם יבקש המשתמש להפסיק לקבל עדכונים מהאתר, יוכל, בכל עת, לבטל את הסכמתו לכך על ידי פנייה לאתר.',
      '"קשר - עסקים ומכרזים" אינה נושאת כל אחריות לגבי תקינות הקישורים, מהימנות התמונות ו/או המידע המוצג בהן. כמו כן, מטבע הדברים, הסובייקטים בתמונות עשויים להשתנות בתדירות גבוהה ו"קשר- עסקים ומכרזים" אינה אחראית לכל טענה בדבר עדכון בזמן אמת.',
      'כאמור לעיל, האחריות הבלעדית לכל תוכן המידע המפורסם באתר ולעצם פרסומו באתר, חלה על מפרסמי ההודעות/הפרסומים בלבד ו/או על צדדים שלישיים. כל הסתמכות של משתמש באתר על כל תוכן או על כל מידע המוצגים ומפורסמים באתר ו/או באתרים אליהם קיימת אפשרות הפנייה, לרבות במידע, תוכן, דעות ועמדות המפורסמות שם, נעשית על פי שיקול דעת המשתמש ועל אחריותו הבלעדית.',
    ],
  },
  {
    title: 'יצירת קשר',

    paragraphs: [
      'אנו מזמינים את המשתמש לפנות אלינו לכל בירור אודות האתר או תנאי השימוש בו, תוך ציון הפרטים המלאים, ומבטיחים לטפל בפניות אלה בהקדם. ניתן ליצור עמנו קשר באחת מהדרכים הבאות: בשעות הפעילות בימים א-ה: 9:00-8:00, ו- 9:00-3:00 בטלפון: 055-5528310 או באמצעות טופס יצירת קשר/צ\'ט באתר',
    ],
  },
];

const RegularP = ({ spaceBetweenP, paragraph, sectionNum, paragraphNum }) => (
  <Typography
    sx={{
      textAlign: "start",
      fontSize: { lg: "20px", xs: "18px" },
      textDecoration:
        sectionNum === 12 && paragraphNum === 7 ? "underline" : "",
      fontWeight: sectionNum === 24 || sectionNum === 25 ? "600" : "400",
      "&:not(:last-of-type)": {
        mb: spaceBetweenP,
      },
    }}
  >
    {paragraph}
  </Typography>
);

export default function Policy({ openModal })
{
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () =>
  {
    // setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  const calcSpace = (sectionNum, paragraphNum) =>
  {
    return "0px";
    // switch (sectionNum)
    // {
    //   case 10:
    //     if (paragraphNum === 1) return "0px";
    //     else return "10px";

    //   case 11:
    //     return "0px";

    //   case 12:
    //     if (paragraphNum === 1 || paragraphNum === 4) return "0px";
    //     else return "20px";

    //   case 20:
    //     if (paragraphNum === 1 || paragraphNum === 9) return "0px";
    //     else return "20px";

    //   case 21:
    //     if (paragraphNum === 1 || paragraphNum === 3) return "0px";
    //     else return "20px";

    //   case 22:
    //     if (paragraphNum === 2) return "0px";
    //     else return "20px";

    //   case 25:
    //     if (
    //       paragraphNum === 1 ||
    //       paragraphNum === 2 ||
    //       paragraphNum === 3 ||
    //       paragraphNum === 4 ||
    //       paragraphNum === 5
    //     )
    //       return "0px";
    //     else return "20px";

    //   default:
    //     return "20px";
    //     break;
    // }
  };

  return (
    <Box>
      <Header openModal={openModal} />
      <Box
        component="main"
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: {
            xl: "1395px",
            lg: "1200px",
            md: "890x",
            sm: "590px",
            xs: "333px",
          },
          marginInline: "auto",
          direction: "rtl",
          position: "relative",
          "& .MuiTypography-root": {
            unicodeBidi: "plaintext",
          },
        }}
      >
        <Typography
          sx={{
            mb: { md: "57px", xs: "23px" },
            mt: { md: "41px", xs: "25px" },
            textAlign: { md: "start", xs: "center" },
            fontSize: { lg: "48px", md: "30px", xs: "24px" },
            fontWeight: "600",
          }}
        >
          מדיניות ותנאי שימוש
        </Typography>

        <Typography
          sx={{
            mb: { md: "10px", xs: "20px" },
            textAlign: "start",
            fontSize: { lg: "20px", xs: "18px" },
          }}
        >
          תנאי שימוש ומדיניות פרטיות – קשר – עסקים ומכרזים
        </Typography>


        {items.map((item, index) =>
        {
          const { title, paragraphs, list } = item;
          const sectionNum = index + 1;

          return (
            <Box key={title} sx={{ mb: "45px" }}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  mb: "5px",
                  textAlign: "start",
                  fontSize: { lg: "20px", xs: "18px" },
                }}
              >
                {title}
              </Typography>
              {paragraphs.map((paragraph, index) =>
              {
                const paragraphNum = index + 1;
                let spaceBetweenP = calcSpace(sectionNum, paragraphNum);

                return (
                  <RegularP
                    key={paragraph}
                    paragraph={paragraph}
                    paragraphNum={paragraphNum}
                    spaceBetweenP={spaceBetweenP}
                    sectionNum={sectionNum}
                  />
                );
              })}

              {list && (
                <List sx={{ listStyle: "disc", paddingInlineStart: "40px" }}>
                  {list.map(({ listText, listTitle }, index) =>
                  {
                    return (
                      <ListItem
                        key={listTitle||index}
                        sx={{
                          display: "list-item",
                          paddingInlineStart: "0px",
                          paddingBlock: "5px",
                          position: "relative",
                          right:  "0px" ,
                          mt:  "0px",
                        }}
                      >
                          <Box
                            sx={{
                              textAlign: "start",
                              "& .MuiTypography-root": {
                                fontSize: {
                                  lg: "20px",
                                  md: "18px",
                                  xs: "18px",
                                },
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                display: "inline",
                                textDecoration: "underline",
                              }}
                            >
                              {listTitle}
                            </Typography>
                            <Typography sx={{ display: "inline" }}>
                              {listText}
                            </Typography>
                          </Box>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Box>
          );
        })}

        
      </Box>
      <Footer openModal={openModal} />
    </Box>
  );
}
