import axios from "axios";
import { userService } from "./userService.service";
import { storageService } from "./storageService.service";
import { AppwideValues, LOCALSTORAGE_KEYS } from "../env";
import { getMakePath } from "../util/actions";
import { ReactiveValue } from "../util/store";

const URL = AppwideValues.serviceURL;



let offers = null;
let lastFetch = null;
let prevFetch = null;
let serviceState = "empty";
function getServiceState()
{
  return serviceState;
}

async function loadAllOffers()
{
  try
  {
    const validity = Date.now() - 1000 * 60 * 60 * 1; //one hour
    if (offers != null && new Date(lastFetch).valueOf() > validity)
    {
      return { offers };
    }
    const data = storageService.load(LOCALSTORAGE_KEYS.offers);

    if (data != null)
    {
      lastFetch = data.lastFetch;
      prevFetch = data.prevFetch;
    }
    if (data != null && new Date(data.lastFetch).valueOf() > validity)
    {
      offers = data.list;
      serviceState = "loaded";
    }
    else
    {
      serviceState = "loading";
      const jwtToken = userService.getToken();

      const now = new Date();
      const res = await axios.get(URL + "offer/offers", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      console.log("res-aidService-aids", res.data);
      const list = await _createAids(res.data);
      list.reverse();
      // list.sort((b, a) => 0 +
      //     a.lastDateToSubmit > now ? 1 : 0 -
      //         b.lastDateToSubmit > now ? 1 : 0)
      const relevant = [],
        irelevant = [];
      for (const o of list)
      {
        if (new Date(o.lastDateToSubmit) > now)
        {
          relevant.push(o);
        }
        else
        {
          irelevant.push(o);
        }
      }
      let sortedlist = [...relevant, ...irelevant];
      prevFetch = lastFetch;
      lastFetch = now;
      if (data?.list != null)
      {
        const oldOffers = new Set(data.list.map((x) => x?.id));
        const previous = prevFetch; //?.toISOString();
        sortedlist = sortedlist.map((x) =>
        {
          if (
            !oldOffers.has(x.id) ||
            (x.createdAt && x.createdAt > previous) ||
            (x.postedDate && x.postedDate > previous)
          )
          {
            x.isNew = true;
          }
          else
          {
            x.isNew = false;
          }
          if (
            (x.updatedAt && x.updatedAt > previous) ||
            (x.extraInfo?.updatedDate && x.extraInfo.updatedDate > previous)
          )
          {
            x.isUpdated = true;
          }
          else
          {
            x.isUpdated = false;
          }
          return x;
        });
      }
      const aids = { lastFetch, prevFetch, list: sortedlist };
      // console.log("aids", aids);

      // console.log("res-aidService-aids", aids);
      storageService.store(LOCALSTORAGE_KEYS.offers, aids);
      offers = sortedlist;
      serviceState = "loaded";
    }
  } catch (err)
  {
    console.log(err);
    serviceState = "empty";
    lastFetch = null;
    prevFetch = null;
    storageService.remove(LOCALSTORAGE_KEYS.offers);
    return { offers, error: err.message };
  }
  return { offers };
}
const UserOffer = {
  /** @type ReactiveValue<{ [k: string]: Offer }>  */
  favoriteOffers: {},
  /** @type ReactiveValue<{tasks:Array<Task>,taskIdToTask:Map<string,Task>,taskToOfferId:Map<Task,string>,taskIdToOfferId:Record<string,string>}> */
  allTasksOffers: { value: { tasks: [], taskIdToTask: new Map(), taskToOfferId: new Map(), taskIdToOfferId: new Map() } },
  RequestTracker:
  {
    favorites: {},
    hidden: {}
  },
  getState: function (id, type)
  {
    if (UserOffer.RequestTracker[type][id] != null)
    { return 'changing'; }
    const u = userService.getUser();
    const ei = u.extraInfo;
    if (!ei) return false;
    const t = ei[type];
    const ret = t?.includes(id) ?? false;
    return ret;
  },
  set: async function ({ id, type, val, notify })
  {
    const notType = type === 'favorites' ? 'hidden' : type === 'hidden' ? 'favorites' : 'bad request'
    if (notType === 'bad request')
      return;
    const cval = UserOffer.getState(id, type);
    if (cval === 'changing')
    { throw new Error('busy'); }
    try
    {
      UserOffer.RequestTracker[type][id] = notify;
      notify('changing');
      await UserOffer.makeRequest({
        endpoint: UserOffer.endpointMap[val][type],
        payload: { offerId: id },
        method: val === true ? 'POST' : 'DELETE',
      });
      let info = userService.getUser().extraInfo;
      const addTo = (ltype) =>
      {
        if (info[ltype] == null)
        {
          info[ltype] = [id];
        }
        else
        {
          info[type].push(id);
        }
      }
      const removeFrom = (ltype) =>
      {
        if (info[ltype] != null)
        {
          const i = info[ltype].indexOf(id);
          if (i > -1)
          { info[ltype].splice(i, 1); }
        }
      }
      if (val)
      {
        addTo(type);
        removeFrom(notType);
      }
      else
      {
        removeFrom(type);
      }

      //UserOffer.RequestTracker[type][id](val)//notify
      //notify(val);
    }
    finally
    {
      delete UserOffer.RequestTracker[type][id];
      notify(UserOffer.getState(id, type));
      userService.setCurrentUser(userService.getUser());
    }
  },
  /**
 * 
 * @returns {{ [k: string]: Offer }} 
 */
  getAllFavOffers: function ()
  {
    const user = userService.getUser();
    if(offers == null)
    {
      Promise.resolve(loadAllOffers())
    }
    if (user.extraInfo?.favorites && user.extraInfo?.offerData)
    {
      const favOffers = offers.filter(o => user.extraInfo?.favorites.includes(o.id))
      const offerById = Object.fromEntries(favOffers.map(x=>[x.id,x]))
      return offerById;
    }
    return {};
  },
  getAllTasks: function ()
  {
    const user = userService.getUser();
    if (user.extraInfo?.favorites && user.extraInfo?.offerData)
    {
      const /** @type {Offer[]}*/favoffers = user.extraInfo.favorites
      const /** @type {Map<Task,string>}*/taskToOfferId = new Map();
      const /** @type {Map<string,string>}*/taskIdToOfferId= new Map()
      const /** @type {Map<string|number,Task>}*/taskIdToTask = new Map()//Object.entries(tasks.map(t=>[t.id,t]))
      favoffers.forEach((id) => Object.entries(user.extraInfo?.offerData[id]?.tasks ?? {}).forEach(t => {taskIdToTask.set(...t); taskIdToOfferId.set(t[0], id);taskToOfferId.set(t[1], id);}));
      const tasks = [...taskToOfferId.keys()];

      return { tasks:tasks, taskIdToTask: taskIdToTask, taskToOfferId: taskToOfferId,taskIdToOfferId:taskIdToOfferId };
      //return alltasks;
    }
    return { tasks: [], taskIdToTask: new Map(), taskToOfferId: new Map(), taskIdToOfferId: new Map()  };
  },
  setTask: async function (task, offerID, onClose)
  {
    const user = userService.getUser();
    if(offerID==null)
    {
      offerID = this.allTasksOffers.value.taskToOfferId.get(task);
    }
    if(task==null || offerID == null || user == null)
    {
      throw new Error(`Error, missing info`);
    }
    const { [offerID]: offer, tasks } = getMakePath(user, {
      extraInfo: {},
      offerData: {},
      [offerID]: {},
      tasks: {}
    })
    const tasksCopy = { ...tasks, [task.id]: task } //a shallow copy with the new task
    const offerDataCopy = { ...offer, tasks: tasksCopy } ///a shallow copy with just tasks replaced by a newer version

    const offerDataDto = {
      offerId: offerID,
      offerData: offerDataCopy,
    };

    const jwtToken = userService.getToken();
    await axios.post(URL + "users/addOfferData", offerDataDto, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });


    //console.log("data.offerData.tasks", data);

    // const offer = getMake(offerDataCopy, offerID, {});
    // const tasks = getMake(offerDataCopy, 'tasks', {});
    tasks[task.id] = task;
    userService.setCurrentUser(user);
    onClose();
  }, delTask: async function (taskId, offerID, onClose)
  {
    const user = userService.getUser();
    // const extraInfo = user.extraInfo;
    // const { offerData, tasks } = getMakePath(extraInfo, {
    //   offerData: {},
    //   [offerID]: {},
    //   tasks: {},
    // })
    const { [offerID]: offer, tasks } = getMakePath(user, {
      extraInfo: {},
      offerData: {},
      [offerID]: {},
      tasks: {}
    })
    const tasksCopy = { ...tasks }
    const offerDataCopy = { ...offer, tasks: tasksCopy } ///a shallow copy with just tasks replaced by a newer version
    // const offerDataCopy = { ...offerData, [offerID]: { ...offerData[offerID], tasksCopy } };
    // offerDataCopy[offerID].tasks=tasksCopy;
    console.log("del", offerDataCopy);

    delete tasksCopy[taskId];


    const offerDataDto = {
      offerId: offerID,
      offerData: offerDataCopy,
    };
    const jwtToken = userService.getToken();
    await axios.post(URL + "users/addOfferData", offerDataDto, {
      headers: { Authorization: `Bearer ${jwtToken}` },
    });
    delete tasks[taskId];
    userService.setCurrentUser(user);
    onClose();
  },
  endpointMap: {
    true: { favorites: "users/addFavorite", hidden: "users/addDeleted" },
    false: { favorites: "users/delFavorite", hidden: "users/delDeleted" },
  },
  makeRequest: async function ({ endpoint, payload, method })
  {
    const HOST = AppwideValues.serviceURL;
    const jwtToken = userService.getToken();
    const response = await fetch(HOST + endpoint, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      method, //: "POST",
      body: JSON.stringify(payload),
    });
    if (response.status !== 200 && response.status !== 201)
      throw new Error(`request to ${endpoint} with payload:
            ${payload}
            errored with ${response.status}: ${response.statusText}`);
    //const result = await response.json();
  },
};
UserOffer.favoriteOffers = new ReactiveValue(UserOffer.getAllFavOffers, [userService.userStore]);
UserOffer.allTasksOffers = new ReactiveValue(UserOffer.getAllTasks, [userService.userStore]);

async function _getAidRegion()
{
  try
  {
    const regions = {};
    const jwtToken = userService.getToken();

    const res = await axios.get(URL + "city-region/cities", {
      params: { completeHierarchy: true },
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    res.data.forEach((city) =>
    {
      const region = city.region.name;
      const cityName = city.name;

      if (regions[region])
      {
        regions[region].push(cityName);
      } else
      {
        regions[region] = [];
        regions[region].push(cityName);
      }
    });

    return regions;

  } catch (err)
  {
    console.log("could not fetch regions: ", err);
  }
}

function _filterOffers(aids, filterBy)
{
  let filtered = aids;
  if (filterBy.region.length > 0)
  {
    filtered = filtered.filter((aid) => filterBy.region.includes(aid.region));
  }
  if (filterBy.type.length > 0)
  {
    filtered = filtered.filter((aid) => filterBy.type.includes(aid.type));
  }
  if (filterBy.text)
  {
    const regex = new RegExp(filterBy.text, "i");
    filtered = filtered.filter((aid) => regex.test(aid.name));
  }
  return filtered;
}

async function getFilteredOffers(filterBy)
{
  if (offers == null)
  {
    await loadAllOffers();
  }
  return _filterOffers(offers, filterBy);
}

async function _createAids(aids)
{
  const regions = await _getAidRegion();

  const data = aids.map((aid) =>
  {
    // const {
    //     id,
    //     originalId,
    //     links,
    //     header,
    //     offerType,
    //     lastDateToSubmit,
    //     categoryNames,
    //     offerGiverName,
    //     cityNames
    // } = aid;
    const {
      id,
      header,
      body,
      categoryNames,
      cityNames,
      lastDateToSubmit,
      links,
      offerGiverName,
      offerType,
      originalId,
      postedDate,
      updatedAt,
      extraInfo,
      createdAt,
    } = aid;

    const cityName = cityNames[0];
    const lastDateTo = lastDateToSubmit ? lastDateToSubmit.slice(0, 10) : null; 
    const region = Object.entries(regions)
      .map((region) =>
      {
        const key = region[0]
        const value = region[1]

        if (value.includes(cityName))
        {
          return key
        } else if (!cityName || cityName === "na")
        {
          return ''
        }
        return ''
      }).filter(region => region)[0]

    return {
      id: id,
      originalId: originalId,
      registrationLinks: [links.linkToBody, ...(links.files ?? [])].filter(x => x.startsWith('http')),
      name: header || body,
      body: header ? body : '',
      offerGiverName: offerGiverName,
      lastDateToSubmit: lastDateTo,
      type: checkType(offerType[0]),
      categories: categoryNames,
      region: region,
      extraInfo: extraInfo,
      cityNames: cityName,
      offerType: offerType,
      postedDate: postedDate,
      updatedAt: updatedAt,
      createdAt: createdAt,
    };
  });
  return data;
}

async function fetchIframeData(sid)
{
  try
  {
    const jwtToken = userService.getToken();
    const res = await axios.get(URL + "offer/demo-offers", {
      params: { completeHierarchy: true, sid },
      headers: { Authorization: `Bearer ${jwtToken}` },
    });

    storageService.store(LOCALSTORAGE_KEYS.iframe, res.data);
    return res.data;
  } catch (err)
  {
    console.log(err);
  }
}

function checkType(type)
{
  switch (type)
  {
    case "מכרז":
      return "AUCTION";
    case "מאגר":
      return "REPOSITORY";
    case undefined || "מנהל/ת" || "יעוץ":
      return "OTHER";

    default:
      return "AUCTION";
  }
}

// async function addFavorite(id)
// {
//   console.log("id", id);
//   const jwtToken = userService.getToken();
//   const res = await axios
//     .post(
//       URL + "users/addFavorite",
//       { offerId: id },
//       {
//         headers: { Authorization: `Bearer ${jwtToken}` },
//       }
//     )
//     .then((res) => console.log(res))
//     .catch((err) => console.log(err));
// }
async function postTender(data)
{
  try
  {
    let res = null;
    if (userService.getUser())
    {
      const jwtToken = userService.getToken();
       res = await axios.post(URL + "offer/postOffer", data, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
    } else
    {
      res = await axios.post(URL + "offer/postPubOffer", data);
    }
    return res.data;
  } catch (err)
  {
    console.log(err);
    return err;
  }
}

// async function addTask(data)
// {
//   const jwtToken = userService.getToken();
//   /*const res =*/ await axios
//     .post(URL + "users/addOfferData", data, {
//       headers: { Authorization: `Bearer ${jwtToken}` },
//     })
//     .then((res) => console.log(res))
//     .catch((err) => console.log(err));
// }


async function sendEscortRequest(offerId)
{
  try
  {
    const jwtToken = userService.getToken();
    const res = await axios.post(`${URL}offer/escortRequest`, { offerId }, {
      headers: { Authorization: `Bearer ${jwtToken}` }
    })
    return res;
  } catch (err)
  {
    throw err
  }
}

function registrationLinksFromOffer(offer)
{
  return offer.registrationLinks
    //.filter(link => link.startsWith('http')) //because of bad data  //done on preprocessing of offers
    .map((link, index) => decodeURI(link)) //makes hebrew look better

}

export const offerService = {
  loadAllOffers,
  getFilteredOffers,
  fetchIframeData,
  getServiceState,
  UserOffer,
  // addFavorite,
  // addTask,
  sendEscortRequest,
  registrationLinksFromOffer,
  postTender,
};
