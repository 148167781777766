import * as React from "react";
import PropTypes from "prop-types";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { experimental_sx as sx } from "@mui/system";
import { useState } from "react";

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) =>
  sx({
    // height: "100%",
    // zIndex: "1500",
  })
);

export const Puller = styled(Box)(({ theme }) => ({
  width: "2px",
  height: "54px",
  backgroundColor: "#1C7CFF",
  borderRadius: "2px",
  marginInline: "20px",
  alignSelf:"center",
}));

export default function Swipeable({
  open,
  children,
})
{

  const toggleDrawer = (newOpen) => () =>
  {
    open.set(newOpen);
  };

  return (
    <Root>
      <SwipeableDrawer
        anchor="right"
        open={open.display}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        // SwipeAreaProps={{}}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiBackdrop-root": {
            // backgroundColor: "red",
          },
          "@.PrivateSwipeArea-root":{
            boxShadow: "-1px -8px 39px rgba(28, 124, 255, 0.39)"
          },
          "&.MuiDrawer-root > .MuiPaper-root": {
            boxShadow: "-1px -8px 39px rgba(28, 124, 255, 0.39)",
            overflow: "visible",
            display: { lg:"none", md: "block", xs: "block" },
            borderRadius: "40px 0 0 40px",
            display: "flex",
            flexDirection: "row",
            height:"90%",
            marginTop:"2%",
          },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            border: "1px solid blue",
          }}
        >

        <Puller />
        </Box>
        {/* only repositories */}
        <Box
          sx={{
            width: "100%",
            backgroundColor: { md: "#FFCC32", xs: "#F5F9FF" },
            color: '#00357D',
            boxShadow: "",
            border: {
              md: "1px solid rgba(28, 124, 255, 0.25)",
              xs: " 1px solid rgba(28, 124, 255, 0.25)",
            },
            // borderRadius: '50px',
            width: 'fit-content',            

            mb: { md: "0px", xs: "30px" },
            width: "100%", maxWidth: "400px", marginInline: "auto",
            height:"100%",
            direction:"rtl",
            overflowY:"scroll",
          }}
        >
          {children}
          </Box>
      </SwipeableDrawer>
    </Root>
  );
}

Swipeable.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
