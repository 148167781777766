
export const msecHour = 1000 * 60 * 60 * 1;
/** make year/month/day from date with time using local date 
 * @param {Date} d
*/
/**
 * The stripTimeFromDate function takes a Date object and returns
 *  a new Date object with the same year, month, and day as the original
 *  date but with hours, minutes, seconds and milliseconds set to 0.
 *
 * 
 * @param {Date} d Used to Get the date from a date object.
 * @return A new date object with the time set to 00:00:00.
 * 
 * @doc-author Trelent
 */
export function stripTimeFromDate(d)
{
    return new Date(d.getFullYear(), d.getMonth(), d.getDate())
}

/**
 * The toInputDate function takes a Date object and returns a string in the format YY-MM-DD.
 *
 * 
 * @param {Date} d Used to Get the date from the input field.
 * @return {string} A string in the format "yy-mm-dd".
 * 
 * @doc-author Trelent
 */
export function toInputDate(d)
{
    // return d.toLocaleString([], {
    //     year: 'numeric',
    //     month: '2-digit',
    //     day: '2-digit',
    // }).replaceAll('/', '-').replaceAll('\\', '-');
    return d.toISOString().slice(0,10)
}
/**
 * The toInputTime function takes a Date object and returns 
 * a string in the format "HH:MM" that Input element expects
 *
 * 
 * @param {Date} d Used to Get the date and time.
 * @return {string} A string, so the value of the input will be a string.
 * 
 * @doc-author Trelent
 */
export function toInputTime(d)
{
    // return d.toLocaleString([], { hour: '2-digit', minute: '2-digit'/*, second: '2-digit'*/ });
    // return d.toISOString().slice(11, 16);
    return d.toLocaleTimeString()
}

/**
 * The isDate function determines whether the passed argument is a Date object.
 *
 * 
 * @param {Date} d Used to Check if the object is a date.
 * @return True if the value is a date object and false otherwise.
 * 
 * @doc-author Trelent
 * @author ChatGPT3
 */
export function isDate(d)
{
    return Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime());
}

/**
 * The isDate function determines whether the passed argument is a Date object.
 *
 * 
 * @param {Date} d Used to Check if the object is a date.
 * @return True if the value is a date object or a valid Date string and false otherwise.
 * 
 * @doc-author Trelent
 * @author ChatGPT3
 */
export function isDateOrDateString(d)
{
    return !isNaN(new Date(d));
}
