import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import
{
  PageContainer,
  BoxForm,
  StyledInput,
  MediaButton,
} from "../../styledComponents/signup";
import InputValidationText from "../InputValidationText";

import { userService } from "../../services/userService.service";

const Image = () => (
  <Box
    component="img"
    src={require("../../assets/img/frame-reg1.png")}
    sx={{
      maxHeight: { lg: "410px", md: "372px" },
      maxWidth: { lg: "550px", md: "455px" },
      height: "100%",
      width: "100%",
      display: { md: "block", xs: "none" },
    }}
  />
);

export default function PageOne({ forward, stepsData, nav })
{
  const [data, setData] = useState(userService.RegistrationController.getRegData(1));
  stepsData[1]=data;

  const [messages, setMessages] = useState({
    username: "",
    businessName: "",
    phoneNumber: "",
    email: "",
  });
  const [errMsg, setErrMsg] = useState("");

  const handleChange = ({ target }) =>
  {
    const field = target.name;
    const value = target.value;
    // if (!validate(field, value)) return;
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const validate = (field, value) =>
  {
    return userService.RegistrationController.valid(field, value);
    //  var reg;

    // switch (field) {
    //   case "username":
    //     reg = new RegExp(/[א-תa-zA-Z]/, "g");

    //      return value.match(reg);

    //   case "businessName":
    //     reg = new RegExp(/[א-תa-zA-Z\d-]/, "g");

    //     return value.match(reg);

    //   case "phoneNumber":
    //     reg = new RegExp(/(0)\d{6,}/, "g");
    //     // reg = new RegExp(/(0)\d{8}/, "g");

    //     return value.match(reg);

    //   case "email":
    //     reg = new RegExp(/[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/, "gi");
    //     //reg = new RegExp(/[a-zA-z0-9.-]+@[\w-]+\.[a-z]/, "gi");
    //     // reg = new RegExp(/[a-zA-z0-9.-]+@[a-zA-z-]+\.(com|edu|net)/, "gi");
    //     // console.log(value.match(reg));

    //     return value.match(reg);

    //    default:
    //      break;
    //  }
  };
  const errorMessages = {
    username: "שם לא תקין. חייב להיות בין 2 ל40 אותיות.",
    businessName: "שם העסק לא תקין. חייב להיות בין 2 ל40 אותיות.",
    phoneNumber: "מספר טלפון לא תקין.",
    email: "אימייל לא תקין.",
  };
  const errorMessage = (field, value) =>
  {
    if (validate(field, value))
    {
      setMessages((prevState) => ({
        ...prevState,
        [field]: '',
      }));
    }
    else
    {
      setMessages((prevState) => ({
        ...prevState,
        [field]: errorMessages[field],
      }));
    }
    // var reg;

    // switch (field) {
    //   case "username":
    //     reg = new RegExp(/[א-תa-zA-Z]/, "g");

    //     if (!value.match(reg)) {
    //       setMessages((prevState) => ({
    //         ...prevState,
    //         username: "שם לא תקין. יכול להכיל אך ורק אותיות.",
    //       }));
    //     } else {
    //       setMessages((prevState) => ({ ...prevState, username: "" }));
    //     }

    //     break;

    //   case "businessName":
    //     reg = new RegExp(/[א-תa-zA-Z]{2,}/, "g");

    //     if (!value.match(reg)) {
    //       setMessages((prevState) => ({
    //         ...prevState,
    //         businessName: "שם העסק חייב להכיל 2 אותיות ומעלה",
    //       }));
    //     } else {
    //       setMessages((prevState) => ({ ...prevState, businessName: "" }));
    //     }
    //     break;

    //   case "phoneNumber":
    //     reg = new RegExp(/(0)\d{6,}/, "g");

    //     if (!value.match(reg)) {
    //       setMessages((prevState) => ({
    //         ...prevState,
    //         phoneNumber: "מספר הטפלון לא תקין.",
    //       }));
    //     } else {
    //       setMessages((prevState) => ({ ...prevState, phoneNumber: "" }));
    //     }
    //     break;

    //   case "email":
    //     reg = new RegExp(/[a-zA-z0-9.-]+@[a-zA-z-]+\.[a-z]/, "gi");

    //     if (!value.match(reg)) {
    //       setMessages((prevState) => ({
    //         ...prevState,
    //         email: "אימייל לא תקין.",
    //       }));
    //     } else {
    //       setMessages((prevState) => ({ ...prevState, email: "" }));
    //     }
    //     break;

    //   default:
    //     break;
    // }
  };

  const nextStep = async () =>
  {
    try
    {
      if (
        validate("username", data.username) &&
        validate("businessName", data.businessName) &&
        validate("phoneNumber", data.phoneNumber) &&
        validate("email", data.email)
      )
      {        
        await userService.RegistrationController.register(data, 1)
        forward(1, { ...data });
        setErrMsg({text:"",color:"red"});
      }
      else
      {
        setErrMsg({
          text:"שדות חסרים או לא תקינים",
          color:"red",
        });
        return;
      }
    } catch (err)
    {
      if (err.response?.data?.statusCode === 406)
      {
        setErrMsg({
          text: "משתמש כבר קיים במערכת, השתמש באימייל אחר",
          color: "red",
        });
      }
      else if (err.response?.data?.statusCode !== 200)
      {
        if (err.response?.data?.statusCode)
        {
          setErrMsg({
            text: `ארעה תקלה בשרת עם קוד ${err.response?.data?.statusCode}
          ${err.response.data.statusText}`,
            color: "red",
          });

        }
        else
        {
          setErrMsg({
            text: `ארעה תקלה לא ידועה: ${err.messages}`,
            color: "red",
          });
        }
      }
      else
      {
        setErrMsg({text:"ארעה תקלה לא ידועה",color:"red"});
      }
    }
  };

  return (
    <PageContainer
      sx={{
        backgroundColor: { sm: "#EBF3FF", xs: "#fff" },
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Box
        component="img"
        src={require("../../assets/img/wave-reg1.png")}
        sx={{
          position: "absolute",
          height: "-webkit-fill-available",
          right: { md: "-70px", sm: "0px" },
          bottom: { md: "-20px", sm: "0px" },
          height: { md: "-webkit-fill-available", sm: "390px" },
          display: { sm: "block", xs: "none" },
        }}
      />
      <Box
        component="img"
        src={require("../../assets/img/title-reg1.png")}
        sx={{
          maxWidth: { xl: "372px", lg: "320px", sm: "310px" },
          maxHeight: { xl: "130px", lg: "112px" },
          width: "100%",
          marginInline: "314px",
          marginBlockStart: { xl: 4, lg: 3, sm: 2.5 },
          marginBlockEnd: { xl: 4, md: 2.5, sm: 3 },
          display: { sm: "block", xs: "none" },
        }}
      />
      <Box
        component="img"
        src={require("../../assets/img/mobile-title1-reg1.png")}
        sx={{
          display: { sm: "none", xs: "block" },
          maxWidth: "330px",
          maxHeight: "84px",
          width: "100%",
          marginInline: "auto",
          mt: 4,
          mb: 3,
        }}
      />
      <Box
        component="img"
        src={require("../../assets/img/mobile-title2-reg1.png")}
        sx={{
          display: { sm: "none", xs: "block" },
          maxWidth: "250px",
          maxHeight: "59px",
          width: "100%",
          marginInline: "auto",
          mb: 2.3,
        }}
      />
      <Box
        sx={{
          width: "100%",
          maxWidth: { xs: "333px", sm: "fit-content" },
          display: "flex",
          justifyContent: { md: "end", xs: "center" },
          position: "relative",
          mb: { lg: 2.1, md: 4, xs: 3 },
          left: { xl: "62px", lg: "58px", md: "50px" },
        }}
      >
        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <BoxForm>
            <Typography
              sx={{
                color: "#00357D",
                fontSize: { lg: "20px", xs: "18px" },
                mb: { md: 2, xs: 0.9 },
              }}
            >
              כמה פרטים ומתחילים
            </Typography>

            {/* username */}
            <StyledInput
              size="small"
              label="שם מלא"
              type="text"
              name="username"
              value={data.username}
              onChange={(ev) =>
              {
                handleChange(ev);
                errorMessage("username", ev.target.value);
              }}
            />
            {messages.username && (
              <InputValidationText text={messages.username} />
            )}

            {/* business name */}
            <StyledInput
              size="small"
              id="outlined-search"
              label="שם העסק"
              type="text"
              name="businessName"
              value={data.businessName}
              onChange={(ev) =>
              {
                handleChange(ev);
                errorMessage("businessName", ev.target.value);
              }}
            />
            {messages.businessName && (
              <InputValidationText text={messages.businessName} />
            )}

            {/* phone number */}
            <StyledInput
              size="small"
              label="טלפון"
              type="text"
              name="phoneNumber"
              value={data.phoneNumber}
              onChange={(ev) =>
              {
                handleChange(ev);
                errorMessage("phoneNumber", ev.target.value);
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  top: { lg: "-4px", md: "-8px" },
                  "&.MuiInputLabel-shrink": {
                    right: "17px",
                    top: "0px",
                  },
                },
              }}
            />
            {messages.phoneNumber && (
              <InputValidationText text={messages.phoneNumber} />
            )}

            {/* email */}
            <StyledInput
              size="small"
              label="מייל"
              type="email"
              name="email"
              value={data.email}
              onChange={(ev) =>
              {
                handleChange(ev);
                errorMessage("email", ev.target.value);
              }}
              sx={{
                "& .MuiInputLabel-root": {
                  "&.MuiInputLabel-shrink": {
                    right: "20px",
                  },
                },
              }}
            />
            {messages.email && <InputValidationText text={messages.email} />}

            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                width: "100%",
                mt: 0.7,
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: "14px",
                },
              }}
              onClick={nextStep}
              startIcon={
                <ChevronLeftRoundedIcon
                  fontSize="small"
                  sx={{ position: "relative", top: "1px", right: "10px" }}
                />
              }
            >
              בואו נתקדם
            </Button>
            {errMsg && (
              <InputValidationText text={errMsg.text} color={errMsg.color} />
            )}
            {/* <Typography
              sx={{
                color: "red",
              }}
            >
              {errMsg.text}
            </Typography> */}
          </BoxForm>

          <Box
            component="div"
            sx={{
              textAlign: "center",
              maxWidth: { lg: "300px", md: "260px" },
              mb: 1.3,
            }}
          >
            {/* <Typography
              sx={{
                color: "#00357D",
                fontSize: { lg: "20px", md: "18px", xs: "18px" },
                mb: 1.5,
                textAlign: "center",
              }}
            >
              אפשר גם להתחבר באמצעות
            </Typography>
            <Container
              sx={{
                display: "flex",
                "&.MuiContainer-root": { padding: 0 },
                mb: 1,
                justifyContent: "space-between",
              }}
            >
              <MediaButton
                content="Facebook"
                icon="facebook"
                sx={{
                  img: {
                    bottom: { md: "2px", xs: "3px" },
                    left: { md: "5px", xs: "3px" },
                    height: { lg: "26px", sm: "23px", xs: "26px" },
                    width: { lg: "26px", sm: "23px", xs: "26px" },
                  },
                  mr: { lg: 0, sm: 3 },
                  paddingInlineStart: { md: "8px", xs: "4px" },
                }}
              />
              <MediaButton
                content="Google"
                icon="google"
                sx={{ ml: { lg: 2 }, color: "#484848" }}
              />
            </Container> */}
            <Button
              sx={{
                "&.MuiButton-root": {
                  borderRadius: "30px",
                  fontWeight: "400",
                  textDecoration: "underline",
                  fontSize: { lg: "14px", sm: "13px" },
                },
              }}
              onClick={() =>
              {
                nav("/login");
              }}
            >
              אנחנו כבר מכירים, רוצה להתחבר
            </Button>
          </Box>
        </Box>

        <Image />
      </Box>
      <Box
        component="img"
        src={require("../../assets/icons/step1.png")}
        sx={{
          height: "100%",
          width: "100%",
          maxHeight: "38px",
          maxWidth: "214px",
          mb: { xs: 4 },
          marginInline: "auto",
          zIndex: "1",
        }}
      />
    </PageContainer>
  );
}
