import { styled } from "@mui/material/styles";
import { experimental_sx as sx } from '@mui/system'
import { Button, IconButton, TableRow } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';




export const Tbutton = styled((props) => (
    <Button {...props}>למסמכי המכרז</Button>
))(({ theme }) => (sx({
    color: "primary",
    "&.MuiButton-root": { borderRadius: "20px", padding: { md: '5px 30px' } },
    margin: '0px',
    display: { sm: 'inline', xs: 'none' }
})))

export const Document = styled((props) => (
    <IconButton color="primary" aria-label="notifications" {...props}>
        <ArticleRoundedIcon />
    </IconButton>
))(({ theme }) => (sx({
    padding: '0px',
    display: { sm: 'none', xs: 'inline-flex' }
})));

export const StyledTableCell = styled(TableCell)(({ theme }) =>
    sx({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "#E8F0FC",
            color: "#1C7CFF",
            fontSize: { lg: "20px", md: "18px" },
            fontWeight: "700",
            padding:"16px",
            "&.MuiTableCell-root": {
                maxWidth: "100%",
            },
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: { lg: "17px", md: "15px", sm: "13px" },
            backgroundColor: "white",
            "&.MuiTableCell-root": {
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                unicodeBidi: "plaintext",
            },
            padding: "16px",
            span: {
                fontSize: { lg: "17px" },
                marginInlineEnd: { sm: "8px" },
              },
              img: {
                display: "inline",
                height: "22px",
                width: "16px",
                position: "relative",
                top: "5px",
              },
        },
        unicodeBidi: "plaintext",
    })
);

export const LockedTableCell = styled((props) => (
    <StyledTableCell {...props}>
        <span>{props.text}</span>
        <img src={require("../../assets/icons/lock.png")} />
    </StyledTableCell>
))(({ theme }) =>
    sx({
        span: {
            fontSize: { lg: "17px" },
            // display: { xs: "none", sm: "inline" },
            marginInlineEnd: { sm: '8px' }
        },
        img: {
            display: "inline",
            height: "22px",
            width:"16px",
        },
        display: "flex",
        flexDirection: "row",
        alignItems: "end",
        border: "none",
        justifyContent: "end",
        "&.MuiTableCell-root": {
            padding: { sm: '10px 0px 10px 5px', xs: '10px 2px 10px 5px' }
        }
    })
);

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));