import { Box, Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { experimental_sx as sx } from '@mui/system'

export const MainContainer = styled(Box)(({ theme }) => (sx({
    maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
    height: "fit-content",
    width: "100%",
    borderRadius: "30px",
    marginInline: 'auto',
    mb: 5
})))

export const PageContainer = styled(Box)(({ theme }) => (sx({
    width: "100%",
    "&.MuiContainer-root": {
        padding: { xs: "0px" },
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: '30px',
    position: "relative",
})))

export const BoxForm = styled((props) => (
    <Box component='form' {...props}></Box>
))(({ theme }) => (sx({
    "& .MuiTextField-root": {
        m: 0.45, width: '100%',
        maxWidth: { sm: "333px" },
        borderRadius: '30px',
        backgroundColor: '#fff'
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    maxHeight: '300px',
    height: '100%',
    mb: { sm: 4, xs: 3 },
    "& .MuiInputLabel-root": {
        left: "auto",
        right: "25px",
        "&.Mui-focused": {
            right: "13px"
        },
        "&.MuiInputLabel-shrink": {
            right: "10px"
        }
    },
    input: {
        direction: "rtl",
        textAlign: "right",
        padding: { lg: '10px 14px', md: "7px 12px" },
        paddingInlineEnd: "2px"
    }
})))

export const StyledInput = styled((props) => (
    <TextField  {...props} />
))(({ theme }) => (sx({
    '& .MuiInputBase-root': {
        borderRadius: '30px',
        backgroundColor: { sm: "#fff", xs: "#F5F9FF" },
        direction: "rtl",
        "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderColor: 'rgba(28, 124, 255, 0.25)',
            }
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid rgba(28, 124, 255, 0.25)",
            textAlign: 'right',

        }
    },
})))

export const MediaButton = styled((props) => (
    <Button variant="outlined"  {...props} >
        <Box component="img" src={require(`../assets/icons/${props.icon}.png`)} />
        {props.content}
    </Button>
))(({ theme }) => (sx({
    borderRadius: "30px",
    backgroundColor: "#F5F9FF",
    paddingInlineStart: "8px",
    alignItems: 'center',
    maxWidth: '164px',
    width: "100%",
    border: '1px solid rgba(28, 124, 255, 0.25)',
    "&.MuiButton-root": {
        fontSize: { lg: '0.9rem', xs: "0.875rem" },
    },

    img: {
        position: 'relative',
        height: { lg: "28px", sm: "24px", xs: "28px" },
        width: { lg: "28px", sm: "24px", xs: "28px" },
        marginInlineEnd: '30px',
    }
})))

export const TitleReg2 = styled(Typography)(({ theme }) => (sx({
    color: "#00357D",
    fontWeight: { sm: "600", xs: "400" },
    fontSize: { lg: "23px", sm: "16px", xs: "18px" },
    textAlign: "center",
    mb: 1,
    pr: 1.5
})))

export const BusinessTypeButton = styled((props) => (
    <Button
        variant="outlined"
        sx={{
            backgroundColor: (props.bc === props.value || props.region) ? '#00357D' : "#F5F9FF",
            color: (props.bc === props.value || props.region) ? "#fff" : "#00357D",
            "&.MuiButton-root:hover": {
                backgroundColor: (props.bc === props.value || props.region) ? "#00357dc4" : "rgba(28, 124, 255, 0.09)"
            }
        }} {...props}></Button>
))(({ theme }) => (sx({
    fontWeight: "600",
    "&.MuiButton-root": {
        fontSize: { md: "14px", sm: "12px", xs: "14px" },
        height: "fit-content"
    },
    border: '1px solid rgba(28, 124, 255, 0.25)',
    borderRadius: "30px",
    ml: { md: 1, sm: 0.3, xs: 0 },
    mb: 1,
    paddingBlock: "3px"
})))

export const StyledInputReg3 = styled((props) => (
    <StyledInput {...props} />
))(({ theme }) => (sx({
    "& .MuiInputBase-root": { backgroundColor: "#F5F9FF", fontSize: "14px", },
    "&.MuiTextField-root": { marginInline: "0px" },
    label: {
        fontSize: "14px",
        direction: "rtl",
        textAlign: "right",
    },
})))

export const TitleAnnualReg3 = styled(Typography)(({ theme }) => (sx({
    color: "#00357D",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "right",
    pr: 1.5,
    direction: "rtl"
})))

export const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: 4,
    width: 20,
    height: 20,
    boxShadow:
        'inset 0 0 0 1px hsla(215, 100%, 55%, 1), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: "#fff",
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
}));

export const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#F5F9FF',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 20,
        height: 20,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='hsla(215, 100%, 55%, 1)'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
    },
});

