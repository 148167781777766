import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../../styledComponents/homePage/accordion';

export default function FilterDropdown({ filterBy, handleFilter, filter }) {
  const [expanded, setExpanded] = useState('');
  const { values, field, summaryTitle, all } = filter;
  // console.log(filter);

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      key={field}
      expanded={expanded === 'panel1'}
      onChange={handleExpand('panel1')}
      defaultExpanded
      sx={{
        width: '100%',
        backgroundColor: { md: '#FFFF', xs: '#F5F9FF' },
        boxShadow: '',
        border: {
          md: expanded ? '1px solid #1C7CFF' : '',
          xs: ' 1px solid rgba(28, 124, 255, 0.25)',
        },
        zIndex: '1000',
        mb: { md: '0px', xs: '30px' },
      }}
    >
      <AccordionSummary
        aria-controls='panel1d-content'
        id='panel1d-header'
        sx={{
          backgroundColor: { md: 'inherit', xs: '#F5F9FF' },
          fontSize: '16px',
          borderRadius: expanded ? '30px 30px 0 0 ' : '40px',
          '& .MuiAccordionSummary-expandIconWrapper': {
            svg: {
              fontSize: '16px',
              color: expanded ? '#1C7CFF' : '#00357D',
            },
          },
          '& .MuiAccordionSummary-content': { margin: '0px' },
          '&.MuiAccordionSummary-root': {
            padding: '0px 8px',
            paddingInlineStart: '4px',
            minHeight: '47px',
          },
          '& .MuiTypography-root': { mr: 1 },
        }}
      >
        <Typography
          sx={{
            color: expanded ? '#1C7CFF' : '#00357D',
            fontWeight: '700',
            position: 'relative',
            left: '3px',
            unicodeBidi: 'plaintext',
            fontSize: { lg: '16px', xs: '17px' },
          }}
        >
          {!filterBy[field].length && 'לא נבחר'}

          {filterBy[field].length === 1 &&
            filterBy[field][0] === 'AUCTION' &&
            'מכרזים'}
          {filterBy[field].length === 1 &&
            filterBy[field][0] === 'REPOSITORY' &&
            'מאגרים'}
          {filterBy[field].length === 1 &&
            filterBy[field][0] === 'OTHER' &&
            'אחר'}
          {filterBy[field].length === 1 &&
            field !== 'type' &&
            filterBy[field][0]}
          {filterBy[field].length === values.length && all.title}
          {filterBy[field].length > 1 &&
            filterBy[field].length < values.length &&
            `${filterBy[field].length}` +
              ' ' +
              `${field === 'region' ? 'אזורים' : 'סוגים'}`}
        </Typography>

        <Typography
          sx={{
            color: expanded ? '#1C7CFF' : '#00357D',
            fontSize: { lg: '16px', xs: '17px' },
          }}
        >
          {summaryTitle}
        </Typography>

        {!filterBy[field].length && (
          <Box
            component='img'
            src={require(`../../../assets/icons/${
              expanded ? 'dashboard-uncheck' : 'unchecked'
            }.png`)}
            sx={{
              mr: 1.5,
              height: { md: '16px', xs: '17px' },
              position: 'relative',
              top: { md: '4.5px', xs: '5px' },
            }}
          />
        )}
        {filterBy[field].length > 0 && (
          <Box
            component='img'
            src={require(`../../../assets/icons/${
              expanded ? 'dashboard-check' : 'checked'
            }.png`)}
            sx={{
              mr: 1.5,
              height: { md: '16px', xs: '17px' },
              position: 'relative',
              top: { md: '4.5px', xs: '5px' },
            }}
          />
        )}
      </AccordionSummary>

      {/* for categories */}
      {field === 'subscription' && (
        <AccordionDetails
          sx={{
            paddingBlockEnd: '8px',
            paddingBlockStart: '0px',
            flexDirection: 'column',
          }}
        >
          <Button
            disableRipple={true}
            endIcon={
              <Box
                component='img'
                src={require(`../../../assets/icons/dashboard-${
                  all.value.length === filterBy[field].length
                    ? 'check'
                    : 'uncheck'
                }.png`)}
                sx={{ height: { md: '16px', xs: '17px' } }}
              />
            }
            sx={{
              borderBlockStart: '1px solid #1C7CFF',
              paddingBlock: '13px',
              borderRadius: '0',
              justifyContent: 'flex-end',
            }}
            name={all.title}
            onClick={(ev) => {
              handleFilter(ev, all.value, field);
            }}
          >
            <Typography>{all.title}</Typography>
          </Button>

          {values.map((category, index) => {
            const isChecked = filterBy[field].includes(category);

            return (
              <Button
                key={index}
                disableRipple={true}
                endIcon={
                  <Box
                    component='img'
                    src={require(`../../../assets/icons/dashboard-${
                      isChecked ? 'check' : 'uncheck'
                    }.png`)}
                    sx={{ height: { md: '16px', xs: '17px' } }}
                  />
                }
                sx={{
                  borderBlockStart: '1px solid #1C7CFF',
                  paddingBlock: '13px',
                  borderRadius: '0',
                  justifyContent: 'flex-end',
                }}
                name={category}
                onClick={(ev) => {
                  handleFilter(ev, category, field);
                }}
              >
                <Typography>{category}</Typography>
              </Button>
            );
          })}
        </AccordionDetails>
      )}

      {/* for not categories */}
      {field !== 'subscription' && (
        <AccordionDetails
          sx={{
            paddingBlockEnd: '8px',
            paddingBlockStart: '0px',
            flexDirection: 'column',
          }}
        >
          <Button
            disableRipple={true}
            endIcon={
              <Box
                component='img'
                src={require(`../../../assets/icons/dashboard-${
                  all.value.length === filterBy[field].length
                    ? 'check'
                    : 'uncheck'
                }.png`)}
                sx={{ height: { md: '16px', xs: '17px' } }}
              />
            }
            sx={{
              borderBlockStart: '1px solid #1C7CFF',
              paddingBlock: '13px',
              borderRadius: '0',
              justifyContent: 'flex-end',
            }}
            name={all.title}
            onClick={(ev) => {
              handleFilter(ev, all.value, field);
            }}
          >
            <Typography>{all.title}</Typography>
          </Button>
          {values.map(({ title, value }, index) => {
            const isChecked = filterBy[field].includes(value);

            return (
              <Button
                key={index}
                disableRipple={true}
                endIcon={
                  <Box
                    component='img'
                    src={require(`../../../assets/icons/dashboard-${
                      isChecked ? 'check' : 'uncheck'
                    }.png`)}
                    sx={{ height: { md: '16px', xs: '17px' } }}
                  />
                }
                sx={{
                  borderBlockStart: '1px solid #1C7CFF',
                  paddingBlock: '13px',
                  borderRadius: '0',
                  justifyContent: 'flex-end',
                }}
                name={title}
                onClick={(ev) => {
                  handleFilter(ev, value, field);
                }}
              >
                <Typography>{title}</Typography>
              </Button>
            );
          })}
        </AccordionDetails>
      )}
    </Accordion>
  );
}
