import * as React from "react";
import { useState, useEffect } from "react";
import { categoriesService } from "../../services/categories.service";
import { messagePopupManager } from "../MessagePopup";

import
  {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListTitle,
  } from "../../styledComponents/homePage/accordion";

import
  {
    Typography,
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
  } from "@mui/material";

export default function CategDropdownList()
{
  const [expanded, setExpanded] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [categories, setCategories] = useState([]);
  const [categoryList, setCategList] = useState([]);

  useEffect(() =>
  {
    const lists = [];

    let chunkLength;
    if (width > 900)
    {
      chunkLength = Math.ceil(categories.length / 4);
    } else if (width <= 900 && width > 600)
    {
      chunkLength = Math.ceil(categories.length / 3);
    } else
    {
      chunkLength = Math.ceil(categories.length / 3);
    }

    for (var i = 0; i < categories.length; i += chunkLength)
    {
      const chunk = categories.slice(i, i + chunkLength);
      lists.push(chunk);
    }

    if (width > 600)
    {
      setCategList(lists.reverse());
    } else
    {
      setCategList(lists);
    }
  }, [categories, width]);

  useEffect(() =>
  {
    fetchCategories();
  }, []);

  const fetchCategories = async () =>
  {
    try
    {
      const categoryList = await categoriesService.loadAllCategories();
      if (categoryList == null)
      {
        const res = await messagePopupManager.pushMessage({
          content: (({ promiseControl }) =>
          {
            return (
              <Box
                sx={{
                  padding: '10px',
                }}
              >
                <Typography
                  sx={{
                    direction: 'rtl',
                    textAlign: 'right',
                    fontSize: '1.25rem',
                  }}
                >
                  <h4>קרתה שגיאה בחיבור למסד הנתונים.</h4>
                  <p>
                    רעננו את הדף, או נסו שוב מאוחר יותר.
                  </p>
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: '30px' }}>
                  <Button
                    variant="contained"
                    onClick={() =>
                    {
                      promiseControl.resolve("close");
                    }}
                    sx={{
                      "&.MuiButton-root": { borderRadius: "30px" },
                      mb: 1.5,
                      padding: '10px',
                    }}
                  >
                    סגור
                  </Button>

                </Box>
              </Box>
            );
          }),
          type: 'error',
          title: 'היי!',
          //color: '',//'primary',
        });
        throw new Error("Failed to catch category list at " + __filename + __filename);
      }
      setCategories((prevData) => [...categoryList]);
    } catch (err)
    {
      console.log(err);
    }
  };

  const handleChange = (panel) => (event, newExpanded) =>
  {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() =>
  {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () =>
  {
    setWidth(window.innerWidth);
  };

  return (
    <Box
      component="div"
      sx={{
        // mt: { sm: 7 },
        mb: { xs: 8.8 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontWeight: "600",
          textAlign: "center",
          fontSize: {
            xl: "38px",
            lg: "34px",
            md: "28px",
            sm: "22px",
            xs: "28px",
          },
          mb: { xl: 5, xs: 2.5 },
        }}
      >
        ?מה התחום שלכם
      </Typography>

      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        sx={{
          boxShadow: expanded ? "0px 8px 48px rgba(161, 194, 244, 0.68)" : "",
          maxWidth: { xs: "354px", sm: "590px", md: "850px", lg: "1400px" },
        }}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{
            borderRadius: expanded ? "20px 20px 0 0 " : "30px",
            border: !expanded ? "1px solid rgba(28, 124, 255, 0.25)" : "",
            "& .MuiTypography-root": {
              fontSize: {
                xl: "25px",
                md: "21px",
                sm: "19px",
                xs: "16px",
              },
            },
          }}
        >
          <ListTitle> צפו ברשימת הענפים המלאה</ListTitle>
        </AccordionSummary>

        <AccordionDetails
          sx={{
            paddingInline: { sm: "16px", xs: "0px" },
            flexDirection: { sm: "row", xs: "row-reverse" },
            justifyContent: { sm: "space-around", xs: "center" },
          }}
        >
          {categoryList.map((list, index) => (
            <List
              key={index}
              sx={{
                padding: "0px",
                width: { sm: "auto", xs: "100%" },
                maxWidth: { sm: "none", xs: "118px" },
              }}
            >
              {list.map((category) => (
                <ListItem
                  key={category}
                  disablePadding
                  sx={{
                    "&.MuiListItem-root:not(:last-child)": {
                      borderBottom: { xs: "1px solid #00000017" },
                    },
                    paddingBlock: "3px",
                  }}
                >
                  <ListItemButton
                    sx={{ textAlign: "center", borderRadius: "20px" }}
                  >
                    <ListItemText
                      primary={category}
                      sx={{
                        "& .MuiTypography-root": {
                          fontSize: {
                            xl: "18px",
                            lg: "17px",
                            md: "17px",
                            xs: "13px",
                          },
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
