import React, { useState, useSyncExternalStore } from "react";
import
{
  Box,
  Button,
  Chip,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { BpCheckedIcon, BpIcon } from "../../../themes/overrides/Input";
import UploadCard from "../../UploadCard";
import { offerService } from "../../../services/offerService.service";
import { userService } from "../../../services/userService.service";
import { useEffect } from "react";
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import { Store, useStore } from "../../../util/store";
import { isDate, isDateOrDateString, toInputDate, toInputTime } from "../../../util/time";

/** as const */
const colorsList = /** @type {const} */[
  "#1C7CFF",
  "#EF4130",
  "#F58220",
  "#FFCC32",
  "#00AB4E",
  "#CE5378",
  "#6838E3",
  "#2AAECD",
  "#AC6927",
  "#1CFF77",
  "#17191C",
  "#EBB376",
];
/**
 * @typedef {Object} Contact - creates a new type named 'Task'
 * @property {string} id
 * @property {string} name
 * @property {string} email 
 * @property {string} number 
 */
/**
 * @typedef {Object} Task - creates a new type named 'Task'
 * @property {any} id
 * @property {Date} endDate
 * @property {Date} postedDate 
 * @property {string} text 
 * @property {string} title 
 * @property {string} color 
 * @property {Contact[]} contacts 
 */

/**
 * @typedef {Object} StoreValue - creates a new type named 'SpecialType'
 * @property {Task} taskOriginal - the original task
 * @property {Task} taskCurrent - a copy of the task to work on
 * @property {string} offerId - ...
 * @prop {boolean} isOpen - show editor?
 * @prop {(Task)=>void} onClose - show editor?
 */

/** @type {StoreValue} */
const initialStoreValue = {
  taskOriginal: null,
  taskCurrent: null,
  offerId: null,
  isOpen: false,
  onClose: null,
}
const editTaskContext = new Store(initialStoreValue)

/**
 * The editTask function is used to open the modal dialog for editing a task.
 *
 * 
 * @param {Task} task Used to Pass the task object to edittask function.
 * @param {string} offerId Used to Determine which offer the task belongs to.
 * @param {(Task)=>void} onClose notification function to run after closing the editor
 * @return Nothing.
 * 
 * @doc-author Trelent && Avihay
 */
function editTask(task, offerId, onClose)
{
  const ctx = editTaskContext.value;
  ctx.isOpen = true;
  if(offerId==null)
  {
    if(task==null)
    throw new Error("can't edit nonexisting task on nonexisting offer");
    offerId = offerService.UserOffer.allTasksOffers.value.taskToOfferId.get(task);
    if (offerId == null)
      throw new Error("can't find task's offer");
  }
  ctx.offerId = offerId;
  if (task === null)
  {
    ctx.taskOriginal = {
      id: new Date().valueOf().toString(),
      contacts: [],
      color: colorsList[0],
      postedDate: toInputDate(new Date()),
    };
  }
  else
  {
    ctx.taskOriginal = task;
    if (!offerId)
    {
      ctx.offerId = offerService.UserOffer.allTasksOffers.value.taskIdToOfferId[task.id];
    }
  }
  ctx.taskCurrent = { ...ctx.taskOriginal,
     contacts: ctx.taskOriginal.contacts instanceof Array? [...ctx.taskOriginal.contacts]:[],
    postedDate: toInputDate(isDateOrDateString(task?.postedDate) ? new Date(task.postedDate) : new Date()),
    endDate: isDateOrDateString(task?.endDate) ? toInputDate(new Date(task.endDate)) :'',
    endTime: isDateOrDateString(task?.endDate) ? toInputTime(new Date(task.endDate)) :'',
};
  ctx.onClose = onClose;

  editTaskContext.value = ctx;
}
export const editTaskManager = {
  editTask
};

function EditTask()
{
  const [selectedValue, setSelectedValue] = React.useState("#1C7CFF");
  const store = useStore(editTaskContext);
  // const store = useSyncExternalStore((f) => editTaskContext.subscribe(f), () => editTaskContext.value); //doesn't work FU Mic..Meta
  const data = store?.taskCurrent;

  // contact control

  const [person, setPerson] = useState(/**@type {Contact} */({}));
  // contact popover control

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(true);

  const [popoverInfo, setPopoverInfo] = useState(/**@type {Contact} */({}));
  function popoverHandleClose(evt)
  {
    setPopoverOpen(false);
  }
  function popoverHandleOpen(evt, contactInfo)
  {
    setPopoverAnchorEl(evt.target);
    setPopoverInfo(contactInfo);
    setPopoverOpen(true);
    evt.stopPropagation()
  }

  const handleChangeFields = ({ target }) =>
  {
    const field = target.name;
    const value = target.value;
    store.taskCurrent[field] = target.value;
    editTaskContext.value = store;
  };

  const handleChangeFieldsPerson = ({ target }) =>
  {
    const field = target.name;
    const value = target.value;
    setPerson((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleAddingPerson = () =>
  {
    const date = new Date();
    let data = { ...person, id: date };
    store.taskCurrent.contacts.push({...data});
    editTaskContext.value = store;
  };


  async function handleSubmit() 
  {
        /**@type {Task} */
    let endDate_fuckyoureactdonttouchmyvariables = new Date(`${data.endDate}T${data.endTime}`);
    if (!isDate(endDate_fuckyoureactdonttouchmyvariables))
    {
      endDate_fuckyoureactdonttouchmyvariables = new Date(data.endDate)
    }
    if (!isDate(endDate_fuckyoureactdonttouchmyvariables))
    {
      endDate_fuckyoureactdonttouchmyvariables = null;
    }
    const newTask = {
      id: store.taskOriginal.id ?? new Date().valueOf().toString(),
      endDate: endDate_fuckyoureactdonttouchmyvariables ,
      postedDate: data?.postedDate,
      startDate: data?.startDate,
      text: data?.text,
      title: data?.title,
      color: data?.color,
      contacts: data?.contacts,
      priority: data?.priority,
    };
    console.log("task", newTask);
    await offerService.UserOffer.setTask(newTask, store.offerId, store.onClose);
    store.isOpen = false;
    editTaskContext.value = store;
  };

  const handleDeletePerson = (item) =>
  {
    store.taskCurrent.contacts = store.taskCurrent.contacts.filter((v) => v.id !== item.id);

    editTaskContext.value = store
  };
  function handleCancel()
  {
    if (store) 
    {
      store.isOpen = false;
      editTaskContext.value = store;
    }
    store.onClose(null)
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          // transition: "transform 0.8s ease",
          zIndex: `8888`,
          // backgroundColor: "#032c65cc",
          // display: `${store?.isOpen ? "flex" : "none"}`,
          display: "flex",

          transform: `translateX(${store?.isOpen ? "0px" : "-100%"})`,

          // opacity: `${store?.isOpen ? "0" : "1"})`
          backgroundColor: `#032c65${store?.isOpen ? "cc" : "00"}`,
          transition: "background-color 0.8s ease",
        }}
      >
        <Box
          sx={{
            transition: "transform 1s ease",
            transform: `translateX(${store?.isOpen ? "0px" : "-100%"})`,
            backgroundColor: "#fff",
            width: { xs: "100%", md: "60%", lg: "43.75rem" },
            padding: { xs: "1rem", sm: "2rem" },
            direction: "rtl",
            overflow: "auto",
            position: "relative",
          }}
        >
          <IconButton
            sx={{
              display: { xs: "flex", sm: "none" },
              position: "sticky",
              top: 0,

              mr: "auto",
              filter: "invert(1)",
            }}
            onClick={() => handleCancel()}
          >
            <Box
              component="img"
              src={require("../../../assets/icons/close-circle.png")}
              sx={{ height: "2.5rem", width: "2.5rem" }}
            />
          </IconButton>

          {/*  Priority */}
          <Stack direction={{ xs: "column", sm: "row" }} alignItems="center">
            <Typography
              component="h4"
              sx={{
                direction: "rtl",
                fontWeight: 700,
                fontSize: "1.125rem",
                fontFamily: "Assistant",
                ml: "auto",
              }}
            >
              הוסף משימה
            </Typography>
            <FormControl sx={{ flexDirection: "row", alignItems: "center" }}>
              <Typography
                component="h6"
                sx={{
                  direction: "rtl",
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  fontFamily: "Assistant",
                }}
              >
                עדיפות:
              </Typography>
              <RadioGroup row name="priority" sx={{ pl: "1rem" }}
                onChange={handleChangeFields}
                value={data?.priority ?? "רגיל"}
                >
                <FormControlLabel value="רגיל" control={<Radio />} label="רגיל" />
                <FormControlLabel value="נמוך" control={<Radio />} label="נמוך" />
                <FormControlLabel value="גבוה" control={<Radio />} label="גבוה" />
              </RadioGroup>
            </FormControl>
          </Stack>
          {/*  Task items */}
          <Box sx={{ mt: "2rem" }}>
            <Grid container spacing={3}>
              {/* input start */}
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="TitleTask"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    כותרת המשימה
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    id="text"
                    name="title"
                    type="title"
                    placeholder="ניהול פרוייקט"
                    onChange={handleChangeFields}
                    value={data?.title ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="CreatedIn"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    נוצר ב:
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    id="postedDate"
                    name="postedDate"
                    type="date"
                    placeholder="16:24 - 22.12.2022"
                    onChange={handleChangeFields}
                    value={data?.postedDate ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="StartingDate"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    החל מתאריך
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    id="startDate"
                    name="startDate"
                    type="date"
                    placeholder="22.5.2022"
                    onChange={handleChangeFields}
                    value={data?.startDate ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="upDate"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    עד לתאריך
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    id="endDate"
                    name="endDate"
                    type="date"
                    placeholder="22.5.2022"
                    onChange={handleChangeFields}
                    value={data?.endDate ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="ToHour"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    עד שעה
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    id="endTime"
                    name="endTime"
                    type="time"
                    placeholder="22:20"
                    onChange={handleChangeFields}
                    value={data?.endTime ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}

              {/* input start */}
              <Grid item xs={12} sm={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Typography
                    component="label"
                    htmlFor="MissionDescription"
                    sx={{
                      fontWeight: 400,
                      fontSize: "1rem",
                      fontFamily: "Assistant",
                      mx: "1.5rem",
                      mb: "0.625rem",
                    }}
                  >
                    תיאור המשימה
                  </Typography>
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "0.9375rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                      resize: "unset",
                    }}
                    component="textarea"
                    rows={6}
                    id="text"
                    name="text"
                    type="text"
                    placeholder="הקלד את תיאור המשימה כאן"
                    onChange={handleChangeFields}
                    value={data?.text ?? ''}
                  />
                </Box>
              </Grid>
              {/* input end */}
            </Grid>
          </Box>
          {/* Colors */}
          <Box sx={{ mt: "2rem" }}>
            <Typography
              component="h6"
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                fontFamily: "Assistant",
                mx: "1rem",
                mb: "0",
              }}
            >
              תווית
            </Typography>
            {colorsList.map((color) => (
              <Radio
                name="color"
                key={color}
                value={color}
                onChange={handleChangeFields}
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                checked={data?.color == color}
                size="lg"
                sx={{
                  color: color,
                  fontSize: "33px",
                  "&.Mui-checked": {
                    color: color,
                  },
                }}
              />
            ))}
          </Box>
          {/* Add a contact */}
          <Box sx={{ mt: "2rem" }}>
            <Typography
              component="h6"
              sx={{
                fontWeight: 400,
                fontSize: "1rem",
                fontFamily: "Assistant",
                mx: "1.5rem",
                mb: "0.625rem",
              }}
            >
              הוסף איש קשר
            </Typography>
            <Grid container spacing={2}>
              {/* input start */}
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    type="text"
                    placeholder="שם מלא"
                    id="name"
                    name="name"
                    onChange={handleChangeFieldsPerson}
                    value={person?.name || ""}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    type="email"
                    placeholder="מייל"
                    id="email"
                    name="email"
                    onChange={handleChangeFieldsPerson}
                    value={person?.email || ""}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Paper
                    sx={{
                      display: "block",
                      padding: "1rem",
                      backgroundColor: "#F5F9FF",
                      border: "1px solid #1c7cff3f",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                    }}
                    component="input"
                    type="text"
                    placeholder="מס’ טלפון"
                    id="phone"
                    name="phone"
                    onChange={handleChangeFieldsPerson}
                    value={person?.phone || ""}
                  />
                </Box>
              </Grid>
              {/* input end */}
              {/* input start */}
              <Grid item xs={6} sm={6} md={4} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    direction: "rtl",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      py: "0.625rem",
                      borderRadius: "3.125rem",
                      fontWeight: 400,
                      fontSize: "0.875rem",
                      fontFamily: "Assistant",
                      gap: "0.625rem",
                    }}
                    endIcon={<img src="/assets/profile-add.png" alt="הוסף" />}
                    onClick={handleAddingPerson}
                  >
                    הוסף
                  </Button>
                </Box>
              </Grid>
              {/* input end */}
              {/* categories */}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  sx={{
                    width: "-webkit-fill-available",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.625rem",

                    backgroundColor: { md: "#F5F9FF" },
                    borderRadius: "30px",
                    padding: "0.5rem",
                    border: { md: "1px solid rgba(28, 124, 255, 0.25)" },
                    minHeight: "32px",
                  }}
                >
                  {data?.contacts?.map((item) => (
                    <Chip
                      key={item.id}
                      label={item.name}
                      onClick={(evt) => popoverHandleOpen(evt, item)}
                      onDelete={() =>
                      {
                        handleDeletePerson(item);
                      }}
                      deleteIcon={
                        <Box
                          component="img"
                          src={require("../../../assets/icons/close-circle.png")}
                          sx={{
                            height: { md: "20px", sm: "18px", xs: "20px" },
                            width: { md: "20px", sm: "18px", xs: "20px" },
                            position: "absolute",
                            right: "0.25rem",
                            top: "50%",
                            transform: "translateY(-50%)",
                          }}
                        />
                      }
                      sx={{
                        color: "#fff",
                        backgroundColor: "#00357D",
                        pr: "1.50rem",
                        pl: "1rem",
                        fontSize: "0.875rem",
                      }}
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Box>
          {/* uploads */}
          {/* <Box sx={{ mt: "2rem" }}>
          <Typography
            component="h6"
            sx={{
              fontWeight: 400,
              fontSize: "1rem",
              fontFamily: "Assistant",
              mx: "1.5rem",
              mb: "0.625rem",
            }}
          >
            צרף קבצים
          </Typography>
          <Grid container spacing={2}>
            {uploadList.map((uploadList, index) => (
              <Grid key={index} item xs={6} md={4} xl={4}>
                <UploadCard />
              </Grid>
            ))}
            <Grid item xs={6} md={4} xl={4}>
              <Box
                as="label"
                htmlFor="upload"
                sx={{
                  backgroundColor: "#F5F9FF",
                  border: "2px dashed #00357D",
                  borderRadius: "0 0 2.375rem 0",
                  height: "9.3125rem",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",

                  direction: "ltr",
                }}
              >
                <span>
                  <img src="/assets/add-square.svg" alt="..." />
                </span>
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  style={{ display: "none" }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
          {/* buttons */}
          <Box sx={{ mt: "2.5rem" }}>
            <Stack
              direction="row"
              gap="1rem"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* <Button
                  type="button"
                  sx={{
                    backgroundColor: "#F5F9FF",
                    border: "1px solid #1C7CFF",
                    borderRadius: "50px",

                    justifyContent: "space-between",
                    gap: "0.5rem",
                    px: "2rem",
                    fontSize: "14px",
                  }}
                >
                  <span>הוסף משימה</span>
                  <Box
                    component="img"
                    src={require("../../../assets/icons/add-circle.png")}
                    sx={{ height: "16px", width: "16px" }}
                  />
                </Button> */}
              <Button
                type="button"
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  justifyContent: "center",
                  gap: "0.5rem",
                  px: "2rem",
                  fontSize: "14px",
                }}
                onClick={handleSubmit}
              >
                <span>שמור</span>
                <Box
                  component="img"
                  src={require("../../../assets/icons/arrow-left.png")}
                  sx={{ height: "16px", width: "16px" }}
                />
              </Button>
            </Stack>
          </Box>
          {/* end */}
        </Box>
        {/* overlay */}
        <span onClick={() => handleCancel()} style={{

          transition: "transform 0.8s ease",
          transform: `translateX(${store?.isOpen ? "0px" : "-100%"})`,
          display: "flex", flex: "1"
        }}>
          <IconButton
            sx={{ display: { xs: "none", sm: "flex" } }}
            onClick={() => handleCancel()}
          >
            <Box
              component="img"
              src={require("../../../assets/icons/close-circle.png")}
              sx={{ height: "2.5rem", width: "2.5rem" }}
            />
          </IconButton>
        </span>
      </Box>
      <ClickAwayListener onClickAway={popoverHandleClose}>
      <Popper
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={popoverHandleClose}
        placement="auto-end"
        sx={{
          zIndex: 8888,
        }}

      >
        <div className="popover-contacts">
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "101px",
                // height: "29px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "29px",
                paddingTop: "15px",
                paddingInlineStart: "41px",

                /* identical to box height */
                textAlign: "right",

                color: "#1C7CFF",
              }}
            >
              איש הקשר :
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "101px",
                // height: "29px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "29px",
                paddingTop: "15px",
                paddingInlineStart: "20px",
                display: "inline-block",

                /* identical to box height */
                textAlign: "right",

                color: "#1C7CFF",
              }}
            >
              דוא"ל:
            </Typography>

            <a href={`mailto:${popoverInfo.email}`} title="email">
              <EmailIcon
                sx={{
                  paddingInlineStart: '16px',
                }} fontSize={'medium'} />
            </a>
          </div>
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "101px",
                // height: "29px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "22px",
                lineHeight: "29px",
                paddingTop: "15px",
                paddingInlineStart: "20px",
                display: "inline-block",

                /* identical to box height */
                textAlign: "right",

                color: "#1C7CFF",
              }}
            >
              טלפון:
            </Typography>

            <a href={`sms:${popoverInfo.phone}`} title="message">
              <MessageIcon
                sx={{
                  paddingInlineStart: '16px',
                }} fontSize={'medium'} />
            </a>
            <a href={`tel:${popoverInfo.phone}`} title="call">
              <CallIcon
                sx={{
                  paddingInlineStart: '16px',
                }}
                fontSize={'medium'} />
            </a>
          </div>
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "113px",
                // height: "52px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                // lineHeight: "26px",
                textAlign: "center",
                paddingTop: "8px",

                color: "#000000",
              }}
            >
              {popoverInfo.name}
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "113px",
                // height: "52px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                // lineHeight: "26px",
                textAlign: "center",
                paddingTop: "8px",

                color: "#000000",
              }}
            >
              {popoverInfo.email}
            </Typography>
          </div>
          <div>
            <Typography
              sx={{
                // position: "absolute",
                // width: "113px",
                // height: "52px",

                fontFamily: "'Assistant'",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "20px",
                // lineHeight: "26px",
                textAlign: "center",
                paddingTop: "8px",

                color: "#000000",
              }}
            >
              {popoverInfo.phone}
            </Typography>
          </div>
          {/* {popoverOpen && Object.entries(popoverInfo).map(([type, events]) =>
                <>
                  <div className="">
                    {type}:{events.toString()}
                  </div>
                  {Object.entries(events).map((event) => (
                    <div className="popover-event-box flex-space-between">
                      <div className="flex-space-between text-overflow-elipsis" style={{ maxWidth: "600px", gap: "50px" }}>
                        <Typography>
                          {event}
                        </Typography>
                        <span
                          className="dayPin"
                          style={{
                            backgroundColor:
                              type === "offer" ? "#1C7CFF" : event.color,
                          }}
                        />
                      </div>
                      .
                    </div>
                  ))}
                </>)} */}
        </div>
      </Popper>
      </ClickAwayListener>
    </>
  );
};

export default EditTask;
