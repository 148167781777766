import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Typography } from "@mui/material";


export const ListTitle = styled(Typography)(({ theme }) => ({
    fontWeight: "600",
    fontSize: {
        xl: "25px",
        lg: "21px",
        md: "18px",
        sm: "17px",
        xs: "15px",
    },
    unicodeBidi: "plaintext"
}));

export const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
    minWidth: "150px",
    maxWidth: '1250px',
    width: '100%',
    maxHeight: { sm: "807px", xs: '100%' },
    "&.MuiPaper-root": { borderRadius: "30px" },

    "&:before": {
        display: "none",
    },
}));

export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={
            <ArrowForwardIosSharpIcon
                sx={{
                    fontWeight: 600,
                    fontSize: { md: "20px", sm: "17px", xs: "16px" },
                    // color: "#1C7CFF",
                    ml: 1,
                    transform: "rotate(90deg)",
                }}
            />
        }
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "#F5F9FF",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotateX(-180deg)",
    },
    "& .MuiAccordionSummary-content": {
        justifyContent: "end",
        color: "#1C7CFF",
        fontWeight: "700"
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    flexWrap: 'wrap',
    display: "flex",
    justifyContent: "space-around"
}));