
const INITIAL_STATE = {
    aids: [],
    filterBy: {
        text: "",
        region: [],
        type: [],
        subscription: [],
    },
}


export function aidReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_AIDS':

            const { data } = action
            return {
                ...state,
                aids: [...data]
            };
        case 'SET_FILTER':
            const { filterBy } = action

            return {
                ...state,
                filterBy: { ...state.filterBy, ...filterBy }
            };
        default:
            return state;
    }
}