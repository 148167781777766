import { Box } from "@mui/material";
import React from "react";

import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import Meeting from "../../assets/animations/meeting.json";
import {
  MainContainer,
  MainTitle,
  SubTitle,
  TextContainerProps,
  Btn,
} from "../../styledComponents/homePage/secFiveStyledCmps";

export default function SecFive() {
  const nav = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Meeting,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <MainContainer
      sx={{
        maxWidth: { xl: "1545px", lg: "1280px", md: "100%" },
        marginInline: "auto",
      }}
    >
      <Lottie
        options={defaultOptions}
        style={{ margin: "0px auto 0 px 0px", maxWidth: "600px" }}
      />
      <Box sx={{ ...TextContainerProps, mb: { xs: 4.5 }, mr: { md: 5 } }}>
        <MainTitle sx={{ mb: { xs: 1.5 } }}>
          מפגישים בין עסקים <span style={{ fontWeight: "700" }}>לעסקאות</span>
        </MainTitle>
        <SubTitle>רוצים להכיר אותנו יותר? כנסו לעמוד הבא</SubTitle>

        <Btn
          variant="contained"
          onClick={() => {
            nav("/about");
          }}
        >
          ?מי אנחנו
        </Btn>
      </Box>
    </MainContainer>
  );
}
