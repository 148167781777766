import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";

export default function SecSix() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: { lg: 12, sm: 0, xs: 15 },
      }}
    >
      <Box
        component="img"
        src={require("../../assets/img/sec6.png")}
        sx={{
          display: { sm: "block", xs: "none" },
          maxWidth: { xl: "1349px", lg: "1150px", md: "870px", sm: "600px" },
          maxHeight: "583px",
          width: "100%",
        }}
      />

      <Box
        component="img"
        src={require("../../assets/img/sec6-mobile.png")}
        sx={{ display: { sm: "none", xs: "block" } }}
      />
    </Box>
  );
}
