import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Box, List, ListItem, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const items = [
  {
    title: '',
    paragraphs: [
      'קשר-עסקים ומכרזים (להלן : "האתר" / "הפלטפורמה") מנגישה את האתר ותכניו לכלל האוכלוסייה, אנחנו עושים את המיטב ע"מ לאפשר חווית גלישה נגישה, נעימה ובטוחה עבורכם.',
      ' ',
      'האתר מייחס חשיבות רבה לשוויון הזדמנויות ועושה כל מאמץ כדי לאפשר לכלל הגולשים להשתמש בו בצורה נוחה וקלה. כדי ליישם את הגישה הזו, הותאם והונגש האתר בהתאם להוראות תקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), תשע"ג – 2013, באמצעות טכנולוגיה מתקדמת המאפשרת נגישות מלאה לכלל התכנים באתר.',
    ],
  },
  {
    title: 'סוג רמת ההנגשה',

    paragraphs: [
      ' ',
      'הנגשת האתר בוצעה בהתאם לכללי התקן הישראלי (ת"י 5568) בנושא "קווים מנחים לנגישות תכנים באינטרנט" וכן להנחיות הנגישות WCAG2.0 ברמה AA.',
    ],
  },
  {
    title: 'שימוש בתפריט ההנגשה',

    paragraphs: [
      'בפינה השמאלית עליונה באתר, נמצא כפתור הנגשה. הקלקה על כפתור זה תפתח תפריט המציע מגוון סוגי הנגשה. לאחר הקלקה על סוג ההנגשה הרצוי, האתר ייטען במצב המונגש. כדי לסגור את התפריט, יש להקליק על כפתור הסגירה.',
    ],
  },
  {
    title: 'אמצעי הנגישות',

    paragraphs: [
      'גודל התצוגה: ניתן להגדיל ולהקטין את גודל הטקסט המופיע באתר, באמצעות לחיצה על האפשרות הנבחרת או זכוכית המגדלת.',
      '',
      'צבעים: ניתן לבחור את צבעי האתר וליצור התאמה אישית מתוך סט צבעים או לחלופין לשנות את רמת הניגודיות בכל האתר.',
      '',
      'סמן העכבר: ניתן לשנות את צבע הסמן לשחור או לבן. בנוסף, בעזרת לחיצה על כפתור CTRL ניתן לאתר את מיקום העכבר במסך.',
      '',
      'הדלקת תפריט הנגישות בעזרת הקליק הימני בעכבר: הפעלת כפתור זה תשנה את הגדרות הקליק הימני בעכבר, כך שלחיצה עליו תפעיל תפריט נגישות מקוצר.',
      '',
      'תפריט אזור אישי: ממוקם בחלקו השמאלי של תפריט הנגישות. תפריט זה הנו אופציונלי, ומאפשר שימוש נגיש יותר באתר באמצעות למידת הרגלי הגלישה של המשתמש.',
    ],
  },
  {
    title: 'יש לכם שאלה? נתקלתם בבעיה?',

    paragraphs: [
      'שתפו אותנו! אנחנו משתדלים לתת לכם תמיד את השירות הטוב ביותר, אבל למרות המאמצים עדיין יתכנו חלקים באתר שצריכים שיפורי הנגשה נוספים.',
      'אם נתקלתם בקושי כלשהו בנושא, נשמח אם תספרו לנו על כך באמצעות הצ\'ט באתר, כפתור צור קשר, או בטלפון: 055 - 5528310',
      '',
      'כדי שנוכל לטפל בבעיה בדרך הטובה ביותר, נשמח אם תציינו את הפרטים הבאים:',
      '',
      '· פרטים ליצירת קשר',
      '· תיאור הבעיה והפעולה שניסיתם לבצע',
      '· תיאור הדף שבו גלשתם',
      '· סוג וגרסה של הדפדפן',
      '· מערכת ההפעלה וגרסתה',
      '· סוג הטכנולוגיה המסייעת (במידה והשתמשתם בה)',
      ' ',
      '',
      'תודה וגלישה נעימה!',
    ],
  },
];

const RegularP = ({ spaceBetweenP, paragraph, sectionNum, paragraphNum }) => (
  <Typography
    sx={{
      textAlign: "start",
      fontSize: { lg: "20px", xs: "18px" },
      textDecoration:
        sectionNum === 12 && paragraphNum === 7 ? "underline" : "",
      fontWeight: sectionNum === 24 || sectionNum === 25 ? "600" : "400",
      "&:not(:last-of-type)": {
        mb: spaceBetweenP,
      },
    }}
  >
    {paragraph}
  </Typography>
);

export default function Accessability({ openModal })
{
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () =>
  {
    // setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  const calcSpace = (sectionNum, paragraphNum) =>
  {
    return "0px";
    // switch (sectionNum)
    // {
    //   case 10:
    //     if (paragraphNum === 1) return "0px";
    //     else return "10px";

    //   case 11:
    //     return "0px";

    //   case 12:
    //     if (paragraphNum === 1 || paragraphNum === 4) return "0px";
    //     else return "20px";

    //   case 20:
    //     if (paragraphNum === 1 || paragraphNum === 9) return "0px";
    //     else return "20px";

    //   case 21:
    //     if (paragraphNum === 1 || paragraphNum === 3) return "0px";
    //     else return "20px";

    //   case 22:
    //     if (paragraphNum === 2) return "0px";
    //     else return "20px";

    //   case 25:
    //     if (
    //       paragraphNum === 1 ||
    //       paragraphNum === 2 ||
    //       paragraphNum === 3 ||
    //       paragraphNum === 4 ||
    //       paragraphNum === 5
    //     )
    //       return "0px";
    //     else return "20px";

    //   default:
    //     return "20px";
    //     break;
    // }
  };

  return (
    <Box>
      <Header openModal={openModal} />
      <Box
        component="main"
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: {
            xl: "1395px",
            lg: "1200px",
            md: "890x",
            sm: "590px",
            xs: "333px",
          },
          marginInline: "auto",
          direction: "rtl",
          position: "relative",
          "& .MuiTypography-root": {
            unicodeBidi: "plaintext",
          },
        }}
      >
        <Typography
          sx={{
            mb: { md: "57px", xs: "23px" },
            mt: { md: "41px", xs: "25px" },
            textAlign: { md: "start", xs: "center" },
            fontSize: { lg: "48px", md: "30px", xs: "24px" },
            fontWeight: "600",
          }}
        >
          הצהרת נגישות
        </Typography>

        {/* <Typography
          sx={{
            mb: { md: "10px", xs: "20px" },
            textAlign: "start",
            fontSize: { lg: "20px", xs: "18px" },
          }}
        >
          תנאי שימוש ומדיניות פרטיות – קשר – עסקים ומכרזים
        </Typography> */}


        {items.map((item, index) =>
        {
          const { title, paragraphs, list } = item;
          const sectionNum = index + 1;

          return (
            <Box key={title} sx={{ mb: "45px" }}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  mb: "5px",
                  textAlign: "start",
                  fontSize: { lg: "20px", xs: "18px" },
                }}
              >
                {title}
              </Typography>
              {paragraphs.map((paragraph, index) =>
              {
                const paragraphNum = index + 1;
                let spaceBetweenP = calcSpace(sectionNum, paragraphNum);

                return (
                  <RegularP
                    key={paragraph}
                    paragraph={paragraph}
                    paragraphNum={paragraphNum}
                    spaceBetweenP={spaceBetweenP}
                    sectionNum={sectionNum}
                  />
                );
              })}

              {list && (
                <List sx={{ listStyle: "disc", paddingInlineStart: "40px" }}>
                  {list.map(({ listText, listTitle }, index) =>
                  {
                    return (
                      <ListItem
                        key={listTitle || index}
                        sx={{
                          display: "list-item",
                          paddingInlineStart: "0px",
                          paddingBlock: "5px",
                          position: "relative",
                          right: "0px",
                          mt: "0px",
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: "start",
                            "& .MuiTypography-root": {
                              fontSize: {
                                lg: "20px",
                                md: "18px",
                                xs: "18px",
                              },
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              display: "inline",
                              textDecoration: "underline",
                            }}
                          >
                            {listTitle}
                          </Typography>
                          <Typography sx={{ display: "inline" }}>
                            {listText}
                          </Typography>
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </Box>
          );
        })}


      </Box>
      <Footer openModal={openModal} />
    </Box>
  );
}
