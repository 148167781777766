export const MONTHS = [
  'ינואר',
  'פברואר',
  'מרץ',
  'אפריל',
  'מאי',
  'יוני',
  'יולי',
  'אוגוסט',
  'ספטמבר',
  'אוקטובר',
  'נובמבר',
  'דצמבר',
];

export const DAYS = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];

export const MOCKEVENTS = [
  {
    date: new Date(2022, 10, 4),
    title: '4 הצעות',
    color: '#1C7CFF',
  },
  {
    date: new Date(2022, 10, 24),
    title: '3 משימות',
    color: '#1C7CFF',
    link: true,
  },
  {
    date: new Date(2022, 10, 24),
    title: '2 הצעות',
    color: '#FFE500',
  },
  {
    date: new Date(2022, 10, 11),
    title: '4 משימות',
    color: '#FFE500',
  },
];
