import * as React from "react";
import { useState, useEffect } from "react";

// MUi components
import {
  Button,
  AppBar,
  Toolbar,
  Container,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import UserAccordion from "./UserAccordion";
import { logout } from "../store/actions/userActions";

const drawerWidth = 248;

export default function Header({ openModal }) {
  const navItems = [
    { text: "דף הבית", to: "/home" },
    { text: "שירותים ומחירים", to: "/prices" },
    { text: "?מי אנחנו", to: "/about" },
    //{ text: "צור קשר", to: "/404" },
  ];
  const { user } = useSelector((state) => state.userModule);
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [height, setHeight] = useState(window.innerHeight - 123);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const calcHight = window.innerHeight - 123;
    setHeight(calcHight);
  };

  const doLogout = () => {
    dispatch(logout());
    nav("/login");
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", height: "100%" }}
    >
      <Container
        sx={{
          height: "fit-content",
          width: "100%",
          display: user ? 'none' : "flex",
          alignItems: "center",
          justifyContent: "space-around",
          mb: 1,
          "&.MuiContainer-root": {
            padding: "0px",
          },
        }}
      >
        <Button
          onClick={() => openModal()}
          sx={{
            "&.MuiButton-root": {
              fontSize: "17px",
              borderRadius: "0px",
              height: "fit-content",
              paddingBlockEnd: "2px",
              transition: "box-shadow .3s ease-in-out",
              "&:hover": {
                backgroundColor: "inherit",
                boxShadow: "inset 0px -4px 0px 0px rgb(0 53 125 / 15%)",
              },
            },
          }}
        >
          הרשם
        </Button>
        <Button
          onClick={() => {
            nav("/login");
          }}
          sx={{
            "&.MuiButton-root": {
              fontSize: "17px",
              borderRadius: "0px",
              height: "fit-content",
              paddingBlockEnd: "2px",
              transition: "box-shadow .3s ease-in-out",
              "&:hover": {
                backgroundColor: "inherit",
                boxShadow: "inset 0px -4px 0px 0px rgb(0 53 125 / 15%)",
              },
            },
          }}
        >
          התחבר
        </Button>
        <IconButton sx={{ padding: 0 }}>
          <img
            src={require("../assets/img/close-x-drawer.png")}
            alt="close"
            sx={{ height: "50px" }}
          />
        </IconButton>
      </Container>

      <Container
        sx={{
          width: "100%",
          height: "fit-content",
          display: "flex",
          justifyContent: "space-evenly",
          marginInline: "auto",
          mb: 2,
          "&.MuiContainer-root": {
            padding: "0px",
          },
        }}
      >
        {/* user name */}
        {user && (
          <Box
            sx={{
              textAlign: "right",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#1C7CFF",
                fontWeight: "600",
                position: "relative",
                fontSize: "16px",
              }}
            >
              {user.username}
            </Typography>
            <Typography
              sx={{
                color: "#808080",
                fontSize: "16px",
              }}
            >
              {user.businessName}
            </Typography>
          </Box>
        )}

        {/* user image */}
        <IconButton
          onClick={(ev) => ev.stopPropagation()}
          sx={{
            padding: 0,
            height: '55px',
            width: '55px',
          }}
        >
          {user && user.image && (
            <img
              src={user.image}
              alt="user-image"
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          )}
          {!user && (
            <img
              src={require("../assets/img/logo-main.png")}
              alt="logo"
            />
          )}
        </IconButton>
      </Container>

      <Divider />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: height,
        }}
      >
        <List>
          {navItems.map(({ text, to }) => (
            <ListItem
              key={text}
              onClick={() => {
                nav(to);
              }}
              disablePadding
              sx={{
                display: { xs: "block", sm: "none" },
                marginBlockEnd: "20px",
              }}
            >
              <ListItemButton
                divider={true}
                sx={{
                  textAlign: "end",
                  position: "relative",
                  paddingInlineEnd: "5px",
                  paddingBlockStart: "10px",
                  paddingBlockEnd: "10px",
                  maxWidth: "200px",
                  width: "100%",
                  marginInline: "auto",
                  borderRadius: "10px 10px 0 0",
                  transition: "box-shadow .3s ease-in-out",

                  "&:hover": {
                    boxShadow: "inset 0px -4px 0px 0px rgb(0 53 125 / 15%)",
                    backgroundColor: "inherit",
                  },
                }}
              >
                <ListItemText
                  primary={text}
                  sx={{
                    span: {
                      fontSize: "17px",
                      fontWeight: "600",
                      color: "#1C7CFF",
                      position: "relative",
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            maxWidth: "203px",
            alignSelf: "center",
            mb: 3.4,
            height: user ? "100%" : "auto",
            justifyContent: user ? "space-between" : "",
          }}
        >
          <Button
            variant="contained"
            onClick={() => openModal()}
            sx={{
              "&.MuiButton-root": { borderRadius: "30px" },
              mb: 1.5,
              display: user ? "none" : "block",
            }}
          >
            התחל בחינם
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              nav("/post");
            }}
            sx={{
              "&.MuiButton-root": {
                borderRadius: "30px",
              },
            }}
          >
            פרסם מכרז
          </Button>
          <Button
            variant="text"
            onClick={doLogout}
            sx={{
              display: user ? "block" : "none",
              "&.MuiButton-root": {
                borderRadius: "30px",
              },
            }}
          >
            התנתק
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", width: "100%", direction: "ltr" }}>
      <AppBar
        component="nav"
        sx={{
          position: "relative",
          backgroundColor: { md: "#fff", xs: "#F5F9FF" },
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.11)",
        }}
      >
        <Toolbar
          sx={{
            minHeight: { md: "83px", sm: "75px", xs: "63px" },
            flexDirection: { md: "row", xs: "row-reverse" },
            paddingInline: { sm: "16px", xs: "12px" },
            backgroundColor: { md: "#fff", xs: "#F5F9FF" },
          }}
        >
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" }, padding: "10px" }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              display: { flexGrow: 1, xs: "none", md: "flex" },
              justifyContent: { sm: "center", md: "flex-start" },
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                nav("/post");
              }}
              sx={{
                color: "primary",
                "&.MuiButton-root": { borderRadius: "30px" },
                mr: 2,
              }}
            >
              פרסם מכרז
            </Button>

            <Button
              variant="contained"
              onClick={() => openModal()}
              sx={{
                color: "primary",
                display: user ? "none" : "flex",
                "&.MuiButton-root": { borderRadius: "30px" },
                mr: 1,
              }}
            >
              התחל בחינם
            </Button>

            <Button
              onClick={() => {
                nav("/login");
              }}
              sx={{
                color: "primary",
                display: user ? "none" : "flex",
                "&.MuiButton-text": { borderRadius: "30px" },
                "&.MuiButton-root": {
                  padding: "6px 20px",
                },
              }}
            >
              התחבר
            </Button>

            {/* when user loggedin */}
            {user && (
              <Box
                sx={{
                  height: "43px",
                  width: "100%",
                  maxWidth: "270px",
                }}
              >
                <UserAccordion user={user} doLogout={doLogout} />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: { md: "none", xs: "flex" },
              flexGrow: { sm: "0", xs: "0.8" },
              justifyContent: "flex-end",
              paddingInlineEnd: "10px",
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/logo-main.png")}
              alt="logo"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: { xs: "48px", sm: "58px", md: "67px" },
                maxWidth: { xs: "90px", sm: "110px", md: "126px" },
              }}
            />
          </Box>

          <Box
            sx={{
              display: { flexGrow: 0.8, xs: "none", sm: "flex" },
              justifyContent: { sm: "space-around" },
            }}
          >
            {navItems.reverse().map(({ text, to }, index) => {
              let count = 0;
              let urlPath = "";
              window.location.pathname.split("").forEach((char) => {
                if (char === "/") count++;

                if (count < 2) {
                  urlPath = urlPath + char;
                }
              });

              return (
                <Button
                  key={text}
                  href={to}
                  sx={{
                    "&.MuiButton-text": {
                      color: urlPath === to ? "#1C7CFF" : "#000000",
                      borderRadius: "20px",
                    },
                    "&.MuiButton-root": {
                      padding: { md: "5px 15px", xs: "3px 10px" },
                      fontSize: {
                        lg: "17px",
                        md: "14px",
                        sm: "13px",
                        xs: "14px",
                      },
                    },
                  }}
                  // onClick={(ev) => {
                  //   ev.stopPropagation();
                  //   setCurrPage(index);
                  // }}
                >
                  {text}
                </Button>
              );
            })}
          </Box>

          <Box
            sx={{
              display: { md: "flex", xs: "none" },
              flexGrow: { sm: "0", xs: "0.8" },
              justifyContent: "flex-end",
              paddingInlineEnd: "10px",
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/logo-main.png")}
              alt="logo"
              sx={{
                width: "100%",
                height: "100%",
                maxHeight: { xs: "48px", sm: "58px", md: "67px" },
                maxWidth: { xs: "90px", sm: "110px", md: "126px" },
              }}
            />
          </Box>

          <Button
            variant="outlined"
            sx={{
              display: { md: "none", xs: "block" },
              color: "primary",
              "&.MuiButton-root": {
                borderRadius: "20px",
                fontSize: { sm: "12px", xs: "14px" },
                paddingBlock: "4px",
                fontWeight: "400",
              },
              maxWidth: "124px",
              width: "100%",
              marginInlineEnd: "auto",
            }}
            onClick={() =>
            {
              nav("/post");
            }}
          >
            פרסם מכרז
          </Button>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          anchor="right"
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: "4000",
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            "& .MuiBackdrop-root": {
              boxSizing: "border-box",
              width: "100%",
              background: "rgba(0, 53, 125, 0.5)",
              backdropFilter: "blur(6px)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
