import { Box, Typography } from "@mui/material";
import React from "react";

export default function InputValidationText({ text, color }) {
  return (
    <Box>
      <Typography
        sx={{ color: color, fontSize: "14px", unicodeBidi: "plaintext" }}
      >
        {text}
      </Typography>
    </Box>
  );
}
