export const process =
{
    env: {
        REACT_APP_SUMIT_ID: 126172704,
        REACT_APP_SUMIT_PUBKEY: 'lsbcRAQZOXaNxTVScaTBCEfD2kaRxFGOQ1RRgz6S5m8JCiMRl8'
    }
}
export const LOCALSTORAGE_KEYS = {
    offers: "offers",
    iframe: "iframeAids",
    offerGivers: "offerGivers",
    loggedInUser: "loggedinUser",
    categories: "categories",
    categoriesV2: "categoriesV2",
};
export const AppwideValues =
{
    price: {
        month: 220,
        year: 2200,
    },
    serviceURL: "https://kesher-business.co.il/",
    // serviceURL: "http://localhost:3005/",
    // serviceURL: "/",
    appVersion: '1.3.2',
    themeColors:{
        red: '#D73129',
        green:'#00EF0A',
        yellow:'#FFCC32',
        blue:'#1C7CFF',
    }
}
