import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import
{
  TableHead,
  TableBody,
  TableRow,
  TableContainer,
  Table,
  Paper,
  Box,
  Button,
  Typography,
} from "@mui/material";

import
{
  Tbutton,
  StyledTableCell,
  LockedTableCell,
  StyledTableRow,
} from "../styledComponents/homePage/SecFourStyledCmps";
import { useParams } from "react-router-dom";
import { iframeData } from "../store/actions/aidActions";

export default function NewIFrame()
{
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const { sid } = useParams();
  const user = useSelector((state) => state.userModule.user);

  const isSpecialFormatSid = sid === "sidD091E4F4A043BA593FC2CCF92B8C367D84A883B";
  const isNoLinkButton = () => sid === "sidD091E4F4A043BA593FC2CCF92B8C367D84A883B";
  function ButtonConditionalLink(props)
  {
    return isNoLinkButton() ?
      <Fragment>
        {props.children}
      </Fragment> :
      <a href={`https://kesher-business.co.il/?affiliate=${sid}`} target="_blank" rel="noopener" style=
        {{
          color: 'inherit',
          textDecoration: 'inherit',
        }}>
        {props.children}
      </a>

  }

  useEffect(() =>
  {
    fetchIframeData();
  }, []);

  const fetchIframeData = async () =>
  {
    const data = await dispatch(iframeData(sid));
    console.log(data);
    setRows([...data]);
  };

  const handleClick = () =>
  {
    const targetWindow = window.opener;
    if (targetWindow)
    {
      targetWindow.postMessage("offer clicked", "*");
    }
    // if (sid ==='sidC00b92e92793dda8468698ec229bf579129ba30')
    // {
    //   window.open('https://kesher-business.co.il/?affiliate=sidC00b92e92793dda8468698ec229bf579129ba30','_blank')
    // }
  };

  if (!rows.length || !rows) return;

  return (
    <Box>
      <Box sx={{ pt: '8px' }}>
        <TableContainer
          component={Paper}
          sx={{
            display: { sm: "block", xs: "none" },
            borderRadius: "20px",
            mb: { md: 18, xs: 17 },
            boxShadow: "0px 8px 48px rgba(161, 194, 244, 0.23)",
            marginInline: "auto",
            width: "100%",
            maxWidth: isSpecialFormatSid
              ? "100%"
              : {
                xs: "354px",
                sm: "590px",
                md: "850px",
                lg: "1050px",
              },
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    "&.MuiTableCell-root": { paddingRight: "0px" },
                    width: "100px",
                  }}
                ></StyledTableCell>

                <StyledTableCell
                  align="right"
                  sx={{
                    width: "150px",
                    display: isSpecialFormatSid ? "none" : "table-cell",
                  }}
                >
                  תאריך יעד
                </StyledTableCell>

                <StyledTableCell align="right" sx={{ width: "150px" }}>
                  גורם מפרסם
                </StyledTableCell>

                <StyledTableCell
                  align="right"
                  sx={{ paddingRight: { xs: "10px", sm: "5px" } }}
                >
                  שם המכרז
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    align="center"
                    sx={{ "&.MuiTableCell-root": { paddingRight: "0px" } }}
                  >
                    <ButtonConditionalLink>
                      <Tbutton
                        variant="contained"
                        onClick={handleClick}
                      ></Tbutton>
                    </ButtonConditionalLink>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{ display: isSpecialFormatSid ? "none" : "table-cell" }}
                  >
                    <Box>
                      <span>למנויים בלבד</span>
                      <img src={require("../assets/icons/lock.png")} />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Box>
                      <span>למנויים בלבד</span>
                      <img src={require("../assets/icons/lock.png")} />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{
                      maxWidth: {
                        lg: "300px",
                        md: "200px",
                        sm: "150px",
                        xs: "60px",
                      },
                      "&.MuiTableCell-root": {
                        padding: {
                          lg: "16px 16px 16px 48px",
                          md: "16px 16px 16px 40px",
                        },
                      },
                    }}
                  >
                    {row.name}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            display: { sm: "none", xs: "block" },
            width: "100%",
            maxWidth: "354px",
            marginInline: "auto",
            borderRadius: "30px",
            paddingBlockStart: 2.3,
            boxShadow: "0px 8px 48px rgba(161, 194, 244, 0.68)",
            mb: 8.8,
          }}
        >
          {rows.map((row, index) => (
            <Box
              key={index}
              sx={{
                width: "100%",
                maxWidth: "315px",
                marginInline: "auto",
                mb: 3,
                "&:not(:last-of-type)": { borderBlockEnd: "1px solid #00357D" },
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #E0E0E0",
                  paddingBlockEnd: "13px",
                  mb: 2,
                }}
              >
                <Typography
                  color="primary"
                  sx={{ fontSize: "18px", fontWeight: "700", textAlign: "end" }}
                >
                  שם המכרז
                </Typography>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "400", textAlign: "end" }}
                >
                  {row.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3.4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRight: "1px solid #E0E0E0",
                    paddingRight: "15px",
                    paddingLeft: "45px",
                  }}
                >
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: "end",
                      marginBlockEnd: 1,
                    }}
                  >
                    תאריך יעד
                  </Typography>
                  {/* will be shown only for members */}
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        img: {
                          display: "inline",
                          height: "22px",
                          width: "16px",
                          position: "relative",
                        },
                      }}
                    >
                      <Typography
                        sx={{ display: "inherit", marginInlineEnd: "8px" }}
                      >
                        למנויים בלבד
                      </Typography>
                      <img src={require("../assets/icons/lock.png")} />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: "end",
                      marginBlockEnd: 1,
                    }}
                  >
                    גורם מפרסם
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        img: {
                          display: "inline",
                          height: "22px",
                          width: "16px",
                          position: "relative",
                        },
                      }}
                    >
                      <Typography
                        sx={{ display: "inherit", marginInlineEnd: "8px" }}
                      >
                        למנויים בלבד
                      </Typography>
                      <img src={require("../assets/icons/lock.png")} />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <ButtonConditionalLink>
                <Button
                  onClick={handleClick}
                  variant="contained"
                  sx={{
                    width: "100%",
                    borderRadius: "30px",
                    mb: 2.9,
                    "&.MuiButton-root": {
                      fontWeight: "700",
                      fontSize: "17px",
                      paddingBlock: "12px",
                    },
                  }}
                >
                  למסמכי המכרז
                </Button>
              </ButtonConditionalLink>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
