import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function Carousel() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mb: { md: 15, xs: "-40px" },
        position: "relative",
        top: "-80px",
        paddingInline: "10px",
      }}
    >
      <Typography
        sx={{
          mb: { md: 8, xs: 0 },
          fontSize: { xl: "45px", lg: "40px", sm: "35px", xs: "28px" },
          fontWeight: "700",
        }}
      >
        פרופיל עסקי
      </Typography>
      <Box
        component="img"
        src={require("../../assets/img/carousel.png")}
        sx={{
          maxWidth: "1360px",
          width: "100%",
          display: { sm: "block", xs: "none" },
        }}
      />

      <Box
        component="img"
        src={require("../../assets/img/carousel.png")}
        sx={{
          maxHeight: "483px",
          maxWidth: "1360px",
          width: "100%",
          display: { sm: "none", xs: "block" },
        }}
      />
    </Box>
  );
}
