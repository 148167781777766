import { Box, List, ListItemButton, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "../styledComponents/homePage/accordion";

const dropdownTitle = [
  { title: "ההצעות שלי", to: "/dashboard/user" },
  // { title: "לוח מעקב", to: "/404" },
  // { title: "ליווי הצעות", to: "/404" },
  { title: "לוח מעקב", to: "/dashboard/calendar" },
  { title: "ליווי הצעות", to: "/dashboard/accompany" },
  { title: "הגדרות פרופיל", to: "/dashboard/settings" },
];

export default function UserAccordion({ user, doLogout }) {
  const [expanded, setExpanded] = useState("");
  const nav = useNavigate();

  const { username, businessName, image } = user;

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleExpand("panel1")}
      sx={{
        display: user ? "block" : "none",
        width: "100%",

        backgroundColor: "inherit",
        boxShadow: "",
        border: "none",
        position: "relative",
        zIndex: expanded ? "2000" : "1",
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          backgroundColor: "inherit",
          fontSize: "16px",
          "& .MuiAccordionSummary-content": { margin: "0px" },
          "&.MuiAccordionSummary-root": {
            padding: "0px 8px",
            paddingInlineStart: "4px",

            svg: {
              color: "#1C7CFF",
            },
          },
        }}
      >
        {/* the user name */}
        <Box
          sx={{
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#1C7CFF",
              fontWeight: "600",
              position: "relative",
              fontSize: "16px",
              mb: "-2px",
            }}
          >
            {username}
          </Typography>
          <Typography
            sx={{
              color: "#808080",
              fontSize: "16px",
            }}
          >
            {businessName}
          </Typography>
        </Box>

        {/* user image */}
        <Box
          component="img"
          src={image}
          sx={{ height: "55px", width: "55px", ml: "16px" }}
        />
      </AccordionSummary>

      {/* dropdown */}
      <AccordionDetails
        sx={{
          paddingBlockEnd: "8px",
          paddingBlockStart: "0px",
          paddingInline: 0,
          flexDirection: "column",
          backgroundColor: "#ffffff",
          borderRadius: "0px 0px 10px 10px ",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

          "&.MuiAccordionDetails-root": {
            boxShadow: "0px 20px 20px 2px #00000026",
          },
        }}
      >
        <List sx={{ paddingBlockEnd: "0px" }}>
          {dropdownTitle.map(({ title, to }) => {
            return (
              <ListItemButton
                key={title}
                sx={{ justifyContent: "end", paddingBlock: "15px" }}
                onClick={() => {
                  nav(to);
                  setExpanded("");
                }}
              >
                <Typography sx={{ color: "#0346A0" }}>{title}</Typography>
              </ListItemButton>
            );
          })}
          <ListItemButton
            sx={{ justifyContent: "end", paddingBlock: "15px" }}
            onClick={() => {
              doLogout();
            }}
          >
            <Typography sx={{ color: "#0346A0" }}>התנתק</Typography>
          </ListItemButton>
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
