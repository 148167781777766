import axios from 'axios';
import { storageService } from './storageService.service.js'
import { process, AppwideValues, LOCALSTORAGE_KEYS } from '../env.js';
import { Store, ReactiveValue, makeCachedCollection } from '../util/store.js';
import { msecHour } from "../util/time";



const REG_STEPS_KEY = "regSteps";
const LOGGEDIN_USER_KEY = "loggedinUser";
const URL = AppwideValues.serviceURL;

let user = null;
const userStore = new Store(null);

const loadUserStore = makeCachedCollection(userStore, "users/user-data", x => x, LOCALSTORAGE_KEYS.loggedInUser, msecHour)

async function forgotPassword(email)
{
    try
    {
        const magicLink = await axios.post(`${URL}auth/sendMagicLink`, { "destination": email })
        return magicLink
    } catch (err)
    {
        console.log(err);
    }
}

async function sendRegData(data, pageNum)
{
    try
    {
        const response = await axios.post(`${URL}users/register${pageNum}`, data)
        // if (pageNum === 3 && res.data) {
        //     const magicLink = await axios.post(`${URL}auth/sendMagicLink`,
        //         { "destination": data.email })
        // }
        // _storeData(data, pageNum)
        // return { data, pageNum }
        if (response.status > 299 || response < 100)
        {
            throw new Error(`${response.status}: ${response.statusText} ${response.message ?? ''}`);
        }
    } catch (err)
    {
        throw err
    }
}

class RegistrationController
{
    static #fields =
        {
            email: String,
            username: String,
            businessName: String,
            phoneNumber: String,
            businessType: String,
            intrestRegion: Array,
            subscriptions: Array,
            businessDescription: String,
            token: String,
            isAnnually: Boolean,
            affiliates: Array,
        };
    static #fieldValidators =
        {
            email: x => x.match(new RegExp(/[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/, "gi")),
            username: x => x.match(new RegExp(/^.{2,40}$/, "g")),
            businessName: x => x.match(new RegExp(/^.{2,40}$/, "g")),
            phoneNumber: x => x.match(new RegExp(/(?:\+|0)\d{6,16}/, "g")),
            businessType: x => x.match(new RegExp(/(?:exempt)|(?:Licenced)|(?:ltd)|(?:association)/, "g")),
            intrestRegion: x => x,
            subscriptions: x =>
            {
                if (x.length > 5 || x.length < 1)
                { return false; }
                const cats = storageService.load("categories")
                if (cats)
                {
                    for (const cat of x)
                    {
                        if (!cats.includes(cat))
                        { return false; }
                    }
                }
                return true;
            },
            businessDescription: x => x.match(new RegExp(/^.{0,1020}$/, "s")),
            token: x => true,
            isAnnually: x => true,
            affiliates: x => x === null || x instanceof Array,
        };
    static #data = Object.fromEntries(Object.entries(this.#fields).map(x =>
    {
        switch (x[1])
        {
            case Boolean: return [x[0], true]
            default: return [x[0], new x[1]()]
        }
    }));
    static #localStorageKey = 'registrationData';
    static #localStorageRegistrationStage = 'registrationStage';
    static valid = function (key, val)
    {
        if (!this.#fields.hasOwnProperty(key))
        { return false; }
        const type = this.#fields[key];
        if (val instanceof type || typeof val === typeof type())
        {
            const validator = this.#fieldValidators[key];
            const v = validator(val);
            return v;//this.#fieldValidators[key](val);
        }
        return false;
    }

    static load = function ()
    {
        try
        {
            const data = storageService.load(this.#localStorageKey)
            for (const key in data)
            {
                const val = data[key]
                if (this.valid(key, val))
                {
                    this.#data[key] = val;
                }
            }
        } catch (error)
        {
            console.log(`can't retrive old registration data from storage properly, defaults will be used`)
        }
    }
    static save = function (data, n)
    {
        this.getRegFields(n).map(key =>
        {
            if (!data.hasOwnProperty(key))
            {
                return null;
            }
            const value = data[key];
            if (!this.valid(key, value))
            {
                return null;
            }
            this.#data[key] = value;
            return null;
        });
        storageService.store(this.#localStorageKey, this.#data);
    }
    static #regDataFields =
        [
            ['email', 'username', 'businessName', 'phoneNumber', 'affiliates'],
            ['email', 'businessType', 'intrestRegion', 'subscriptions', 'businessDescription', 'affiliates'],
            ['email', 'isAnnually', 'affiliates', 'token']
        ]
    static getRegFields = function (n)
    {
        return [...this.#regDataFields[n - 1]];
    }
    static getRegData = function (n)
    {
        return Object.fromEntries(this.#regDataFields[n - 1].map(key => [key, this.#data[key]]))
    }
    static setEmail = async function (email)
    {
        if (this.#data.email && email === this.#data.email)
        {
            return;
        }
        this.load();
        if (email === this.#data.email)
        {
            return;
        }
        this.#data.email = email;
        storageService.store(this.#localStorageKey, this.#data);
    }
    static register = async function (data, n)
    {
        data.affiliates = storageService.load('affiliates');
        if (!this.#fieldValidators['affiliates'](data.affiliates))
        {
            data.affiliates = null;
        }
        this.getRegFields(n).map(key =>
        {
            if (!data.hasOwnProperty(key))
            {
                const err = new Error(`missing property: ${key}`)
                err.missingProperty = key;
                throw err;
            }
            const value = data[key];
            if (!this.valid(key, value))
            {
                const err = new Error(`bad property value: ${key}, ${value}`)
                err.badProperty = key;
                err.badValue = key;
                throw err;
            }
            this.#data[key] = value;
            return null;
        });
        storageService.store(this.#localStorageKey, this.#data);
        await sendRegData(this.getRegData(n), n);
    }
    static getRegState = function ()
    {
        try
        {
            return Number(
                storageService.load(this.#localStorageRegistrationStage, 1)
            );
        }
        catch (e) { return 1; }
    }
    static setRegState = function (n)
    {
        storageService.store(this.#localStorageRegistrationStage, n);
    }
    static isAnnually = function ()
    {
        return this.#data.isAnnually
    }
    static flipIsAnnually = function (v)
    {
        this.#data.isAnnually = !this.#data.isAnnually;
    }
    static setIsAnnually = function (v)
    {
        this.#data.isAnnually = v;
    }
}

/**
 * The tokenizeCreditCard function takes a credit card number, expiration month and year, CVV code and citizen ID
 * (Israeli national ID) as parameters. It then sends them to the Sumit API server in order to get a token that can be used
 * for charging the user's credit card. The function returns this token as its result. If an error occurs during the process,
 * it throws an Error object with details about what went wrong.
 * 
 * @param CardNumber Used to Identify the card to be charged.
 * @param ExpirationMonth Used to Specify the expiration month of the credit card.
 * @param ExpirationYear Used to Determine whether the year is 2 or 4 digits.
 * @param CVV Used to Verify the card.
 * @param CitizenID Used to Identify the user in sumit's system.
 * @return A singleusetoken.
 * 
 * @doc-author Trelent
 */
async function tokenizeCreditCard(CardNumber, ExpirationMonth, ExpirationYear, CVV, CitizenID)
{
    const ExpirationYearN = Number(ExpirationYear)
    if (ExpirationYearN < 2000)
    {
        //throw Error("ExpirationYear needs to be the full 4 digit number")
        ExpirationYear = (ExpirationYearN + 2000).toString(); //shooold handle most cases
    }

    const CompanyID = process.env.REACT_APP_SUMIT_ID;
    const APIPublicKey = process.env.REACT_APP_SUMIT_PUBKEY;
    const HOST = "https://api.sumit.co.il";
    const endpoint = "/creditguy/vault/tokenizesingleusejson/";
    const payload = {
        Credentials: {
            CompanyID,
            APIPublicKey
        },
        CardNumber,
        ExpirationMonth,
        ExpirationYear,
        CVV,
        CitizenID
    };

    const response = await axios.post(HOST + endpoint, payload, {
        headers: { "Content-Type": "application/json" }
    })

    const result = response.data

    if (result.TechnicalErrorDetails || result.UserErrorMessage)
    {
        throw new Error((result.TechnicalErrorDetails ?? '') + (result.UserErrorMessage ?? ''));
    }
    if (response.status > 299 || response < 100)
    {
        throw new Error(`${response.status}: ${response.statusText} ${response.message ?? ''}`);
    }
    return result.Data.SingleUseToken;
}

/**
 * The update function updates the logged in user's details.
 *
 * 
 * @param newUserDetails Used to Update the user's details.
 * @return A promise.
 * 
 * @doc-author Trelent
 */
async function update(newUserDetails)
{
    try
    {
        const jwtToken = getToken()
        const res = await axios.patch(`${URL}users/update-user-data`, newUserDetails, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })

        const loggedinUser = getLoggedinUser()
        user = { ...loggedinUser, ...newUserDetails }
        userStore.value = user;
        setLoggedinUser(user);
        return user
    } catch (err)
    {
        console.log(err);
    }
}

async function updatePassword(newData)
{
    try
    {
        const jwtToken = getToken()
        console.log("service-change", newData);
        const res = await axios.patch(`${URL}users/update-user`, newData, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })
        return res;
    } catch (err)
    {
        throw err
    }
}

async function updateBilling(newData)
{
    try
    {
        const jwtToken = getToken()
        const res = await axios.post(`${URL}users/changeBillingInfo`, newData, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })

        // console.log("res", res);
        // _storeData(data, pageNum)
        // return { data, pageNum }
    } catch (err)
    {
        console.log(err);
    }
}

async function updateImage(imageData)
{
    try
    {
        const jwtToken = getToken()
        await axios.patch(`${URL}users/set-image`, imageData, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })

        const loggedinUser = getLoggedinUser()
        const updatedUser = { ...loggedinUser, ...imageData }
        storageService.store(LOGGEDIN_USER_KEY, updatedUser)

        return updatedUser
    } catch (err)
    {
        throw err
    }
}

async function handleLogin(userInfo)
{

    try
    {
        const { data } = await _login(userInfo)
        const token = data.token
        setToken(token)
        return token
    } catch (err)
    {
        console.log(err)
    }
}

/**
 * The getPublicUserInfo function is used to retrieve the public information of a user.
 *
 * 
 * @param token Used to Authenticate the user.
 * @return A user's public info.
 * 
 * @doc-author Trelent
 */
async function getPublicUserInfo(token)
{
    try
    {
        const res = await axios.get(`${URL}users/user-data`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
        return res.data
    } catch (err) { throw err }
}

/**
 * The setAuthToken function sets the Authorization header for all axios requests.
 *
 * 
 * @param token Used to Set the token in local storage.
 * @return The token.
 * 
 * @doc-author Trelent
 */
function setAuthToken(token)
{
    if (token) axios.defaults.headers.common["Authorization"] = token;
    else delete axios.defaults.headers.common["Authorization"];
};


/**
 * The setCurrentUser function is a redux action creator that returns an object with the type
 *  "SET_CURRENT_USER" and a payload of the userInfo parameter.
 *
 * 
 * @param userInfo Used to Store the user information in the local storage
export function login(userinfo) {
    return dispatch => {.
 * @return An object with a type property and a payload property.
 * 
 * @doc-author Trelent
 */
function setCurrentUser(userInfo)
{
    user = userInfo;
    userStore.value = user;
    storageService.store(LOGGEDIN_USER_KEY, userInfo)
    return {
        type: "SET_CURRENT_USER",
        payload: { ...userInfo }
    };
}

/**
 * The logout function removes the user-token from sessionStorage and removes the logged in user from local storage.
 * 
 * @return A promise.
 * 
 * @doc-author Trelent
 */
function logout()
{
    sessionStorage.removeItem("user-token");
    storageService.remove(LOGGEDIN_USER_KEY);
    user=null;
}

/**
 * The getLoggedinUser function returns the logged in user.
 *
 * 
 * @return The user object.
 * 
 * @doc-author Trelent
 */
function getLoggedinUser()
{
    if (!sessionStorage.getItem("user-token"))
    {
        // console.log("remove token");
        storageService.remove(LOGGEDIN_USER_KEY)
        user = null;
    }
    if (user == null)
    { user = storageService.load(LOGGEDIN_USER_KEY) }
    return user;
}
function setLoggedinUser()
{
    storageService.store(LOGGEDIN_USER_KEY, user);
}

/**
 * The getToken function returns the token stored in sessionStorage.
 *
 * 
 * @return The token from the sessionstorage.
 * 
 * @doc-author Trelent
 */
function getToken()
{
    return sessionStorage.getItem("user-token")
}

/**
 * The setToken function sets the token in session storage.
 *
 * 
 * @param token Used to Store the token in session storage.
 * @return Nothing.
 * 
 * @doc-author Trelent
 */
function setToken(token)
{
    sessionStorage.setItem("user-token", token)
}

/**
 * The _storeData function stores the data from each registration step in local storage.
 *
 * 
 * @param data Used to Store the data in local storage
export default {
    getdata,
    _storedata,
}.
 * @param pageNum Used to Determine which page the user is on.
 * 
 * 
 * @doc-author Trelent
 */
function _storeData(data, pageNum)
{
    if (pageNum !== 4)
    {
        let details = storageService.load(REG_STEPS_KEY)
        if (!details) details = { [`reg${pageNum}`]: { ...data } }
        else details = { ...details, [`reg${pageNum}`]: { ...data } }
        storageService.store(REG_STEPS_KEY, details)
    } else
    {
        storageService.remove(REG_STEPS_KEY)
    }
}

/**
 * The _login function is a helper function that uses the axios library to make an HTTP POST request to the /auth/signin endpoint of our server.
 * The userInfo object is passed as a parameter and contains username and password properties.
 * This function returns a promise, which resolves with the user token.
 * 
 * @param userInfo Used to Pass in the user's information to be used for authentication
async function _register(userinfo)
{
    try { return await axios.
 * @return A promise, which is a data type that represents the result of an asynchronous operation.
 * 
 * @doc-author Trelent + cat
 */
async function _login(userInfo)
{
    try
    {
        return await axios.post(`${URL}auth/signin`, { ...userInfo })
    } catch (err)
    {
        console.log(err)
    }

/**
 * The sendUnsubscribeEmail function sends a request to the server to send an email
 * with the unsubscribe message.
 *
 * 
 * @param message Used to Send a message to the admin.
 * @return A promise.
 * 
 * @doc-author Trelent
 */}
async function sendUnsubscribeEmail(message)
{
    try
    {
        const jwtToken = getToken()
        const res = await axios.post(`${URL}users/sendUnsubscribeRequest`, { message }, {
            headers: { Authorization: `Bearer ${jwtToken}` }
        })
        return res;
    } catch (err)
    {
        throw err
    }
}

/**
 * The getUser function returns the user object.
 *
 * 
 * @return The user object.
 * 
 * @doc-author Trelent
 */
function getUser()
{
    return user;
}


export const userService = {
    sendRegData,
    handleLogin,
    getToken,
    setAuthToken,
    setCurrentUser,
    getLoggedinUser,
    update,
    updatePassword,
    updateBilling,
    logout,
    forgotPassword,
    setToken,
    updateImage,
    getPublicUserInfo,
    tokenizeCreditCard,
    RegistrationController,
    getUser,
    sendUnsubscribeEmail,
    userStore,
}
