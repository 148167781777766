import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, IconButton, Item, Modal, Stack, Toolbar, Typography } from "@mui/material";
import { Checkbox, Link, List, Tooltip } from "@mui/material";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

import Pagination from "./pagination";
import { StyledInput } from "../../../styledComponents/signup";
import FilterDropdown from "./FilterDropdown";
import Swipeable, { Puller } from "./Swipeable";
import AidList from "./AidList";
import { setFilter } from "../../../store/actions/aidActions";
import Lottie from "react-lottie";
import Loader from "../../../assets/animations/loader.json";
import { offerService } from "../../../services/offerService.service";
import NewTable from "./NewTable";
import UserDashboard from "./UserDashboard";
import DetailComponent from "./DetailComponent";
import TaskCard from "./TaskCard";
import UploadCard from "../../UploadCard";
import EditTask, { editTaskManager } from "./EditTask";
import { BodyPreview } from "./Table";
import { offerGiverService } from "../../../services/offerGiverService";
import { Store, useStore } from "../../../util/store";
import { AppwideValues } from "../../../env";
import { useStableState } from "../../../util/StableState";


const regionNames = [
  { title: "ירושלים", value: "ירושלים" },
  { title: "השרון", value: "השרון" },
  { title: "דרום ", value: "דרום" },
  { title: "מרכז", value: "מרכז" },
  { title: "צפון", value: "צפון" },
];

const types = [
  { title: "מכרזים", value: "AUCTION" },
  { title: "מאגרים", value: "REPOSITORY" },
  { title: "הצעות אחרות", value: "OTHER" },
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const noLogo = require("../../../assets/img/close-x-drawer.png");



/** @type {StoreValue} */
const initialStoreValue = {
  offerId: null,
  isOpen: false,
  taskId: null,
  onClose: () => { },
  openOnOffer: null,
}
const accompanyDashboardContext = new Store(initialStoreValue)

/**
 * The editTask function is used to open the modal dialog for editing a task.
 *
 * 
 * @param {Task} task Used to Pass the task object to edittask function.
 * @param {string} offerId Used to Determine which offer the task belongs to.
 * @param {(Task)=>void} onClose notification function to run after closing the editor
 * @return Nothing.
 * 
 * @doc-author Trelent && Avihay
 */
function editOffer(offerId, taskId, onClose)
{
  const ctx = accompanyDashboardContext.value;
  ctx.isOpen = true;
  ctx.offerId = offerId;
  ctx.taskId = taskId;
  if (taskId != null)
  {
    const task = offerService.UserOffer.allTasksOffers.value.taskIdToTask[taskId] ??
      offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(taskId) ??
      offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(String(taskId));
    editTaskManager.editTask(task, offerId, () => { });
  }
  if (ctx.openOnOffer != null)
  {
    const offerById = offerService.UserOffer.favoriteOffers.value;
    ctx.openOnOffer(offerById[ctx.offerId]);
  }

  ctx.onClose = onClose;

  accompanyDashboardContext.value = ctx;
}
function onInit(openOnOffer)
{
  const ctx = accompanyDashboardContext.value;
  ctx.openOnOffer = openOnOffer;
  if (ctx.isOpen && ctx.offerId)
  {
    const offerById = offerService.UserOffer.favoriteOffers.value;
    openOnOffer(offerById[ctx.offerId]);
    if (ctx.taskId != null)
    {
      editTaskManager.editTask(offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(String(ctx.taskId)), ctx.offerId, () => { });
    }
  }
}
function onFinal()
{
  const ctx = accompanyDashboardContext.value;
  ctx.openOnOffer = null;
  accompanyDashboardContext.value = ctx;
}
export const editOfferManager = {
  editOffer,
  onInit,
  onFinal,
};



function AccompanyDashboard()
{
  const [swipeableOpen, setSwipeableOpen, user, offers, resort] = useOutletContext();
  const [showDetails, setShowDetails] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [favourites, setFavourites] = useState([]);
  const [tasks, setTasks] = useState([]);
  const offerTasks = (selectedOffer && user.extraInfo?.offerData?.[selectedOffer.id]?.tasks || {});

  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedTaskForEdit, setTaskForEdit] = useState(null);
  const [selectedEditOfferId, setSelectedEditOfferId] = useState(null);

  const [bodyModelOffer, setBodyModelOffer] = useState(false);
  const offerGiverToLogo = useStore(offerGiverService.offerGiverToLogo);
  const detailSwipeableOpen = useStableState(false);

  const uploadList = [{}, {}, {}, {}, {}, {}, {}];
  const _setSelected = (offer) =>
  {
    setSelectedOffer(offer);
    setShowDetails(offer ? true : false);
    setSelectedEditOfferId(offer?.id);
  }
  useEffect(() =>
  {
    editOfferManager.onInit(_setSelected);
    return () => { editOfferManager.onFinal() };
  }, [])
  function addTask(evt)
  {
    setTaskForEdit({
      postedDate: new Date().toISOString().substring(0, 10),
    }) //new task
    setEditOpen(true);
    editTaskManager.editTask(null, selectedEditOfferId, () => { })
  }
  function onCloseEditor(task)
  {
    if (task == null)
    {
      setEditOpen(false);
      return;
    }
    offerService.UserOffer.setTask(task, selectedEditOfferId, () => setEditOpen(false));
  }
  useEffect(() =>
  {
    const allFav = [];
    const allTask = [];

    user?.extraInfo?.favorites?.forEach((v) =>
    {
      let filtered = offers.filter((item) => item.id === v);

      allFav.push(...filtered);
    });
    setFavourites(allFav);

    setTasks(offerService.UserOffer.getAllTasks());

  }, [user?.extraInfo, offers]);
  let favorites = [];
  if (user.extraInfo?.favorites ?? false)
  {
    favorites = offers.filter((o) => user.extraInfo.favorites.includes(o.id))
  }


  const [showSendEscortRequestModal, setShowSendEscortRequestModal] = useState(false);
  const SendEscortRequestModal = function ({ closeFunc })
  {
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState('initial');
    const states = {
      initial: () =>
      {
        const onMessageChange =
          (e) =>
          {
            setMessage(e.target.value);
          };
        const sendMessage = async () =>
        {
          try
          {
            setState('sending');
            await offerService.sendEscortRequest(selectedOffer.id);
            setState('done');
          } catch (error)
          {
            setErrorMessage(error?.message + '\n' + '\n' + Object.entries(error?.response?.data ?? {}).map(([key, val]) => `${key}: ${val}`).join('\n'));
            setState('error');
          }
        }
        return (
          <>על ידי לחיצה על כפתור השליחה, אתם מאשרים כי אתם רוצים לקבל דוא"ל מקשר עסקים בנוגע לליווי הגשת המכרז או הצטרפות למאגר הנ"ל

            <br />
            <Button
              onClick={sendMessage}
            >
              שלח בקשת ליווי
            </Button>
            <br />
          </>
        )
      },
      sending: () =>
      {
        return (<Lottie
          height={400}
          width={400}
          options={defaultOptions}
          style={{ maxWidth: "600px" }}
        />)
      },
      done: () =>
      {
        return (
          <>
            <Box>ההודעה נשלחה בהצלחה</Box>
            <Box
              component="img"
              src={require("../../../assets/icons/list-v-icon.png")}
              sx={{
                // maxWidth: { sm: "330px", xs: "277px" },
                // maxHeight: "156px",
                width: "100%",
                height: "100%",
                // marginBlockStart: { md: "140px", sm: "100px", xs: "132px" },
                // marginBlockEnd: { sm: "35px", xs: "80px" },
                backgroundColor: "#fff",
              }}
            />
          </>
        )
      },
      error: () =>
      {
        return (
          <>
            <Box>ארעה שגיאה בשליחה</Box><br />
            <Box>{errorMessage.split('\n').map(text => (
              <>{text}<br /></>))}</Box><br />
            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                width: "100%",
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                  padding: { sm: "15px", xs: "10px" },
                },
              }}
              onClick={() =>
              {
                setState('initial');
              }}
            >
              חזור
            </Button>
          </>
        )
      },

    }
    return states[state]();
  }



  return (
    <>
      <Modal
        open={bodyModelOffer}
        sx={{
          backgroundColor: "rgba(3, 44, 101, 0.8)",
          overflowY: "auto",
          fontFamily: 'Assistant,sans-serif',
          direction: 'rtl',
        }}
        onClose={() => setBodyModelOffer(false)}
      >
        <>
          <IconButton
            aria-label="Close"
            onClick={() => setBodyModelOffer(false)}
            sx={{
              width: "min-content",
              marginBlock: { xl: "15px", md: "10px" },
              display: "flex",
              marginInline: "auto",
            }}
          >
            <Box
              component="img"
              src={require("../../../assets/icons/close-circle.png")}
              sx={{
                height: { md: "30px", xs: "30px" },
                width: { md: "30px", xs: "30px" },
                marginBlock: { md: 0, xs: 1 },
              }}
            />
          </IconButton>
          <Box
            sx={{
              maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
              height: "fit-content",
              width: "fit-content",
              borderRadius: "30px",
              marginInline: 'auto',
              mb: 5,
              backgroundColor: 'white',
              textAlign: 'center',
              padding: '30px',
              overflow: 'auto',
            }}

          >

            {bodyModelOffer?.body?.split('\n').map((txt, index) =>
              <>
                {index && <br />}
                {txt}
              </>)}
          </Box>
        </>
      </Modal>

      <EditTask
        open={editOpen}
        onClose={onCloseEditor}
        task={selectedTaskForEdit}
        offerId={selectedEditOfferId}
      />
      {!showDetails && (

        <Box
          sx={{
            display: { sm: 'flex', xs: 'unset' },
            flexDirection: 'column',
            // overflow: { xs: 'scroll' },
            width: '100%',
          }}
        >
          <Box
            sx={{
              flexBasis: 'auto',
              height: { sm: '50%', xs: 'unset' },
            }}>
            <UserDashboard user={user} offers={favorites} swipeableOpen={swipeableOpen} setSwipeableOpen={setSwipeableOpen} setSelected={_setSelected} />
          </Box>
          <NewTable data={user.extraInfo} aidsForDisplay={{ items: favorites }}
            setEditOpen={setEditOpen}
            setTaskForEdit={setTaskForEdit}
            addTask={addTask}
            setSelectedEditOfferId={setSelectedEditOfferId}
            setSelectedOffer={setSelectedOffer}
            offerGiverToLogo={offerGiverToLogo}
          />
          {/* <AidList user={user} aidsForDisplay={favorites} resort={resort} /> */}

        </Box>
      )}
      {showDetails && (
        <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {/* upload + tasks Start */}
            <Grid item xs={12} lg={showDetails ? 6 : 12} sx={{
              direction: 'rtl',
              padding: "24px",
              overflowY: "scroll",
              marginBlockEnd: '7vh',
            }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  lg={showDetails ? 12 : 6}
                  sx={{ display: `${showDetails ? "block" : "none"}` }}
                >
                  <div>
                    <div class="display-md">
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          padding: '10px',
                          borderBottom: "0.5px solid #C0C0C0",

                          "&.active": {
                            backgroundColor: "#F5F9FF",
                          },

                          "&:hover": {
                            backgroundColor: "#F5F9FF",
                          },
                        }}

                        onClick={() => _setSelected(null)}
                      >
                        <Box
                          sx={{
                            width: "32px",
                            height: "32px",
                            minWidth: "5rem",

                            transform: 'rotate(180deg) translate(25%, 0)',
                          }}
                          component="svg"
                          width="32" height="32" viewBox="0 0 32 -32" fill="none"
                        >
                          <path d="M11.8672 10.0803C12.2805 5.28031 14.7472 3.32031 20.1472 3.32031H20.3205C26.2805 3.32031 28.6672 5.70698 28.6672 11.667V20.3603C28.6672 26.3203 26.2805 28.707 20.3205 28.707H20.1472C14.7872 28.707 12.3205 26.7736 11.8805 22.0536" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M19.9936 16H4.82031" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M7.8026 11.5342L3.33594 16.0008L7.8026 20.4675" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />

                        </Box>
                        <Typography
                          sx={{
                            marginInlineStart: '10px',
                          }}>חזור</Typography>
                      </Stack>
                    </div>
                    <Stack
                      justifyContent="space-between"
                      alignItems="center"
                      direction={{ xs: "row", md: "row" }}
                      flexWrap="wrap"
                    >
                      <Stack
                        justifyContent="space-between"
                        alignItems="baseline;"
                        direction={{ xs: "row", md: "row" }}
                        flexWrap="wrap"
                      >
                        <Typography
                          component="h4"
                          sx={{
                            direction: "rtl",
                            fontWeight: 700,
                            fontSize: "1.125rem",
                            fontFamily: "Assistant",
                            mb: "1rem",
                          }}
                        >
                          גורם מפרסם:
                        </Typography>
                        {/* <Box
                        component="div"
                        sx={{
                          display: "block",
                          height: "4rem",
                          minWidth: "4rem",
                          borderRadius: "100%",
                          backgroundColor: "#fff",
                        }}
                      ></Box> */}
                        <Stack
                          justifyContent="space-between"
                          alignItems="center"
                          direction={{ xs: "row", md: "row" }}
                          flexWrap="wrap"
                          margin="20px"
                        >
                          <Typography>
                            {selectedOffer.offerGiverName}
                          </Typography>
                          {/* <Box
                        component="div"
                        sx={{
                          display: "block",
                          height: "4rem",
                          minWidth: "4rem",
                          borderRadius: "100%",
                          backgroundColor: "#fff",
                        }}
                      ></Box> */}
                          <Box
                            component="div"
                            sx={{
                              display: "block",
                              height: "4rem",
                              minWidth: "4rem",
                              borderRadius: "100%",
                              backgroundColor: "#fff",
                            }}
                          >
                            <img
                              style={{
                                display: "block",
                                height: "100%",
                                width: "100%",
                              }}
                              src={offerGiverToLogo?.[selectedOffer.offerGiverName] ?? noLogo}
                              alt="logo"
                            />
                          </Box>
                        </Stack>
                      </Stack>

                      <Box
                        component={Button}
                        sx={{
                          width: "157px",
                          height: "36px",
                          flexShrink: 0,
                        }}

                        onClick={() => setShowSendEscortRequestModal(true)}
                      >
                        <Box
                          sx={{
                            /* Auto layout */
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "0px 10px",
                            gap: "5px",

                            position: "absolute",
                            width: "157px",
                            height: "36px",

                            background: "#1C7CFF",
                            borderRadius: "50px",
                          }}
                        >
                          <Box
                            sx={{
                              /* Auto layout */
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0px",
                              gap: "5px",

                              width: "79px",
                              height: "36px",


                              /* Inside auto layout */
                              flex: "none",
                              order: "0",
                              alignSelf: "stretch",
                              flexGrow: "0",
                            }}
                          >
                            <Box

                              component="img"
                              src={require("../../../assets/icons/people.png")}
                              sx={{
                                position: "absolute",
                                width: "24px",
                                height: "24px",
                                left: "10px",
                                // top: "0px",
                              }}

                            >

                            </Box>
                            <Typography
                              sx={{
                                textAlign: "center",
                                fontWeight: 700,
                                color: "#FFFFFF",
                              }}
                            >
                              לבקשת ליווי
                            </Typography>
                          </Box>
                        </Box>

                      </Box>
                      <Modal
                        sx={{
                          backgroundColor: "rgba(3, 44, 101, 0.8)",
                          overflowY: "auto",
                          fontFamily: 'Assistant,sans-serif',
                          direction: 'rtl',
                        }}
                        open={showSendEscortRequestModal}
                        onClose={() => setShowSendEscortRequestModal(false)}
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"

                      >
                        <>
                          <IconButton
                            aria-label="Close"
                            onClick={() => setShowSendEscortRequestModal(false)}
                            sx={{
                              width: "min-content",
                              marginBlock: { xl: "15px", md: "10px" },
                              display: "flex",
                              marginInline: "auto",
                            }}
                          >
                            <Box
                              component="img"
                              src={require("../../../assets/icons/close-circle.png")}
                              sx={{
                                height: { md: "30px", xs: "30px" },
                                width: { md: "30px", xs: "30px" },
                                marginBlock: { md: 0, xs: 1 },
                              }}
                            />
                          </IconButton>
                          <Box
                            sx={{
                              maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
                              height: "fit-content",
                              width: "fit-content",
                              borderRadius: "30px",
                              marginInline: 'auto',
                              mb: 5,
                              backgroundColor: 'white',
                              textAlign: 'center',
                              padding: '30px',
                            }}

                          >

                            <SendEscortRequestModal></SendEscortRequestModal>
                          </Box>
                        </>
                      </Modal>
                      <Stack
                        alignItems="right"
                        direction={{ xs: "column", md: "column" }}
                        gap="1rem"
                        sx={{
                          mb: "1rem",
                        }}
                      >
                        <Typography
                          component="h4"
                          sx={{
                            direction: "rtl",
                            fontWeight: 400,
                            fontSize: "1.125rem",
                            fontFamily: "Assistant",
                            textAlign: 'end',
                          }}
                        >
                          תאריך יעד: <b>{selectedOffer.lastDateToSubmit}</b>
                        </Typography>
                        {selectedOffer?.extraInfo?.updatedDate && (
                          <Typography
                            component="h4"
                            sx={{
                              direction: "rtl",
                              fontWeight: 400,
                              fontSize: "1.125rem",
                              fontFamily: "Assistant",
                              textAlign: 'end',
                            }}
                          >
                            תאריך עדכון: <b>{new Date(selectedOffer.extraInfo.updatedDate).toISOString().substring(0, 10)}</b>
                          </Typography>)}

                      </Stack>
                    </Stack>
                    <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                      <span
                        style={{
                          fontWeight: "600",
                          textDecoration: "underline",
                          marginInlineEnd: "5px",
                        }}
                      >
                        אזור:
                      </span>
                      {selectedOffer.region}
                    </Typography>
                    <Stack
                      alignItems="center"
                      justifyContent="space-between"
                      direction={{ xs: "row", md: "row" }}
                      gap="1rem"
                      sx={{
                        mb: "1rem",
                      }}
                    >


                      {selectedOffer.originalId && (
                        <Typography sx={{ fontSize: "14px", marginBlockEnd: "10px" }}>
                          <span
                            style={{
                              fontWeight: "600",
                              textDecoration: "underline",
                              marginInlineEnd: "5px",
                            }}
                          >
                            מס' מכרז:
                          </span>
                          {selectedOffer.originalId}
                        </Typography>)}


                    </Stack>

                    <Typography
                      component="h5"
                      sx={{
                        direction: "rtl",
                        fontWeight: 400,
                        fontSize: "1rem",
                        fontFamily: "Assistant",
                        color: "#646464",
                        py: "1rem",
                        borderTop: "0.25px solid #00357D",
                        borderBottom: "0.25px solid #00357D",
                      }}
                    >
                      {selectedOffer?.name?.split('\n').map(text => (
                        <>{text}<br /></>))}
                    </Typography>
                    <Box
                      sx={{
                        textAlign: "right",
                        paddingInline: "50px",
                        // maxWidth: { lg: "80%", md: "80%" },
                      }}
                    >
                      <BodyPreview offer={selectedOffer} openBody={setBodyModelOffer} />
                      <Typography sx={{ textAlign: "center", fontWeight: "600" }}>
                        קישורים נוספים
                      </Typography>
                      <List
                        sx={{
                          direction: 'ltr',
                          textAlign: 'start',
                        }}>
                        {offerService.registrationLinksFromOffer(selectedOffer).map((link, index) => (
                          <>
                            <Box
                              sx={{
                                borderBlock: '1px solid rgba(0,0,0,0.1)',
                                marginBlock: '-1px',
                                wordBreak: "break-word"
                              }}
                            >
                              <Link
                                key={index}
                                href={decodeURI(link)}
                                target="_blank"
                                underline="none"

                              >
                                {decodeURI(link)}
                              </Link></Box>
                          </>
                        ))}
                      </List>
                    </Box>
                  </div>
                </Grid>

                <Grid item xs={12} lg={showDetails ? 12 : 6}>
                  <Typography
                    component="h4"
                    sx={{
                      direction: "rtl",
                      fontWeight: 700,
                      fontSize: "1.125rem",
                      fontFamily: "Assistant",
                      mb: "1rem",
                    }}
                  >
                    משימות
                  </Typography>
                  <Stack direction="column" gap="0.5rem">
                    {Object.values(offerTasks).map((task) => (
                      <TaskCard
                        key={task.id}
                        task={task}
                        setOpen={setEditOpen}
                        setSelected={setTaskForEdit}
                      />
                    ))}
                    <Stack
                      direction="row"
                      gap="1rem"
                      sx={{ display: `${showDetails ? "flex" : "none"}` }}
                    >
                      <Button
                        type="button"
                        sx={{
                          backgroundColor: "#fff",
                          border: "1px solid #1c7cff3f",
                          borderRadius: "50px",
                          flex: 1,
                          justifyContent: "space-between",
                          px: "1rem",
                        }}
                      >
                        <span>{Object.keys(offerTasks).length} משימות</span>
                      </Button>
                      <Button
                        onClick={addTask}
                        type="button"
                        sx={{
                          backgroundColor: "#fff",
                          border: "1px dashed #1C7CFF",
                          borderRadius: "50px",
                          flex: 1,
                          justifyContent: "space-between",
                          px: "1rem",
                        }}
                      >
                        <span>הוסף משימה</span>
                        <Box
                          component="img"
                          src={require("../../../assets/icons/add-circle.png")}
                          sx={{ height: "16px", width: "16px" }}
                        />
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
                {/* <Grid item xs={12} lg={showDetails ? 12 : 6}>
                <div>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      padding: "1.25rem",
                      borderRadius: "15px",
                      boxShadow: "2px 4px 4px #1c7cff38",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        direction: "rtl",
                        fontWeight: 700,
                        fontSize: "1.125rem",
                        fontFamily: "Assistant",
                        mb: "1rem",
                        pb: "1rem",
                        borderBottom: "1px solid #000000",
                      }}
                    >
                      צרף קבצים
                    </Typography>
                    <div>
                      <Grid container spacing={2}>
                        {uploadList.map((uploadList) => (
                          <Grid item xs={6} md={4} xl={4}>
                            <UploadCard />
                          </Grid>
                        ))}
                        <Grid item xs={6} md={4} xl={4}>
                          <Box
                            as="label"
                            htmlFor="upload"
                            sx={{
                              backgroundColor: "#F5F9FF",
                              border: "2px dashed #00357D",
                              borderRadius: "0 0 2.375rem 0",
                              height: "9.3125rem",

                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",

                              direction: "ltr",
                            }}
                          >
                            <span>
                              <img src="/assets/add-square.svg" alt="..." />
                            </span>
                            <input
                              type="file"
                              name="upload"
                              id="upload"
                              style={{ display: "none" }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </div>
              </Grid> */}
              </Grid>
              {/* === */}



            </Grid>
            <div class="display-md">
              <Swipeable open={detailSwipeableOpen}>
                {OfferList(showDetails, _setSelected, favorites, selectedOffer, offerGiverToLogo)}
              </Swipeable>
              <Stack
                direction="column"
                alignItems="center"
                sx={{
                  // paddingBottom: '25vh',
                  // borderBottom: "0.5px solid #C0C0C0",
                  // height: '100%',
                  // right: 0,
                  // zIndex: 1199,
                  // position: "fixed",
                  justifyContent: "center",

                  "&.active": {
                    backgroundColor: "#F5F9FF",
                  },

                  "&:hover": {
                    backgroundColor: "#F5F9FF",
                  },
                }}

                onClick={() => detailSwipeableOpen.set(true)}
              >
                <Box sx={{ height: "12vh" }} />
                <Box sx={{position:"relative", zIndex:1199}}>
                <Puller />
                <Box
                  sx={{
                    width: "32px",
                    height: "32px",
                    // minWidth: "5rem",

                    transform: 'rotate(180deg) translate(0%, 0)',
                  }}
                  component="svg"
                  width="32" height="32" viewBox="0 0 32 -32" fill="none"
                >
                  <path d="M11.8672 10.0803C12.2805 5.28031 14.7472 3.32031 20.1472 3.32031H20.3205C26.2805 3.32031 28.6672 5.70698 28.6672 11.667V20.3603C28.6672 26.3203 26.2805 28.707 20.3205 28.707H20.1472C14.7872 28.707 12.3205 26.7736 11.8805 22.0536" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M19.9936 16H4.82031" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M7.8026 11.5342L3.33594 16.0008L7.8026 20.4675" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />

                </Box>
                <Typography
                  sx={{
                    marginInlineStart: '10px',
                    writingMode: "vertical-rl",
                    textOrientation: "mixed",
                  }}>הצעות</Typography>
                <Puller />
                </Box>
                {/* <Box sx={{ height: "50vh" }} /> */}
              </Stack>
            </div>
          </div>
          {/* upload + tasks End */}
          {/* Details Start */}
          <div class="display-md_">
            {OfferList(showDetails, _setSelected, favorites, selectedOffer, offerGiverToLogo)}
          </div>
          {/* Details End */}
          <Box
            sx={{ height: "7vh" }} />
        </>



      )
      }
    </>

  );
}

AccompanyDashboard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default AccompanyDashboard;
export function OfferList(showDetails, _setSelected, favorites, selectedOffer, offerGiverToLogo)
{
  return <Grid
    item
    xs={12}
    lg={showDetails ? 6 : 12}
    sx={{
      display: `${showDetails ? "block" : "none"}`,
      marginBlockEnd: '7vh',
      direction: 'rtl'
    }}
  >
    <Box
      component="ul"
      sx={{
        margin: 0,
        padding: 0,
        height: "100%",
        backgroundColor: "#fff",
        boxShadow: "-2px 0px 27px #00000021",
        overflow: "auto",
        // display: "none",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          padding: '10px',
          borderBottom: "0.5px solid #C0C0C0",

          "&.active": {
            backgroundColor: "#F5F9FF",
          },

          "&:hover": {
            backgroundColor: "#F5F9FF",
          },
        }}

        onClick={() => _setSelected(null)}
      >
        <Box
          sx={{
            width: "32px",
            height: "32px",
            minWidth: "5rem",

            transform: 'rotate(180deg) translate(25%, 0)',
          }}
          component="svg"
          width="32" height="32" viewBox="0 0 32 -32" fill="none"
        >
          <path d="M11.8672 10.0803C12.2805 5.28031 14.7472 3.32031 20.1472 3.32031H20.3205C26.2805 3.32031 28.6672 5.70698 28.6672 11.667V20.3603C28.6672 26.3203 26.2805 28.707 20.3205 28.707H20.1472C14.7872 28.707 12.3205 26.7736 11.8805 22.0536" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M19.9936 16H4.82031" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.8026 11.5342L3.33594 16.0008L7.8026 20.4675" stroke="#1C7CFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />

        </Box>
        <Typography
          sx={{
            marginInlineStart: '10px',
          }}>חזור</Typography>
      </Stack>
      {favorites.map((detailList) => (
        <DetailComponent data={detailList} selected={selectedOffer} setSelected={_setSelected} logo={offerGiverToLogo?.[detailList.offerGiverName] ?? noLogo} />
      ))}
    </Box>
  </Grid>;
}
