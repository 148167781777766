import { Button, IconButton, Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import Lottie from "react-lottie";
import Loader from "../../../assets/animations/loader.json";
import { AppwideValues } from "../../../env";
import { offerService } from "../../../services/offerService.service";
import { userService } from "../../../services/userService.service";
import { Store, useStore } from "../../../util/store";


const URL = AppwideValues.serviceURL;

const { useStableState } = require("../../../util/StableState");
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

/**
 * Function to send an event to email manager.
 * @returns {Object} An object containing the following properties:
 *  - `open`: A function to open the event.
 *  - `close`: A function to close the event.
 *  - `context`: A store object containing the current state of the event.
 */
export const sendEventToEmailManager = (() =>
{
    /**
     * The initial value for the event state.
     * @typedef {Object} StoreInitialValue
     * @property {boolean} open - Whether the event is currently open.
     * @property {Offer} offer - The offer associated with the event.
     * @property {string} offerId - The ID of the offer associated with the event.
     * @property {Task} task - The task associated with the event.
     * @property {string} taskId - The ID of the task associated with the event.
     */
    const /** @type {StoreInitialValue} */
        storeInitialValue = { open: false, offer: null, offerId: null, task: null, taskId: null };
    const context = new Store(storeInitialValue);
    const taskDB = offerService.UserOffer.allTasksOffers;
    const offerdb = offerService.UserOffer.favoriteOffers;

    /**
     * Function to open the event.
     * @param {Object} params - An object containing the following parameters:
     *  - `offer`: The offer associated with the event.
     *  - `offerId`: The ID of the offer associated with the event.
     *  - `task`: The task associated with the event.
     *  - `taskId`: The ID of the task associated with the event.
     */
    function open({ offer, offerId, task, taskId })
    {
        const ctx = context.value;
        if (offer || offerId || task || taskId)
        {
            if (task)
            {
                taskId = task.id;
            } else if (taskId)
            {
                task = taskDB.value.taskIdToTask.get(taskId) ?? taskDB.value.taskIdToTask.get(String(taskId)) ?? taskDB.value.taskIdToTask.get(Number(taskId));
            }
            if (offer)
            {
                offerId = offer.id;
            }
            if (!offer && !offerId && task)
            {
                taskDB.value.taskToOfferId.get(task);
            } else if (!offer && offerId)
            {
                offer = offerdb.value[offerId];
            }
            if (!offerId)
            {
                offerId = offer.id;
            }
            Object.assign(ctx, { open: true, offer, offerId, task, taskId });
        } else
        {
            ctx.open = false;
        }
        context.value = ctx;
    }

    /**
     * Function to close the event.
     */
    function close()
    {
        const ctx = context.value;
        ctx.open = false;
        context.value = ctx;
    }
    async function send()
    {
        const ctx = context.value
        const data = {
            offerId: ctx.offerId,
            taskId: String(ctx.taskId),
        };
        // try
        // {
        //     const jwtToken = userService.getToken();
        //     const res = await axios.post(`${URL}offer/sendEventToMail`, data, {
        //         headers: { Authorization: `Bearer ${jwtToken}` }
        //     })
        //     return res;
        // } catch (err)
        // {
        //     throw err
        // }
        const token = userService.getToken();
        const res = await fetch(`${URL}offer/sendEventToMail`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        return res;
    }

    return {
        open,
        close,
        context,
        send,
    };
})();

/**
 * @type {()=>{offerById:string,
 * taskId:string|null,
 * closeFunc: ()=>{} }}
*/
export const SendEventToEmail = function ({/**@type {StableState}*/ open, /**@type {string}*/offerId, /**@type {string|null}*/taskId = null, /**@type {()=>{}}*/closeFunc })
{
    const errorMessage = useStableState('');
    const context = useStore(sendEventToEmailManager.context);
    const user = userService.getUser();
    /**@type {string}*/
    const email = user.email
    const currPage = useStableState('initial');

    function onClose(e)
    {
        currPage.set('initial');
        sendEventToEmailManager.close()
    }

    /** @type{{
        display: keyof pages;
        set: (value: keyof pages) => void;
        get: () => keyof pages;
    }}*/
    /** @type {Object.<string, JSX.Element>} - react render function dict} */
    const pages = {
        initial: () =>
        {
            // const onMessageChange =
            //     (e) =>
            //     {
            //         setMessage(e.target.value);
            //     };
            const sendMessage = async () =>
            {
                // try
                // {
                // } catch (error)
                // {
                // }
                currPage.set('sending');
                try
                {
                    const res = await sendEventToEmailManager.send();
                    if (res.ok)
                        currPage.set('done');
                    else
                    {
                        errorMessage.set(res.statusText + '\n' + '\n' + Object.entries(await res.json?.() ?? {}).map(([key, val]) => `${key}: ${val}`).join('\n'));
                        currPage.set('error');
                    }
                }
                catch (e)
                {
                    errorMessage.set(e?.message ?? 'unknown error');
                    currPage.set('error');
                }
            }
            return (
                <>בלחיצה על הכפתור ישלח לכתובת הדואר:  "{email}" דואר

                    <br />
                    עם קובץ אירוע להוספה קלה ללוח השנה שלך
                    <br />
                    <Button
                        onClick={sendMessage}
                    >
                        שלח לי אירוע לדוא"ל
                    </Button>
                    <br />
                </>
            )
        },
        sending: () =>
        {
            return (<Lottie
                height={400}
                width={400}
                options={defaultOptions}
                style={{ maxWidth: "600px" }}
            />)
        },
        done: () =>
        {
            return (
                <>
                    <Box>ההודעה נשלחה בהצלחה</Box>
                    <Box
                        component="img"
                        src={require("../../../assets/icons/list-v-icon.png")}
                        sx={{
                            // maxWidth: { sm: "330px", xs: "277px" },
                            // maxHeight: "156px",
                            width: "100%",
                            height: "100%",
                            // marginBlockStart: { md: "140px", sm: "100px", xs: "132px" },
                            // marginBlockEnd: { sm: "35px", xs: "80px" },
                            backgroundColor: "#fff",
                        }}
                    />
                </>
            )
        },
        error: () =>
        {
            return (
                <>
                    <Box>ארעה שגיאה בשליחה</Box><br />
                    <Box>{errorMessage.display.split('\n').map(text => (
                        <>{text}<br /></>))}</Box><br />
                    <Button
                        variant="outlined"
                        sx={{
                            borderRadius: "30px",
                            width: "100%",
                            alignItems: "center",
                            "&.MuiButton-root": {
                                fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                                padding: { sm: "15px", xs: "10px" },
                            },
                        }}
                        onClick={() =>
                        {
                            currPage.set('initial');
                        }}
                    >
                        חזור
                    </Button>
                </>
            )
        },

    }
    return (
        <Modal
            open={context.open}
            sx={{
                backgroundColor: "rgba(3, 44, 101, 0.8)",
                overflowY: "auto",
                fontFamily: 'Assistant,sans-serif',
                direction: 'rtl',
            }}
            onClose={onClose}
        >
            <>
                <IconButton
                    aria-label="Close"
                    onClick={onClose}
                    sx={{
                        width: "min-content",
                        marginBlock: { xl: "15px", md: "10px" },
                        display: "flex",
                        marginInline: "auto",
                    }}
                >
                    <Box
                        component="img"
                        src={require("../../../assets/icons/close-circle.png")}
                        sx={{
                            height: { md: "30px", xs: "30px" },
                            width: { md: "30px", xs: "30px" },
                            marginBlock: { md: 0, xs: 1 },
                        }}
                    />
                </IconButton>
                <Box
                    sx={{
                        maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
                        height: "fit-content",
                        width: "fit-content",
                        borderRadius: "30px",
                        marginInline: 'auto',
                        mb: 5,
                        backgroundColor: 'white',
                        textAlign: 'center',
                        padding: '30px',
                    }}

                >

                    {pages[currPage.display]()}
                </Box>
            </>
        </Modal >

    );
}
