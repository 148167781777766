import React, { useState } from "react";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { AppwideValues } from "../env";
import { userService } from "../services/userService.service";

const AnnuallyBtn = ({ annually, btn }) => (
  <Box
    sx={{
      opacity: annually && !btn ? "0" : "1",
      transition: "opacity .3s ease-out",
      width: btn ? "100%" : "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingInlineStart: { md: "25px" },
      position: "relative",
    }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: { md: "column" },
        alignItems: { md: "center" },
        position: "relative",
        top: { md: "-4px" },
        ml: "6px",
      }}
    >
      <Typography
        sx={{
          fontSize: { lg: "24px", md: "20px", xs: "18px" },
          ml: { md: "0px", xs: "0px" },
          mr: { md: "0px", xs: "20px" },
        }}
      >
        שנתי
      </Typography>

      {/* <Box
        component="img"
        src={require("../assets/img/benefit-prices.png")}
        sx={{ height: { lg: "100%", md: "20px" } }}
      /> */}
      <Typography
        sx={{
          fontSize: { lg: "11px", md: "9px", xs: "9px" },
          pt: { xs: "7px", md: "3px" },
          pb: { xs: "7px", md: "3px" },
          mr: { xs: "7px", md: "3px" },
          display: { xs: "block", md: "block" },
          backgroundColor: 'gold',
          color: 'black',
          borderRadius: '15px',
        }}
      >
        חודשיים מתנה
      </Typography>
    </Box>
    <Box
      component="img"
      src={require(`../assets/img/mumlatz-prices${annually ? "-annually" : ""
        }.png`)}
      sx={{
        width: { lg: "46px", md: "40px", xs: "32px" },
        height: { lg: "46px", md: "40px", xs: "32px" },
      }}
    />
  </Box>
);

const secThreeDetails = [
  {
    imgPath: "Helping a partner",
    title: "תמיכה VIP ",
    text: "הצוות שלנו ישמח לעזור לעסק שלכם במגוון אמצעי תקשורת: צ'ט בפרופיל האישי/ whatsapp / טלפון עסקי.",
  },
  {
    imgPath: "Push notifications",
    title: "התראות שוטפות",
    text: {
      t1: "הפרופיל מתעדכן על בסיס יומי בהצעות חדשות ממגוון מפרסמים רחב הכולל:      משרדי ממשלה , עיריות ומועצות מקומיות , בתי חולים , מוסדות לימוד ותאגידים ציבוריים ועירוניים.",
      t2: " בנוסף, אנחנו אוספים עבורכם גם הצעות עבודה פומביות שמתפרסמות בפלטפורמה שלנו וברחבי הרשת.",
    },
  },
  {
    imgPath: "Online calendar",
    title: "התאמה אישית",
    text: {
      t1: "המנוי שלכם ניתן לעיצוב ושינוי בהתאם לצרכים העסקיים שלכם.",
      t2: 'כל לקוח מקבל את האפשרות להתאים את ה"סוכן החכם" באופן מדויק לעסקו באמצעות הפרמטרים הבאים :',
      list: [
        'סוג העסק (עוסק מורשה/חברה בע"מ/עמותה)',
        "מיקום גאוגרפי",
        "סוג ההצעות – מכרז, קול קורא, מאגרים והצעות פרטיות ובלעדיות",
        "פילוח קטגוריות עסקיות.",
      ],
    },
    // text: `כל לקוח מקבל את האפשרות להתאים את ה"סוכן החכם" לעסקו, פעולה זאת ניתנת לשינוי ועריכה בהגדרות הפרופיל.`,
  },
];

export default function Prices({ openModal })
{

  const [annually, setAnnually] = useState(userService.RegistrationController.isAnnually());
  useEffect(
    () => setAnnually((prevData)=>userService.RegistrationController.isAnnually()),
   );
  const [width, setWidth] = useState(window.innerWidth);
  const nav = useNavigate();

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // useEffect(() =>
  // {
  //   nav(`/prices/${annually}`, {
  //     replace: true,
  //     state: { isAnnually: annually },
  //   });
  // }, [annually]);

  const updateDimensions = () =>
  {
    // setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  const createAccuont = () =>
  {
    openModal();
  };

  return (
    <Box component="div" sx={{ position: "relative", overflow: "hidden" }}>
      <Header openModal={openModal} />
      <Box
        component="img"
        src={require("../assets/img/wave-bottom-prices.png")}
        sx={{
          display: { md: "block", xs: "none" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          bottom: { lg: "0px", md: "220px" },
        }}
      />
      <Box
        component="img"
        src={require("../assets/img/wave-bottom-prices-mobile.png")}
        sx={{
          display: { md: "none", xs: "block" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          bottom: { sm: "-370px", xs: "200px" },
        }}
      />
      <Box
        component="img"
        src={require("../assets/img/rectangle-prices.png")}
        sx={{
          position: "absolute",
          zIndex: "-1",
          bottom: {
            xl: "1130px",
            lg: "1000px",
            md: "900px",
            sm: "1120px",
            xs: "1255px",
          },
          width: { md: "100vw" },
          height: {
            xl: "960px",
            lg: "1064px",
            md: "920px",
            sm: "600px",
            xs: "600px",
          },
        }}
      />
      <Box
        component="img"
        src={require("../assets/img/wave-top-prices.png")}
        sx={{
          display: { md: "block", xs: "none" },
          position: "absolute",
          zIndex: "-1",
          top: { xl: "200px", lg: "240px", md: "170px" },
          width: "100vw",
          height: {
            xl: "1630px",
            lg: "1500px",
            md: "1180px",
            sm: "600px",
            xs: "600px",
          },
        }}
      />
      <Box
        component="img"
        src={require("../assets/img/wave-top-prices-mobile.png")}
        sx={{
          display: { md: "none", xs: "block" },
          position: "absolute",
          zIndex: "-1",
          height: { sm: "665px" },
          top: { sm: "730px", xs: "725px" },
          width: "100vw",
        }}
      />

      <Box
        component="main"
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: { md: "1360px", xs: "333px" },
          marginInline: "auto",
          direction: "rtl",
          position: "relative",
        }}
      >
        {/* title */}
        <Box
          component="div"
          sx={{
            mb: { md: "57px", xs: "60px" },
            mt: { md: "41px", xs: "25px" },
            position: "relative",
            left: { xl: width > 1665 ? "129px" : "50px" },
            paddingInlineStart: { lg: "0px", md: "15px" },
            textAlign: { md: "start", xs: "center" },
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "48px", md: "30px", xs: "24px" },
              fontWeight: "600",
              mb: { md: "0px", xs: "4px" },
            }}
          >
            שירותים ומחירים
          </Typography>
          <Typography sx={{ fontSize: { lg: "38px", md: "30px", xs: "18px" } }}>
            בחרו את המסלול המתאים לעסק שלכם וקבלו{" "}
            <span style={{ fontWeight: "600" }}>28</span> ימי ניסיון חינם
            להתנסות בפלטפורמה
          </Typography>
        </Box>

        {/* first sec */}
        <Box
          component="div"
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", xs: "column" },
            paddingInlineStart: { lg: "0px", md: "15px" },
            mb: { lg: "107px", mb: "120px", xs: "103px" },
          }}
        >
          <Box
            component="div"
            sx={{
              width: "100%",
              maxWidth: { xl: "416px", md: "350px" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {/* display: md + */}
            <Typography
              sx={{
                display: { md: "block", xs: "none" },
                fontSize: { lg: "53px", md: "43px" },
                fontWeight: "600",
                color: "#00357D",
                lineHeight: "normal",
              }}
            >
              {annually ?
                `${AppwideValues.price.year.toLocaleString('he-IL')} ₪` :
                `${AppwideValues.price.month.toLocaleString('he-IL')} ₪`}
            </Typography>
            <Typography
              sx={{
                display: { md: "block", xs: "none" },
                fontSize: {
                  lg: annually ? "43px" : "33px",
                  md: annually ? "33px" : "23px",
                },
                fontWeight: annually ? "600" : "400",
                color: "#00357D",
                lineHeight: "normal",
                height: "86px",
                mb: "36px",
              }}
            >
              {annually && "מומלץ! חודשיים מתנה "}
              {!annually && "לחודש, מסלול חודשי מתחדש אפשרות לביטול בכל עת"}
            </Typography>

            <Box
              sx={{
                background: "#F5F9FF",
                border: "1px solid rgba(28, 124, 255, 0.25)",
                borderRadius: "40px",
                width: "fill-available",
                height: { lg: "72px", md: "60px", xs: "44px" },
                alignSelf: "end",
                position: "relative",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                paddingInline: "5px",
                mb: { md: "14px", xs: "18px" },
              }}
              onClick={(ev) =>
              {
                ev.stopPropagation();
                //setAnnually(!annually);

                const b = userService.RegistrationController.isAnnually();
                userService.RegistrationController.flipIsAnnually(!userService.RegistrationController.isAnnually());
                const a = userService.RegistrationController.isAnnually();
                setAnnually(a);
                const c = a;
                //this.setState((state)=>state);
              }}
            >
              <Button
                variant="contained"
                name="annually"
                onClick={(ev) =>
                {
                  ev.stopPropagation();
                  //setAnnually(!annually);

                  const b = userService.RegistrationController.isAnnually();
                  userService.RegistrationController.flipIsAnnually(!userService.RegistrationController.isAnnually());
                  const a = userService.RegistrationController.isAnnually();
                  setAnnually(a);
                  const c = a;
                  //this.setState((state) => state);
                  // nav(`/prices/${annually}`, {
                  //   replace: true,
                  //   state: { isAnnually: annually },
                  // });
                }}
                sx={{
                  width: {
                    lg: "55%",
                    md: annually ? "55%" : "50%",
                    xs: annually ? "55%" : "45%",
                  },
                  borderRadius: "40px",
                  height: { lg: "66px", md: "56px", xs: "40px" },
                  paddingBlock: { md: "16px", xs: "0px" },
                  paddingInline: { md: "", xs: "4px" },
                  "&.MuiButton-root": {
                    fontSize: { lg: "24px", md: "20px", xs: "18px" },
                    fontWeight: "400",
                  },
                  position: "absolute",
                  zIndex: "1000",
                  transition: "left .2s ease-in-out, right .3s ease-in-out",
                  left: {
                    xl: annually ? "3px" : "184px",
                    lg: annually ? "3px" : "155px",
                    md: annually ? "3px" : "171px",
                    xs: annually ? "2px" : "180px",
                  },
                }}
              >
                {annually ? (
                  <AnnuallyBtn annually={annually} btn={true} />
                ) : (
                  "חודשי"
                )}
              </Button>

              <Typography
                sx={{
                  opacity: annually ? "1" : "0",
                  transition: "opacity .3s ease-out",
                  fontSize: { lg: "24px", md: "20px" },
                  position: "relative",
                  textAlign: "center",
                  width: "50%",
                }}
              >
                חודשי
              </Typography>

              <AnnuallyBtn annually={annually} btn={false} />
            </Box>

            {/* display: xs - sm */}
            <Typography
              sx={{
                display: { md: "none" },
                fontSize: { lg: "53px", xs: "45px" },
                fontWeight: "700",
                color: "#00357D",
                lineHeight: "normal",
                textAlign: "center",
                mb: "5px",
              }}
            >
              {annually ?
                `${AppwideValues.price.year.toLocaleString('he-IL')} ₪` :
                `${AppwideValues.price.month.toLocaleString('he-IL')} ₪`}
            </Typography>
            <Typography
              sx={{
                display: { md: "none" },
                fontSize: {
                  lg: annually ? "43px" : "33px",
                  xs: annually ? "28px" : "18px",
                },
                fontWeight: annually ? "700" : "400",
                color: "#00357D",
                lineHeight: "normal",
                height: "50px",
                textAlign: "center",
                mb: "25px",
                marginInline: "auto",
                maxWidth: { xs: annually ? "100%" : "194px" },
              }}
            >
              {annually && "מומלץ! חודשיים מתנה"}
              {!annually && "לחודש, מסלול חודשי מתחדש אפשרות לביטול בכל עת"}
            </Typography>

            <Button
              variant="contained"
              sx={{
                display: { md: "flex", xs: "none" },
                borderRadius: "30px",
                width: "100%",
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: { lg: "24px", md: "20px" },
                  paddingBlock: { lg: "6px", md: "4px" },
                },
              }}
              endIcon={
                <ChevronLeftRoundedIcon
                  sx={{
                    position: "relative",
                    top: "1px",
                    right: "10px",
                    "&.MuiSvgIcon-root": {
                      fontSize: { lg: "24px", md: "20px" },
                    },
                  }}
                />
              }
              onClick={createAccuont}
            >
              צרו מנוי כעת
            </Button>
          </Box>

          <Box
            component="img"
            src={require("../assets/img/preview-userdashboard.png")}
            sx={{
              height: "100%",
              maxHeight: { lg: "547px", md: "400px", xs: "181px" },
              width: { md: "100%", xs: "268px" },
              marginInline: "auto",
              mb: { md: "0px", xs: "15px" },
            }}
          />

          <Button
            variant="contained"
            sx={{
              display: { md: "none" },
              borderRadius: "30px",
              width: "100%",
              alignItems: "center",
              "&.MuiButton-root": {
                fontSize: { lg: "24px", xs: "17px" },
                paddingBlock: { lg: "6px", md: "4px" },
              },
            }}
            endIcon={
              <ChevronLeftRoundedIcon
                sx={{
                  position: "relative",
                  top: "1px",
                  right: "10px",
                  "&.MuiSvgIcon-root": {
                    fontSize: { lg: "24px", xs: "17px" },
                  },
                }}
              />
            }
            onClick={createAccuont}
          >
            צרו מנוי כעת
          </Button>
        </Box>

        {/* second sec */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: { lg: "240px", md: "130px", xs: "98px" },
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontSize: { lg: "48px", md: "43px", xs: "24px" },
              fontWeight: "600",
              color: "#00357D",
              textAlign: { md: "center" },
              maxWidth: "884px",
              mb: { lg: "106px", md: "80px", xs: "19px" },
            }}
          >
            בהקמת "סוכן חכם" תקבלו התראות שוטפות עבור ההצעות הבאות:
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: { md: "center" },
              flexDirection: { md: "row", xs: "column-reverse" },
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/preview2.png")}
              sx={{
                ml: { md: "90px" },
                mt: { md: "0", xs: "34px" },
                height: { lg: "360px", md: "210px", xs: "194px" },
              }}
            />
            <ul
              style={{
                color: "#00357D",
                height: "fit-content",
                fontSize: width > 1200 ? "48px" : width > 600 ? "38px" : "18px",
                margin: "0",
              }}
            >
              {[
                "מכרזים",
                "מאגרים ציבוריים",
                "קולות קוראים",
                "הצעות פומביות ובלעדיות",
              ].map((text) => (
                <li key={text}>
                  <Typography
                    sx={{ fontSize: { lg: "48px", md: "38px", xs: "18px" } }}
                  >
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>

        {/* third sec */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            mb: "57px",
            position: "relative",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: { lg: "48px", md: "43px", xs: "24px" },
              color: "#00357D",
              textAlign: "center",
              mb: { md: "65px" },
            }}
          >
            המנויים בפלטפורמת קשר מקבלים יותר
          </Typography>

          {secThreeDetails.map(({ imgPath, title, text }) =>
          {
            // for the last paragraph
            if (imgPath === "Online calendar")
            {
              return (
                <Box
                  key={imgPath}
                  sx={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: { lg: "start", md: "center" },
                    flexDirection: {
                      md: title === "התראות שוטפות" ? "row-reverse" : "row",
                      xs: "column-reverse",
                    },
                    mb: { md: "17px", xs: "54px" },
                    "&:last-of-type": {
                      mb: "2px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "588px",
                      textAlign: { md: "start", xs: "center" },

                      paddingInlineStart: { lg: "0px", md: "10px" },
                      paddingInlineEnd: "0px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: { md: "600", xs: "700" },
                        fontSize: { lg: "38px", md: "33px", xs: "18px" },
                        color: "#00357D",
                        mb: { lg: "32px", md: "20px", xs: "13px" },
                      }}
                    >
                      {title}
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{ fontSize: { lg: "24px", xs: "18px" }, m: 0 }}
                    >
                      {text.t1}
                    </Typography>
                    <Typography
                      paragraph={true}
                      sx={{
                        fontSize: { lg: "24px", xs: "18px" },
                      }}
                    >
                      {text.t2}
                    </Typography>

                    <List
                      sx={{
                        p: 0,
                        listStyle: "disc",
                        paddingInlineStart: "30px",
                      }}
                    >
                      {text.list.map((item) => (
                        <ListItem
                          key={item}
                          sx={{ display: "list-item", paddingInline: "0" }}
                        >
                          <Typography
                            sx={{
                              fontSize: { lg: "24px", xs: "18px" },
                              textAlign: "right",
                            }}
                          >
                            {item}
                          </Typography>
                        </ListItem>
                      ))}
                    </List>
                  </Box>

                  <Box
                    component="img"
                    src={require(`../assets/img/${imgPath}.png`)}
                    sx={{
                      display: { md: "block", xs: "none" },
                      height: { lg: "100%", md: "450px" },
                    }}
                  />
                  <Box
                    component="img"
                    src={require(`../assets/img/${imgPath}-mobile.png`)}
                    sx={{
                      display: { md: "none", xs: "block" },
                      width: "fit-content",
                      marginInline: "auto",
                    }}
                  />
                </Box>
              );
            }

            return (
              <Box
                key={imgPath}
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: { lg: "start", md: "center" },
                  flexDirection: {
                    md: title === "התראות שוטפות" ? "row-reverse" : "row",
                    xs: "column-reverse",
                  },
                  mb: { md: "17px", xs: "54px" },
                  "&:last-of-type": {
                    mb: "2px",
                  },
                }}
              >
                <Box
                  sx={{
                    maxWidth: "588px",
                    textAlign: { md: "start", xs: "center" },

                    paddingInlineStart:
                      title === "התראות שוטפות"
                        ? "0px"
                        : { lg: "0px", md: "10px" },
                    paddingInlineEnd:
                      title === "התראות שוטפות"
                        ? { lg: "0px", md: "10px" }
                        : "0px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: { md: "600", xs: "700" },
                      fontSize: { lg: "38px", md: "33px", xs: "18px" },
                      color: "#00357D",
                      mb: { lg: "32px", md: "20px", xs: "13px" },
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    paragraph={true}
                    sx={{ fontSize: { lg: "24px", xs: "18px" } }}
                  >
                    {title === "התראות שוטפות" ? text.t1 : text}
                  </Typography>
                  <Typography
                    paragraph={true}
                    sx={{
                      display: title === "התראות שוטפות" ? "block" : "none",
                      fontSize: { lg: "24px", xs: "18px" },
                    }}
                  >
                    {text.t2}
                  </Typography>
                </Box>

                <Box
                  component="img"
                  src={require(`../assets/img/${imgPath}.png`)}
                  sx={{
                    display: { md: "block", xs: "none" },
                    height: { lg: "100%", md: "450px" },
                  }}
                />
                <Box
                  component="img"
                  src={require(`../assets/img/${imgPath}-mobile.png`)}
                  sx={{
                    display: { md: "none", xs: "block" },
                    width: "fit-content",
                    marginInline: "auto",
                  }}
                />
              </Box>
            );
          })}
        </Box>

        {/* button sec */}
        <Box
          sx={{
            display: "flex",
            alignItems: { md: "flex-end" },
            flexDirection: { md: "row", xs: "column-reverse" },
            maxWidth: { md: "536px", xs: "100%" },
            marginInline: "auto",
            mb: { md: "87px", xs: "57px" },
            position: "relative",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              "&.MuiButton-root": {
                borderRadius: "40px",
                padding: "11px 40px",
              },
            }}
          >
            אני מעדיף לדבר עם סוכן קודם
          </Button> */}
          {/* <Box
            component="img"
            src={require("../assets/img/help-prices.png")}
            sx={{
              position: "relative",
              left: "15px",
              mb: { md: "0px", xs: "20px" },
              alignSelf: { xs: "center" },
            }}
          /> */}
        </Box>
      </Box>
      <Footer openModal={openModal} />
    </Box>
  );
}
