import React from "react";
import { Box, IconButton, InputLabel, Paper, Typography } from "@mui/material";

import { Icon } from "@iconify/react";
import { StyledInput } from "../../../styledComponents/signup";

export default function PaymentForm({
  handleChange,
  width,
  billingInfo,
  setBillingInfo,
  registrationState,
  regStateColor,
}) {
  const { cardNum, month, year, cvv, cardID, invoice, username } = billingInfo;

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        background: "#F5F9FF",
        border: "1px solid rgba(28, 124, 255, 0.25)",
        borderRadius: "15px",
        position: "relative",
        paddingBlockStart: { md: "12px", xs: "28px" },
        paddingBlockEnd: { md: "18px", xs: "24px" },
        paddingInlineEnd: { lg: "22px", md: "10px", sm: "20px", xs: "8px" },
        paddingInlineStart: {
          xl: "0px",
          lg: "48px",
          md: "10px",
          sm: "20px",
          xs: "7px",
        },
        mb: { lg: "27px", xs: "20px" },
        width: "-webkit-fill-available",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: { lg: "19px", xs: "21px" },
        }}
      >
        <Box sx={{ display: "flex", mr: { md: 8, xs: 4 } }}>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "14px",
              textAlign: "right",
              color: " #000000",
            }}
          >
            {registrationState === "active" && "פעיל"}
            {registrationState === "active trial" && "פעיל - ניסיון"}
            {registrationState === "active ending" && "פעיל - מנוי בוטל"}
            {registrationState === "inactive" && "לא פעיל"}
          </Typography>
          <Box
            sx={{
              height: "14px",
              width: "14px",
              backgroundColor: regStateColor(),
              borderRadius: "50%",
              ml: "7px",
              position: "relative",
              top: "4px",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#00357D",
            fontSize: { md: "14px", xs: "17px" },
            fontWeight: "600",
          }}
        >
          כרטיס אשראי - 1111
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xl: "row-reverse", xs: "column" },
          flexWrap: { xl: "wrap" },
        }}
      >
        {/* cardNum */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "245px",
            mb: "16px",
            height: "fit-content",
            display: { xl: "block", xs: "none" },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#000",
              position: "relative",
              right: "8.5%",
              mb: "3px",
            }}
          >
            מס' כרטיס
          </InputLabel>
          <StyledInput
            name="cardNum"
            value={cardNum}
            onChange={(ev) => {
              handleChange(ev);
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                input: {
                  paddingBlock: "6.5px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F5F9FF",
                },
              },
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mr: "25.5px",
            ml: "68px",
            height: "fit-content",
            display: { xl: "flex", xs: "none" },
          }}
        >
          {/* month */}
          <Box sx={{ width: "100%", maxWidth: "70px" }}>
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: "31.5%",
                mb: "3px",
              }}
            >
              חודש
            </InputLabel>
            <StyledInput
              name="month"
              value={month}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>

          <Icon
            icon="mdi:slash-forward"
            height="18"
            inline={true}
            style={{ position: "relative", top: "13px", marginInline: "5px" }}
          />

          {/* year */}
          <Box sx={{ width: "100%", maxWidth: "87px" }}>
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: "31.5%",
                mb: "3px",
              }}
            >
              שנה
            </InputLabel>
            <StyledInput
              name="year"
              value={year}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* mobile display */}
        <Box
          sx={{
            display: { xl: "none", xs: "flex" },
            flexDirection: { md: "row-reverse", xs: "column" },
            mb: "27px",
          }}
        >
          {/* cardNum */}
          <Box
            sx={{
              width: "100%",
              maxWidth: { md: "245px", xs: "100%" },
              height: "fit-content",
              mb: { xs: "20px" },
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: { sm: "4.5%", xs: "6.5%" },
                mb: "3px",
              }}
            >
              מס' כרטיס
            </InputLabel>
            <StyledInput
              name="cardNum"
              value={cardNum}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              mr: { md: "25.5px" },
              ml: { xl: "73px", lg: "0px" },
              height: "fit-content",
              width: "-webkit-fill-available",
            }}
          >
            {/* CVV */}
            <Box
              sx={{
                display: { md: "none", xs: "block" },
                width: "100%",
                maxWidth: { sm: "100px", xs: "80px" },
                height: "fit-content",
                mr: "auto",
              }}
            >
              <InputLabel
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#000",
                  mb: "3px",
                  maxWidth: "fit-content",
                  marginInline: "auto",
                }}
              >
                CVV
              </InputLabel>
              <StyledInput
                name="cvv"
                value={cvv}
                onChange={(ev) => {
                  handleChange(ev);
                }}
                sx={{
                  "&.MuiFormControl-root": {
                    width: "100%",
                    input: {
                      paddingBlock: "6.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#F5F9FF",
                    },
                  },
                }}
              />
            </Box>

            {/* month */}
            <Box sx={{ width: "100%", maxWidth: { sm: "100px", xs: "80px" } }}>
              <InputLabel
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#000",
                  maxWidth: "fit-content",
                  marginInline: "auto",
                  mb: "3px",
                }}
              >
                חודש
              </InputLabel>
              <StyledInput
                name="month"
                value={month}
                onChange={(ev) => {
                  handleChange(ev);
                }}
                sx={{
                  "&.MuiFormControl-root": {
                    width: "100%",
                    input: {
                      paddingBlock: "6.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#F5F9FF",
                    },
                  },
                }}
              />
            </Box>

            <Icon
              icon="mdi:slash-forward"
              height="18"
              inline={true}
              style={{
                position: "relative",
                top: "13px",
                marginInline: width > 900 ? "5px" : "15px",
              }}
            />

            {/* year */}
            <Box sx={{ width: "100%", maxWidth: { sm: "130px", xs: "100px" } }}>
              <InputLabel
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  color: "#000",
                  maxWidth: "fit-content",
                  marginInline: "auto",
                  mb: "3px",
                }}
              >
                שנה
              </InputLabel>
              <StyledInput
                name="year"
                value={year}
                onChange={(ev) => {
                  handleChange(ev);
                }}
                sx={{
                  "&.MuiFormControl-root": {
                    width: "100%",
                    input: {
                      paddingBlock: "6.5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: "#F5F9FF",
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* mobile display */}
        <Box
          sx={{
            display: { xl: "none", xs: "flex" },
            mb: "20px",
          }}
        >
          {/* CVV */}
          <Box
            sx={{
              width: "100%",
              maxWidth: "80px",
              height: "fit-content",
              mr: "25.5px",
              display: { md: "block", xs: "none" },
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: "35.5%",
                mb: "3px",
              }}
            >
              CVV
            </InputLabel>
            <StyledInput
              name="cvv"
              value={cvv}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>

          {/* fullName */}
          <Box
            sx={{
              width: "100%",
              maxWidth: { xl: width > 1655 ? "245px" : "230px" },
              height: "fit-content",
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: { sm: "4.5%", xs: "5.5%" },
                mb: "3px",
              }}
            >
              שם מלא של בעל הכרטיס
            </InputLabel>
            <StyledInput
              name="username"
              value={username}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* CVV */}
        <Box
          sx={{
            width: "100%",
            maxWidth: "80px",
            ml: "45px",
            height: "fit-content",
            display: { xl: "block", xs: "none" },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#000",
              position: "relative",
              right: "35.5%",
              mb: "3px",
            }}
          >
            CVV
          </InputLabel>
          <StyledInput
            name="cvv"
            value={cvv}
            onChange={(ev) => {
              handleChange(ev);
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                input: {
                  paddingBlock: "6.5px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F5F9FF",
                },
              },
            }}
          />
        </Box>

        {/* fullName */}
        <Box
          sx={{
            width: "100%",
            maxWidth: { xl: width > 1655 ? "245px" : "230px" },
            ml: "25.5px",
            height: "fit-content",
            display: { xl: "block", xs: "none" },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#000",
              position: "relative",
              right: "8.5%",
              mb: "3px",
            }}
          >
            שם מלא של בעל הכרטיס
          </InputLabel>
          <StyledInput
            name="username"
            value={username}
            onChange={(ev) => {
              handleChange(ev);
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                input: {
                  paddingBlock: "6.5px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F5F9FF",
                },
              },
            }}
          />
        </Box>

        {/* mobile display */}
        <Box
          sx={{
            display: { xl: "none", xs: "flex" },
            flexDirection: "column",
            width: "100%",
            maxWidth: "465px",
            alignSelf: "end",
          }}
        >
          {/* cardID */}
          <Box
            sx={{
              width: "100%",
              maxWidth: { xl: width > 1655 ? "245px" : "230px" },
              height: "fit-content",
              mb: "20px",
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: "3.5%",
                mb: "3px",
              }}
            >
              מס' הזהות של בעל הכרטיס
            </InputLabel>
            <StyledInput
              name="cardID"
              value={cardID}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>

          {/* invoice */}
          <Box
            sx={{
              width: "100%",
              maxWidth: { xl: width > 1655 ? "245px" : "230px" },
              height: "fit-content",
            }}
          >
            <InputLabel
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#000",
                position: "relative",
                right: "3.5%",
                mb: "3px",
              }}
            >
              ?חשבונית על שם
            </InputLabel>
            <StyledInput
              name="invoice"
              value={invoice}
              onChange={(ev) => {
                handleChange(ev);
              }}
              sx={{
                "&.MuiFormControl-root": {
                  width: "100%",
                  input: {
                    paddingBlock: "6.5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#F5F9FF",
                  },
                },
              }}
            />
          </Box>
        </Box>

        {/* cardID */}
        <Box
          sx={{
            width: "100%",
            maxWidth: { xl: width > 1655 ? "245px" : "230px" },
            ml: "25.5px",
            height: "fit-content",
            display: { xl: "block", xs: "none" },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#000",
              position: "relative",
              right: "8.5%",
              mb: "3px",
            }}
          >
            מס' הזהות של בעל הכרטיס
          </InputLabel>
          <StyledInput
            name="cardID"
            value={cardID}
            onChange={(ev) => {
              handleChange(ev);
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                input: {
                  paddingBlock: "6.5px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F5F9FF",
                },
              },
            }}
          />
        </Box>

        {/* invoice */}
        <Box
          sx={{
            width: "100%",
            maxWidth: { xl: width > 1655 ? "245px" : "230px" },
            height: "fit-content",
            display: { xl: "block", xs: "none" },
            mt: { xl: width < 1656 ? "15px" : "0px" },
          }}
        >
          <InputLabel
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "#000",
              position: "relative",
              right: "8.5%",
              mb: "3px",
            }}
          >
            ?חשבונית על שם
          </InputLabel>
          <StyledInput
            name="invoice"
            value={invoice}
            onChange={(ev) => {
              handleChange(ev);
            }}
            sx={{
              "&.MuiFormControl-root": {
                width: "100%",
                input: {
                  paddingBlock: "6.5px",
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#F5F9FF",
                },
              },
            }}
          />
        </Box>
      </Box>

      {/* <IconButton
        sx={{
          position: "absolute",
          left: { xl: "19px", lg: "5px", sm: "15px", xs: "10px" },
          bottom: { xl: "18px", lg: "15px" },
          top: { lg: "auto", md: "4px", sm: "20px", xs: "19px" },
        }}
        onClick={() => {
          setBillingInfo({
            email: "",
            username: "",
            cardNum: "",
            month: "",
            year: "",
            cvv: "",
            cardID: "",
            invoice: "",
          });
        }}
      >
        <Box
          component="img"
          src={require("../../../assets/icons/trash-settings.png")}
        />
      </IconButton> */}
    </Paper>
  );
}
