import { userService } from "../../services/userService.service";

const isEmpty = require("is-empty");

const INITIAL_STATE = {
    user: userService.getLoggedinUser(),
    regData: { reg1: {}, reg2: {}, reg3: {} }
}


export function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'NEXT_STEP':
            const { data, pageNum } = action.payload
            return {
                ...state,
                regData: { ...state.regData, [`reg${pageNum}`]: { ...data } }
            };
        case 'SET_CURRENT_USER':


            return {
                ...state,
                user: { ...action.payload }
            };
        case 'UPDATE_USER':

            return {
                ...state,
                user: { ...action.payload }
            };
        case 'LOGOUT':

            return {
                ...state,
                user: action.payload
            };

        default:
            return state;
    }
}