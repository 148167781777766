import
{
  Box,
  Divider,
  IconButton,
  ListItem,
  Typography,
  Collapse,
  Link,
  List,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { offerService } from "../../../services/offerService.service";
import { BodyPreview, statusCircles, TableButtons } from "./Table";

export default function AidListItem({ aid, resort, openBody, setSelected, clickableRows })
{
  const [open, setOpen] = useState(false);

  const {
    id,
    registrationLinks,
    name,
    offerGiverName,
    originalId,
    lastDateToSubmit,
    region,
    type,
  } = aid;
  function typeToHeb(type)
  {

    switch (type)
    {
      case "REPOSITORY":

        return "מאגר";
      case "OTHER":

        return "הצעות שונות";

      case "AUCTION":
      default:
        return "מכרז";
    }
  }

  return (
    <ListItem
      onClick={() =>{
        if(clickableRows)
        {
          setSelected(aid);
        }
        else
        {
         setOpen(!open)
        }
      }}
      sx={{
        width: "100%",
        maxWidth: "354px",
        display: "flex",
        boxShadow: "0px 8px 48px rgba(161, 194, 244, 0.45)",
        borderRadius: "20px",
        padding: 0,
        marginInline: "auto",
        mb: "12px",
        overflow: "hidden",
        backgroundColor: "#FFF",
        direction: "ltr",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F5F9FF",
          flex: "1",
          width: "100%",
          height: "100%",
        }}
      >
        <Box
          sx={{
            padding: "13px 12px 11px 25px",
            textAlign: "right",
          }}
        >
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: "700",
              color: "#1C7CFF",
            }}
          >
            {offerGiverName || typeToHeb(type)}
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
            {name}
          </Typography>

          <Divider sx={{ mt: 1, mb: 0.75 }} />

          {/* collapse details */}
          <Collapse in={open} orientation="vertical" sx={{}}>
            <Box component="div" sx={{}}>
              {/* region */}
              <Typography sx={{ fontSize: "16px", marginBlockEnd: "10px" }}>
                <span
                  style={{
                    fontWeight: "700",
                    color: "#1C7CFF",
                    marginInlineStart: "8px",
                  }}
                >
                  אזור:
                </span>
                {region}
              </Typography>
              <BodyPreview offer={aid} openBody={openBody}/>
              {/* links */}
              <Box sx={{}}>
                <Typography
                  sx={{
                    textAlign: "right",
                    fontWeight: "600",
                    color: "#1C7CFF",
                  }}
                >
                  : קישורים נוספים
                </Typography>
                <List
                  sx={{
                    padding: 0,
                  }}
                >
                  {offerService.registrationLinksFromOffer(aid).map((link, index) => (
                    <Link
                      key={index}
                      href={link}
                      target="_blank"
                      underline="none"
                      sx={{ color: "#000000", display: "list-item" }}
                      onClick={(ev) =>
                      {
                        ev.stopPropagation();
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          backgroundColor: "#1C7CFF",
                          borderRadius: "50%",
                          height: "7px",
                          width: "7px",
                          position: "relative",
                          top: "15px",
                        }}
                      />

                      <Typography
                        sx={{
                          textAlign: "center",
                          textDecoration: "underline",
                        }}
                      >
                        {link}
                      </Typography>
                    </Link>
                  ))}
                  <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                    <span
                      style={{
                        fontWeight: "700",
                        color: "#1C7CFF",
                        marginInlineStart: "8px",
                      }}
                    >
                      מספר מכרז:
                    </span>
                    {originalId}
                  </Typography>
                </List>
              </Box>
            </Box>
          </Collapse>

          <Divider sx={{ mt: 1, mb: 0.75, display: open ? "block" : "none" }} />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <TableButtons id={id} resort={resort}/>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "155px",
              }}
            >
              <IconButton>
                <Box
                  component="img"
                  src={require("../../../assets/icons/bin.png")}
                  sx={{
                    height: { xl: "25px", md: "22px", sm: "20px" },
                  }}
                />
              </IconButton>
              <IconButton>
                <Box
                  component="img"
                  src={require("../../../assets/icons/star-empty.png")}
                  sx={{
                    height: { xl: "35px", md: "30px", sm: "28px" },
                  }}
                />
              </IconButton>
              <IconButton>
                <Box
                  component="img"
                  src={require("../../../assets/icons/share.png")}
                  sx={{
                    height: { xl: "25px", md: "22px", sm: "20px" },
                  }}
                />
              </IconButton>
            </Box> */}
            <Box>
              {statusCircles(aid)}
              <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {lastDateToSubmit}
              </Typography>
              {/* <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
                {originalId}
              </Typography> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
}
