import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { experimental_sx as sx } from '@mui/system'


import Image from "../../assets/img/bg.png";
import MobileBcg from "../../assets/img/bcg-sec1-mobile.png"


// component properties

export const paperContainerBcgProps = {
    backgroundImage: { md: `url(${Image})`, xs: `url(${MobileBcg})` },
    backgroundPositionY: {
        xl: "115px",
        lg: "115px",
        md: "110px",
        sm: "75px",
        xs: "244px",
    },
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "contain" },
    position: "relative"

};

export const ImageProps = {
    width: '100%',
    height: '100%',
    maxWidth: { xl: '907px', lg: '750px', md: '620px', xs: "353px" },
    maxHeight: '667px',
    mt: { xs: 5, sm: 8, xs: "33px" },
    ml: { sm: 2, xl: 2 },
    mb: { xl: 6, lg: 5, md: 4, xs: 3 },
    marginInline: { xs: "auto" },
};

export const TextContainerProps = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: { md: "450px", xs: "300px" },
    mt: { xl: 27, lg: 24, md: 22, sm: 16, xs: 8 },
    marginInline: { sm: "none", xs: "auto" },
    mr: { md: 1, sm: 2, lg: 2, xl: 8 },
};


// styled components

export const MainContainer = styled(Box)(({ theme }) => (sx({
    backgroundColor: "#FFF",
    maxHeight: { md: "796px" },
    boxShadow: { md: '0px 2px 4px rgba(0, 0, 0, 0.25)' },
    mb: { lg: 12, md: 10, sm: 7, xs: 4.3 },

})))

export const SecondaryContainer = styled(Box)(({ theme }) => (sx({
    maxWidth: { lg: "1630px", xs: "100%" },
    marginInline: 'auto',
    display: "flex",
    flexDirection: { xs: "column-reverse", sm: "row" },
    justifyContent: { xs: "center", sm: "none" },
    position: "relative"
})))

export const MainTitle = styled(Typography)(({ theme }) => (sx({
    fontWeight: "600",
    textAlign: "center",
    fontSize: {
        xl: "38px",
        lg: "34px",
        md: "28px",
        sm: "22px",
        xs: "28px",
    },
    mb: { xl: 8, lg: 6, xs: 6 },
    maxWidth: { xs: "212px", sm: "none" },
})))

export const ButtonTitle = styled(Button)(({ theme }) => (sx({
    borderRadius: "40px",
    padding: { lg: "8px 50px", sm: "6px 45px", xs: "5px 40px" },
    "&.MuiButton-root": {
        fontSize: {
            xl: "28px",
            lg: "25px",
            md: "22px",
            sm: "17px",
            xs: "18px",
        },
    },
})))








