import { Box, Button, Chip, Container, Grid, IconButton, List, ListItem, ListItemButton, ListItemText, Modal, Paper, Stack, Typography } from '@mui/material';
import { useState, useSyncExternalStore } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import UploadCard from '../components/UploadCard';
import { useStableState } from '../util/StableState';
import { useStore } from '../util/store';
import { categoriesService } from "../services/categories.service";
import { Accordion, AccordionDetails, AccordionSummary, ListTitle } from '../styledComponents/homePage/accordion';
import { axiosErrorToErrorText, deleteItemFromArrayByIdentity } from '../util/actions';
import { offerService } from '../services/offerService.service';
import { useEffect } from 'react';
import { userService } from '../services/userService.service';
import Lottie from 'react-lottie';
import Loader from "../assets/animations/loader.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const postingList = [
  { text: 'חשיפה לעסקים רלוונטיים לקבלת הצעות מחיר מתאימות.' },
  { text: ' קבלת פניות ישירות לפרופיל האישי שלכם.' },
  { text: ' המחיר הטוב ביותר תוך התחייבות לאיכות העבודה.' },
];

const uploadList = [{}, {}, {}];

const PostTender = ({ openModal }) =>
{
  const selectedCats = useStableState([]);
  const loaderModalOpen = useStableState(false);
  const categories = useStore(categoriesService.categoriesStore);
  // const categories = useSyncExternalStore((f) => categoriesService.categoriesStore.subscribe(f), () => categoriesService.categoriesStore.value);//doesn't work, damn you Microso...Meta!!!!
  // console.log(categoriesService, categoriesService?.categoriesStore, categories, useSyncExternalStore, useStore)
  const [expanded, setExpanded] = useState("");
  const [data, setData] = useState({ subscriptions: [] });
  const sendingPromise = useStableState([]);
  if (categories == null)
  {
    categoriesService.loadcategoriesStore.loadStore();
  }
  const handleDelete = (subscription) =>
  {
    deleteItemFromArrayByIdentity(data.subscriptions, subscription);
    setData({ ...data });
  };
  useEffect(() =>
  {
    const user = userService.getUser()
    if (user)
    {
      const dat = {
        subscriptions: [],
        ContactName: user.username,
        CompanyName: user.businessName,
        CompanyPhone: user.phoneNumber,
        email: user.email,
        ContactPhone: user.phoneNumber,
      }
      setData(dat);
    }
  }, [])
  function handleSubmit()
  {
    const now = new Date();
    const cleandData = {
      header: data.title,
      body: data.TenderDetails,
      categories: data.subscriptions,
      contactName: data.ContactName,
      companyName: data.CompanyName,
      companyPhone: data.CompanyPhone,
      email: data.email,
      contactPhone: data.ContactPhone,
      endDate: new Date(data.endDate),
      address: data.Address,
    }
    loaderModalOpen.set(true);
    const g = sendingPromise.get();
    if (!g.length)
    {
      const p = offerService.postTender(cleandData);
      g.push(p);
      sendingPromise.set(g);
    }
  }
  const handleExpand = (panel) => (event, newExpanded) =>
  {
    setExpanded(newExpanded ? panel : false);
    // here I will set the call to the API
  };
  const handleChange = (
    { target },
    region = null,
    { name = null, subscription = null }
  ) =>
  {
    if (name === 'subscriptions' && data.subscriptions.length < 3)
    {
      if (data.subscriptions.includes(subscription))
      {
        deleteItemFromArrayByIdentity(data.subscriptions, subscription);
      }
      else
      {
        data.subscriptions.push(subscription);
      }
    }
    else
    {
      const field = target.name;
      const value = target.value;
      data[field] = value;
    }
    setData({ ...data });
  };

  function LoaderMessage({ closeFunc, response })
  {
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState('sending');
    useEffect(() =>
    {
      const g = sendingPromise.get();
      if (g.length > 0)
      {
        sendingPromise?.get()?.[0].then(
          (res) =>
          {

            g.pop();
            sendingPromise.set(g);
            if (res == "")
            { setState('done'); }
            else
            {
              const message = axiosErrorToErrorText(res);
              setErrorMessage(message);
              setState('error');
            }
          }
        )
      }
      else
      {
        debugger;
      }
    }, [sendingPromise.display])

    const states = {
      sending: () =>
      {
        return (<Lottie
          height={400}
          width={400}
          options={defaultOptions}
          style={{ maxWidth: "600px" }}
        />)
      },
      done: () =>
      {
        return (
          <>
            <Box>המכרז נשלח בהצלחה</Box>
            <Box
              component="img"
              src={require("../assets/icons/list-v-icon.png")}
              sx={{
                // maxWidth: { sm: "330px", xs: "277px" },
                // maxHeight: "156px",
                width: "100%",
                height: "100%",
                // marginBlockStart: { md: "140px", sm: "100px", xs: "132px" },
                // marginBlockEnd: { sm: "35px", xs: "80px" },
                backgroundColor: "#fff",
              }}
            />
          </>
        )
      },
      error: () =>
      {
        return (
          <>
            <Box>ארעה שגיאה בשליחה:</Box><br />
            <Box>{errorMessage.split('\n').map(text => (
              <>{text}<br /></>))}</Box><br />
            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                width: "100%",
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                  padding: { sm: "15px", xs: "10px" },
                },
              }}
              onClick={() =>
              {
                setState('loading');
                loaderModalOpen.set(false);
              }}
            >
              סגור
            </Button>
          </>
        )
      },

    }
    return states[state]();
  }

  return (
    <main>
      <Header openModal={openModal} />
      <Container
        component='section'
        sx={{
          maxWidth: { xl: '1920px', xs: '100%' },
          '&.MuiContainer-root': { width: '100%', padding: { xs: '0px' } },
          position: 'relative',
        }}
      >
        <Box
          sx={{
            direction: 'rtl',
            backgroundColor: '#F9F9F9',
            padding: { xs: '1.125rem', lg: '2.5rem' },
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: { md: '48px', sm: '35px', xs: '24px' },
            }}
          >
            פרסם מכרז
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: '#F3F3F3',
            py: { xs: '1.5rem', lg: '4rem' },
            px: { xs: '0.5rem', lg: '2.5rem' },
          }}
        >
          <form noValidate>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={5} md={4}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '15px',
                    boxShadow: '2px 4px 4px #1c7cff38',
                  }}
                >
                  <Box
                    sx={{
                      padding: '1.25rem',
                      backgroundColor: '#FFCC32',
                      borderRadius: '15px 15px 0px 0px',
                      textAlign: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '700',
                        fontSize: '1.1875rem',
                      }}
                    >
                      פרסום מכרז בפלטפורמה נותן לכם יותר!
                    </Typography>
                  </Box>
                  <Box sx={{ direction: 'rtl', padding: '1.25rem' }}>
                    <Box
                      component='ul'
                      sx={{
                        my: 0,
                        fontSize: '1.25rem',
                        fontFamily: 'Assistant',
                        fontWeight: 600,
                      }}


                    >
                      {postingList.map((postingList) => (
                        <li style={{ margin: '1.25rem 0' }}>
                          <span>{postingList.text}</span>
                        </li>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={7} md={8}>
                <div>
                  <Typography
                    component='h2'
                    sx={{
                      direction: 'rtl',
                      fontWeight: 600,
                      fontSize: '1.5rem',
                      fontFamily: 'Assistant',
                      mx: '1.5rem',
                      mb: '1rem',
                      pb: '1rem',
                      borderBottom: '1px solid #000000',
                    }}
                  >
                    פרטי המכרז
                  </Typography>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='title'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          כותרת
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='title'
                          value={data.title ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='title'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='TenderDetails'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          פרטי המכרז
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '1.25rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                            resize: 'none',
                          }}
                          component='textarea'
                          rows={6}
                          id='TenderDetails'
                          value={data.TenderDetails ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='TenderDetails'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>


                    <Grid item xs={12} sm={12} md={12}>
                      {/* categories */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "end",
                          flexDirection: "column",
                          width: "100%",
                          mt: "35px",
                          mb: { md: "0px", xs: "22px" },
                          marginInline: "auto",
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='categories'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          בחר קטגוריות רלוונטיות
                        </Typography>

                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleExpand("panel1")}
                          sx={{
                            // maxWidth: { xl: "907px", md: "784px", sm: "550px" },
                            // mb: { sm: 3.5, xs: 1 },
                            mb: "8px",
                            boxShadow: expanded
                              ? "0px 8px 48px rgba(161, 194, 244, 0.68)"
                              : "",
                            width: "100%",

                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id='panel1d-header'
                            sx={{
                              borderRadius: expanded ? "20px 20px 0 0 " : "30px",
                              border: expanded
                                ? "none"
                                : "1px solid rgba(28, 124, 255, 0.25)",
                              "& .MuiAccordionSummary-expandIconWrapper": {
                                ml: 1,
                                svg: {
                                  fontSize: { md: "14px", sm: "12px", xs: "14px" },
                                  color: "#00357D",
                                },
                              },
                              "& .MuiAccordionSummary-content": { margin: "0px" },
                              "&.MuiAccordionSummary-root": {
                                minHeight: "42px",
                                padding: "0px 3px",
                              },
                              "& .MuiTypography-root": { mr: 1 },
                              // display: 'block',
                              // direction: 'rtl',
                              // padding: '1rem',
                              // boxSizing: 'border-box',
                              backgroundColor: '#F5F9FF',
                              // border: '1px solid #1c7cff3f',
                              // borderRadius: '1.25rem',
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              fontFamily: 'Assistant',
                              resize: 'none',
                              width: '100%',
                              height: '1rem',
                            }}

                          >
                            {!data.subscriptions.length && (
                              <ListTitle
                                sx={{
                                  fontSize: { md: "14px", sm: "12px", xs: "14px" },
                                  color: "#00357D",
                                }}
                              >
                                צפו ברשימת הענפים המלאה
                              </ListTitle>
                            )}
                            {data.subscriptions.length > 0 && (
                              <Stack
                                direction="row"
                                sx={{
                                  flexWrap: "wrap",
                                  justifyContent: "end",
                                  alignItems: "center",
                                  padding: { sm: "0px", xs: "4px" },

                                  paddingBlockEnd: "0px",
                                  display: { xs: "none", sm: "flex" },
                                }}
                              >
                                {data.subscriptions.map((suscription) => (
                                  <Chip
                                    key={suscription}
                                    label={suscription}
                                    onDelete={() =>
                                    {
                                      handleDelete(suscription);
                                    }}
                                    deleteIcon={
                                      <Box
                                        component="img"
                                        src={require("../assets/icons/close-circle.png")}
                                        sx={{
                                          height: { md: "20px", sm: "18px", xs: "20px" },
                                          width: { md: "20px", sm: "18px", xs: "20px" },
                                          position: "relative",
                                          top: { md: "0px", sm: "1px" },
                                        }}
                                      />
                                    }
                                    sx={{
                                      color: "#fff",
                                      backgroundColor: "#00357D",
                                      padding: "2px",
                                      "&:not(:last-of-type)": { mr: 0.4 },
                                      fontSize: { md: "14px", sm: "12px", xs: "14px" },
                                      mb: 0.5,
                                      mt: 0.5,
                                    }}
                                  />
                                ))}
                              </Stack>
                            )}
                            {data.subscriptions.length > 0 && (
                              <ListTitle
                                sx={{
                                  fontSize: { md: "14px", sm: "12px", xs: "14px" },
                                  color: "#00357D",
                                  display: { sm: "none", xs: "block" },
                                }}
                              >
                                צפו ברשימת הענפים המלאה
                              </ListTitle>
                            )}
                          </AccordionSummary>

                          <AccordionDetails
                            sx={{
                              padding: { sm: "16px", xs: "0px" },
                              flexDirection: { sm: "row", xs: "row-reverse" },
                              justifyContent: { sm: "space-around", xs: "center" },
                            }}
                          >
                            <List
                              sx={{
                                padding: "0px",
                                width: { sm: "auto", xs: "100%" },
                                maxWidth: { sm: "none", xs: "111px" },
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                              }}
                            >
                              {categories?.map((category, index) => (
                                <ListItem
                                  key={index}
                                  disablePadding
                                  sx={{
                                    "&.MuiListItem-root:nth-child(n+5)": {
                                      borderTop: { xs: "1px solid #00000017" },
                                    },
                                    paddingBlock: "3px",
                                  }}
                                >
                                  <ListItemButton
                                    onClick={(ev) =>
                                    {
                                      handleChange(ev, null, {
                                        name: "subscriptions",
                                        subscription: category,
                                      });
                                    }}
                                    sx={{
                                      textAlign: "center",
                                      borderRadius: "30px",
                                      backgroundColor: data.subscriptions.includes(category)
                                        ? "#00357d7a"
                                        : "none",
                                    }}
                                  >
                                    <ListItemText
                                      onClick={(ev) =>
                                      {
                                        ev.stopPropagation();
                                        handleChange(ev, null, {
                                          name: "subscriptions",
                                          subscription: category,
                                        });
                                      }}
                                      primary={category}
                                      sx={{
                                        "& .MuiTypography-root": {
                                          fontSize: {
                                            xl: "18px",
                                            lg: "17px",
                                            md: "17px",
                                            xs: "13px",
                                          },
                                        },
                                      }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        <Typography
                          sx={{
                            width: "65px",
                            height: "16px",

                            fontFamily: "'Assistant'",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "12px",
                            lineHeight: "16px",

                            /* identical to box height */
                            textAlign: "center",

                            color: "rgba(0, 0, 0, 0.25)",


                            /* Inside auto layout */
                            flex: "none",
                            order: "0",
                            flexGrow: "0",
                          }}
                        >
                          עד 3 קטגוריות
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={6} sx={{ mt: '1.25rem' }}>
              <Grid item xs={12} sm={5} md={4}>
                {/* <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '1.25rem',
                    borderRadius: '15px',
                    boxShadow: '2px 4px 4px #1c7cff38',
                  }}
                >
                  <Typography
                    component='h2'
                    sx={{
                      direction: 'rtl',
                      fontWeight: 600,
                      fontSize: '1.5rem',
                      fontFamily: 'Assistant',
                      mb: '1rem',
                      pb: '1rem',
                      borderBottom: '1px solid #000000',
                    }}
                  >
                    צרף קבצים
                  </Typography>
                  <div>
                    <Grid container spacing={2}>
                      {uploadList.map((uploadList) => (
                        <Grid item xs={6}>
                          <UploadCard />
                        </Grid>
                      ))}
                      <Grid item xs={6}>
                        <Box
                          as='label'
                          htmlFor='upload'
                          sx={{
                            backgroundColor: '#F5F9FF',
                            border: '2px dashed #00357D',
                            borderRadius: '0 0 2.375rem 0',
                            height: '9.3125rem',

                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          <span>
                            <img src='/assets/add-square.svg' alt='...' />
                          </span>
                          <input
                            type='file'
                            name='upload'
                            id='upload'
                            value={data.upload ?? ''}
                            onChange={ev => handleChange(ev, null, {})}
                            style={{ display: 'none' }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </Box> */}
              </Grid>
              <Grid item xs={12} sm={7} md={8}>
                <Box
                  sx={{
                    backgroundColor: '#fff',
                    padding: '2rem',
                    boxShadow: '2px 4px 4px #1c7cff38',
                    borderRadius: '1rem',
                  }}
                >
                  <Typography
                    component='h2'
                    sx={{
                      direction: 'rtl',
                      fontWeight: 600,
                      fontSize: '1.5rem',
                      fontFamily: 'Assistant',
                      mx: '1.5rem',
                      mb: '1rem',
                      pb: '1rem',
                      borderBottom: '1px solid #000000',
                    }}
                  >
                    פרטים אישיים
                  </Typography>

                  <Grid container spacing={3}
                    sx={{ direction: "rtl" }}>
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='Contact name'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          שם איש קשר: (שם מלא)
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='ContactName'
                          value={data.ContactName ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='ContactName'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='CompanyName'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          שם החברה:
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='CompanyName'
                          value={data.CompanyName ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='CompanyName'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='CompanyPhone'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          טלפון החברה:
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='CompanyPhone'
                          value={data.CompanyPhone ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='CompanyPhone'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='email'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          מייל:
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='email'
                          value={data.email ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='email'
                          type='email'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='ContactPhone'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          טלפון איש קשר:
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='ContactPhone'
                          value={data.ContactPhone ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='ContactPhone'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='endDate'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          תאריך יעד:                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          type="date"
                          id='endDate'
                          value={data.endDate ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='endDate'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    {/* input start */}
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          direction: 'rtl',
                        }}
                      >
                        <Typography
                          component='label'
                          htmlFor='Address'
                          sx={{
                            fontWeight: 400,
                            fontSize: '1rem',
                            fontFamily: 'Assistant',
                            mx: '1.5rem',
                            mb: '0.625rem',
                          }}
                        >
                          כתובת
                        </Typography>
                        <Paper
                          sx={{
                            display: 'block',
                            padding: '1rem',
                            backgroundColor: '#F5F9FF',
                            border: '1px solid #1c7cff3f',
                            borderRadius: '3.125rem',
                            fontWeight: 400,
                            fontSize: '0.875rem',
                            fontFamily: 'Assistant',
                          }}
                          component='input'
                          id='Address'
                          value={data.Address ?? ''}
                          onChange={ev => handleChange(ev, null, {})}
                          name='Address'
                          type='text'
                          placeholder='מילוי שדה'
                        />
                      </Box>
                    </Grid>
                    {/* input end */}
                    <Typography
                      sx={{
                        fontFamily: "Assistant",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "19px",
                        letterSpacing: "0em",
                        textSlign: "right",
                      }}>
                        <p style={{marginRight:'20px'}}>
                        בלחיצה על כפתור “פרסם מכרז” אתם  מאשרי
                        ם שההצעה תועבר לאישור מנהל לאימות המידע ונכונותו</p>
                    </Typography>
                  </Grid>
                </Box>
                <Box sx={{ mt: '2.2rem', textAlign: 'left' }}>
                  <Button
                    type='button'
                    variant='contained'
                    sx={{
                      borderRadius: '30px',
                      width: { xs: '100%', md: '60%', lg: '35%' },
                      alignItems: 'center',
                      mb: '0',
                      '&.MuiButton-root': {
                        fontSize: '14px',
                        paddingBlock: '12px',
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    <ChevronLeftRoundedIcon
                      fontSize='small'
                      sx={{ position: 'relative', top: '1px', right: '10px' }}
                    />
                    פרסם מכרז
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
      <Footer openModal={openModal} />
      <Modal
        sx={{
          backgroundColor: "rgba(3, 44, 101, 0.8)",
          overflowY: "auto",
          fontFamily: 'Assistant,sans-serif',
          direction: 'rtl',
        }}
        open={loaderModalOpen.display}
        onClose={() => loaderModalOpen.set(false)}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"

      >
        <>
          <IconButton
            aria-label="Close"
            onClick={() => loaderModalOpen.set(false)}
            sx={{
              width: "min-content",
              marginBlock: { xl: "15px", md: "10px" },
              display: "flex",
              marginInline: "auto",
            }}
          >
            <Box
              component="img"
              src={require("../assets/icons/close-circle.png")}
              sx={{
                height: { md: "30px", xs: "30px" },
                width: { md: "30px", xs: "30px" },
                marginBlock: { md: 0, xs: 1 },
              }}
            />
          </IconButton>
          <Box
            sx={{
              maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
              height: "fit-content",
              width: "fit-content",
              borderRadius: "30px",
              marginInline: 'auto',
              mb: 5,
              backgroundColor: 'white',
              textAlign: 'center',
              padding: '30px',
            }}

          >
            {/* דף זה בבניה <br/>
                  <Button
                    onClick={() =>
                    {
                      window.location.href = 'mailto:info@kesher-business.co.il?subject=בקשת ביטול מנוי';
                    }}>
                  נא ליצור קשר עימנו ב<br/>
                  info@kesher-business.co.il
                  </Button> */}
            {/* {cancleSubscriptionModalState.renderFunc != null && cancleSubscriptionModal.renderFunc} */}
            <LoaderMessage></LoaderMessage>
          </Box>
        </>
      </Modal>
    </main>
  );
};

export default PostTender;
