import { Box } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { offerService } from "../../../services/offerService.service";
import { MOCKEVENTS } from "./conts";

// import { calendarService } from '../../../services/calendarService.service';
import FullCalendar from "./FullCalendar";
import { userService } from "../../../services/userService.service";
import { makePushToList } from "../../../util/actions";
import { stripTimeFromDate } from "../../../util/time";
import { useStore } from "../../../util/store";

export default function CalenderDashboard()
{
  const [, , user, offers] = useOutletContext();
  const taskData = useStore(offerService.UserOffer.allTasksOffers);
  const favoriteOffers = useStore(offerService.UserOffer.favoriteOffers);
  console.log(favoriteOffers);
  const offerEvents = Object.values(favoriteOffers).map(({ name, id, lastDateToSubmit, originalId, registrationLinks }) => ({
      title: name,
      offerId: id,
      date: new Date(lastDateToSubmit),
      color: "#1C7CFF",
      description: `id: ${originalId}\n links: ${registrationLinks}`,
      link: registrationLinks?.[0],
      type: "offer",
    }))??[];

  //todo: pull tasks from user.extraInfo.offerData[favoritIDs] instead of mock data like so:
  // const taskEvents = favoritIDs.map(id=>
  //   user.extraInfo.offerData[id]?.tasks ?? []
  //   ).flat()
  // const taskEvents = MOCKEVENTS.map(({ date, title, description, color }) => ({
  //   date,
  //   title,
  //   description,
  //   color,
  //   type: 'task',
  // }));
  const taskEvents = taskData.tasks.map(task => (
      {
        color: task?.color,
        taskId: task.id,
        offerId: taskData.taskToOfferId.get(task),
        date: new Date(task?.endDate),
        description: task?.text,
        title: task?.title,
        type: "task",
      }));

  console.log("allTask", taskEvents, offerEvents, user);


  const events = [...offerEvents, ...taskEvents];
  /** {Object.<Date, event>} */
  const dayDict = {};
  events.forEach(evt =>
  {
    const date = stripTimeFromDate(evt.date);
    makePushToList(dayDict, date, evt);
  });
  return (
    <Box
      sx={{
        height: "100%",
        width: "auto",
        flex: 1,
        // padding: "1.875rem",
        backgroundColor: "#E5E5E5",
      }}
    >
      <FullCalendar startingDate={new Date()} eventsArr={events} dayDict={dayDict} />
    </Box>
  );


}
