import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../../../styledComponents/homePage/accordion";

const regionNames = [
  { value: "ירושלים", text: "ירושלים" },
  { value: "השרון", text: "השרון" },
  { value: "דרום", text: "דרום" },
  { value: "מרכז", text: "מרכז" },
  { value: "צפון", text: "צפון" },
];

const businessTypes = [
  { value: "association", text: "עמותה" },
  { value: "ltd", text: 'חברה בע"מ' },
  { value: "Licenced", text: "עוסק מורשה" },
  { value: "exempt", text: "פטור" },
];

export default function Dropdown({ user, handleChange, type }) {
  // console.log("user", user);
  const [expanded, setExpanded] = useState("");
  const { intrestRegion, businessType } = user;

  const data = type === "intrestRegion" ? regionNames : businessTypes;

  const handleExpand = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleExpand("panel1")}
      sx={{
        width: "100%",
        backgroundColor: "#F5F9FF",
        boxShadow: "",
        border: {
          md: expanded ? "1px solid #1C7CFF" : "",
          xs: " 1px solid rgba(28, 124, 255, 0.25)",
        },
        position: "relative",
        zIndex: expanded ? "2000" : "1",
      }}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
        sx={{
          backgroundColor: { md: "inherit", xs: "#F5F9FF" },
          fontSize: "16px",
          borderRadius: expanded ? "30px 30px 0 0 " : "40px",
          "& .MuiAccordionSummary-expandIconWrapper": {
            svg: {
              fontSize: "16px",
              color: expanded ? "#1C7CFF" : "#00357D",
            },
          },
          "& .MuiAccordionSummary-content": { margin: "0px" },
          "&.MuiAccordionSummary-root": {
            padding: "0px 8px",
            paddingInlineStart: "4px",
            minHeight: "44px",
          },
          "& .MuiTypography-root": { mr: type === "intrestRegion" ? 1 : 1.3 },
        }}
      >
        <Typography
          sx={{
            color: expanded ? "#1C7CFF" : "#00357D",
            fontWeight: "700",
            position: "relative",
            left: "3px",
            unicodeBidi: "plaintext",
            fontSize: { lg: "16px", xs: "17px" },
          }}
        >
          {type !== "intrestRegion" &&
            businessTypes.map(({ text, value }) => {
              if (businessType === value) return text;
            })}
          {!intrestRegion.length && type === "intrestRegion" && "לא נבחר"}
          {intrestRegion.length === 1 &&
            type === "intrestRegion" &&
            intrestRegion[0]}
          {intrestRegion.length === 5 && type === "intrestRegion" && "כל הארץ"}
          {intrestRegion.length > 1 &&
            intrestRegion.length < 5 &&
            type === "intrestRegion" &&
            `${intrestRegion.length}` + " " + "אזורים"}
        </Typography>
        <Typography
          sx={{
            color: expanded ? "#1C7CFF" : "#00357D",
            fontSize: { lg: "16px", xs: "17px" },
          }}
        >
          {type === "intrestRegion" ? ":אזור" : ":סוג העסק"}
        </Typography>
        {!intrestRegion.length && (
          <Box
            component="img"
            src={require(`../../../assets/icons/${
              expanded ? "dashboard-uncheck" : "unchecked"
            }.png`)}
            sx={{
              mr: 1.5,
              height: { md: "16px", xs: "17px" },
              position: "relative",
              top: { md: "4.5px", xs: "5px" },
              display: type === "intrestRegion" ? "block" : "none",
            }}
          />
        )}
        {intrestRegion.length > 0 && (
          <Box
            component="img"
            src={require(`../../../assets/icons/${
              expanded ? "dashboard-check" : "checked"
            }.png`)}
            sx={{
              mr: 1.5,
              height: { md: "16px", xs: "17px" },
              position: "relative",
              top: { md: "4.5px", xs: "5px" },
              display: type === "intrestRegion" ? "block" : "none",
            }}
          />
        )}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          paddingBlockEnd: "8px",
          paddingBlockStart: "0px",
          flexDirection: "column",
        }}
      >
        <Button
          disableRipple={true}
          endIcon={
            <Box
              component="img"
              src={require(`../../../assets/icons/dashboard-${
                intrestRegion.length === 5 ? "check" : "uncheck"
              }.png`)}
              sx={{
                height: { md: "16px", xs: "17px" },
              }}
            />
          }
          sx={{
            borderBlockStart: "1px solid #1C7CFF",
            paddingBlock: "13px",
            borderRadius: "0",
            justifyContent: "flex-end",
            display: type === "intrestRegion" ? "inline-flex" : "none",
          }}
          name="כל הארץ"
          onClick={(ev) => {
            const regions = regionNames.map((reg) => reg.value);
            handleChange(ev, type, [...regions]);
          }}
        >
          <Typography>כל הארץ</Typography>
        </Button>
        {data.map((item) => {
          const { text, value } = item;
          let isChecked = null;
          if (type === "intrestRegion") {
            isChecked = intrestRegion.includes(text);
          } else {
            isChecked = businessType === value;
          }
          return (
            <Button
              key={text}
              disableRipple={true}
              endIcon={
                <Box
                  component="img"
                  src={require(`../../../assets/icons/dashboard-${
                    isChecked ? "check" : "uncheck"
                  }.png`)}
                  sx={{
                    height: { md: "16px", xs: "17px" },
                    // display: type === "intrestRegion" ? "block" : "none",
                  }}
                />
              }
              sx={{
                borderBlockStart: "1px solid #1C7CFF",
                paddingBlock: "13px",
                borderRadius: "0",
                justifyContent: "flex-end",
              }}
              name={text}
              onClick={(ev) => {
                handleChange(ev, type, value);
              }}
            >
              <Typography>{text}</Typography>
            </Button>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
