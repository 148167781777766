import { Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';

const index = [
  { title: 'הצעות', color: '#1C7CFF' },
  { title: 'משימות', color: '#FFE500' },
];

export default function Pagination({ monthly, setMonthly, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: { xs: 'center', md: 'space-between' },
        mb: '1rem',
        padding: '1rem',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {/* index */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {index.map(({ title, color }) => (
            <Box
              key={color}
              sx={{
                display: 'flex',
                alignItems: 'center',
                '&:not(:first-of-type)': { ml: '1rem' },
              }}
            >
              <Typography sx={{ color: '#646464' }}>{title}</Typography>
              <Box
                sx={{
                  height: '20px',
                  width: '20px',
                  borderRadius: '50%',
                  backgroundColor: color,
                  ml: '10px',
                }}
              />
            </Box>
          ))}
        </Box>

        {/* toggle button */}
        <Box
          sx={{
            backgroundColor: '#fff',
            border: '0.25px solid #C1C1C1',
            borderRadius: '40px',
            width: '148px',
            height: '42px',
            alignSelf: 'end',
            position: 'relative',
            display: { xs: 'flex', sm: 'none' },
            ml: '1rem',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Button
            variant='contained'
            name='annually'
            sx={{
              width: '75px',
              borderRadius: '40px',
              height: '38px',
              '&.MuiButton-root': {
                fontSize: '17px',
                fontWeight: '400',
              },
              position: 'absolute',
              zIndex: '1000',
              transition: 'left .2s ease-in-out, right .3s ease-in-out',
              left: monthly ? '71px' : '2px',
            }}
          >
            {monthly ? 'חודשי' : 'שנתי'}
          </Button>

          <Typography
            onClick={() => setMonthly(!monthly)}
            sx={{
              opacity: monthly ? '1' : '0',
              transition: 'opacity .3s ease-out',
              fontSize: '17px',
              position: 'relative',
              textAlign: 'center',
              width: '50%',
              color: '#8B8B8B',
            }}
          >
            שנתי
          </Typography>
          <Typography
            onClick={() => setMonthly(!monthly)}
            sx={{
              opacity: monthly ? '0' : '1',
              transition: 'opacity .3s ease-out',
              fontSize: '17px',
              position: 'relative',
              textAlign: 'center',
              width: '50%',
              color: '#8B8B8B',
            }}
          >
            חודשי
          </Typography>
        </Box>
      </Box>

      {/* actions */}
      <Box sx={{ display: 'flex' }}>
        {/* current time view controls */}
        <Box
          sx={{
            mr: { md: '39px' },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={!monthly ? props.nextYear : props.nextMonth}
            sx={{ padding: '13px' }}
          >
            <Box
              component='img'
              src={require(`../../../assets/icons/arrow-left-chosen.png`)}
              sx={{ height: '20px' }}
            />
          </IconButton>
          <span className='selectYear'>
            {!monthly ? (
              <>{props.year}</>
            ) : (
              <>
                {props.month} {props.year}
              </>
            )}
          </span>
          <IconButton
            onClick={!monthly ? props.prevYear : props.prevMonth}
            sx={{ padding: '13px' }}
          >
            <Box
              component='img'
              src={require(`../../../assets/icons/arrow-right-chosen.png`)}
              sx={{ height: '20px' }}
            />
          </IconButton>
        </Box>

        {/* toggle button */}
        <Box
          sx={{
            backgroundColor: '#fff',
            border: '0.25px solid #C1C1C1',
            borderRadius: '40px',
            width: '148px',
            height: '42px',
            alignSelf: 'end',
            position: 'relative',
            display: { xs: 'none', sm: 'flex' },
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Button
            variant='contained'
            name='annually'
            sx={{
              width: '75px',
              borderRadius: '40px',
              height: '38px',
              '&.MuiButton-root': {
                fontSize: '17px',
                fontWeight: '400',
              },
              position: 'absolute',
              zIndex: '1000',
              transition: 'left .2s ease-in-out, right .3s ease-in-out',
              left: monthly ? '71px' : '2px',
            }}
          >
            {monthly ? 'חודשי' : 'שנתי'}
          </Button>

          <Typography
            onClick={() => setMonthly(!monthly)}
            sx={{
              opacity: monthly ? '1' : '0',
              transition: 'opacity .3s ease-out',
              fontSize: '17px',
              position: 'relative',
              textAlign: 'center',
              width: '50%',
              color: '#8B8B8B',
            }}
          >
            שנתי
          </Typography>
          <Typography
            onClick={() => setMonthly(!monthly)}
            sx={{
              opacity: monthly ? '0' : '1',
              transition: 'opacity .3s ease-out',
              fontSize: '17px',
              position: 'relative',
              textAlign: 'center',
              width: '50%',
              color: '#8B8B8B',
            }}
          >
            חודשי
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
