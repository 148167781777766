import { useState } from "react";

/**
 * Custom hook for creating a stable state value and associated functions for setting and getting the value.
 * @template {T}
 * @param {T} initialState - The initial value for the stable state.
 * @returns {{display: T, set: (value: T) => void, get: () => T}} An object with three properties:
 *  - `display`: The UI value of the stable state (of type `T`).
 *  - `set`: A function for updating the stable state value (accepts a value of type `T`).
 *  - `get`: A function for retrieving the current stable state value (returns a value of type `T`).
 *
 * @example
 * const count = useStableState(0);
 *
 * count.set(1);
 * console.log(count.display); // prints 0
 * console.log(count.get()); // prints 1
 * 
 * @author `chatGPT3` and `avihay`
 */
export function useStableState(initialState)
{
    const [display, setter] = useState(initialState);
    let val = display;

    /** @param {T} value */
    function set(value)
    {
        setter(value);
        val = value;
    }
    function get()
    {
        return val;
    }
    return {
        display,
        set,
        get,
    };
}
