import * as React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadAllAids, setFilter } from "../store/actions/aidActions";
import Lottie from "react-lottie";
import Loader from "../assets/animations/loader.json";

import isEmpty from "is-empty";

import Header from "../components/Header";
import Footer from "../components/dashboard/Footer";
import
{
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Typography,
  Drawer,
  Box,
} from "@mui/material";
import { useRef } from "react";
import { offerService } from "../services/offerService.service";
import { userService } from "../services/userService.service";
import { useStableState } from "../util/StableState";

const drawerTitle = [
  { type: "note", title: "לוח הצעות", to: "/dashboard/user" },
  // { type: "note", title: "לוח מעקב", to: "/404" },
  // { type: "face", title: "ליווי הצעות", to: "/404" },
  { type: "folder", title: "ההצעות שלי", to: "/dashboard/accompany" },
  { type: "calender", title: "מעקב הצעות", to: "/dashboard/calendar" },
  { type: "settings", title: "הגדרות פרופיל", to: "/dashboard/settings" },
  // { type: "settings", title: "הגדרות פרופיל", to: "/dashboard/settings" },
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function Dashboard(props)
{
  const dispatch = useDispatch();
  //const { user } = useSelector((state) => state.userModule);
  //const { aids, filterBy } = useSelector((state) => state.aidModule);

  const [swipeableOpen, setSwipeableOpen] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const nav = useNavigate();
  const location = useLocation();

  /** @returns {{text: string, region: string[], subscription: string[], type: string[]}} */
  function makeInitialFilter()
  {
    //dispatch(
    //setFilter({
    return {
      text: "",
      region: [...user.intrestRegion],
      //subscription: [...user.subscriptions],
      subscription: [], //prevents filtering the extra repository categoty
      type: [],
    }
    //})
    //);
  };

  const [user, setUser] = useState(userService.getUser());
  const [offers, setOffers] = useState([]);
  const [offersSortedByDelete, setOffersSortedByDelete] = useState([]);
  const [loader, setLoader] = useState(offerService.getServiceState());
  const [error, setError] = useState('');
  const /** @type {{
    display: {
      text: string,
      region: string[],
      subscription: string[],
      type: string[]
      },
      set:(
        value: {
        text: string,
        region: string[],
        subscription: string[],
        type: string[]
        }) => void,
      get: () => {
        text: string,
      region: string[],
      subscription: string[],
        type: string[]
      }}} */ filters = useStableState(user ?
    makeInitialFilter() :
    /** @type {{text: string, region: string[], subscription: string[], type: string[]}} */{
      text: "",
      region: [],
      subscription: [],
      type: [],
    });
  //useRef(setInitialFilter())

  useEffect(() =>
  {
    async function effect()
    {
      if (user == null)
      {
        const tmp = userService.getLoggedinUser();
        setUser(tmp);
        if (tmp == null)
        {
          setError('טעינת מידע המשתמש נכשלה. נא להתחבר מחדש');
        }
        else
        {
          filters.set(makeInitialFilter());
        }
      }
      else
      {
        filters.set(makeInitialFilter());
      }
    };
    effect();
  }, []);
  function sortByDeleted(offers)
  {
    const undeleted = [], deleted = [];
    for (const o of offers ?? [])
    {
      if (!offerService.UserOffer.getState(o.id, 'hidden'))
      {
        undeleted.push(o);
      }
      else
      {
        deleted.push(o);
      }
    }
    return [...undeleted, ...deleted];
  }
  function resortByDelete()
  {
    setTimeout(() => setOffersSortedByDelete(sortByDeleted(offers)), 0);
  }
  useEffect(() =>
  {
    async function effect()
    {
      const allOffersPromise = offerService.loadAllOffers();
      setLoader(offerService.getServiceState());
      const allOffers = await allOffersPromise;
      setOffers(allOffers.offers);
      setOffersSortedByDelete(sortByDeleted(allOffers.offers));
      setLoader(offerService.getServiceState());
      if (allOffers.error)
      {
        setError(`אראה שגיעה עם הודעה: ${allOffers.error}\nנא לנסות לרענן את הדפדפן`);
        console.log(error);
      }
    };
    effect();
  }, []);
  // useEffect(() => {
  //   fetchAids();
  //   if (!isEmpty(user)) {
  //     setInitialFilter();
  //   }
  // }, [user]);

  useEffect(() =>
  {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  // const fetchAids = () => {
  //   dispatch(loadAllAids());
  // };

  const updateDimensions = () =>
  {
    setHeight(window.innerHeight);
    // setWidth(window.innerWidth);
  };



  const drawer = (
    <Box sx={{ height: "100%" }}>
      <List
        sx={{
          display: { md: "block", xs: "flex" },
          padding: 0,
          height: "100%",
          flexDirection: { xs: "row-reverse" },
          width: "100%",
        }}
      >
        {drawerTitle.map(({ type, to }) => (
          <ListItem
            key={type}
            disablePadding
            sx={{
              width: "100%",
              height: { md: "21%", xs: "50%" },
              backgroundColor: location.pathname === to ? "#1C7CFF" : "inherit",
            }}
          >
            <ListItemButton
              onClick={(ev) =>
              {
                nav(to);
              }}
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "0px",
                paddingBlock: { md: "0", sm: "10px", xs: "18px 5px" },
              }}
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                <Box
                  component="img"
                  src={require(`../assets/icons/drawer-dashboard-${location.pathname === to ? `${type}-chosen` : type
                    }.png`)}
                  sx={{
                    height: { xl: "35px", md: "30px", sm: "27px", xs: "25px" },
                    mb: 0.5,
                  }}
                />
              </ListItemIcon>
              {drawerTitle.map((item) =>
              {
                if (type === item.type)
                {
                  return (
                    <Typography
                      key={item}
                      sx={{
                        fontSize: {
                          lg: "17px",
                          md: "15px",
                          sm: "14px",
                          xs: "12px",
                        },
                        color: location.pathname === to ? "#FFF" : "#0346A0",
                      }}
                    >
                      {item.title}
                    </Typography>
                  );
                }
              })}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (error)
  {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          padding: '30px',
        }}
      >
        {error}
      </Box>
    )
  }
  if (loader !== 'loaded' || user == null)
  {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <Box
          component="img"
          src={require("../assets/img/logo-loader.png")}
          sx={{ maxWidth: "fit-content" }}
        />
        <Lottie
          height={400}
          width={400}
          options={defaultOptions}
          style={{ maxWidth: "600px" }}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ position: "relative", height: "100vh", overflow: "hidden" }}>
      <Header />
      <Box
        sx={{
          display: "flex",
          position: "relative",
          height: { sm: height > 800 ? "92%" : "90%", xs: "93%" },
          justifyContent: "center",
        }}
      >
        <Outlet context={[
          swipeableOpen,
          setSwipeableOpen,
          user,
          offersSortedByDelete,
          filters,
          resortByDelete,
        ]} />
        <Box
          component="nav"
          sx={{
            width: { lg: "150px", md: "115px", sm: "115px" },
            //flexShrink: { sm: 0 },
            zIndex: "2000",
          }}
          aria-label="navigation-bar"
        >
          {!swipeableOpen && (
            <Drawer
              variant="permanent"
              anchor="bottom"
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", md: "none" },
                height: "100%",
                maxHeight: "72px",
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: "100%",
                  border: "none",
                  background: "#F1F7FF",
                  boxShadow: "0px -8px 28px rgba(0, 53, 125, 0.4)",
                  borderRadius: "15px 15px 0px 0px",
                },
              }}
            >
              {drawer}
            </Drawer>
          )}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              height: "100%",
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                border: "none",
                background: "#F1F7FF",
                boxShadow: "-6px 14px 24px rgba(0, 0, 0, 0.08)",
                position: "static",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}
