import { Box, Typography } from "@mui/material";
import React from "react";

const DetailComponent = ({ data, selected, logo, setSelected }) => {
  console.log(data, selected, logo, setSelected);
  return (
    <Box
      component="li"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0.375rem",
        padding: "0.375rem 0.625rem 0.375rem 1rem",
        backgroundColor: data == selected ? "lightBlue" : "#fff",
        borderBottom: "0.5px solid #C0C0C0",

        "&.active": {
          backgroundColor: "#F5F9FF",
        },

        "&:hover": {
          backgroundColor: "#F5F9FF",
        },
        
      }}
      onClick={() => setSelected(data)}
    >
      <Box
        component="div"
        sx={{
          display: "block",
          height: "5rem",
          minWidth: "5rem",
          backgroundColor: "#fff",
          backgroundImage: `url(${logo})`,
          backgroundSize:"contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* <img
          style={{
            display: "block",
            height: "100%",
            width: "100%",
          }}
          src={logo}
          alt="logo"
        /> */}
      </Box>
      <Typography
        className="line-clamp-2"
        component="h4"
        sx={{
          direction: "rtl",
          fontWeight: 400,
          fontSize: "1rem",
          fontFamily: "Assistant",
          color: "#646464",
        }}
      >
        {data?.name}
      </Typography>
    </Box>
  );
};

export default DetailComponent;
