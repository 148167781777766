import { Box, Button, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { experimental_sx as sx } from '@mui/system'


export const FooterListBtn = styled((props) => (
    <Button variant="text" {...props}>{props.text}</Button>
))(({ theme }) => (sx({
    "&.MuiButton-root": {
        display:{sm:"block", xs:"none"},
        fontSize: { lg: "24px", md: "22px", xs: "18px" },
        fontWeight: "600",
        textAlign: "right",
        alignItems: "end",
        justifyContent: "end",
        color: "#fff",
        paddingBlock: 0.2,
        lineHeight: "inherit",
        borderRadius: "30px"
    }
})))