import { AxiosError } from "axios";

export function getMake(obj, key, val)
{
    if (obj[key] == null)
    { obj[key] = val; }
    return obj[key];
}

export function getMakePath(obj,path)
{
    const ret={};
    for (const key in path) {
        if (obj[key] == null) {
            obj[key] = path[key];            
        }
        ret[key]=obj[key];
        obj=obj[key];
    }
    return ret;
}


export function makePushToList(obj, key, item)
{
    if (obj[key])
    {
        obj[key].push(item);
    }

    else
    {
        obj[key] = [item];
    }
}


/**
 * The deleteItemFromArrayByIdentity function deletes an item from an array by identity.
 *
 * 
 * @param arr{any[]} Used to Specify the array that we want to remove an item from.
 * @param item Used to Identify the item to be deleted from the array.
 * @return Nothing.
 * 
 * @doc-author Trelent
 */
export function deleteItemFromArrayByIdentity(arr,item)
{
    arr.splice(arr.indexOf(item),1);
}

/**
 * The axiosErrorToErrorText function takes an axios error object and returns a string containing the error message.
 *
 * 
 * @param res {AxiosError} Used to Get the response from the api call.
 * @return A string.
 * 
 * @doc-author Trelent && Avihay
 */
export function axiosErrorToErrorText(res)
{
    let message = 'Unexpected error';
    if (res)
    {
        if (res.response)
        {
            if (res?.response?.responseText)
            {
                message = res.response.responseText;
            }
            else if (res?.response?.data)
            {
                message = JSON.stringify(res.response.data, null, 2);
            }
            else if (res?.response?.status && res?.response?.statusText)
            {
                message = `Error code: ${res.response.status}\n
              message: ${res.response.statusText}`;
            }
        }
        else
        {
            if (res?.code || res?.message)
            {
                message = `Error code: ${res.code}\n
              message: ${res.message}`;
            }
        }
    }
    return message;
}
