import React from "react";
import { Grid, Box } from "@mui/material";

import { useEffect } from "react";
import { useState } from "react";

export default function SecThird() {
  const [isMobile, setIsMobile] = useState("-mobile");
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (width < 900) {
      setIsMobile("-mobile");
    } else setIsMobile("");
  }, [width]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };

  const cards = [
    "electricity",
    "hitech",
    "nadlan",
    "cleaning",
    "publish",
    "building",
  ];

  return (
    <Grid
      container
      sx={{
        mb: 7.5,
        marginInline: "auto",
        width: "100%",
        justifyContent: "space-around",
        maxWidth: { sm: "600px", md: "1145px", lg: "1170px", xl: "1400px" },
      }}
      spacing={4}
    >
      {cards.map((path, index) => {
        const str = path + isMobile;

        return (
          <Grid
            item
            key={index}
            sx={{
              "&.MuiGrid-root": {
                paddingBlockStart: { md: "0px", xs: "20px" },
                paddingInline: "0px",
                maxHeight: {
                  xl: "330px",
                  md: "295px",
                  sm: "260px",
                  xs: "245px",
                },
                maxWidth: {
                  xs: "466px",
                  lg: "390px",
                  md: "380px",
                  sm: "275px",
                  xs: "354px",
                },
                width: "100%",
              },
            }}
          >
            <Box
              component="img"
              src={require(`../../assets/img/${str}-sec3.png`)}
              sx={{ width: "100%", height: "100%" }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}
