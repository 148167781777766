import { msecHour } from "./time";
import { userService } from "../services/userService.service";
import { storageService } from "../services/storageService.service";
import axios from 'axios';
import { useEffect, useState } from "react";
import { AppwideValues } from "../env";
/**  @template T */
export class Store
{
    dirty = true;
    /** @type {T} */
    #value;
    /**
     * @type {Set<function(): void}
 */
    subscribers = new Set();
    /**
     * @type {T}
 */
    fn = undefined;
    /** @param {T} initial - initial value for the store
     */
    constructor(initial)
    {
        this.fn = initial;
    }
    /** @returns {T} - current value*/
    get value()
    {
        if (this.dirty)
        {
            this.dirty = false;
            if (this.fn instanceof Function)
            {
                this.#value = this.fn();
            }
            else
            {
                this.#value = this.fn;
            }
        }
        return this.#value;
    }
    /** @param {T} @returns {void} - new value*/
    set value(v)
    {
        this.#value = v;
        this.subscribers.forEach(fn => fn());
    }
    /**
     * @param {function(): void} fn - notification function 
     * @returns {function():void} -unsubscribe method
 */
    subscribe(fn)
    {
        this.subscribers.add(fn);
        return () => this.subscribers.delete(fn);
    }
}

/**
 * @template T
 * @class ReactiveValue
 */
export class ReactiveValue
{
    /**
     * Flag that indicates whether the value is dirty or not
     * @type {boolean}
     */
    dirty = true;
    /**
     * Private value field that holds the reactive value
     * @private
     * @type {T}
     */
    #value;
    /**
     * Set of functions that are subscribed to the reactive value changes
     * @type {Set<Function>}
     */
    subscribers = new Set();
    /**
     * Function that computes the reactive value
     * @type {()=>T}
     */
    fn;
    /**
     * List of dependencies for the reactive value
     * @type {Array<ReactiveValue<any>|Store<any>>}
     */
    dependencies;

    /**
     * Constructor that takes in a function and dependencies and subscribes to the changes of the dependencies
     * @param {()=>T} fn - Function that computes the reactive value
     * @param {Array<ReactiveValue<any>>} dependencies - List of dependencies for the reactive value
     * @returns {ReactiveValue<T>}
     */
    constructor(fn, dependencies)
    {
        this.fn = fn;
        this.dependencies = dependencies;
        dependencies.forEach(x => x.subscribe(() => this.hadChange()));
    }

    /**
     * Getter that returns the reactive value
     * @returns {T}
     */
    get value()
    {
        if (this.dirty)
        {
            this.dirty = false;
            this.#value = this.fn(this.dependencies);
        }
        return this.#value;
    }

    /**
     * Setter that sets the reactive value and notifies subscribers
     * @param {T} v - New value to be set
     */
    set value(v)
    {
        this.#value = v;
        this.dirty = false;
        this.subscribers.forEach(fn => fn(v));
    }

    /**
     * Function that marks the reactive value as dirty and notifies subscribers
     */
    hadChange()
    {
        this.dirty = true;
        this.subscribers.forEach(fn => fn());
    }

    /**
     * Function that subscribes to the changes of the reactive value
     * @param {Function} fn - Function to be subscribed
     * @returns {Function} - Unsubscribe function
     */
    subscribe(fn)
    {
        this.subscribers.add(fn);
        return () => this.subscribers.delete(fn);
    }
}




// function a(b)
// {
//     if (b>0)
//         return b*2;
//         return 0;
// }
// export function useStore(store) 
// {
//     return useSyncExternalStore((f) => store.subscribe(f), () => store.value);
// }
/**
 * The useStore function is a React hook that allows you to subscribe to an external store.
 * It returns the current value of the store, and will re-render your component whenever it changes.
 *
 * @template T storeType
 * @param {Store<T>|ReactiveValue<T>} store Used to Subscribe to the store and get its value.
 * @return {T} current store value
 * 
 * @doc-author Avihay
 */


export function useStore(store)
{
    const [state, set] = useState(() => store.value);
    const [, forceUpdate] = useState({});

    useEffect(() =>
    {
        function asset()
        {
            set(store.value);
            forceUpdate({});
        }
        // Do mounting stuff here
        const unsubscribe = store.subscribe(asset);
        return () =>
        {
            // Do unmounting stuff here
            unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return state;
}




/**
 * The makeCachedCollection function creates a collection of data that is cached in local storage.
 * The cache is invalidated after the specified cacheValidityDuration has elapsed.
 * 
 * @template T
 * @param {Store<T>} store - Store object used to store the data in.
 * @param {string} path - URL path used to determine the endpoint to fetch data from.
 * @param {(any) => T} processNewDataFn - Function to transform the data received from the server into a format suitable for your application.
 * @param {string} storageKey - Key used to store the data in local storage.
 * @param {number} [chacheValidityDuration=msecHour] - Cache validity duration in milliseconds.
 * @param {({lastFetch: Date|string, prevFetch: Date|string, list: T[]}) => boolean} [checkCacheValidity] - Function to check the validity of the cached data.
* @returns {{loadStore: <U extends T>() => Promise<U>}} An object with a single property, loadStore, which is a function that returns a promise resolving to the fetched data.
 */
export function makeCachedCollection(store, path, processNewDataFn, storageKey, chacheValidityDuration = msecHour, checkCacheValidity = (() => true))
{
    const lastFetch = new ReactiveValue(([store]) => store.value ? new Date() : null, [store]);
    const prevFetch = new ReactiveValue((() =>
    {
        let _last = lastFetch;
        return ([lastFetch]) =>
        {
            const newPrev = _last;
            _last = lastFetch;
            return newPrev;
        };
    })(), [lastFetch]);


    /**
     * The isStale function determines whether a given date is stale.
     *
     * 
     * @param {Date} d Used to Compare the current date to the date passed in.
     * @return {boolean} A boolean value.
     * 
     * @doc-author Trelent + avihay
     */
    function isStale(d)
    {
        if (d == null)
        { return true; }
        return new Date().valueOf() - d.valueOf() > chacheValidityDuration;
    }
    let isLoading = false;
    /**
 * The loadStore function fetches the data, checks its validity, and updates the store.
 * If data is already cached and still valid, the store is updated with the cached data.
 * 
 * @template T
 * @returns {Promise<T>} A promise that resolves to the fetched data.
 */
    async function loadStore()
    {
        if (isLoading)
            return;
        try
        {
            isLoading = true;
            if (store.value != null && !isStale(lastFetch.value))
            {
                return;
            }
            //let serviceState = "empty";
            const data = storageService.load(storageKey);

            if (data != null && checkCacheValidity(data))
            {
                lastFetch.value = new Date(data.lastFetch);
                prevFetch.value = new Date(data.prevFetch);
            }
            if (data != null && !isStale(lastFetch.value))
            {
                store.value = data.list;
                //serviceState = "loaded";
            }
            else
            {
                //serviceState = "loading";
                const jwtToken = userService.getToken();
                const URL = AppwideValues.serviceURL;
                // const now = new Date();
                const res = await axios.get(URL + path, {
                    headers: { Authorization: `Bearer ${jwtToken}` },
                });

                //debugger;
                //console.log("res-aidService-aids", res.data);
                const list = processNewDataFn(res.data);
                store.value = list;
                const forStorage = { lastFetch: lastFetch.value, prevFetch: prevFetch.value, list };
                // console.log("aids", aids);

                // console.log("res-aidService-aids", aids);
                storageService.store(storageKey, forStorage);
                //serviceState = "loaded";
            }
        } catch (err)
        {
            console.log(err);
            //serviceState = "empty";
            lastFetch.value = null;
            prevFetch.value = null;
            storageService.remove(storageKey);
            return err.message;
        }
        finally
        {
            isLoading = false;
        }
        return null;
    }
    return { loadStore };
}
