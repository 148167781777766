import { Box, Typography } from "@mui/material";
import React from "react";
import { AppwideValues } from "../../env";

export default function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: "#001433",
        height: { xl: "7vh", md: "5vh" },
        display: { md: "flex", xs: "none" },
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        width: "100%",
        zIndex: "2500",
        bottom: "0",
      }}
    >
      <Typography sx={{ fontSize: "15px", color: "#fff" }}>
        כל הזכויות שמורות לקשר - עסקים ומכרזים
      </Typography>
      <Typography sx={{ fontSize: "15px", color: "#fff", mx:'30px' }}>
        גירסה: {AppwideValues.appVersion}
      </Typography>
    </Box>
  );
}
