import axios from "axios";
import { AppwideValues, LOCALSTORAGE_KEYS } from "../env";
import { makeCachedCollection, Store } from "../util/store";
import { msecHour } from "../util/time";
import { storageService } from "./storageService.service";


const URL = AppwideValues.serviceURL;
const categoriesStore = new Store(null);
const loadcategoriesStore = makeCachedCollection(
    categoriesStore,
     "category/categories",
      categorys => categorys.map(c=>c.name),
       LOCALSTORAGE_KEYS.categories,
        msecHour,
         data=>data.list instanceof Array);

export const categoriesService = {
    loadAllCategories,
    loadcategoriesStore,
    categoriesStore: categoriesStore,
}

async function loadAllCategories()
{
    try
    {

        const res = await axios.get(URL + 'category/categories')
        const categories = _createCategories(res.data)
        storageService.store("categories", JSON.stringify(categories));
        return categories
    } catch (err)
    {
        console.log(err);
    }
}

function _createCategories(data)
{
    return data.map(category => category.name)
}

/**
 * @typedef {Object} SubCategory
 * @property {number} id
 * @property {string} name
 * @property {string} description
 */

/**
 * @typedef {Object} Category
 * @property {number} id
 * @property {string} name
 * @property {string} description
 * @property {SubCategory[]} subcategories
 */



/**
 *  @type {Store<Category[]>}
 */
export const categoriesV2Store = new Store(null);
/**
 * @type {{
 *  loadStore: () => Promise<Category[]>;
 * }}
 */
export const loadcategoriesV2Store = makeCachedCollection(
    categoriesV2Store,
    "categoryV2/categories",
    category => category,
    LOCALSTORAGE_KEYS.categoriesV2,
    msecHour,
    data => data.list instanceof Array);

export const categoriesV2StoreMock=new Store(
    [
        {
          "id": 2,
          "name": "test",
          "description": "a test",
          "subcategories": [
            {
              "id": 1,
              "name": "subtest",
              "description": "a subtest"
            }
          ]
        }
      ]
);
