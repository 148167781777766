import React from "react";
import { Box, Button, Typography } from "@mui/material";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Lottie from "react-lottie";
import Circles from "../assets/animations/about-circles.json";
import { useState } from "react";
import { useEffect } from "react";

const items = [
  "מכרזים",
  "מאגרים רלוונטים (ספקים,יועצים, קבלנים)",
  "קולות קוראים",
  "הצעות מלקוחות פרטיים ועסקיים",
];

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Circles,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const profiles = [
  {
    image: "shemer",
    name: "שמר בינר",
    job: "Frontend developer",
    text: [
      "שמר אחראי להפוך את העיצוב הכללי למציאות דיגיטלית, אבל לא רק.",
      "למרות היותו הצעיר בצוות, הוא מקצוען בכל מה שקשור לאתגרים.",
      "בין אם אתם צריכים את שמר כשותף לשביל ישראל או עבור משימה קשה במיוחד  - ",
      "שמר הוא איש שלנו למשימות מיוחדות.",
    ],
  },
  {
    image: "avichi",
    name: "אביחי ברץ",
    job: "CTO",
    text: [
      "אביחי מנהל את הצד הטכנולוגי במיזם ואחראי לכלל ההיבטים הטכנולוגיים הן ברמת הפיתוח, העיצוב והשירותים החיצוניים בהם אנו משתמשים.",
      "לאביחי יש ניסיון של יותר מ15 שנים בעולמות הפיתוח והתוכנה .",
      "כיום, אביחי מתעניין בחדשנות טכנולוגית מעשית ובתנועת הקוד הפתוח.",
      "אביחי מעיד על עצמו כמתקשה לבחור בין כלבים לחתולים (עורך אנונימי- הוא לא מתקשה, הוא בחר בחתולים).",
    ],
  },
];

export default function About({ openModal }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    // setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  };

  return (
    <Box component="div" sx={{ position: "relative", overflow: "hidden" }}>
      <Header openModal={openModal} />

      <Box
        component="img"
        src={require("../assets/img/wave-about.png")}
        sx={{
          display: { lg: "block", xs: "none" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          height: { xl: "1412px", lg: "1412px", md: "1412px" },
          top: { xl: "1150px", lg: "1100px", md: "220px" },
        }}
      />

      <Box
        component="img"
        src={require("../assets/img/wave-bottom-prices.png")}
        sx={{
          display: { lg: "none", xs: "block" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          bottom: { md: "220px", sm: "240px", xs: "470px" },
        }}
      />

      <Box
        component="img"
        src={require("../assets/img/rectangle-about.png")}
        sx={{
          display: { lg: "none", xs: "block" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          bottom: {
            md: "1275px",
            sm: "980px",
            xs: "1140px",
          },
          height: {
            md: "1000px",
            sm: "600px",
            xs: "635px",
          },
        }}
      />

      <Box
        component="img"
        src={require("../assets/img/wave-middle-about-mobile.png")}
        sx={{
          display: { lg: "none", xs: "block" },
          position: "absolute",
          zIndex: "-1",
          width: "100vw",
          bottom: {
            md: "3030px",
            sm: "2055px",
            xs: "2200px",
          },
          height: {
            md: "1770px",
            sm: "1370px",
            xs: "1390px",
          },
        }}
      />

      <Box
        component="main"
        sx={{ maxWidth: "1485px", marginInline: "auto", position: "relative" }}
      >
        {/* first sec */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
            justifyContent: { xl: "center", lg: "space-around" },
            mt: "69px",
            mb: { lg: "87px", md: "100px", xs: "110px" },
          }}
        >
          <Box
            component="img"
            src={require("../assets/img/preview2.png")}
            sx={{
              height: {
                xl: "550px",
                lg: "470px",
                md: "485px",
                sm: "320px",
                xs: "235px",
              },
              maxWidth: { md: "100%", sm: "650px", xs: "auto" },
              marginInline: "auto",
            }}
          />
          <Box
            component="div"
            sx={{
              textAlign: { lg: "start", xs: "center" },
              maxWidth: { lg: "500px", md: "700px", xs: "333px" },
              position: "relative",
              right: { xl: "40px" },
              alignSelf: { lg: "end", xs: "center" },
              mb: { lg: "0px", md: "40px", xs: "7px" },
              paddingInlineEnd: { xl: "0px", lg: "20px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "48px", md: "40px", xs: "24px" },
                fontWeight: "600",
                mb: { lg: "33px", md: "10px", xs: "7px" },
                unicodeBidi: "plaintext",
              }}
            >
              קשר מכרזים
            </Typography>
            {/* <Typography
              sx={{
                fontSize: { lg: "20px", md: "28px", xs: "18px" },
                unicodeBidi: "plaintext",
              }}
            >
              אנחנו יודעים שמה שמעניין אתכם זה לקוחות.
            </Typography> */}
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "28px", xs: "18px" },
                mb: { lg: "31px", md: "80px", xs: "44px" },
                unicodeBidi: "plaintext",
              }}
            >
              {/* אז בנינו עבורכם את הפלטפורמה היחידה שמספקת הצעות מותאמות מהמגזר
              הציבורי ועוזרת לכם לעקוב אחריהם ונהל אותם בקלות. ובסופו של יום,
              להרוויח מהן כסף! */}
              קשר - עסקים ומכרזים היא פלטפורמה למכרזים והצעות עבודה מהמגזר הציבורי והפרטי.  אנחנו מאתרים עבורכם הצעות עבודה ממאות ארגונים בפריסה ארצית. המטרה שלנו, שתרוויחו יותר כסף בסוף השנה מחוזים שלא חשבתם לחפש!
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "24px", md: "28px", xs: "18px" },
                fontWeight: "600",
                unicodeBidi: "plaintext",
                textAlign: { lg: "center", md: "start", xs: "center" },
                mb: { lg: "0px", md: "40px", xs: "30px" },
              }}
            >
              בהקמת "פרופיל עסקי" תקבלו התראות שוטפות עבור סוגי ההצעות הבאות :
            </Typography>
            <ul style={{ direction: "rtl", fontSize: "20px" }}>
              {items.map((item) => (
                <li key={item}>
                  <Typography
                    sx={{
                      textAlign: "start",
                      fontSize: { lg: "24px", md: "28px", xs: "18px" },
                      unicodeBidi: "plaintext",
                    }}
                  >
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>
          </Box>
        </Box>

        {/* second sec */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
            justifyContent: { xl: "space-between", lg: "space-around" },
            maxHeight: { lg: "460px" },
            mb: { lg: "269px", sm: "20px", xs: "30px" },
          }}
        >
          <Box
            sx={{
              maxWidth: {
                xl: "720px",
                lg: "630px",
                md: "700px",
                sm: "400px",
                xs: "333px",
              },
              margin: "0px",
              position: "relative",
              top: { xl: "0px", lg: "50px" },
              left: { xl: "-50px" },
              alignSelf: "center",
            }}
          >
            <Lottie options={defaultOptions} />
          </Box>

          <Box
            component="div"
            sx={{
              textAlign: { lg: "start", md: "center", xs: "start" },
              maxWidth: { xl: "636px", lg: "500px", md: "760px", xs: "333px" },
              position: "relative",
              right: { xl: "40px" },
              mb: { lg: "288px" },
              alignSelf: { lg: "start", xs: "center" },
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "48px", md: "30px", xs: "24px" },
                fontWeight: "600",
                mb: { md: "33px", xs: "24px" },
                unicodeBidi: "plaintext",
              }}
            >
              איך זה עובד?
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                unicodeBidi: "plaintext",
                mb: "25px",
              }}
            >
              פלטפורמת קשר מכרזים מנגישה עבורכם מידע עסקי בנוגע למכרזים והצעות
              עבודה ציבוריות ופרטיות.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              בסיום ההרשמה לפלטפורמה תקבלו פרופיל אישי לעסק שיעודכן בהתאם
              לנתונים שהזנתם אליו לפי כמה פרמטרים - סוג העסק , אופי העסק
              (קטגוריות מקצועיות) ומיקום.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              ההצעות בפרופיל האישי נאספות ומתעדכנות על בסיס יום-יומי וניתנות
              לניהול לצורך הגשה בתוך ממשק המשתמש שלכם, בין היתר: ניהול מעקב יומן
              אחר מכרזים וחלוקת משימות, שמירת מסמכים וקבצים בהקשר למכרז ספציפי
              וצ’ט עם מומחים במקרה שתצטרכו עזרה.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "24px", md: "30px", xs: "18px" },
                fontWeight: "600",
                unicodeBidi: "plaintext",
              }}
            >
              בשורה התחתונה - אנחנו מביאים לכם הזדמנויות שיעזרו לכם לגדול!
            </Typography>
          </Box>
        </Box>

        {/* third sec */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
            justifyContent: { lg: "space-around", xs: "space-between" },
            mb: { lg: "94px", md: "170px", xs: "109px" },
          }}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: { xl: "flex-end", lg: "center" },
              maxWidth: { lg: "480px", md: "600px", xs: "333px" },
              alignSelf: { lg: "start", xs: "center" },
            }}
          >
            <Box
              component="img"
              src={require("../assets/img/san.png")}
              sx={{
                mb: { md: "42px", xs: "9px" },
                height: { md: "214px", xs: "100px" },
                width: { md: "214px", xs: "100px" },
                marginInline: "auto",
              }}
            />

            <Box component="div" sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  fontSize: { lg: "24px", md: "30px", xs: "24px" },
                  fontWeight: "700",
                }}
              >
                סאן חרתי
              </Typography>
              <Typography
                sx={{
                  fontSize: { lg: "20px", md: "30px", xs: "18px" },
                  fontWeight: "600",
                  mb: "35px",
                  unicodeBidi: "plaintext",
                }}
              >
                Owner & CEO
              </Typography>

              <Typography
                sx={{
                  fontSize: { lg: "20px", md: "30px", xs: "18px" },
                  unicodeBidi: "plaintext",
                  mb: "35px",
                }}
              >
                יזם ועו"ד, תחרותי גם כשצריך וגם כשלא. חובב טניס ומומחה
                בהמלצות תוכן.
              </Typography>
              <ul
                style={{
                  fontSize: width > 900 ? "20px" : "18px",
                  direction: "rtl",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "0px",
                }}
              >
                <li>
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", md: "30px", xs: "18px" },
                      unicodeBidi: "plaintext",
                      textAlign: "start",
                    }}
                  >
                    תואר ראשון במשפטים (LL.B) (2019)
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", md: "30px", xs: "18px" },
                      unicodeBidi: "plaintext",
                      textAlign: "start",
                    }}
                  >
                    חבר בלשכת עורכי הדין (2021)
                  </Typography>
                </li>
                <li>
                  <Typography
                    sx={{
                      fontSize: { lg: "20px", md: "30px", xs: "18px" },
                      unicodeBidi: "plaintext",
                      textAlign: "start",
                    }}
                  >
                    בוגר תוכנית היזמות והעסקים של קרן FIMI
                  </Typography>
                </li>
              </ul>
            </Box>
          </Box>

          <Box
            component="div"
            sx={{
              textAlign: "start",
              maxWidth: { xl: "909px", lg: "645px", md: "760px", xs: "333px" },
              alignSelf: { lg: "start", xs: "center" },
              mb: { lg: "0px", md: "84px", xs: "130px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { lg: "48px", md: "30px", xs: "24px" },
                fontWeight: "600",
                mb: { md: "15px", xs: "23px" },
                unicodeBidi: "plaintext",
              }}
            >
              מי אנחנו?
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                unicodeBidi: "plaintext",
                mb: "25px",
              }}
            >
              <span style={{ color: "#1C7CFF" }}>קשר - עסקים ומכרזים </span>
              הוקמה ע"י עו"ד סאן חרתי במטרה לעזור לעסקים בכל הגדלים והסוגים
              להשתתף ברכש ציבורי ולקבל הצעות בדרך פשוטה וקלה יותר.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                fontWeight: "600",
                mb: { md: "25px", xs: "0px" },
                unicodeBidi: "plaintext",
              }}
            >
              סאן משתף:
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              את החזון להקמת פלטפורמת "
              <span style={{ color: "#1C7CFF" }}>קשר</span>" להצעות עבודה
              ומכרזים קיבלתי במהלך התמחותי לעורך דין במחלקת המכרזים של עיריית
              חדרה תחת
              <span style={{ color: "#1C7CFF", marginInline: "5px" }}>
                עו"ד עדנה גלבר גראפי
              </span>
              לה אני חב המון באשר לגישה, הידע וההבנה שקיבלתי על עולם המכרזים.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              כחלק מתפקידי, ניסחתי מכרזים, בדקתי הצעות והשתתפתי בוועדת המכרזים
              העירונית. ראיתי מבפנים את הקושי של עסקים להגיע לפרסומים הציבוריים
              וגם כשהם מגיעים, ראיתי את הקושי שלהם מול ההליכים הבירוקרטים
              והדרישות המשפטיות.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              כל הדוחות בישראל מראים שמעט מדי עסקים משתתפים ברכש ציבורי כלשהו
              (כ-18%), לדעתי, בעלי עסקים צריכים לשמוע על יותר הצעות בתחום שלהם
              ולהבין טוב יותר מה נדרש מהם לעשות כדי להפוך אותן לחוזה.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              בצניעות רבה, אני גם קורא למדינה לעשות יותר למען כלל העסקים בכלל
              השכבות, לחלק את העוגה התקציבית בצורה שווה יותר ולהבטיח הליך
              שוויוני והוגן בפני הקהל העסקי.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "20px", md: "30px", xs: "18px" },
                mb: "25px",
                unicodeBidi: "plaintext",
              }}
            >
              מכרזים הם לא סתם שיטה עסקית ליצירת חוזים - הם הדרך ההוגנת להשתמש
              בכסף של הציבור, ובשימוש נכון במכרזים- אפשר לבנות כבישים ובתי חולים
              חדשים, לחזק תשתיות ישנות ולקיים אירועים לאומיים.
            </Typography>
            <Typography
              sx={{
                fontSize: { lg: "21px", md: "30px", xs: "19px" },
                fontWeight: "700",
                mb: "50px",
                unicodeBidi: "plaintext",
              }}
            >
              מגיע ליותר עסקים בישראל שהמדינה תשקיע בהם בחזרה ותאפשר להם להשתתף
              ברכש הציבורי.
            </Typography>
          </Box>
        </Box>

        {/* last sec */}
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: { lg: "row", xs: "column-reverse" },
            justifyContent: "end",
            alignItems: { lg: "flex-start", xs: "center" },
            mb: "50px",
          }}
        >
          {profiles.map(({ image, name, job, text }) => (
            <Box
              key={image}
              component="div"
              sx={{
                display: "flex",
                alignItems: { lg: "start", xs: "center" },
                flexDirection: { lg: "row", xs: "column-reverse" },
                mr: { lg: image === "shemer" ? "77px" : "0" },
                mb: { lg: "0px", xs: image !== "shemer" ? "114px" : "81px" },
              }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  textAlign: { lg: "end", xs: "center" },
                  maxWidth: {
                    lg: image === "shemer" ? "394px" : "412px",
                    md: "600px",
                    xs: "345px",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: { lg: "24px", md: "30px", xs: "24px" },
                    fontWeight: "700",
                  }}
                >
                  {name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { lg: "20px", md: "30px", xs: "18px" },
                    fontWeight: "600",
                    mb: "20px",
                    unicodeBidi: "plaintext",
                  }}
                >
                  {job}
                </Typography>

                {text.map((txt) => (
                  <Typography
                    key={txt}
                    sx={{
                      fontSize: { lg: "20px", md: "30px", xs: "18px" },
                      unicodeBidi: "plaintext",
                      textAlign: { lg: "start", xs: "center" },
                      "&:last-of-type": {
                        mt: "20px",
                      },
                    }}
                  >
                    {txt}
                  </Typography>
                ))}
              </Box>

              <Box
                component="img"
                src={require(`../assets/img/${image}.png`)}
                sx={{
                  width: { md: "214px", xs: "100px" },
                  height: { md: "214px", xs: "100px" },
                  ml: { lg: "36px" },
                  mb: { lg: "0px", md: "20px", xs: "10px" },
                }}
              />
            </Box>
          ))}
        </Box>

        <Typography
          sx={{
            maxWidth: { sm: "100%", xs: "220px" },
            marginInline: "auto",
            fontSize: { md: "20px", xs: "16px" },
            textAlign: "center",
            fontFamily: "Montserrat",
            mb: { md: "31px", xs: "5px" },
          }}
        >
          UX/UI Product design by:{" "}
          <span style={{ fontWeight: "600" }}>Nir Sharf</span>{" "}
          <a href="https://nirsharf.com/" target="_blank" rel="noreferrer noopener">
          <Button
            variant="text"
            sx={{
              "&.MuiButton-root": {
                fontWeight: "600",
                fontSize: { md: "20px", xs: "16px" },
                fontFamily: "Montserrat",
              },
            }}
          >
            Portfolio
          </Button>
          </a>
        </Typography>
      </Box>
      <Footer openModal={openModal} />
    </Box>
  );
}
