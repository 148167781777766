import { Box, Typography } from "@mui/material";
import * as React from "react";

import Question from "./Question";

const questions = [
  {
    question: "לא עדיף לחפש הצעות ציבוריות בלבד?",
    answer:
      "ובכן, זה אפשרי. אבל זה ידרוש ממכם המון זמן. אנחנו מחפשים עבורכם ביותר מ300 ארגונים ציבוריים, מרחבי הרשת ומהצעות בלעדיות בפורטל. קשר לא נועדה להחליף את הפעילות של חיפוש אקטיבי אחר הזדמנויות אלא לאפשר לכם עוד זרם הצעות ממקומות שלא חשבתם לחפש בהם.",
  },
  {
    question: "כיצד אני מתנסה במערכת? קיימת תקופת ניסיון?",
    answer:
      'בהחלט, החלטנו לתת הטבה חינמית של 28 ימי ניסיון במערכת למשתמשים חדשים. להצעה זאת אין התחייבות מצדכם, אך כדי למנוע ניצול לרעה אנו דורשים הזנת כרטיס אשראי לצורך אימות העסק והמשתמש. שימו לב- לא יבוצע חיוב בתקופת הניסיון. לאחריה, במידה ולא תפסיקו את המנוי, כרטיס זה ישמש לתשלום המנוי החודשי בסכום הקבוע של 250 ש"ח.',
  },
  {
    question: "כיצד ניתן להתנתק? (רמז: אפילו לא צריך להתקשר)",
    answer:
      "בקלות, בפרופיל האישי שלכם במסך ההגדרות קיים כפתור ביטול המנוי. מרגע הלחיצה עליו ואישור כי ברצונכם לבטל את המנוי, המנוי שלכם והחיובים יופסקו לצמיתות.",
  },
  {
    question: "כיצד אתם מתאימים מכרזים לעסק שלי?",
    answer:
      "שאלה טובה, כחלק מבניית הפרופיל העסקי שלכם אתם תגדירו את העסק ודרישותיו מבחינת קטגוריות עסקיות ואזור גאורפי. הסוכן החכם שלכם יחפש עבורכם בין אלפי המכרזים המפורסמים ויבצע התאמה אישית לפרופיל שלכם בהתאם למאפייניו.",
  },
  {
    question:
      "העסק שלי צריך שירות הצעות רכש ציבוריות (מכרזים, מאגרים, קולות קוראים..?)",
    answer:
      "אנחנו מאמינים שכל עוד יש מכרזים והצעות בתחום שלכם שמתאימים למידות העסק, עליכם לנסות להגיש. עלויות ההשתפות נמוכות ופוטנציאל ההזדמנות בכל מכרז והצעה הוא גבוה מאוד. אם העסק לא מספיק גדול למכרזים, הרשמו למאגרים הציבוריים ותוכלו להתמודד על הצעות לעסקים קטנים יותר. (נתון מעניין - עסקים מספרים שזכייה במכרז היוותה כ20-30% מהמחזור שלהם באותה שנה).",
  },
];

export default function Questions() {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        mb: { xs: 15 },
      }}
    >
      <Typography
        sx={{
          mb: { md: 8, xs: 1.8 },
          fontSize: { xl: "45px", lg: "40px", sm: "35px", xs: "28px" },
          fontWeight: "700",
        }}
      >
        לקוחות שואלים אותנו
      </Typography>

      {questions.map((question) => (
        <Question key={question.question} question={question} />
      ))}
    </Box>
  );
}
