import React, { useEffect, useState } from "react";

import
  {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListTitle,
  } from "../../styledComponents/homePage/accordion";

import
  {
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemText,
    ListItemButton,
    Chip,
    Stack,
    TextField,
    Divider,
    Typography,
  } from "@mui/material";

import
  {
    PageContainer,
    TitleReg2,
    BusinessTypeButton,
    StyledInput,
  } from "../../styledComponents/signup";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { categoriesService } from "../../services/categories.service";
import InputValidationText from "../InputValidationText";

import { userService } from "../../services/userService.service";

const regionNames = ["ירושלים", "השרון", "דרום", "מרכז", "צפון"];

export default function PageTwo({ forward, back, stepsData })
{
  const [expanded, setExpanded] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [categoryList, setCategList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState(
    userService.RegistrationController.getRegData(2));
  const [username,] = useState(
    userService.RegistrationController.getRegData(1).username??'');
  stepsData[2] = data;

  useEffect(() =>
  {
    const lists = [];

    let chunkLength;
    if (width > 900)
    {
      chunkLength = Math.ceil(categories.length / 4);
    } else if (width <= 900 && width > 600)
    {
      chunkLength = Math.ceil(categories.length / 3);
    } else
    {
      chunkLength = Math.ceil(categories.length / 3);
    }

    for (var i = 0; i < categories.length; i += chunkLength)
    {
      const chunk = categories.slice(i, i + chunkLength);
      lists.push(chunk);
    }

    if (width > 600)
    {
      setCategList(lists.reverse());
    } else
    {
      setCategList(lists);
    }
  }, [categories, width]);

  useEffect(() =>
  {
    fetchCategories();
  }, []);

  useEffect(() =>
  {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleChange = (
    { target },
    region = null,
    { name = null, subscription = null }
  ) =>
  {
    const field = target.name || name;
    let value = target.value;
    if (field === "intrestRegion" && region) value = intrestRegionArr(region);
    if (field === "subscriptions" && subscription)
    {
      value = subscriptionsArr(subscription);
      if (value.length >= 6) return;
    }

    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };


  const errorMessages = {
    businessType: 'יש לבחור סוג עסק',
    intrestRegion: 'יש לבחור אזורי עניין',
    subscriptions: 'יש לבחור קטגוריות, אך לא יותר מחמש',
    businessDescription: 'תיאור עסק ארוך מדי',
  };
  const nextStep = async () =>
  {
    try
    {
      // if (
      //   data.businessDescription &&
      //   data.businessType &&
      //   data.intrestRegion &&
      //   data.intrestRegion.length > 0 &&
      //   data.subscriptions &&
      //   data.subscriptions.length > 0

      // ) {
      //   forward(2, { ...data });
      // } else {
      //   setErrMsg({ text: "שדות חסרים או לא תקינים", color: "red" });
      // }

      for (const field of userService.RegistrationController.getRegFields(2))
      {
        if (!userService.RegistrationController.valid(field, data[field]))
        {
          setErrMsg({ text: errorMessages[field], color: "red" });
          return;
        }
      }
      await userService.RegistrationController.register(data, 2);
      forward(2, { ...data });
      setErrMsg("");

    } catch (err)
    {
      if (err.response?.data?.statusCode !== 200)
      {
        if (err.response?.data?.statusCode)
        {
          setErrMsg({
            text: `ארעה תקלה בשרת עם קוד ${err.response?.data?.statusCode}
          ${err.response.data.statusText}`,
            color: "red",
          });

        }
        else
        {
          setErrMsg({
            text: `ארעה תקלה לא ידועה: ${err.messages}`,
            color: "red",
          });
        }
      }
      else
      {
        setErrMsg("");
      }
    }
  };

  const subscriptionsArr = (subscription) =>
  {
    let subscriptions = [...data.subscriptions];
    if (subscriptions.includes(subscription))
    {
      subscriptions = subscriptions.filter((sub) => sub !== subscription);
    } else subscriptions.push(subscription);
    return subscriptions;
  };

  const handleDelete = (subscription) =>
  {
    let subscriptions = [...data.subscriptions];
    if (subscriptions.includes(subscription))
    {
      subscriptions = subscriptions.filter((sub) => sub !== subscription);
      setData((prevData) => ({
        ...prevData,
        ["subscriptions"]: subscriptions,
      }));
    } else return;
  };

  const intrestRegionArr = (region) =>
  {
    let newArr;
    if (Array.isArray(region))
    {
      if (data.intrestRegion.length < 5)
      {
        newArr = [...region];
      } else newArr = [];
    } else if (!data.intrestRegion.includes(region))
    {
      newArr = [...data.intrestRegion, region];
    } else
    {
      newArr = [...data.intrestRegion].filter((reg) => reg !== region);
    }
    return newArr;
  };

  const handleExpand = (panel) => (event, newExpanded) =>
  {
    setExpanded(newExpanded ? panel : false);
    // here I will set the call to the API
  };

  const updateDimensions = () =>
  {
    setWidth(window.innerWidth);
    // setHeight(window.innerHeight);
  };

  const fetchCategories = async () =>
  {
    try
    {
      const categs = await categoriesService.loadAllCategories();
      setCategories((prevData) => [...categs]);
    } catch (err)
    {
      console.log(err);
    }
  };

  return (
    <Box>
      <PageContainer sx={{ backgroundColor: "#fff", mb: { sm: 0, xs: 2 } }}>
        <Box
          component="img"
          src={require("../../assets/img/benefits-reg2.png")}
          sx={{
            maxHeight: {
              xl: "327px",
              lg: "310px",
              md: "290px",
              sm: "240px",
              xs: "410px",
            },
            maxWidth: {
              xl: "360px",
              lg: "325px",
              md: "310px",
              sm: "225px",
              xs: "354px",
            },
            width: "100%",
            height: "100%",
            position: "absolute",
            left: { lg: "25", md: "30px", sm: "20px" },
            top: { lg: "23", md: "25px", sm: "25px" },
            display: { sm: "block", xs: "none" },
          }}
        />
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "fit-content",
            alignItems: "end",
            paddingInlineEnd: { md: "50px", sm: "25px" },
            maxWidth: { sm: "100%", xs: "334px" },
            marginBlockStart: { md: "40px", sm: "35px", xs: "24px" },
          }}
        >
          <TitleReg2
            sx={{
              alignSelf: { sm: "end", xs: "center" },
              fontWeight: { xs: "600" },
              fontSize: { lg: "23px", sm: "16px", xs: "23px" },
              pr: 0,
            }}
          >
            שלום {username}
          </TitleReg2>
          <TitleReg2
            sx={{
              mb: { xs: 4 },
              fontWeight: { xs: "600" },
              fontSize: { lg: "23px", sm: "16px", xs: "23px" },
              pr: 0,
            }}
          >
            עכשיו עוד כמה פרטים שנדע להתאים עבורכם הצעות
          </TitleReg2>          

          <TitleReg2>סוג העסק</TitleReg2>
          <Container
            sx={{
              "&.MuiContainer-root": {
                padding: { xs: 0 },
                pr: 0,
              },
              textAlign: "end",
              mb: { sm: 3, xs: 0 },
              maxWidth: { md: "auto", xs: "318px" },
              width: "100%",
              marginInlineEnd: { sm: "0px", xs: "auto" },
              marginInlineStart: "auto",
            }}
          >
            <BusinessTypeButton
              name="businessType"
              value="association"
              bc={data.businessType}
              onClick={(ev) =>
              {
                ev.stopPropagation();
                handleChange(ev, null, { name: null, subscription: null });
              }}
            >
              עמותה
            </BusinessTypeButton>
            <BusinessTypeButton
              name="businessType"
              value="ltd"
              bc={data.businessType}
              onClick={(ev) =>
              {
                ev.stopPropagation();
                handleChange(ev, null, { name: null, subscription: null });
              }}
            >
              חברה בע"מ
            </BusinessTypeButton>
            <BusinessTypeButton
              name="businessType"
              value="Licenced"
              bc={data.businessType}
              onClick={(ev) =>
              {
                ev.stopPropagation();
                handleChange(ev, null, { name: null, subscription: null });
              }}
            >
              עוסק מורשה
            </BusinessTypeButton>
            <BusinessTypeButton
              name="businessType"
              value="exempt"
              bc={data.businessType}
              onClick={(ev) =>
              {
                ev.stopPropagation();
                handleChange(ev, null, { name: null, subscription: null });
              }}
            >
              פטור
            </BusinessTypeButton>
          </Container>

          <Divider
            sx={{
              width: "100%",
              maxWidth: "314px",
              padding: 0,
              borderColor: "rgba(28, 124, 255, 0.25)",
              borderWidth: 0.5,
              marginBlock: "20px",
              marginInline: "auto",
              display: { sm: "none", xs: "block" },
            }}
          />

          {/* regions */}
          <TitleReg2 sx={{ mb: { sm: 0.5, xs: 1 } }}>
            :אזור מועדף לקבלת הצעות
          </TitleReg2>
          <Container
            sx={{
              "&.MuiContainer-root": {
                padding: { xs: "0px" },
                pr: 0,
                maxHeight: { sm: "95px" },
                maxWidth: { md: "520px", xs: "318px" },
                justifyContent: "end",
                display: "flex",
                flexWrap: "wrap-reverse",
                height: "100%",
                width: "100%",
                marginInlineEnd: { sm: "0px", xs: "auto" },
                marginInlineStart: "auto",
              },
              textAlign: "end",
              m: 0,
              mb: { sm: 3, xs: 0 },
            }}
          >
            {regionNames.map((region, index) =>
            {
              const isChecked = data.intrestRegion.includes(region)
                ? "checked"
                : "unchecked";

              return (
                <BusinessTypeButton
                  key={region}
                  region={data.intrestRegion.includes(region) ? "true" : ""}
                  name="intrestRegion"
                  value={region}
                  onClick={(ev) =>
                    handleChange(ev, region, { name: null, subscription: null })
                  }
                  sx={{
                    padding: {
                      sm: "3px 15px",
                      xs: index === 1 || index === 0 ? "5px 32px" : "5px 23px",
                    },
                  }}
                >
                  {region}
                  <Box
                    component="img"
                    name="intrestRegion"
                    src={require(`../../assets/icons/${isChecked}.png`)}
                    sx={{
                      ml: 0.7,
                      height: { md: "14px", sm: "12px", xs: "14px" },
                    }}
                  />
                </BusinessTypeButton>
              );
            })}
            <BusinessTypeButton
              name="intrestRegion"
              region={data.intrestRegion.length === 7 ? "true" : ""}
              value={regionNames}
              onClick={(ev) =>
                handleChange(ev, regionNames, {
                  name: null,
                  subscription: null,
                })
              }
              sx={{
                padding: {
                  sm: "3px 15px",
                  xs: "5px 23px",
                },
              }}
            >
              כל הארץ
              <Box
                component="img"
                name="intrestRegion"
                src={require(`../../assets/icons/${data.intrestRegion.length === 7 ? "checked" : "unchecked"
                  }.png`)}
                sx={{ ml: 0.5, height: "14px" }}
              />
            </BusinessTypeButton>
          </Container>

          <Divider
            sx={{
              width: "100%",
              maxWidth: "314px",
              padding: 0,
              borderColor: "rgba(28, 124, 255, 0.25)",
              borderWidth: 0.5,
              marginBlock: "20px",
              marginInline: "auto",
              display: { sm: "none", xs: "block" },
            }}
          />

          {/* categories */}
          <TitleReg2>קטגוריות נבחרות לעסק</TitleReg2>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleExpand("panel1")}
            sx={{
              maxWidth: { xl: "907px", md: "784px", sm: "550px" },
              // mb: { sm: 3.5, xs: 1 },
              mb: "8px",
              boxShadow: expanded
                ? "0px 8px 48px rgba(161, 194, 244, 0.68)"
                : "",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                borderRadius: expanded ? "20px 20px 0 0 " : "30px",
                border: expanded
                  ? "none"
                  : "1px solid rgba(28, 124, 255, 0.25)",
                "& .MuiAccordionSummary-expandIconWrapper": {
                  ml: 1,
                  svg: {
                    fontSize: { md: "14px", sm: "12px", xs: "14px" },
                    color: "#00357D",
                  },
                },
                "& .MuiAccordionSummary-content": { margin: "0px" },
                "&.MuiAccordionSummary-root": {
                  minHeight: "42px",
                  padding: "0px 3px",
                },
                "& .MuiTypography-root": { mr: 1 },
              }}
            >
              {!data.subscriptions.length && (
                <ListTitle
                  sx={{
                    fontSize: { md: "14px", sm: "12px", xs: "14px" },
                    color: "#00357D",
                  }}
                >
                  צפו ברשימת הענפים המלאה
                </ListTitle>
              )}
              {data.subscriptions.length > 0 && (
                <Stack
                  direction="row"
                  sx={{
                    flexWrap: "wrap",
                    justifyContent: "end",
                    alignItems: "center",
                    padding: { sm: "0px", xs: "4px" },

                    paddingBlockEnd: "0px",
                    display: { xs: "none", sm: "flex" },
                  }}
                >
                  {data.subscriptions.map((suscription) => (
                    <Chip
                      key={suscription}
                      label={suscription}
                      onDelete={() =>
                      {
                        handleDelete(suscription);
                      }}
                      deleteIcon={
                        <Box
                          component="img"
                          src={require("../../assets/icons/close-circle.png")}
                          sx={{
                            height: { md: "20px", sm: "18px", xs: "20px" },
                            width: { md: "20px", sm: "18px", xs: "20px" },
                            position: "relative",
                            top: { md: "0px", sm: "1px" },
                          }}
                        />
                      }
                      sx={{
                        color: "#fff",
                        backgroundColor: "#00357D",
                        padding: "2px",
                        "&:not(:last-of-type)": { mr: 0.4 },
                        fontSize: { md: "14px", sm: "12px", xs: "14px" },
                        mb: 0.5,
                        mt: 0.5,
                      }}
                    />
                  ))}
                </Stack>
              )}
              {data.subscriptions.length > 0 && (
                <ListTitle
                  sx={{
                    fontSize: { md: "14px", sm: "12px", xs: "14px" },
                    color: "#00357D",
                    display: { sm: "none", xs: "block" },
                  }}
                >
                  צפו ברשימת הענפים המלאה
                </ListTitle>
              )}
            </AccordionSummary>

            <AccordionDetails
              sx={{
                padding: { sm: "16px", xs: "0px" },
                flexDirection: { sm: "row", xs: "row-reverse" },
                justifyContent: { sm: "space-around", xs: "center" },
              }}
            >
              {categoryList.map((list, index) => (
                <List
                  key={index}
                  sx={{
                    padding: "0px",
                    width: { sm: "auto", xs: "100%" },
                    maxWidth: { sm: "none", xs: "111px" },
                  }}
                >
                  {list.map((category) => (
                    <ListItem
                      key={category}
                      disablePadding
                      sx={{
                        "&.MuiListItem-root:not(:last-child)": {
                          borderBottom: { xs: "1px solid #00000017" },
                        },
                        paddingBlock: "3px",
                      }}
                    >
                      <ListItemButton
                        onClick={(ev) =>
                        {
                          handleChange(ev, null, {
                            name: "subscriptions",
                            subscription: category,
                          });
                        }}
                        sx={{
                          textAlign: "center",
                          borderRadius: "30px",
                          backgroundColor: data.subscriptions.includes(category)
                            ? "#00357d7a"
                            : "none",
                        }}
                      >
                        <ListItemText
                          onClick={(ev) =>
                          {
                            ev.stopPropagation();
                            handleChange(ev, null, {
                              name: "subscriptions",
                              subscription: category,
                            });
                          }}
                          primary={category}
                          sx={{
                            "& .MuiTypography-root": {
                              fontSize: {
                                xl: "18px",
                                lg: "17px",
                                md: "17px",
                                xs: "13px",
                              },
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ))}
            </AccordionDetails>
          </Accordion>

          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
              justifyContent: "end",
              padding: { sm: "0px", xs: "4px" },
              paddingBlockEnd: "0px",
              flexDirection: "column",
              display: { sm: "none" },
            }}
          >
            {data.subscriptions.map((suscription) => (
              <Chip
                key={suscription}
                label={suscription}
                onDelete={() =>
                {
                  handleDelete(suscription);
                }}
                deleteIcon={
                  <Box
                    component="img"
                    src={require("../../assets/icons/close-circle.png")}
                    sx={{
                      height: { md: "20px", sm: "18px", xs: "20px" },
                      width: { md: "20px", sm: "18px", xs: "20px" },
                      position: "relative",
                      top: { md: "0px", sm: "1px" },
                    }}
                  />
                }
                sx={{
                  color: "#fff",
                  backgroundColor: "#00357D",
                  padding: "2px",
                  "&:not(:last-of-type)": { mr: 0.4 },
                  fontSize: { md: "14px", sm: "12px", xs: "14px" },
                  mb: { xs: 0.5, sm: 0 },
                  width: "fit-content",
                  alignSelf: "end",
                  justifyContent: "end",
                }}
              />
            ))}
          </Stack>

          <Typography
            sx={{
              unicodeBidi: "plaintext",
              fontSize: "14px",
              mb: { sm: 3.5, xs: 1 },
            }}
          >
            *יש לבחור לפחות קטגוריה אחת, ועד ל - 5 קטגוריות
          </Typography>

          <Divider
            sx={{
              width: "100%",
              maxWidth: "314px",
              padding: 0,
              borderColor: "rgba(28, 124, 255, 0.25)",
              borderWidth: 0.5,
              marginBlock: "20px",
              marginInline: "auto",
              display: { sm: "none", xs: "block" },
            }}
          />

          {/* about the business */}
          <TitleReg2>ספרו לנו קצת על השירותים שאתם מציעים</TitleReg2>
          <TextField
            placeholder="ספרו לנו קצת על עצמכם, נסו לשלב מילות מפתח"
            size="small"
            name="businessDescription"
            value={data.businessDescription}
            onChange={(ev) =>
            {
              handleChange(ev, null, {
                name: null,
                subscription: null,
              });
            }}
            multiline={true}
            minRows={4}
            InputLabelProps={{ display: "none" }}
            sx={{
              maxWidth: { xl: "907px", md: "784px", sm: "550px" },
              width: "100%",
              "& .MuiInputBase-root": {
                borderRadius: "15px",
                backgroundColor: "#F5F9FF",
                fontSize: "14px",
                "&.MuiOutlinedInput-root": {
                  textarea: {
                    direction: "rtl",
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(28, 124, 255, 0.25)",
                  textAlign: "right",
                },
              },
            }}
          />

          {/* buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { sm: "row", xs: "column" },
              width: "100%",
              maxWidth: { xl: "907px", md: "784px", sm: "550px" },
              mb: { sm: 3, xs: 0 },
              mt: { sm: 2, xs: 4 },
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                maxWidth: { sm: "234px", xs: "100%" },
                width: "100%",
                alignItems: "center",
                mb: { sm: 0, xs: "20px" },
                "&.MuiButton-root": {
                  fontSize: { lg: "14px", sm: "14px", xs: "17px" },
                  padding: { sm: "4px", xs: "10px" },
                  alignSelf: { sm: "start", xs: "center" },
                },
              }}
              onClick={nextStep}
            >
              <ChevronLeftRoundedIcon
                fontSize="small"
                sx={{
                  position: "relative",
                  top: "1px",
                  right: "10px",
                  display: { sm: "inline", xs: "none" },
                }}
              />
              בואו נתקדם
            </Button>
            {errMsg && (
              <InputValidationText text={errMsg.text} color={errMsg.color} />
            )}

            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                maxWidth: { sm: "234px", xs: "100%" },
                width: "100%",
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: { lg: "14px", sm: "14px", xs: "17px" },
                  padding: { sm: "4px", xs: "10px" },
                  alignSelf: { sm: "start", xs: "center" },
                },
              }}
              onClick={() =>
              {
                back(data,2);
              }}
            >
              חזור
            </Button>
          </Box>

          <Divider
            sx={{
              width: "100%",
              maxWidth: "314px",
              padding: 0,
              borderColor: "#E7E7E7",
              borderWidth: 0.5,
              marginBlock: 4,
              marginInline: "auto",
              display: { sm: "none", xs: "block" },
            }}
          />

          <Box
            component="img"
            src={require("../../assets/icons/step2.png")}
            sx={{
              height: "100%",
              width: "100%",
              maxHeight: "38px",
              maxWidth: "214px",
              mb: { xs: 4 },
              marginInline: "auto",
            }}
          />
        </Box>
      </PageContainer>
      <Box
        component="img"
        src={require("../../assets/img/benefits-mobile-reg2.png")}
        sx={{
          display: { sm: "none", xs: "block" },
        }}
      />
    </Box>
  );
}
