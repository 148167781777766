import { Box, Button, Divider, Typography, Checkbox } from "@mui/material";
import React, { useState } from "react";

import
{
  PageContainer,
  //MainContainer,
  BoxForm,
  //StyledInput,
  StyledInputReg3,
  TitleAnnualReg3,
  BpCheckedIcon,
  BpIcon,
} from "../../styledComponents/signup";

import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { useLocation } from "react-router-dom";
import { useEffect, useReducer } from "react";
import InputValidationText from "../InputValidationText";
import { userService } from "../../services/userService.service";
import { AppwideValues } from "../../env"

const MainTitle = () => (
  <Box
    component="img"
    src={require("../../assets/img/title-reg3.png")}
    sx={{
      width: "100%",
      height: "100%",
      maxWidth: { lg: "350px", md: "300px", sm: "350px", xs: "246px" },
      margin: {
        lg: "43px auto 21px",
        md: "35px auto 21px",
        sm: "43px auto 21px",
        xs: "24px auto 2px",
      },
    }}
  />
);

const CardImage = ({ dis }) => (
  <Box
    component="img"
    src={require("../../assets/img/group-reg3.png")}
    sx={{
      mb: { md: 3, sm: 4, xs: 1.5 },
      height: { md: "159px", sm: "170px", xs: "125px" },
      width: { md: "292px", sm: "320px", xs: "200px" },
      alignSelf: "center",
      display: { ...dis },
    }}
  />
);

const BpCheckbox = (props) =>
{
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};

export default function PageThree({ forward, back, stepsData, isNoBack, nav })
{
  const location = useLocation();
  const isPrices = location?.state;
  // const [isAnnually, setAnnually] = useState(userService.RegistrationController.isAnnually());

  // const [isAnnually, setAnnually] = useReducer(
  //   (state, value) =>
  //   {
  //     userService.RegistrationController.setIsAnnually(value);
  //     return value;
  //   }, userService.RegistrationController.isAnnually());

  const regData3 = userService.RegistrationController.getRegData(3);
  const [data, setData] = useState({
    cardnumber: "",
    ccmonth: "",
    ccyear: "",
    cvv: "",
    cardID: "",
    isAnnually: regData3.isAnnually,
    acceptAgreement: false,
    acceptMailDelivery: false,
    email: regData3.email,
  });
  stepsData[3] = data;

  useEffect(
    () => setData((prevData) =>
    {
      if (prevData.isAnnually === userService.RegistrationController.isAnnually())
      {return prevData;}
      else
      return ({ ...prevData, isAnnually: userService.RegistrationController.isAnnually() })}),
    );
  // useEffect(
  //   () => setAnnually((prevData) => userService.RegistrationController.isAnnually() ),
  //   );

  const [errMsg, setErrMsg] = useState("");

  // useEffect(() =>
  // {
  //   setData((prevData) => ({ ...prevData, isAnnually: isAnnually }));
  // }, [isAnnually]);

  //const fields = ["cardNum", "month", "year", "cvv", "cardID"];

  const handleChange = ({ target }, { val, name } = {}) =>
  {
    const field = target.name || name;
    let value = target.value || val;

    if (value === "on") value = val;

    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // const validate = (field, value) => {
  //   var reg;
  //   const date = new Date();
  //   const year = date.getFullYear();
  //   const month = date.getMonth() + 1;
  //   if (!value) return null;

  //   switch (field) {
  //     case "cardNum":
  //       reg = new RegExp(/\d{16,}/, "g");
  //       return value.match(reg) && value.length >= 16;

  //     case "month":
  //       reg = new RegExp(/^(0[1-9]|1[012])$/, "g");

  //       if (value[0] === "0") {
  //         return value.match(reg) && +value[1] >= month && value.length === 2;
  //       } else {
  //         return value.match(reg) && value.length === 2;
  //       }

  //     case "year":
  //       reg = new RegExp(/\d{4}/, "g");
  //       return value.match(reg) && +value >= year && value.length === 4;

  //     case "cvv":
  //       reg = new RegExp(/\d{3}/, "g");
  //       if (value.match(reg)) {
  //         return value.match(reg)[0] && value.length === 3;
  //       } else {
  //         return value.match(reg);
  //       }

  //     case "cardID":
  //       reg = new RegExp(/\d{1,9}/, "g");
  //       return value.match(reg) && value.length > 0 && value.length <= 9;

  //     default:
  //       return false;
  //   }
  // };

  const nextStep = async () =>
  {
    try
    {
      const { cardnumber, ccyear, ccmonth, cvc, cardID, /*email, isAnnually,*/ acceptAgreement, acceptMailDelivery } = data;
      if (acceptAgreement &&
        acceptMailDelivery)
      {
        try
        {
          data.token = await userService.tokenizeCreditCard(cardnumber, ccmonth, ccyear, cvc, cardID)
        }
        catch (e)
        {
          setErrMsg({
            text: `ארעה שגיאה בעיבוד כרטיס האשראי הנתון
            ${e.message}`,
            color: "red",
          });
          return;
        }
        await userService.RegistrationController.register(data, 3)
        userService.forgotPassword(data.email);
        await forward(3, { ...data });
      }
      else
      {
        setErrMsg({
          text: "יש לאשר את הסכם השירות וקבלת דואר אלקטרוני כדי להשתמש בשירות",
          color: "red",
        });
      }
    } catch (err)
    {
      // if (err.response?.data?.statusCode === 409)
      // {
      //   setErrMsg({
      //     text: "משתמש כבר קיים במערכת, השתמש באימייל אחר",
      //     color: "red",
      //   });
      // } else 
      if (err.response?.data?.statusCode !== 200)
      {
        if (err.response?.data?.statusCode)
        {
          setErrMsg({
            text: `ארעה תקלה בשרת עם קוד ${err.response?.data?.statusCode}
          ${err.response.data.message}`,
            color: "red",
          });

        }
        else
        {
          setErrMsg({
            text: `ארעה תקלה לא ידועה: ${err.message}`,
            color: "red",
          });
        }
      }
      else
      {
        setErrMsg("");
      }
    }
    // } catch (err) {
    //   console.log("step3 err: ", err);
    //   setErrMsg({ text: "העיסקה נידחתה - בדוק את הפרטים שהזנת", color: "red" });
    // }
  };

  return (
    <PageContainer
      sx={{
        flexDirection: { md: "row", sm: "column-reverse", xs: "column" },
        justifyContent: "space-between",
        alignItems: { md: "flex-start" },
        position: "relative",
        left: { xl: "45px", lg: "51px", md: "-4px" },
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: { lg: "460px", md: "435px" },
          display: "flex",
          flexDirection: "column",
          mr: { xl: 2 },
        }}
      >
        <Box
          component="img"
          src={require(`../../assets/img/benefits-${data.isAnnually ? "annually-" : ""
            }reg3.png`)}
          sx={{
            width: "-webkit-fill-available",
            display: { sm: "block", xs: "none" },
          }}
        />

        <Box
          component="img"
          src={require("../../assets/img/frame-reg3.png")}
          sx={{
            display: { sm: "block", xs: "none" },
            maxWidth: { lg: "420px", md: "395px", sm: "535px" },
            width: "100%",
            alignSelf: "center",
            mt: { lg: 1.5, md: 0.9 },
          }}
        />
      </Box>

      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "30px",
          width: "100%",
          maxWidth: { md: "550px" },
          height: "100%",
          position: "relative",
          top: { md: "20px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingInline: { md: "60px", sm: "70px", xs: "22px" },
            //width: "100%",
            maxWidth: "-webkit-fill-available",
          }}
        >
          <MainTitle />

          <CardImage dis={{ sm: "none", xs: "block" }} />

          <BoxForm
            sx={{
              "& .MuiTextField-root": { maxWidth: "100%", mb: 1 },
              mb: { xs: 0 },
            }}
          >
            {/* <lable htmlFor="frmCardnumber"></lable>  */}
            <StyledInputReg3
              size="small"
              label="מס' כרטיס"
              name="cardnumber"
              id="frmCardnumber"
              autoComplete="cc-number"
              onChange={handleChange}
              sx={{
                "& .MuiInputLabel-root": {
                  "&.MuiInputLabel-shrink": {
                    right: "13px",
                  },
                },
                "&.Mui-focused": {
                  borderColor: "rgba(28, 124, 255, 0.25)",
                },
              }}
            />
            <Box sx={{ display: "flex", width: "100%" }}>

              {/* <lable htmlFor="frmCcmonth"></lable>  */}
              <StyledInputReg3
                size="small"
                label="חודש"
                name="ccmonth"
                id="frmCcmonth"
                autoComplete="cc-exp-month"
                onChange={handleChange}
                sx={{
                  "& .MuiInputLabel-root": {
                    "&.MuiInputLabel-shrink": {
                      right: "20px",
                    },
                  },
                }}
              />
              <Box
                component="img"
                src={require("../../assets/icons/slash.png")}
                sx={{
                  position: "relative",
                  height: "16px",
                  width: "16px",
                  top: "17px",
                  marginInline: "7px",
                }}
              />

              {/* <lable htmlFor="frmCcyear"></lable>  */}
              <StyledInputReg3
                size="small"
                label="שנה"
                name="ccyear"
                id="frmCcyear"
                autoComplete="cc-exp-year"
                onChange={handleChange}
                sx={{
                  "& .MuiInputLabel-root": {
                    "&.MuiInputLabel-shrink": {
                      right: "20px",
                    },
                  },
                }}
              />
            </Box>

            <Box sx={{ display: "flex", width: "100%" }}>
              <Box
                component="img"
                src={require("../../assets/img/cvv.png")}
                sx={{
                  position: "relative",
                  height: "38px",
                  width: "90px",
                  top: "6px",
                  ml: { lg: 4, md: 3 },
                  mr: 1,
                }}
              />

              {/* <lable htmlFor="frmCvc"></lable>  */}
              <StyledInputReg3
                size="small"
                label="3 ספרות אחרונות בגב הכרטיס / cvv"
                name="cvc"
                id="frmCvc"
                autoComplete="cc-csc"
                onChange={handleChange}
                sx={{
                  "& .MuiInputLabel-root": {
                    "&.MuiInputLabel-shrink": {
                      right: "-20px",
                    },
                  },
                }}
              />
            </Box>

            <StyledInputReg3
              size="small"
              label="מס' הזהות של בעל הכרטיס"
              name="cardID"
              onChange={handleChange}
              sx={{
                "& .MuiInputLabel-root": {
                  "&.MuiInputLabel-shrink": {
                    right: "-10px",
                  },
                },
              }}
            />
          </BoxForm>

          {errMsg && (
            <InputValidationText text={errMsg.text} color={errMsg.color} />
          )}

          <Divider
            sx={{
              width: "100%",
              maxWidth: { md: "345px", sm: "415px" },
              padding: 0,
              borderColor: "rgba(28, 124, 255, 0.25)",
              borderWidth: 0.5,
              marginBlockStart: { lg: 2.5, sm: 2, xs: 1.5 },
              marginBlockEnd: 3,
              marginInline: "auto",
            }}
          />

          <Box
            sx={{
              background: "#F5F9FF",
              border: "1px solid rgba(28, 124, 255, 0.25)",
              borderRadius: "30px",
              width: "220px",
              height: "38px",
              alignSelf: "end",
              position: "relative",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              cursor: "pointer",
              mb: 2.2,
            }}
            onClick={(ev) =>
            {
              ev.stopPropagation();
              const b = userService.RegistrationController.isAnnually();
              userService.RegistrationController.flipIsAnnually(!userService.RegistrationController.isAnnually());
              const a = userService.RegistrationController.isAnnually();
              const c = a;
              //setAnnually(!data.isAnnually)
              handleChange(ev, {
                val: !data.isAnnually, name: "isAnnually"
              })
            }
            }
          >
            <Button
              variant="contained"
              name="isAnnually"
              onClick={(ev) =>
              {
                ev.stopPropagation();

                const b = userService.RegistrationController.isAnnually();
                userService.RegistrationController.flipIsAnnually(!userService.RegistrationController.isAnnually());
                const a = userService.RegistrationController.isAnnually();
                const c = a;
                handleChange(ev, { val: !data.isAnnually, name: "isAnnually" });
              }}
              sx={{
                width: "120px",
                borderRadius: "30px",
                height: "30px",
                paddingBlock: "16px",
                "&.MuiButton-root": {
                  fontSize: { lg: "0.9rem", xs: "0.875rem" },
                  fontWeight: "400",
                },
                position: "absolute",
                transition: "left .2s ease-in-out, right .3s ease-in-out",
                left: data.isAnnually ? "3px" : "97px",
              }}
            >
              {data.isAnnually ? "שנתי" : "חודשי"}
            </Button>

            <Typography
              sx={{
                opacity: data.isAnnually ? "0" : "1",
                transition: "opacity .3s ease-out",
                fontSize: "14px",
              }}
            >
              שנתי
            </Typography>
            <Typography
              sx={{
                opacity: data.isAnnually ? "1" : "0",
                transition: "opacity .3s ease-out",
                fontSize: "14px",
              }}
            >
              חודשי
            </Typography>

            <Box
              component="img"
              src={require("../../assets/img/mumlatz-reg3.png")}
              sx={{
                position: "absolute",
                left: { sm: "-85px", xs: "-90px" },
                top: "-5px",
                display: data.isAnnually ? "block" : "none",
              }}
              onClick={(ev) => ev.stopPropagation()}
            />
            <Box
              component="img"
              src={require("../../assets/img/mumlatz-grey-reg3.png")}
              sx={{
                position: "absolute",
                left: { sm: "-85px", xs: "-90px" },
                top: "-5px",
                display: data.isAnnually ? "none" : "block",
              }}
              onClick={(ev) => ev.stopPropagation()}
            />
          </Box>

          {data.isAnnually && (
            <TitleAnnualReg3>
              <span
                style={{
                  display: "inline",
                  fontSize: "14px",
                  fontWeight: "700",
                  direction: "rtl",
                  marginInlineEnd: "3px",
                }}
              >
                מומלץ!
              </span>
              מסלול שנתי מתחדש ב- {AppwideValues.price.year} ₪ לשנה
            </TitleAnnualReg3>
          )}
          {!data.isAnnually && (
            <Box>
              <TitleAnnualReg3>מסלול חודשי מתחדש</TitleAnnualReg3>
              <TitleAnnualReg3>
                ב- {AppwideValues.price.month} ₪ לחודש עם אפשרות ביטול בכל עת
              </TitleAnnualReg3>
            </Box>
          )}

          <Divider
            sx={{
              width: "100%",
              maxWidth: { md: "345px", sm: "415px" },
              padding: 0,
              borderColor: "rgba(28, 124, 255, 0.25)",
              borderWidth: 0.5,
              marginBlockStart: { sm: 4.3, xs: 3.5 },
              marginBlockEnd: 2,
              marginInline: "auto",
            }}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: "end",
              marginBlockEnd: "-8px",
            }}
          >
            <Button
              sx={{
                display: "inline",
                padding: " 0px",
                fontWeight: "400",
                direction: "rtl",
                position: "relative",
                left: "11px",
                borderRadius: "30px",
                "&.MuiButton-root": {
                  fontSize: "14px",
                  textDecoration: "underline",
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                },
              }}
              onClick={() =>
              {
                nav("/policy");
              }}
            >
              התקנון
            </Button>
            <TitleAnnualReg3 sx={{ padding: 0 }}>אני מאשר את</TitleAnnualReg3>
            <BpCheckbox
              onChange={(ev) =>
                handleChange(ev, {
                  val: !data.acceptAgreement,
                  name: "acceptAgreement",
                })
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              alignSelf: "end",
              mb: { sm: 2.5, xs: 1.5 },
            }}
          >
            <TitleAnnualReg3 sx={{ padding: 0 }}>
              אני מאשר קבלת עדכונים והצעות במייל
            </TitleAnnualReg3>
            <BpCheckbox
              onChange={(ev) =>
                handleChange(ev, {
                  val: !data.acceptMailDelivery,
                  name: "acceptMailDelivery",
                })
              }
            />
          </Box>

          <Box sx={{ mb: { sm: 0, xs: 4.3 } }}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "30px",
                width: "100%",
                alignItems: "center",
                mb: { sm: "20px", xs: "15px" },
                "&.MuiButton-root": {
                  fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                  padding: { sm: "15px", xs: "10px" },
                },
              }}
              onClick={nextStep}
            >
              <ChevronLeftRoundedIcon
                sx={{
                  position: "relative",
                  top: "1px",
                  right: "5px",
                  display: "inline",
                }}
              />

              {data.isAnnually
                ? `צרו מנוי שנתי ב- ${AppwideValues.price.year.toLocaleString('he-IL')} ₪ לשנה`
                : `צרו מנוי חודשי ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לחודש`}
            </Button>
            {isNoBack ? '' :
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  width: "100%",
                  alignItems: "center",
                  "&.MuiButton-root": {
                    fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                    padding: { sm: "15px", xs: "10px" },
                  },
                }}
                onClick={() =>
                {
                  back(data, 3);
                }}
              >
                חזור
              </Button>}
          </Box>

          <CardImage dis={{ sm: "block", xs: "none" }} />

          <Box
            component="img"
            src={require("../../assets/icons/step3.png")}
            sx={{
              height: "100%",
              width: "100%",
              maxHeight: "38px",
              maxWidth: "214px",
              mb: { md: 2.5, xs: 3.8 },
              marginInline: "auto",
            }}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
            maxWidth: "354px",
            width: "100%",
          },
        }}
      >
        <Box
          component="img"
          src={require(`../../assets/img/benefits-${data.isAnnually ? "annually-" : ""
            }mobile-reg3.png`)}
          sx={{ width: "100%", marginBlock: 2 }}
        />
        <Box
          component="img"
          src={require("../../assets/img/frame-reg3.png")}
          sx={{ width: "100%" }}
        />
      </Box>
    </PageContainer>
  );
}
