import { IconButton, Stack, Box, Typography } from "@mui/material";
import React from "react";
import { userService } from "../../../services/userService.service";
import { offerService } from "../../../services/offerService.service";
import { isDate, stripTimeFromDate } from "../../../util/time";
import { editTaskManager } from "./EditTask";
import { editOfferManager } from "./AccompanyDashboard";
import { useNavigate } from "react-router-dom";

const TaskCard = ({ task, setOpen, setSelected }) =>
{
  const iconSize = { xs: "16px", md: "18px" };

  const nav = useNavigate();
  const onClose = () =>
  {
    console.log("close");
  };

  const handleDelete = (offerId, taskId) =>
  {
    taskId = task.id;
    if (!task.offerId)
    {
      offerId = offerService.UserOffer.allTasksOffers.value.taskIdToOfferId.get(task.id);
    }
    else
    {
      offerId = task.offerId;
    }
    offerService.UserOffer.delTask(taskId, offerId, onClose);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="flex-end"
      direction={{ xs: "row", md: "row" }}
      sx={{
        backgroundColor: "#fff",
        border: "1px solid #1c7cff3f",
        borderRadius: "50px",
        gap: "1rem",
        padding: "0.5rem 1rem",
        flexWrap: { xs: "wrap", md: "nowrap" },
      }}
    >
      <Box
        sx={{
          flex: { xs: "100%", md: "1 auto" },
        }}
      >
        <Typography
          component="h6"
          sx={{
            color: "#646464",
            fontWeight: 400,
            fontSize: "0.875rem",
            fontFamily: "Assistant",
            textAlign: { xs: "center", md: "unset" },
          }}
        >
          {task?.title}
        </Typography>
      </Box>
      <div>
        <Typography
          component="h6"
          sx={{
            color: "#646464",
            fontWeight: 400,
            fontSize: "0.875rem",
            fontFamily: "Assistant",
          }}
        >
          {task?.endDate ? new Date(task.endDate).toLocaleString([], {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
          }) : ''}
        </Typography>
      </div>
      <div>
        <Typography
          component="h6"
          sx={{
            color: "#646464",
            fontWeight: 400,
            fontSize: "0.875rem",
            fontFamily: "Assistant",
          }}
        >

          {task?.endDate ? new Date(task.endDate).toLocaleString([], { hour: '2-digit', minute: '2-digit'/*, second: '2-digit'*/ }) : ''}
        </Typography>
      </div>
      <div>
        <span
          className="dayPin"
          style={{
            backgroundColor: `${task?.color}`,
          }}
        />
      </div>
      <div>
        <Stack
          direction={{ xs: "row", sm: "row" }}
          sx={{
            gap: "0.1875rem",
          }}
        >
          {/* <IconButton>
            <Box
              component="img"
              src={require("../../../assets/icons/share.png")}
              sx={{ height: iconSize, width: iconSize }}
            />
          </IconButton> */}
          <IconButton
            onClick={() =>
            {
              // setSelected(data);
              // setOpen(true);
              const stupidMap = offerService.UserOffer.allTasksOffers.value.taskToOfferId;
              const offerId = stupidMap.get(task);
              editTaskManager.editTask(task, offerId ?? task?.offerId, () => { })
            }}
          >
            <Box
              component="img"
              src={require("../../../assets/icons/edit.png")}
              sx={{ height: iconSize, width: iconSize }}
            />
          </IconButton>
          <IconButton onClick={() => handleDelete(task?.offerId, task?.id)}>
            <Box
              component="img"
              src={require("../../../assets/icons/bin.png")}
              sx={{ height: iconSize, width: iconSize }}
            />
          </IconButton>
          <IconButton
            onClick={() =>
            {
              //editTaskManager.editTask(offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(String(event.taskId)), event?.offerId, () => { });
              const stupidMap = offerService.UserOffer.allTasksOffers.value.taskToOfferId;
              const offerId = stupidMap.get(task);
              editOfferManager.editOffer(offerId, task.id, () => { });
              nav("/dashboard/accompany");
            }}>
            <Box
              component="img"
              src={require("../../../assets/icons/arrow-left-chosen.png")}
              sx={{ height: iconSize, width: iconSize }}
            />
          </IconButton>
        </Stack>
      </div>
    </Stack>
  );
};

export default TaskCard;
