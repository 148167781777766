import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";

import Footer from "../components/Footer";
import Header from "../components/Header";

export default function NotFound({ openModal }) {
  const nav = useNavigate();

  return (
    <Box sx={{ height: "100vh" }}>
      <Header openModal={openModal} />

      {NotFoundComponent()}

      <Footer openModal={openModal} />
    </Box>
  );
}
export function NotFoundComponent()
{
  const nav = useNavigate();
  return <Box
    component="main"
    sx={{
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      maxWidth: { md: "100%", xs: "319px" },
      marginInline: "auto",
    }}
  >
    <Typography
      sx={{
        fontWeight: "600",
        fontSize: { md: "48px", sm: "35px", xs: "24px" },
        mt: { md: "60px", sm: "40px", xs: "25px" },
      }}
    >
      העמוד בבנייה
    </Typography>

    <Box
      component="img"
      src={require("../assets/img/404.png")}
      sx={{
        maxHeight: { md: "600px", sm: "500px", xs: "319px" },
        maxWidth: { md: "600px", sm: "500px", xs: "319px" },
      }} />

    <Button
      variant="contained"
      onClick={() =>
      {
        nav("/home");
      } }
      sx={{
        borderRadius: "30px",
        width: "100%",
        maxWidth: { sm: "338px", xs: "231px" },
        mb: { md: "80px", sm: "60px", xs: "38px" },
        alignItems: "center",
        "&.MuiButton-root": {
          fontSize: { sm: "20px", xs: "17px" },
          svg: { fontSize: { sm: "24px", xs: "20px" } },
          span: { mr: 0 },
        },
      }}
      //   the button icon
      startIcon={<ChevronLeftRoundedIcon
        sx={{
          position: "relative",
          top: "1px",
          right: "10px",
        }} />}
    >
      חיזרו לעמוד הבית
    </Button>
  </Box>;
}
