import { Box, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import Tasks from "../../assets/animations/hompage-tasks-anim.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Tasks,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const sentences = [
  "שירות חכם ופשוט לניהול כלל המכרזים וההצעות בעסק",
  "סוכן חכם - סינון הצעות לפי אופי העסק ומיקומו",
  "ליווי אישי - שירות ותמיכה VIP לכל המנויים",
  "מתאים למגוון רחב של עסקים בגדלים שונים",
];

export default function SecSeven() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const animationDimensions = () => {
    if (width <= 1200 && width > 900) {
      return {
        margin: "0px auto 0px 0px",
        height: "400px",
        width: "400px",
      };
    } else if (width <= 600) {
      return {
        margin: "60px auto 0px auto",
        height: "330px",
        width: "330px",
      };
    } else if (width <= 900 && width > 600) {
      return {
        margin: "0px auto 0px 0px",
        height: "300px",
        width: "300px",
      };
    } else if (width > 1200) {
      return {
        margin: "0px auto 0px 0px",
        height: "600px",
        width: "600px",
      };
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        mb: "170px",
        mt: { md: "227px", sm: "140px" },
      }}
    >
      {width > 600 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            height: "100%",
            maxWidth: "1550px",
            marginInline: { md: "auto" },
          }}
        >
          <Lottie options={defaultOptions} style={animationDimensions()} />

          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: { md: "fit-content", xs: "354px" },
              paddingInlineEnd: { md: "20px", sm: "15px" },
            }}
          >
            <Typography
              sx={{
                unicodeBidi: "plaintext",
                fontWeight: "600",
                fontSize: { xl: "45px", lg: "42px", md: "35px", xs: "28px" },
                mr: { md: "15px" },
                mb: { xl: "60px", lg: "50px", md: "30px", xs: "10px" },
                textAlign: { md: "start", xs: "center" },
              }}
            >
              למה כדאי "קשר" לעסק?
            </Typography>

            <List>
              {sentences.map((sentence, index) => (
                <ListItem
                  key={index}
                  sx={{
                    justifyContent: "flex-end",
                    paddingInline: "0px",
                    "&:not(:last-of-type)": {
                      mb: { xl: "37px", lg: "30px", md: "20px", xs: "10px" },
                    },
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "start",
                      unicodeBidi: "plaintext",
                      color: "#00357D",
                      fontSize: {
                        xl: "25px",
                        lg: "22px",
                        md: "20px",
                        xs: "18px",
                      },
                    }}
                  >
                    {sentence}
                  </Typography>
                  <Box
                    component="img"
                    src={require("../../assets/icons/list-v-icon.png")}
                    sx={{
                      ml: { md: "20px", sm: "10px", xs: "20px" },
                      height: {
                        xl: "42px",
                        lg: "35px",
                        md: "30px",
                        xs: "25px",
                      },
                      width: { xl: "42px", lg: "35px", md: "30px", xs: "25px" },
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>
      )}

      {width <= 600 && (
        <Box sx={{ textAlign: "center" }}>
          <Box
            component="img"
            src={require("../../assets/img/title-mobile-sec7.png")}
          />
          <Lottie options={defaultOptions} style={animationDimensions()} />
        </Box>
      )}
    </Box>
  );
}
