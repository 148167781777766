import
{
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  Popover,
  Modal,
} from "@mui/material";

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyledInput } from "../../../styledComponents/signup";
import Dropdown from "./Dropdown";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PaymentForm from "./PaymentForm";
import MobileSettingsDesign from "./MobileSettingsDesign";
import { useOutletContext } from "react-router-dom";
import
{
  updateBillingDetails,
  updatePassword,
  updateUser,
  uploadImage,
} from "../../../store/actions/userActions";
import InputValidationText from "../../InputValidationText";
import { AppwideValues } from "../../../env";
import { userService } from "../../../services/userService.service";
import { useCallback } from "react";
import Lottie from "react-lottie";
import Loader from "../../../assets/animations/loader.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
export default function SettingsDashboard()
{
  const dispatch = useDispatch();
  const [, , user] = useOutletContext();
  const { subscriptions } = user;
  //const { user:user2 } = useSelector((state) => state.userModule);
  const user2 = user;


  const [width, setWidth] = useState(window.innerWidth);
  // const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isEmailMatch, setIsEmailMatch] = useState(true);
  const [data, setData] = useState({ ...user });
  const [billingInfo, setBillingInfo] = useState({
    email: user.email,
    username: user.username,
    cardNum: "",
    month: "",
    year: "",
    cvv: "",
    cardID: "",
    invoice: "",
    annually: user?.isAnnually || true,
  });
  const [loginDets, setLoginDets] = useState({
    email: "",
    password: "",
  });

  // const RecursiveProxyHandlerRef = new WeakMap();
  // function makeRecursiveProxy(obj, notify)
  // {
  //   if (obj == null)
  //     return null;
  //   const ret = RecursiveProxyHandlerRef.get(obj)
  //   if (ret != null)
  //   { return ret; }
  //   const prox = new Proxy(obj, {
  //     get(target, prop, receiver)
  //     {
  //       const val = target[prop];
  //       if (val instanceof Object)
  //       {
  //         return makeRecursiveProxy(val, notify);
  //       }
  //       else
  //       {
  //         return val;
  //       }
  //     },
  //     set(target, property, value, receiver)
  //     {
  //       target[property] = value;
  //       notify();
  //       return true;
  //     },
  //   })
  //   RecursiveProxyHandlerRef.set(obj, prox);
  //   return prox;
  // }
  // function useReactive(val)
  // {
  //   const [, doNotify] = useState({});
  //   //let revalidate = false;
  //   // useEffect(()=>{
  //   //   if(revalidate)
  //   //     doNotify({});
  //   // })
  //   const ref = useRef(makeRecursiveProxy(val, () => setTimeout(()=>doNotify({}),0)));
  //   return ref.current;
  // }
  // const makeCancleSubscriptionModalState = () => ({
  //   show: false,
  //   message: '',
  //   renderfunc: null,
  // })
  //const cancleSubscriptionModalState = useReactive(makeCancleSubscriptionModalState());
  const [showCancleSubscriptionModal, setShowCancleSubscriptionModal] = useState(false);
  const CancleSubscriptionModal = function ({ closeFunc })
  {
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [state, setState] = useState('initial');

    const states = {
      initial: () =>
      {
        const onMessageChange =
          (e) =>
          {
            setMessage(e.target.value);
          };
        const sendMessage = async () =>
        {
          try
          {
            setState('sending');
            await userService.sendUnsubscribeEmail(message);
            setState('done');
          } catch (error)
          {
            setErrorMessage(error?.message + '\n' + '\n' + Object.entries(error?.response?.data??{}).map(([key, val]) => `${key}: ${val}`).join('\n'))
            setState('error');
          }
        }
        return (
          <>לשם ביטול המנוי, יש לשלוח בקשת ביטול מנוי ע"י כפתור השליחה<br />
            ניתן להוסיף הודעה לבקשת ביטול המנוי:
            <TextField
              placeholder="הודעה שתצורף לבקשת התנתקות"
              size="small"
              name="message"
              value={message}
              onChange={onMessageChange}
              multiline={true}
              minRows={4}
              InputLabelProps={{ display: "none" }}
              sx={{
                maxWidth: { xl: "907px", md: "784px", sm: "550px" },
                width: "100%",
                "& .MuiInputBase-root": {
                  borderRadius: "15px",
                  backgroundColor: "#F5F9FF",
                  fontSize: "14px",
                  "&.MuiOutlinedInput-root": {
                    textarea: {
                      direction: "rtl",
                    },
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(28, 124, 255, 0.25)",
                    textAlign: "right",
                  },
                },
              }}
            />
            <Button
              onClick={sendMessage}
            >
              שלח בקשת התנתקות
            </Button>
            <br />
            <br />
            <Button
              onClick={() =>
              {
                window.location.href = 'mailto:info@kesher-business.co.il?subject=בקשת ביטול מנוי';
              }}>
              נא ליצור קשר עימנו ב<br />
              info@kesher-business.co.il
            </Button>
          </>
        )
      },
      sending: () =>
      {
        return (<Lottie
          height={400}
          width={400}
          options={defaultOptions}
          style={{ maxWidth: "600px" }}
        />)
      },
      done: () =>
      {
        return (
          <>
            <Box>ההודעה נשלחה בהצלחה</Box>
            <Box
              component="img"
              src={require("../../../assets/icons/list-v-icon.png")}
              sx={{
                // maxWidth: { sm: "330px", xs: "277px" },
                // maxHeight: "156px",
                width: "100%",
                height: "100%",
                // marginBlockStart: { md: "140px", sm: "100px", xs: "132px" },
                // marginBlockEnd: { sm: "35px", xs: "80px" },
                backgroundColor: "#fff",
              }}
            />
          </>
        )
      },
      error: () =>
      {
        return (
          <>
            <Box>ארעה שגיאה בשליחה:</Box><br />
            <Box>{errorMessage.split('\n').map(text => (
              <>{text}<br /></>))}</Box><br/>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "30px",
                width: "100%",
                alignItems: "center",
                "&.MuiButton-root": {
                  fontSize: { lg: "15px", sm: "14px", xs: "17px" },
                  padding: { sm: "15px", xs: "10px" },
                },
              }}
              onClick={() =>
              {
                setState('initial');
              }}
            >
              חזור
            </Button>
          </>
        )
      },

    }
    return states[state]();
  }


  const [anchorEl, setAnchorEl] = useState(null);

  const details = [
    { label: "מייל", data: data.email, name: "email" },
    { label: "מס' טלפון", data: data.phoneNumber, name: "phoneNumber" },
    { label: "שם מלא", data: data.username, name: "username" },
    { label: "שם העסק", data: data.businessName, name: "businessName" },
  ];

  const loginDetails = [
    { label: "סיסמה חדשה", data: loginDets.password, name: "password" },
    { label: "מייל", data: loginDets.email, name: "email" },
  ];

  useEffect(() =>
  {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const handleChange = ({ target }, type = null, newData = null) =>
  {
    let field = target.name;
    let value = target.value;

    if (type === "intrestRegion" && newData)
    {
      value = intrestRegionArr(newData);
      field = type;
    }
    if (type === "businessType" && newData)
    {
      value = newData;
      field = type;
    }
    setData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleChangeBilling = ({ target }) =>
  {
    let field = target.name;
    let value = target.value;

    setBillingInfo((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleChangePassword = ({ target }) =>
  {
    let field = target.name;
    let value = target.value;

    if (!isEmailMatch)
    {
      setIsEmailMatch(true);
    }

    setLoginDets((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const intrestRegionArr = (region) =>
  {
    let newArr;
    if (Array.isArray(region))
    {
      if (data.intrestRegion.length < 5)
      {
        newArr = [...region];
      } else newArr = [];
    } else if (!data.intrestRegion.includes(region))
    {
      newArr = [...data.intrestRegion, region];
    } else
    {
      newArr = [...data.intrestRegion].filter((reg) => reg !== region);
    }
    return newArr;
  };

  const changePassword = async () =>
  {
    try
    {
      if (loginDets.email !== data.email)
      {
        setIsEmailMatch(false);
        setErrMsg({ text: 'כתובת הדוא"ל שגויה', color: "red" })
        return;
      } else
      {
        setIsEmailMatch(true);
        //dispatch(updatePassword({ ...loginDets, username: data.username }));
        const res = await userService.updatePassword({ ...loginDets, username: data.username });
        if (res.status === 200 || res.status === 201)
        {
          setErrMsg({ text: "הסיסמה שונתה בהצלחה", color: "green" });
        }
        else
        {
          setErrMsg({ text: res.data.message, color: "red" });
        }
        setTimeout(() =>
        {
          setErrMsg("");
        }, 6000);
        setLoginDets((prevState) => ({ email: "", password: "" }));
      }
    } catch (err)
    {
      console.log("component: ", err);
      setErrMsg({ text: `לא ניתן היה לשנות סיסמה: ${err.response.data.message}`, color: "red" });
      setTimeout(() =>
      {
        setErrMsg("");
      }, 6000);
    }
  };

  const saveBillingInfo = () =>
  {
    dispatch(updateBillingDetails({ ...billingInfo }));
  };

  const saveChanges = () =>
  {
    dispatch(
      updateUser({
        username: data.username,
        businessName: data.businessName,
        phoneNumber: data.phoneNumber,
        email: user.email,
        businessType: data.businessType,
        businessDescription: data.businessDescription,
        //intrestRegion: data.intrestRegion,
      })
    );
  };

  const regStateColor = () =>
  {
    switch (data.registrationState)
    {
      case "active ending":
        return AppwideValues.themeColors.yellow;
      case "inactive":
        return "#ea5369";
      default:
        return AppwideValues.themeColors.green;
    }
  };

  const updateDimensions = () =>
  {
    setWidth(window.innerWidth);
  };

  const onImageChange = async (ev) =>
  {
    if (ev.target.files && ev.target.files[0])
    {
      let img = ev.target.files[0];
      const resized = await process_image(img, 64);

      dispatch(uploadImage({ image: resized }));
    }
  };

  const reduce_image_file_size = async (
    base64Str,
    MAX_WIDTH = 100,
    MAX_HEIGHT = 100
  ) =>
  {
    let resized_base64 = await new Promise((resolve) =>
    {
      let img = new Image();
      img.src = base64Str;
      img.onload = () =>
      {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height)
        {
          if (width > MAX_WIDTH)
          {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else
        {
          if (height > MAX_HEIGHT)
          {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
    return resized_base64;
  };

  const image_to_base64 = async (file) =>
  {
    let result_base64 = await new Promise((resolve) =>
    {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.onerror = (error) =>
      {
        console.log(error);
        alert("An Error occurred please try again, File might be corrupt");
      };
      fileReader.readAsDataURL(file);
    });
    return result_base64;
  };

  const process_image = async (file, min_image_size = 100) =>
  {
    const res = await image_to_base64(file);
    if (res)
    {
      const old_size = calc_image_size(res);
      if (old_size > min_image_size)
      {
        const resized = await reduce_image_file_size(res);
        const new_size = calc_image_size(resized);
        console.log("new_size=> ", new_size, "KB");
        console.log("old_size=> ", old_size, "KB");
        return resized;
      } else
      {
        console.log("image already small enough");
        return res;
      }
    } else
    {
      console.log("return err");
      return null;
    }
  };

  function calc_image_size(image)
  {
    let y = 1;
    if (image.endsWith("=="))
    {
      y = 2;
    }
    const x_size = image.length * (3 / 4) - y;
    return Math.round(x_size / 1024);
  }

  const handlePopoverOpen = (event) =>
  {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () =>
  {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        backgroundColor: "#E5E5E5",
        width: "100%",
        //overflowY: "overlay",
        overflowY: "auto",
        height: "89.5vh",
        direction: "rtl",

        "&::-webkit-scrollbar": {
          display: "block",
          width: "12px",
        },
        "&::-webkit-scrollbar-track": {
          borderRadius: "20px",
          mt: "5px",
          mb: "57px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#1C7CFF",
          borderRight: "none",
          borderLeft: "none",
          borderRadius: "20px",
          border: "4px solid #E5E5E5",
        },
      }}
    >
      <Grid
        container
        spacing={0}
        sx={{
          width: "100%",
          maxWidth: {
            xl: width > 1655 ? "1500px" : "1355px",
            lg: width > 1360 ? "1190px" : "1020px",
            md: "780px",
            sm: "510px",
            xs: "354px",
          },
          paddingBlockStart: "39px",
          justifyContent: "space-between",
          marginInline: "auto",
          direction: "ltr",
          mb: "100px",
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "right",
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "end", xs: "center" },
            height: "fit-content",
            mb: { md: "22px", xs: "63px" },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              mb: { md: "8px", xs: "22px" },
            }}
          >
            הגדרות פרופיל
          </Typography>
          <Box
            sx={{
              width: "-webkit-fill-available",
              minWidth: '325px',
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { md: "row", xs: "column" },
              paddingBlockStart: { md: "19px", xs: "30px" },
              paddingBlockEnd: "29px",
              paddingInlineEnd: { md: width > 1360 ? "29px" : "15px", xs: '10px' },
              paddingInlineStart: '10px',
              backgroundColor: "#FFF",
              borderRadius: "15px",
              boxShadow: {
                md: "2px 4px 4px rgba(28, 124, 255, 0.22)",
                xs: "0px 8px 48px rgba(161, 194, 244, 0.68)",
              },
            }}
          >
            {/* left side */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                flexBasis: { md: "250px", xs: "200px" },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: { md: "24px" },
                  width: "100%",
                  maxWidth: { md: "140px", sm: "190px", xs: "170px" },
                }}
              >
                {/* image */}
                <Box
                  sx={{
                    height: "124px",
                    width: "124px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    src={user2.image}
                    sx={{
                      borderRadius: "50%",
                      height: "inherit",
                      width: "inherit",
                    }}
                  />
                </Box>

                {/* buttons */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    mt: { md: "17px", xs: "21px" },
                    justifyContent: "space-around",
                  }}
                >
                  <IconButton component="label">
                    <Box
                      component="img"
                      src={require("../../../assets/icons/document-upload-settings.png")}
                    />
                    <input
                      id="upload-image"
                      type="file"
                      hidden
                      onChange={onImageChange}
                    />
                  </IconButton>
                  {/* <IconButton>
                    <Box
                      component="img"
                      src={require("../../../assets/icons/camera-settings.png")}
                    />
                  </IconButton> */}
                  <IconButton>
                    <Box
                      component="img"
                      src={require("../../../assets/icons/trash-settings.png")}
                    />
                  </IconButton>
                </Box>
              </Box>

              <Button
                variant="contained"
                onClick={saveChanges}
                sx={{
                  display: { md: "flex", xs: "none" },
                  borderRadius: "30px",
                  maxWidth: { sm: "210px", xs: "100%" },
                  width: "100%",
                  alignItems: "center",
                  "&.MuiButton-root": {
                    fontSize: { sm: "14px", xs: "17px" },
                    padding: { sm: "10px 50px", xs: "10px" },
                  },
                }}
              >
                <ChevronLeftRoundedIcon
                  sx={{
                    position: "relative",
                    top: "1px",
                    right: "10px",
                    display: { sm: "inline", xs: "none" },
                  }}
                />
                שמור שינויים
              </Button>
            </Box>

            {/* right side */}
            <Box
              component="form"
              sx={{
                width: "100%",
                flexBasis: {
                  xl: "1060px",
                  lg: width > 1360 ? "920px" : "750px",
                  md: "500px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "flex-start" },
                  flexDirection: { md: "row-reverse", xs: "column" },
                  alignItems: { md: "end", xs: "center" },
                  width: "100%",
                  flexWrap: { md: "wrap" },
                }}
              >
                {details.map(({ label, data, name }) => (
                  <Box
                    key={name}
                    sx={{
                      width: "100%",
                      maxWidth: { md: "206px", sm: "360px", xs: "324px" },

                      ml: { md: "11px" },
                      mb: {
                        lg: "15px",
                        md: "15px",
                        xs: "31px",
                      },
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#000",
                        position: "relative",
                        right: { sm: "5.5%", xs: "8.5%" },
                        mb: "9px",
                      }}
                    >
                      {label}
                    </InputLabel>
                    <StyledInput
                      name={name}
                      value={data}
                      onChange={(ev) =>
                      {
                        handleChange(ev);
                      }}
                      sx={{
                        "&.MuiFormControl-root": {
                          width: "100%",
                          input: {
                            paddingBlock: "10.5px",
                          },
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "#F5F9FF",
                          },
                        },
                      }}
                    />
                  </Box>
                ))}

                {/* dropdown */}
                <Box
                  sx={{
                    display: { xl: "none", xs: "flex" },
                    flexDirection: { md: "row", xs: "column" },
                    maxWidth: { md: "409px", sm: "360px", xs: "324px" },
                    width: "100%",
                    mt: { md: "20px" },
                    mb: { md: "20px" },
                  }}
                >
                  {["intrestRegion", "businessType"].map((type, index) => (
                    <Box
                      key={type}
                      sx={{
                        width: "100%",
                        height: "44px",
                        maxWidth: {
                          md: type === "intrestRegion" ? "185px" : "206px",
                          sm: "360px",
                          xs: "324px",
                        },
                        mr: { md: type === "intrestRegion" ? "11px" : "0px" },
                        mt: { md: "0px", xs: "20px" },
                        mb: { md: "0px", xs: "31px" },
                      }}
                    >
                      <Dropdown
                        user={data}
                        handleChange={handleChange}
                        type={type}
                      />
                    </Box>
                  ))}
                </Box>

                <Divider
                  sx={{
                    display: { md: "none", xs: "block" },
                    mt: "11px",
                    mb: "20px",
                    width: "100%",
                    maxWidth: { sm: "360px", xs: "324px" },
                    marginInline: "auto",
                    borderColor: "rgba(28, 124, 255, 0.25)",
                  }}
                />
                {/* change password */}
                <Box
                  sx={{
                    display: { xl: "none", lg: "inline-flex", xs: "flex" },
                    width: { xl: "auto", xs: "100%" },
                    maxWidth: { md: "100%", xs: "355px" },
                    flexDirection: { md: "row-reverse", xs: "column" },
                    alignItems: { lg: "flex-end" },
                  }}
                >
                  <Typography
                    sx={{
                      display: { md: "none" },
                      fontWeight: { md: "400", xs: "600" },
                      fontSize: { md: "14px", xs: "17px" },
                      color: "#000",
                      position: "relative",
                      right: "1.3%",
                      mb: { md: "9px", xs: "13px" },
                      alignSelf: { md: "end", xs: "center" },
                    }}
                  >
                    שינוי סיסמה
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row", xs: "column-reverse" },
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {loginDetails.map(({ label, data, name }) => (
                      <Box
                        key={name}
                        sx={{
                          width: "100%",
                          maxWidth: { md: "206px", sm: "360px", xs: "324px" },
                          ml: { md: "11px" },
                          mb: { md: "0px", xs: "31px" },
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color:
                              !isEmailMatch && name === "email"
                                ? "red"
                                : "#000",
                            position: "relative",
                            right: { sm: "5.5%", xs: "8.5%" },
                            mb: "9px",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "end",
                          }}
                        >
                          {name === "password" && (
                            <InfoTwoToneIcon
                              color="primary"
                              sx={{ mr: "5px" }}
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                            />
                          )}
                          {label}
                        </InputLabel>

                        {/* info popup */}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                            "& .MuiTypography-root": {
                              unicodeBidi: "plaintext",
                              justifyContent: "end",
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                              svg: {
                                fontSize: "12px",
                                ml: "5px",
                              },
                            },
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography
                            sx={{
                              p: 1,
                              fontWeight: "600",
                              textDecoration: "underline",
                            }}
                          >
                            הסיסמה חייבת להכיל:
                          </Typography>
                          {/* <Typography>
                            ללא רווחים
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                          <Typography>
                            לא לחזור על אותו תו יותר מפעמיים ברצף
                            <FiberManualRecordIcon color="primary" />
                          </Typography> */}
                          <Typography>
                            בין 8 ל 64 תווים
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                          <Typography>
                              ללא יותר משני תווים זהים ברצף 
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                          
                        </Popover>

                        <StyledInput
                          type={name}
                          name={name}
                          value={data}
                          onChange={(ev) =>
                          {
                            handleChangePassword(ev);
                          }}
                          sx={{
                            "&.MuiFormControl-root": {
                              width: "100%",
                              input: {
                                paddingBlock: "10.5px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#F5F9FF",
                              },
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    {errMsg && (
                      <InputValidationText
                        text={errMsg.text}
                        color={errMsg.color}
                      />
                    )}
                    <Button
                      variant="contained"
                      onClick={changePassword}
                      sx={{
                        display: "flex",
                        borderRadius: "30px",
                        maxWidth: {
                          lg: "165px",
                          md: "110px",
                          sm: "360px",
                          xs: "324px",
                        },
                        width: "100%",
                        height: "44px",
                        alignItems: "center",
                        alignSelf: { md: "end", xs: "center" },
                        "&.MuiButton-root": {
                          fontSize: { sm: "14px", xs: "17px" },
                          padding: { lg: "10px 50px", md: "0px", xs: "10px" },
                        },
                      }}
                    >
                      שנה סיסמה
                    </Button>
                  </Box>
                </Box>

                {/* dropdown */}
                <Box
                  sx={{
                    display: { xl: "flex", xs: "none" },
                    flexDirection: { md: "row", xs: "column" },
                    justifyContent: { md: "end", xs: "center" },
                    maxWidth: { md: "409px", sm: "360px", xs: "324px" },
                    width: "100%",
                    ml: { xl: "10px" },
                    height: "47px",
                  }}
                >
                  {["intrestRegion", "businessType"].map((type, index) => (
                    <Box
                      key={type}
                      sx={{
                        width: "100%",
                        height: "44px",
                        maxWidth: {
                          md: type === "intrestRegion" ? "185px" : "206px",
                          sm: "360px",
                          xs: "324px",
                        },
                        mr: { md: type === "intrestRegion" ? "11px" : "0px" },
                        mt: { lg: "0px", md: "15px", xs: "31px" },
                        mb: { md: "0px", xs: "31px" },
                      }}
                    >
                      <Dropdown
                        user={data}
                        handleChange={handleChange}
                        type={type}
                      />
                    </Box>
                  ))}
                </Box>

                {/* change password */}
                <Box
                  sx={{
                    display: { xl: "inline-flex", xs: "none" },
                    width: { xl: "auto", xs: "100%" },
                    maxWidth: { md: "100%", xs: "355px" },
                    mt: { xl: "31px" },
                    flexDirection: { md: "row-reverse", xs: "column" },
                    alignItems: { lg: "flex-end" },
                  }}
                >
                  <Typography
                    sx={{
                      display: { md: "none" },
                      fontWeight: { md: "400", xs: "600" },
                      fontSize: { md: "14px", xs: "17px" },
                      color: "#000",
                      position: "relative",
                      right: "1.3%",
                      mb: { md: "9px", xs: "13px" },
                      alignSelf: { md: "end", xs: "center" },
                    }}
                  >
                    שינוי סיסמה
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { md: "row", xs: "column-reverse" },
                      justifyContent: "flex-end",
                    }}
                  >
                    {loginDetails.map(({ label, data, name }) => (
                      <Box
                        key={name}
                        sx={{
                          width: "100%",
                          maxWidth: { md: "206px", sm: "360px", xs: "324px" },
                          ml: { md: "11px" },
                          mb: { md: "0px", xs: "31px" },
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                        }}
                      >
                        <InputLabel
                          sx={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color:
                              !isEmailMatch && name === "email"
                                ? "red"
                                : "#000",
                            position: "relative",
                            right: { sm: "5.5%", xs: "8.5%" },
                            mb: "9px",
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "end",
                          }}
                        >
                          {name === "password" && (
                            <InfoTwoToneIcon
                              color="primary"
                              sx={{ mr: "5px" }}
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                            />
                          )}
                          {label}
                        </InputLabel>

                        {/* info popup */}
                        <Popover
                          id="mouse-over-popover"
                          sx={{
                            pointerEvents: "none",
                            "& .MuiTypography-root": {
                              unicodeBidi: "plaintext",
                              justifyContent: "end",
                              display: "flex",
                              alignItems: "center",
                              p: 1,
                              svg: {
                                fontSize: "12px",
                                ml: "5px",
                              },
                            },
                          }}
                          open={open}
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          onClose={handlePopoverClose}
                          disableRestoreFocus
                        >
                          <Typography
                            sx={{
                              p: 1,
                              fontWeight: "600",
                              textDecoration: "underline",
                            }}
                          >
                            הסיסמה חייבת להכיל:
                          </Typography>
                          {/* <Typography>
                            ללא רווחים
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                          <Typography>
                            לא לחזור על אותו תו יותר מפעמיים ברצף
                            <FiberManualRecordIcon color="primary" />
                          </Typography> */}
                          <Typography>
                          בין 8 ל 64 תווים
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                          <Typography>
                            ללא יותר משני תווים זהים ברצף
                            <FiberManualRecordIcon color="primary" />
                          </Typography>
                         
                        </Popover>

                        <StyledInput
                          type={name}
                          name={name}
                          value={data}
                          onChange={(ev) =>
                          {
                            handleChangePassword(ev);
                          }}
                          sx={{
                            "&.MuiFormControl-root": {
                              width: "100%",
                              input: {
                                paddingBlock: "10.5px",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "#F5F9FF",
                              },
                            },
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                  <Box>
                    {errMsg && (
                      <InputValidationText
                        text={errMsg.text}
                        color={errMsg.color}
                      />
                    )}
                    <Button
                      variant="contained"
                      onClick={changePassword}
                      sx={{
                        display: "flex",
                        borderRadius: "30px",
                        maxWidth: { lg: "165px", md: "110px", xs: "100%" },
                        width: "100%",
                        height: "44px",
                        alignItems: "center",
                        alignSelf: "end",
                        "&.MuiButton-root": {
                          fontSize: { sm: "14px", xs: "17px" },
                          padding: { lg: "10px 50px", md: "0px", xs: "10px" },
                        },
                      }}
                    >
                      שנה סיסמה
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Divider
                sx={{
                  display: { md: "none", xs: "block" },
                  mt: "31px",
                  mb: "21px",
                  width: "100%",
                  maxWidth: { sm: "360px", xs: "324px" },
                  marginInline: "auto",
                  borderColor: "rgba(28, 124, 255, 0.25)",
                }}
              />

              {/* categories */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: { md: "100%", sm: "360px", xs: "324px" },
                  mt: "35px",
                  mb: { md: "0px", xs: "22px" },
                  marginInline: "auto",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: { md: "400", xs: "600" },
                    fontSize: { md: "14px", xs: "17px" },
                    color: "#000",
                    position: "relative",
                    right: "1.3%",
                    mb: { md: "9px", xs: "13px" },
                    alignSelf: { md: "end", xs: "center" },
                  }}
                >
                  קטגוריות נבחרות
                </Typography>

                <Stack
                  direction="row"
                  sx={{
                    width: "-webkit-fill-available",
                    display: "flex",
                    flexDirection: { md: "row", xs: "column" },
                    justifyContent: "end",
                    alignItems: { md: "center", xs: "end" },
                    flexWrap: "wrap",

                    paddingBlockEnd: "0px",
                    backgroundColor: { md: "#F5F9FF" },
                    borderRadius: "30px",
                    padding: { md: "5px 6px", xs: "5px 12px" },
                    border: { md: "1px solid rgba(28, 124, 255, 0.25)" },
                  }}
                >
                  {subscriptions.map((subscription) => (
                    <Chip
                      key={subscription}
                      label={subscription}
                      onDelete={() => { }}
                      deleteIcon={
                        <Box
                          component="img"
                          src={require("../../../assets/icons/close-circle.png")}
                          sx={{
                            height: { md: "20px", sm: "18px", xs: "20px" },
                            width: { md: "20px", sm: "18px", xs: "20px" },
                            position: "relative",
                            top: { md: "0px", sm: "1px" },
                          }}
                        />
                      }
                      sx={{
                        color: "#fff",
                        backgroundColor: "#00357D",
                        padding: "2px",
                        "&:not(:last-of-type)": { mr: { md: 0.4 } },
                        fontSize: { md: "14px", sm: "12px", xs: "14px" },
                        mb: { md: 0, xs: "9px" },
                      }}
                    />
                  ))}
                </Stack>
              </Box>

              <Divider
                sx={{
                  display: { md: "none", xs: "block" },
                  mb: "25px",
                  width: "100%",
                  maxWidth: { sm: "360px", xs: "324px" },
                  marginInline: "auto",
                  borderColor: "rgba(28, 124, 255, 0.25)",
                }}
              />

              {/* description */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "end",
                  flexDirection: "column",
                  width: "100%",
                  mt: "23px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: { md: "400", xs: "600" },
                    fontSize: { md: "14px", xs: "17px" },
                    mb: { md: "9px", xs: "13px" },
                    alignSelf: { md: "end", xs: "center" },
                    color: "#000",
                    position: "relative",
                    right: "1.2%",
                  }}
                >
                  תיאור העסק
                </Typography>

                <TextField
                  value={data.businessDescription}
                  name="businessDescription"
                  onChange={(ev) =>
                  {
                    handleChange(ev);
                  }}
                  multiline={true}
                  minRows={2}
                  InputLabelProps={{ display: "none" }}
                  sx={{
                    width: "100%",
                    maxWidth: { md: "100%", sm: "360px", xs: "324px" },
                    marginInline: "auto",
                    "& .MuiInputBase-root": {
                      borderRadius: "15px",
                      backgroundColor: "#F5F9FF",
                      fontSize: "14px",
                      "&.MuiOutlinedInput-root": {
                        textarea: {
                          direction: "rtl",
                        },
                        padding: "9px 14px",
                        "&:hover": {
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "rgba(28, 124, 255, 0.25)",
                          },
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "rgba(28, 124, 255, 0.25)",
                        textAlign: "right",
                      },
                    },
                  }}
                />
              </Box>

              <Button
                variant="contained"
                onClick={saveChanges}
                sx={{
                  display: { md: "none", xs: "flex" },
                  borderRadius: "30px",
                  maxWidth: "333px",
                  width: "100%",
                  alignItems: "center",
                  marginInline: "auto",
                  mt: "40px",
                  "&.MuiButton-root": {
                    fontSize: { sm: "14px", xs: "17px" },
                    padding: { sm: "10px 50px", xs: "10px" },
                  },
                }}
              >
                <ChevronLeftRoundedIcon
                  sx={{
                    position: "relative",
                    top: "1px",
                    right: "10px",
                    display: { sm: "inline", xs: "none" },
                  }}
                />
                שמור שינויים
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* registrationState */}
        <Grid
          item
          md={5}
          sx={{
            display: { md: "flex", xs: "none" },
            textAlign: "right",
            flexDirection: "column",
            alignItems: "end",
            width: "100%",
            "&.MuiGrid-root": {
              maxWidth: "523px",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              mb: { md: "8px", xs: "22px" },
            }}
          >
            הגדרות מנוי
          </Typography>

          <Box
            sx={{
              display: "flex",
              width: "-webkit-fill-available",
              flexDirection: "column",
              backgroundColor: "#FFF",
              borderRadius: "15px",
              boxShadow: {
                md: "2px 4px 4px rgba(28, 124, 255, 0.22)",
                xs: "0px 8px 48px rgba(161, 194, 244, 0.68)",
              },
              paddingBlockStart: "22px",
              paddingInlineEnd: { lg: "36px", sm: "20px" },
              paddingInlineStart: { lg: "59px", sm: "20px" },
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "700", mb: "7px" }}>
              הגדרות פרופיל
            </Typography>
            <Box
              sx={{ display: "flex", mb: "19px", justifyContent: "flex-end" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    textAlign: "right",
                    color: " #000000",
                  }}
                >
                  {data.registrationState === "active" && "פעיל"}
                  {data.registrationState === "active trial" && "פעיל - ניסיון"}
                  {data.registrationState === "active ending" &&
                    "פעיל - מנוי בוטל"}
                  {data.registrationState === "inactive" && "לא פעיל"}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    textAlign: "right",
                    color: " #000000",
                  }}
                >
                  {!data?.isAnnually &&
                    `מסלול חודשי מתחדש ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לחודש עם אפשרות ביטול בכל עת`}
                  {data?.isAnnually && `מסלול שנתי ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לשנה`}
                  {/* {!data?.isAnnually &&
                    data?.isAnnually &&
                    `סלול חודשי מתחדש ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לחודש עם אפשרות ביטול בכל עת`} */}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "14px",
                  width: "14px",
                  backgroundColor: regStateColor(),
                  borderRadius: "50%",
                  ml: "7px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </Box>

            <Button
              variant="text"
              sx={{
                "&.MuiButton-root": {
                  fontSize: "14px",
                  borderRadius: "30px",
                  padding: " 6px 12px",
                },
                mb: { lg: "39px", sm: "20px" },
                width: "fit-content",
                alignSelf: "end",
              }}
              onClick={() => setShowCancleSubscriptionModal(true)}
            >
              ביטול מנוי
            </Button>
            <Modal
              sx={{
                backgroundColor: "rgba(3, 44, 101, 0.8)",
                overflowY: "auto",
                fontFamily: 'Assistant,sans-serif',
                direction: 'rtl',
              }}
              open={showCancleSubscriptionModal}
              onClose={() => setShowCancleSubscriptionModal(false)}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"

            >
              <>
                <IconButton
                  aria-label="Close"
                  onClick={() => setShowCancleSubscriptionModal(false)}
                  sx={{
                    width: "min-content",
                    marginBlock: { xl: "15px", md: "10px" },
                    display: "flex",
                    marginInline: "auto",
                  }}
                >
                  <Box
                    component="img"
                    src={require("../../../assets/icons/close-circle.png")}
                    sx={{
                      height: { md: "30px", xs: "30px" },
                      width: { md: "30px", xs: "30px" },
                      marginBlock: { md: 0, xs: 1 },
                    }}
                  />
                </IconButton>
                <Box
                  sx={{
                    maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
                    height: "fit-content",
                    width: "fit-content",
                    borderRadius: "30px",
                    marginInline: 'auto',
                    mb: 5,
                    backgroundColor: 'white',
                    textAlign: 'center',
                    padding: '30px',
                  }}

                >
                  {/* דף זה בבניה <br/>
                  <Button
                    onClick={() =>
                    {
                      window.location.href = 'mailto:info@kesher-business.co.il?subject=בקשת ביטול מנוי';
                    }}>
                  נא ליצור קשר עימנו ב<br/>
                  info@kesher-business.co.il
                  </Button> */}
                  {/* {cancleSubscriptionModalState.renderFunc != null && cancleSubscriptionModal.renderFunc} */}
                  <CancleSubscriptionModal></CancleSubscriptionModal>
                </Box>
              </>
            </Modal>
          </Box>
        </Grid>

        {/* billing details */}
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "flex-end", xs: "center" },
            textAlign: "right",
            ml: { xl: "20px" },
            mb: { md: "0px", xs: "66px" },
            width: "100%",
            "&.MuiGrid-root": {
              maxWidth: {
                xl: width > 1655 ? "950px" : "810px",
                lg: "520px",
                md: "420px",
              },
              flexBasis: {
                xl: width > 1655 ? "950px" : "810px",
                lg: "520px",
                md: "420px",
              },
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              mb: { md: "8px", xs: "22px" },
            }}
          >
            הגדרות חשבון
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: { md: "flex-end", xs: "center" },
              width: "-webkit-fill-available",
              flexDirection: "column",
              backgroundColor: "#FFF",
              borderRadius: "15px",
              boxShadow: {
                md: "2px 4px 4px rgba(28, 124, 255, 0.22)",
                xs: "0px 8px 48px rgba(161, 194, 244, 0.68)",
              },
              paddingBlockStart: { md: "22px", xs: "11px" },
              paddingInlineEnd: { xl: "33px", lg: "30px", xs: "10px" },
              paddingInlineStart: { lg: "30px", xs: "10px" },
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: "600", mb: "11px" }}
            >
              אמצעי תשלום
            </Typography>

            {/* payment form */}
            <PaymentForm
              handleChange={handleChangeBilling}
              billingInfo={billingInfo}
              setBillingInfo={setBillingInfo}
              width={width}
              regStateColor={regStateColor}
              registrationState={data.registrationState}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                mb: "25px",
                flexDirection: { lg: "row", xs: "column-reverse" },
              }}
            >
              <Button
                variant="contained"
                onClick={saveBillingInfo}
                sx={{
                  borderRadius: "30px",
                  maxWidth: { lg: "210px", xs: "100%" },
                  width: "100%",
                  alignItems: "center",
                  "&.MuiButton-root": {
                    fontSize: { sm: "14px", xs: "17px" },
                    padding: { sm: "10px 50px", xs: "10px" },
                    height: "44px",
                  },
                }}
              >
                <ChevronLeftRoundedIcon
                  sx={{
                    position: "relative",
                    top: "1px",
                    right: "10px",
                    display: { sm: "inline", xs: "none" },
                  }}
                />
                שמור שינויים
              </Button>
            </Box>
          </Box>
        </Grid>

        {/* mobile design - registrationState */}
        <Grid
          item
          md={5}
          lg={width > 1360 ? 6 : 5}
          sx={{
            display: { md: "none", xs: "flex" },
            alignItems: "center",
            mb: "130px",
            textAlign: "right",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "700",
              mb: { md: "8px", xs: "22px" },
            }}
          >
            הגדרות מנוי
          </Typography>

          <Box
            sx={{
              width: "-webkit-fill-available",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFF",
              borderRadius: "15px",
              boxShadow: {
                md: "2px 4px 4px rgba(28, 124, 255, 0.22)",
                xs: "0px 8px 48px rgba(161, 194, 244, 0.68)",
              },
              paddingBlockStart: "21px",
              paddingInlineEnd: "24px",
              paddingInlineStart: "27px",
            }}
          >
            <Typography
              sx={{ fontSize: "17px", fontWeight: "600", mb: "22px" }}
            >
              הגדרות פרופיל
            </Typography>

            <Box
              sx={{ display: "flex", mb: "19px", justifyContent: "flex-end" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    color: " #000000",
                  }}
                >
                  {data.registrationState === "active" && "פעיל"}
                  {data.registrationState === "active trial" && "פעיל - ניסיון"}
                  {data.registrationState === "active ending" &&
                    "פעיל - מנוי בוטל"}
                  {data.registrationState === "inactive" && "לא פעיל"}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: " #000000",
                  }}
                >
                  {!data?.isAnnually &&
                    `מסלול חודשי מתחדש ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לחודש עם אפשרות ביטול בכל עת`}
                  {data?.isAnnually && `מסלול שנתי ב- ${AppwideValues.price.year.toLocaleString('he-IL')} ₪ לשנה`}
                  {!data?.isAnnually &&
                    data?.isAnnually &&
                    `סלול חודשי מתחדש ב- ${AppwideValues.price.month.toLocaleString('he-IL')} ₪ לחודש עם אפשרות ביטול בכל עת`}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "14px",
                  width: "14px",
                  backgroundColor: regStateColor(),
                  borderRadius: "50%",
                  ml: "7px",
                  position: "relative",
                  top: "4px",
                }}
              />
            </Box>

            <Button
              variant="text"
              sx={{
                "&.MuiButton-root": {
                  fontSize: "14px",
                  borderRadius: "30px",
                  padding: " 6px 12px",
                },
                mb: "30px",
                width: "fit-content",
                alignSelf: "end",
              }}
            // onClick={cansu}
            >
              ביטול מנוי
            </Button>
          </Box>
        </Grid>
      </Grid>

      <MobileSettingsDesign />
    </Box >
  );
}
