import React from "react";
import { Box, Button } from "@mui/material";

import { PageContainer } from "../../styledComponents/signup";

export default function PageFour({ forward }) {
  const setStep = () => {
    forward(4);
  };

  return (
    <PageContainer
      sx={{
        backgroundColor: "#fff",
        backgroundImage: {
          sm: `url(${require("../../assets/img/confetti-reg4.png")})`,
          xs: `url(${require("../../assets/img/confetti-mobile-reg4.png")})`,
        },
        backgroundSize: { sm: "contain", xs: "cover" },
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        component="img"
        src={require("../../assets/img/title-reg4.png")}
        sx={{
          maxWidth: { sm: "330px", xs: "277px" },
          maxHeight: "156px",
          width: "100%",
          height: "100%",
          marginBlockStart: { md: "140px", sm: "100px", xs: "132px" },
          marginBlockEnd: { sm: "35px", xs: "80px" },
          backgroundColor: "#fff",
        }}
      />

      <Box
        component="img"
        src={require("../../assets/img/Finding-brilliant-ideas-reg4.png")}
        sx={{
          maxWidth: "320px",
          width: "100%",
          display: { sm: "none", xs: "block" },
        }}
      />

      <Button
        variant="contained"
        onClick={setStep}
        sx={{
          maxWidth: { xs: "277px" },
          width: "100%",
          borderRadius: "30px",
          padding: "10px 51px ",
          "&.MuiButton-root": {
            fontSize: { sm: "18px", xs: "17px" },
            fontWeight: "700",
          },
          marginBlockEnd: { sm: "-30px", xs: "65px" },
        }}
      >
        התחבר לחשבון
      </Button>

      <Box
        component="img"
        src={require("../../assets/img/Finding-brilliant-ideas-reg4.png")}
        sx={{
          maxWidth: "500px",
          width: "100%",
          display: { sm: "block", xs: "none" },
        }}
      />
    </PageContainer>
  );
}
