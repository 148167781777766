function store(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
}

function load(key, defaultValue = null) {
    var value = localStorage.getItem(key) || defaultValue;
    return JSON.parse(value);
}

function remove(key) {
    localStorage.removeItem(key)
}
export const storageService = {
    store,
    load,
    remove
}
