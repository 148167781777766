import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import
  {
    TableHead,
    TableBody,
    TableRow,
    TableContainer,
    Table,
    Paper,
    Box,
    Button,
    Typography,
  } from "@mui/material";

import
  {
    Tbutton,
    Document,
    StyledTableCell,
    LockedTableCell,
    StyledTableRow,
  } from "../../styledComponents/homePage/SecFourStyledCmps";
import { useParams } from "react-router-dom";
import { iframeData } from "../../store/actions/aidActions";

// function createData(id, name, publisher, dueDate) {
//   return { id, name, publisher, dueDate };
// }

export default function IFrame({ openModal })
{
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const user = useSelector((state) => state.userModule.user);

  useEffect(() =>
  {
    fetchIframeData();
  }, []);

  useEffect(() =>
  {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const fetchIframeData = async () =>
  {
    const data = await dispatch(iframeData());
    setRows([...data]);
  };

  const updateDimensions = () =>
  {
    setWidth(window.innerWidth);
  };

  const handleClick = () =>
  {
    const targetWindow = window.opener;
    if (targetWindow)
    { targetWindow.postMessage("offer clicked"); }
    if (openModal) { openModal(); }
  };

  if (!rows.length || !rows) return;

  return (
    <Box>
      <Box
      sx={{
        alignItems: "center",
      display: "flex",
      flexDirection: "column",
      }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            fontSize: {
              xl: "38px",
              lg: "34px",
              md: "28px",
              sm: "22px",
              xs: "28px",
            },
            mb: 5,
          }}
        >
          מכרזים שפורסמו לאחרונה
        </Typography>
        <TableContainer
          component={Paper}
          sx={{
            display: { sm: "block", xs: "none" },
            borderRadius: "20px",
            mb: { md: 18, xs: 17 },
            boxShadow: "0px 8px 48px rgba(161, 194, 244, 0.23)",
            marginInline: "auto",
            width: "100%",
            maxWidth: {
              xs: "354px",
              sm: "590px",
              md: "850px",
              lg: "1050px",
              xl: "1400px",
            },
          }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    "&.MuiTableCell-root": { paddingRight: "0px" },
                    width: "200px",
                  }}
                ></StyledTableCell>
                <StyledTableCell align="right" sx={{ width: "150px" }}>
                  גורם מפרסם
                </StyledTableCell>
                <StyledTableCell
                  align="right"
                  sx={{ paddingRight: { xs: "10px", sm: "5px" } }}
                >
                  שם המכרז
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={row.id ? row.id : index}>
                  <StyledTableCell align="center">
                    <Tbutton
                      variant="contained"
                      onClick={handleClick}
                    ></Tbutton>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Box>
                      <span>למנויים בלבד</span>
                      <img src={require("../../assets/icons/lock.png")} />
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="right"
                    sx={{
                      fontWeight: "600",
                      maxWidth: {
                        lg: "615px",
                        md: "470px",
                        sm: "300px",
                        xs: "60px",
                      },
                      "&.MuiTableCell-root": {
                        padding: {
                          lg: "16px 16px 16px 48px",
                          md: "16px 16px 16px 40px",
                        },
                      },
                    }}
                  >
                    {row.name}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* mobil version */}
        <Box
          sx={{
            display: { sm: "none", xs: "block" },
            width: "100%",
            maxWidth: "354px",
            marginInline: "auto",
            borderRadius: "30px",
            paddingBlockStart: 2.3,
            boxShadow: "0px 8px 48px rgba(161, 194, 244, 0.68)",
            mb: 8.8,
          }}
        >
          {rows.map((row, index) => (
            <Box
              key={row.id ? row.id : index}
              sx={{
                width: "100%",
                maxWidth: "315px",
                marginInline: "auto",
                mb: 3,
                "&:not(:last-of-type)": { borderBlockEnd: "1px solid #00357D" },
              }}
            >
              <Box
                sx={{
                  borderBottom: "1px solid #E0E0E0",
                  paddingBlockEnd: "13px",
                  mb: 2,
                }}
              >
                <Typography
                  color="primary"
                  sx={{ fontSize: "18px", fontWeight: "700", textAlign: "end" }}
                >
                  שם המכרז
                </Typography>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "400", textAlign: "end" }}
                >
                  {row.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 3.4,
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    color="primary"
                    sx={{
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: "end",
                      marginBlockEnd: 1,
                    }}
                  >
                    גורם מפרסם
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {row.publisher && user && (
                      <Typography sx={{ color: "#91909F", mr: 1 }}>
                        {row.publisher}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        img: {
                          display: "inline",
                          height: "22px",
                          width: "16px",
                          position: "relative",
                        },
                      }}
                    >
                      <Typography
                        sx={{ display: "inherit", marginInlineEnd: "8px" }}
                      >
                        למנויים בלבד
                      </Typography>
                      <img src={require("../../assets/icons/lock.png")} />
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  width: "100%",
                  borderRadius: "30px",
                  mb: 2.9,
                  "&.MuiButton-root": {
                    fontWeight: "700",
                    fontSize: "17px",
                    paddingBlock: "12px",
                  },
                }}
              >
                למסמכי המכרז
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
