import { Box, IconButton, Modal, Popover, Popper, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { offerService } from "../../../services/offerService.service";
import { makePushToList } from "../../../util/actions";
import { editOfferManager } from "../user/AccompanyDashboard";
import EditTask, { editTaskManager } from "../user/EditTask";
import { SendEventToEmail, sendEventToEmailManager } from "../user/SendEventToEmail";
import TaskCard from "../user/TaskCard";
import { DAYS, MONTHS } from "./conts";
import "./fullCalendar.css";
import Pagination from "./Pagination";


const noFlex = { flex: "0 0" };
const noPadding = { padding: 0 };

const typeEngToHeb = {
  task: 'משימות',
  offer: 'הצעות',
}
const range = (end) =>
{
  const { result } = Array.from({ length: end }).reduce(
    ({ result, current }) => ({
      result: [...result, current],
      current: current + 1,
    }),
    { result: [], current: 1 }
  );

  return result;
};

const getDaysInMonth = (month, year) =>
{
  return new Date(year, month + 1, 0).getDate();
};

const getSortedDays = (month, year) =>
{
  const dayIndex = new Date(year, month, 1).getDay();
  return [...DAYS.slice(dayIndex), ...DAYS.slice(0, dayIndex)];
};

const getDateObj = (day, month, year) =>
{
  return new Date(year, month, day);
};

const areDatesTheSame = (first, second) =>
{
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
};

function calculateTheDayTheMonthStartsFrom(currentYear, currentMonth)
{
  return new Date(currentYear, currentMonth, 1).getDay();
}
/**
 * 
 * @param {Object} param0 
 * @param {Date}param0.startingDate
 * @param {Array<event>}param0.eventsArr
 * @param {Object<Date, event>}param0.dayDict
 * @returns 
 */
const FullCalendar = ({ startingDate, eventsArr, dayDict }) =>
{
  console.log("eventsArr", eventsArr);
  const [monthly, setMonthly] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(startingDate.getMonth());
  const [currentYear, setCurrentYear] = useState(startingDate.getFullYear());

  const DaysInAMonth = getDaysInMonth(currentMonth, currentYear);
  const DaysFromPrevMonth = calculateTheDayTheMonthStartsFrom(
    currentYear,
    currentMonth
  );
  const DaysFromNextMonth = 7 * 5 - DaysInAMonth - DaysFromPrevMonth;

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(true);

  const [popoverInfo, setPopoverInfo] = useState({});
  function popoverHandleClose(evt)
  {
    setPopoverOpen(false);
  }
  function popoverHandleOpen(evt, eventsByType, date)
  {
    setPopoverAnchorEl(evt.target);
    setPopoverInfo({ eventsByType, date });
    setPopoverOpen(true);
    evt.stopPropagation()
  }

  const nextMonth = () =>
  {
    if (currentMonth < 11)
    {
      setCurrentMonth((prev) => prev + 1);
    } else
    {
      setCurrentMonth(0);
      setCurrentYear((prev) => prev + 1);
    }
  };

  const prevMonth = () =>
  {
    if (currentMonth > 0)
    {
      setCurrentMonth((prev) => prev - 1);
    } else
    {
      setCurrentMonth(11);
      setCurrentYear((prev) => prev - 1);
    }
  };

  const nextYear = () =>
  {
    // if (currentMonth < 11)
    // {
    //   setCurrentMonth((prev) => prev + 1);
    // } else
    // {
    //   setCurrentMonth(0);
    //   setCurrentYear((prev) => prev + 1);
    // }
    setCurrentYear((prev) => prev + 1);
  };

  const prevYear = () =>
  {
    // if (currentMonth > 0)
    // {
    //   setCurrentMonth((prev) => prev - 1);
    // } else
    // {
    //   setCurrentMonth(11);
    //   setCurrentYear((prev) => prev - 1);
    // }
    setCurrentYear((prev) => prev - 1);
  };

  return (
    <>
      <EditTask
        onClose={() => { }}
      />
      <SendEventToEmail />
      <section>
        <div className="fullCalendar-head">
          <Pagination
            monthly={monthly}
            setMonthly={setMonthly}
            month={MONTHS[currentMonth]}
            year={currentYear}
            prevMonth={prevMonth}
            nextMonth={nextMonth}
            prevYear={prevYear}
            nextYear={nextYear}
          />
        </div>

        {!monthly ? (
          <ul className="fullYear-wrapper">
            {MONTHS.map((currentMonthName, currentMonth) => (
              <li>
                <div className="fullYearBox">
                  <div className="fullYearMonth">{currentMonthName}</div>
                  <div className="fullYearSevenColGrid">
                    {getSortedDays(currentMonthName, currentYear).map((day) => (
                      <div>{day}</div>
                    ))}
                  </div>
                  <div
                    className="fullYearDaysBody"
                    style={{
                      gridTemplateRows: `repeat(${DaysInAMonth === 28 ? "4" : "5"
                        }, 1fr)`,
                    }}
                  >
                    {range(
                      calculateTheDayTheMonthStartsFrom(currentYear, currentMonth)
                    ).map((day) => (
                      <span></span>
                    ))}
                    {range(getDaysInMonth(currentMonth, currentYear)).map((day) =>
                      DayBoxMonth(day, currentMonth)
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="fullCalendar-wrapper"
            onClick={popoverHandleClose}>
            <Popper
              className="display-sx_"
              open={popoverOpen}
              anchorEl={popoverAnchorEl}
              onClose={popoverHandleClose}
              placement="auto"

            >
                <div className="popover">
              {popoverContent(popoverOpen, popoverInfo)}
              </div>
            </Popper>
            <Modal
              className="display-sx"
              open={popoverOpen}
              onClose={popoverHandleClose}>
              <>
                <IconButton
                  aria-label="Close"
                    onClick={popoverHandleClose}
                  sx={{
                    width: "min-content",
                    marginBlock: { xl: "15px", md: "10px" },
                    display: "flex",
                    marginInline: "auto",
                  }}
                >
                  <Box
                    component="img"
                    src={require("../../../assets/icons/close-circle.png")}
                    sx={{
                      height: { md: "30px", xs: "30px" },
                      width: { md: "30px", xs: "30px" },
                      marginBlock: { md: 0, xs: 1 },
                    }}
                  />
                </IconButton>
                <Box
                  sx={{
                    // maxWidth: { lg: "970px", md: "840px", sm: "588px", xs: "354px" },
                    height: "fit-content",
                    maxHeight: "85%",
                    width: "85",//"fit-content",
                    borderRadius: "30px",
                    marginInline: 'auto',
                    mb: 5,
                    backgroundColor: 'white',
                    textAlign: 'center',
                    padding: '30px',
                  }}

                >
                  {popoverContent(popoverOpen, popoverInfo)}
                </Box>
                </>
              </Modal>
              <div className="sevenColGrid">
              </div>
              <div
                className="daysBody"
                style={{
                  gridTemplateRows: `repeat(${DaysInAMonth === 28 && DaysFromPrevMonth == 0 ? "5" : "6"
                }, 1fr)`,
              }}
              >
                {getSortedDays().map((day) => (
                  <div className="headDay display-sx_">{day}</div>
                ))}
                {range(DaysFromPrevMonth)
                  .reverse()
                  .map((day) => DayBox(-day + 1, "grey"))}
                {range(DaysInAMonth).map((day) => DayBox(day))}
                {range(DaysFromNextMonth).map((day) =>
                  DayBox(day + DaysInAMonth, "grey")
                )}
              </div>
          </div>
        )}
      </section>
    </>
  );

  function DayBoxMonth(day, currentMonth)
  {
    return (
      <>
        {eventsArr.map(
          (ev) =>
            areDatesTheSame(
              getDateObj(day, currentMonth, currentYear),
              ev.date
            ) && (
              <span
                className={`fullYearDay `}
                style={{
                  backgroundColor: ev.type === "offer" ? "#1C7CFF" : "#FFE500",
                  color: "white",
                }}
              >
                <span>{day}</span>
              </span>
            )
        )}
        <span className={`fullYearDay  `}>
          <span>{day}</span>
        </span>
      </>
    );
  }
  /** 
   * @param {Number} day
   * @param {"grey"|undefined} color
   */
  function DayBox(day, color)
  {
    const date = getDateObj(day, currentMonth, currentYear);
    /**
     * {Object.<string,Event[]>}
     */
    const eventsByType = {}
    for (const evt of dayDict[date] ?? [])
    {
      makePushToList(eventsByType, evt.type, evt);
    }
    return (
      <div class="flexbox-row margin-Vertical5vw-sx">
        <span className="display-sx day-number-external">{date.getDate()}</span>
        <span
          className={`dayBox  ${areDatesTheSame(
            new Date(),
            date
          ) && "isToday"
            } ${color == "grey" && "isPrevMonth"}`}
        >

          <div className="dayHeader" >
            <span className="display-sx_">{date.getDate()}</span>
            <span className="display-sx">{date.toLocaleString("he-il", { weekday: "narrow" })}</span>
            {/* {eventsArr.map(
            (ev) =>
              areDatesTheSame(
                getDateObj(day, currentMonth, currentYear),
                ev.date
              ) && (
                <button type="button">
                  <img src="/assets/link.svg" alt="..." />
                </button>
              )
          )} */}
          </div>
          <div style={{ display: "flex" }}>
            <div className="dayPinContainer" style={{ flexDirection: "column", marginInline: "10px" }}>

              {Object.keys(eventsByType).map(
                (type) =>
                (
                  <span
                    className="dayPin"
                    style={{
                      backgroundColor:
                        type === "offer" ? "#1C7CFF" : "#FFE500",
                    }}
                  />
                )
              )}
            </div>
            {Object.keys(eventsByType).length == 0 && <div className={`dayTitle hidden`}></div> ||
              Object.keys(eventsByType).length != 0 &&
              <div className={`dayTitle cursor`} onClick={(evt) => popoverHandleOpen(evt, eventsByType, date)}>
                {
                  Object.keys(eventsByType).length == 1 &&
                  Object.values(eventsByType)[0].length == 1 &&
                  <span>{Object.values(eventsByType)[0][0].title}</span>
                  ||
                  <span><br />{Object.entries(eventsByType).map(([type, events]) => (
                    `${events.length} ${typeEngToHeb[type]}`)).join(', ')} <br /><br /></span>
                }
              </div>
            }
          </div>
        </span>
      </div>
    );
  }
};

function popoverContent(popoverOpen, popoverInfo)
{
  return (
    <>
    {popoverOpen && Object.entries(popoverInfo.eventsByType).map(([type, events]) => <>
      <div className="popover-event-type">
        {typeEngToHeb[type]}
      </div>
      {Object.values(events).map((event) => (
        // type == "task" ?
        //   <TaskCard
        //     data={event}
        //   />
        //   :
        <div className="popover-event-box flex-space-between">
          {/* <div className="flex-space-between text-overflow-elipsis" style={{ maxWidth: "600px", gap: "50px" }}>
                  </div> */}
          <Typography className="text-overflow-elipsis" sx={{ flex: "1 0" }}>
            {event.title}
          </Typography>

          <span
            className="dayPin"
            style={{
              backgroundColor: type === "offer" ? "#1C7CFF" : event.color,
              flex: noFlex
            }} />
          <EventButtons event={event} />
        </div>
      ))}
    </>)}
  </>);
}

function EventButtons({ event })
{
  const iconSize = { xs: "16px", md: "18px" };
  const nav = useNavigate();
  function handleDelete(taskId, offerId)
  {
    offerService.UserOffer.delTask(taskId, offerId, () => { });
  }


  function editTaskFromEvent()
  {
    editTaskManager.editTask(offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(String(event.taskId)), event?.offerId, () => { });
  }
  function gotoFromEvent()
  {
    //editTaskManager.editTask(offerService.UserOffer.allTasksOffers.value.taskIdToTask.get(String(event.taskId)), event?.offerId, () => { });
    editOfferManager.editOffer(event.offerId, event.type === "task" ? event.taskId : null, () => { });
    nav("/dashboard/accompany");
  }
  return (<>
    {event.type === "task" &&
      <>
        <IconButton
          onClick={() =>
          {
            // setSelected(data);
            // setOpen(true);
            editTaskFromEvent();
          }}
          sx={{ ...noFlex, ...noPadding }}
        >
          <Box
            component="img"
            src={require("../../../assets/icons/edit.png")}
            sx={{ height: iconSize, width: iconSize }}
          />
        </IconButton>
        <IconButton
          onClick={() => handleDelete(event?.offerId, event?.taskId)}

          sx={{ flex: noFlex }}>
          <Box
            component="img"
            src={require("../../../assets/icons/bin.png")}
            sx={{ height: iconSize, width: iconSize }}
          />
        </IconButton>
      </>
    }

    <IconButton

      sx={{ flex: noFlex }}>
      <Box
        component="img"
        src={require("../../../assets/icons/drawer-dashboard-calender.png")}
        sx={{ height: iconSize, width: iconSize }}
        onClick={() =>
        {
          sendEventToEmailManager.open({ offerId: event.offerId, taskId: event.taskId ?? null })
        }}
      />
    </IconButton>
    <IconButton

      sx={{ flex: noFlex }}>
      <Box
        component="img"
        src={require("../../../assets/icons/arrow-left-chosen.png")}
        sx={{ height: iconSize, width: iconSize }}
        onClick={() =>
        {
          gotoFromEvent()
        }}
      />
    </IconButton>
  </>
  )
}

export default FullCalendar;
