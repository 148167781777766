import { Box } from "@mui/material";
import axios from "axios";
import React, { useEffect } from "react";
import Lottie from "react-lottie";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Loader from "../assets/animations/loader.json";
import { magicLinkLogin } from "../store/actions/userActions";
import { AppwideValues } from "../env";

const URL = AppwideValues.serviceURL;

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: Loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default function EmailVerification() {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    doFirstLogin();
  }, []);

  const doFirstLogin = async () => {
    try {
      const tokenToSend = location.search;

      const res = await axios.get(
        `${URL}auth/magiclogin${tokenToSend}`
      );

      const userToken = res.data.token;

      await dispatch(magicLinkLogin(userToken));

      nav("/dashboard/user");
    } catch (err) {
      console.log("could not do first login", err);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        component="img"
        src={require("../assets/img/logo-loader.png")}
        sx={{ maxWidth: "fit-content" }}
      />
      <Lottie
        height={400}
        width={400}
        options={defaultOptions}
        style={{ maxWidth: "600px" }}
      />
    </Box>
  );
}
