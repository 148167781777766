import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import './style.css';
import theme from './themes';

import HomePage from './pages/HomePage';
import IFrame from './components/home page main/IFrame';
import NewIFrame from './components/NewIFrame';
import Dashboard from './pages/Dashboard';
import UserDashboard from './components/dashboard/user/UserDashboard';
import CalenderDashboard from './components/dashboard/calendar/CalendarDashboard';
import SettingsDashboard from './components/dashboard/settings/SettingsDashboard';
import LoginPage from './pages/Login';
import About from './pages/About';
import Policy from './pages/Policy';
import Accessability from './pages/Accessability';
import Prices from './pages/Prices';
import Signup from './pages/Signup';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';
import { NotFoundComponent } from './pages/NotFound';
import EmailVerification from './pages/EmailVerification';
import { userService } from './services/userService.service';
import PostTender from './pages/PostTender';
import AccompanyDashboard from './components/dashboard/user/AccompanyDashboard';
import { MessagePopupModal, messagePopupManager } from './components/MessagePopup';

function App()
{
  useEffect(() => userService.RegistrationController.load(), []);
  let modalStart = false;
  if (!window.location.pathname.startsWith('/IFrame'))
  {
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.type = 'text/javascript';
    // script.onload = function() {
    //     callFunctionFromScript();
    // }

    script.src = 'https://code.tidio.co/5t8itykfy0ju0sliywvnvgwpqoboa0lr.js';
    script.async = true;
    head.appendChild(script);
    const params = new URLSearchParams(window.location.search);
    if (params.has('affiliate'))
    {
      const newAfill = params.get('affiliate');
      const oldAffilStr = localStorage.getItem('affiliates');
      try
      {
        const oldAffil = JSON.parse(oldAffilStr);
        if (!oldAffil.includes(newAfill))
        {
          oldAffil.push();
          localStorage.setItem('affiliates', JSON.stringify(oldAffil));
        }
      } catch (error)
      {
        localStorage.setItem('affiliates', JSON.stringify([newAfill]));
      }
    }

    if (params.has('reg3'))
    {
      const email = params.get('reg3');
      userService.RegistrationController.setRegState(3);
      userService.RegistrationController.setEmail(email);
      modalStart = true;
    }
  }
  const [isOpen, setIsOpen] = useState(modalStart);
  const [isNoBack, setIsNoBack] = useState(modalStart);

  const openModal = () =>
  {
    setIsOpen(true);
  };
  const closeModal = () =>
  {
    setIsOpen(false);
  };

  useEffect(() =>
  {
    (async() =>
    {
    try
    {
      const sStopDate = sessionStorage.getItem('stop message');
      if (sStopDate === "true" || sStopDate > Date.now())
      {
        return;
      }
      const res = await messagePopupManager.pushMessage({
        content: (({promiseControl}) =>
        {
          return (
            <Box
            sx={{
              padding: '10px',
            }}
            >
              <Typography
                sx={{
                  direction: 'rtl',
                  textAlign: 'right',
                  fontSize: '1.25rem',
                }}
              >
              <h4>שלום.</h4>
              כשהשירות של קשר עסקים הוקם, הפיתוח שלו בוסס על טכניקות בדוקות ובשימוש נרחב כדי לאסוף מידע כמה שיותר מדויק לכם.<br />
              אבל העולם השתנה בינתיים. טכניקות חדשות בעולם הבינה המלאכותית צצו, שיכולות להתמודד עם הטקסטים השונים יותר טוב, לחלץ מידע יותר טוב ואף לעבד אותו לכדי מידע יותר מדויק מאי פעם.<br />
              עקב ההבדל המשמעותי בין התוצאות האפשריות, עצרנו את כל הפעילות באתר, והפננו את כל המשאבים כדי להביא ערך עוד יותר טוב לכם, שכולל:<br />
              <br />
              קטגוריות שמוגדרות יותר טוב.<br />
              הוצאת מידע יותר איכותי ורלוונטי מהמכרזים כדי שניתן יהיה להבין יותר בקלות את פרטי המכרז בתצוגה המקדימה.<br />
              הבנה יותר עמוקה של תיאור מהות העסק שלכם, ומציאת התאמות רלוונטיות אוטומטית.<br />
              סוכן לומד לתקשורת אתכם להבנת הצרכים שלכם בשביל לשפר את תיאור מהות העסק, ולנסות לעבוד אתכם להבין צרכים שקשה לבטא במילים.<br />
              <br />
                בינתיים, תוכלו ליצור אתנו קשר ב <a href="mailto:info@kesher-business.co.il"> info@kesher-business.co.il </a> <br />&nbsp;
              </Typography>
              <Box sx={{display: 'flex', justifyContent: 'center', gap: '30px'}}>
              <Button
                variant="contained"
                onClick={() =>
                {
                  promiseControl.resolve("close");
                }}
                sx={{
                  "&.MuiButton-root": { borderRadius: "30px" },
                  mb: 1.5,
                  padding: '10px',
                }}
              >
                 סגור עד הסשן הבא
              </Button>

                <Button
                  variant="contained"
                  onClick={() =>
                  {
                    promiseControl.resolve("15");
                  }}
                  sx={{
                    "&.MuiButton-root": { borderRadius: "30px" },
                    mb: 1.5,
                    padding: '10px',
                  }}
                >
                    סגור ל15 דקות
                </Button>
              </Box>
              </Box>  
          );
        }),
        type: 'info',
        title: 'היי!',
        color: 'primary',
      });
      if (res === '15')
      {
        sessionStorage.setItem('stop message', Date.now() + 15 * 60 * 1000);
      }
      else if (res === 'close')
      {
        sessionStorage.setItem('stop message', true);
      }
    }
    catch (err)
    {
      console.log(err);
    }
  })();
  }, []);

  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <Signup isOpen={isOpen} closeModal={closeModal} isNoBack={isNoBack} />
        <Routes>
          <Route path='verify' element={<EmailVerification />} />
          <Route path='home' element={<HomePage openModal={openModal} />} />
          <Route path='post' element={<PostTender openModal={openModal} />} />
          <Route path='dashboard' element={<Dashboard />}>
            <Route path='user' element={<UserDashboard />} />
            <Route path='settings' element={<SettingsDashboard />} />
            <Route path='calendar' element={<CalenderDashboard />} />
            <Route path='accompany' element={<AccompanyDashboard />} />
            <Route
              path='calendar'
              element={<NotFoundComponent openModal={openModal} />}
            />
            <Route
              path='accompany'
              element={<NotFoundComponent openModal={openModal} />}
            />
          </Route>
          <Route path='IFrame/:sid' element={<NewIFrame />} />
          <Route path='login' element={<LoginPage openModal={openModal} />} />
          <Route path='about' element={<About openModal={openModal} />} />

          <Route path='policy' element={<Policy openModal={openModal} />} />
          <Route
            path='accessability'
            element={<Accessability openModal={openModal} />}
          />
          <Route
            path='prices/:isAnnually'
            element={<Prices openModal={openModal} />}
          />
          <Route path='prices' element={<Prices openModal={openModal} />} />
          <Route path='contact' element={<Navigate to='404' replace />} />
          <Route path='blog' element={<Navigate to='404' replace />} />
          {/* <Route path="contact" element={<Contact openModal={openModal} />} /> */}
          <Route path='404' element={<NotFound openModal={openModal} />} />
          <Route path='' element={<Navigate to='home' replace />} />
          <Route path='*' element={<Navigate to='404' replace />} />
        </Routes>
        <MessagePopupModal />
      </ThemeProvider>
    </div>
  );
}

export default App;
