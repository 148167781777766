import React from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { FooterListBtn } from "../styledComponents/footer";
import { useNavigate } from "react-router-dom";
import { AppwideValues } from "../env";

const list1 = [
  { text: "תקנון ותנאי שימוש", to: "/policy" },
  { text: "הצהרת נגישות", to: "/accessability" },
  {
    text: "בקרו בבלוג שלנו",
    to: "https://www.sbcil.co.il/%d7%91%d7%9c%d7%95%d7%92/",
  },
];

const list2 = [
  { text: "דף הבית", to: "/home" },
  { text: "שירותים ומחירים", to: "/prices" },
  { text: "?מי אנחנו", to: "/about" },
  //{ text: "צור קשר", to: "/404" },
];

export default function Footer({ openModal })
{
  const nav = useNavigate();
  return (
    <Box
      sx={{
        backgroundColor: "#043F90",
        paddingBlock: { sm: "100px", xs: "55px 58px" },
        direction: "ltr",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          alignItems: { sm: "inherit", xs: "center" },
          justifyContent: "space-between",
          flexDirection: { sm: "row", xs: "column-reverse" },
          width: "100%",
          maxWidth: { xl: "1400px", lg: "1000px", md: "850px", sm: "600px" },
          height: "100%",
        }}
      >
        {/* social icons */}
        <Box
          sx={{
            display: { sm: "none", xs: "flex" },
            justifyContent: "space-around",
            width: "100%",
            maxWidth: "177px",
          }}
        >
          <a href="https://www.facebook.com/kesherBids">
            <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }} aria-label="Facebook">
              <Box
                component="img"
                src={require("../assets/icons/facebook-footer.png")}
                sx={{ height: "43px", width: "43px" }}
              />
            </IconButton>
          </a>
          {/* <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }} aria-label="Instagram">
            <Box
              component="img"
              src={require("../assets/icons/instagram-footer.png")}
              sx={{ height: "43px", width: "43px" }}
            />
          </IconButton>
          <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }} aria-label="LinkedIn">
            <Box
              component="img"
              src={require("../assets/icons/linkedin-footer.png")}
              sx={{ height: "43px", width: "43px" }}
            /> 
          </IconButton>*/}
        </Box>

        {/* logo */}
        <Box
          component="img"
          src={require("../assets/img/logo-footer.png")}
          sx={{
            marginBlock: { sm: "43px", xs: 0 },
            width: { lg: "208px", md: "180px", sm: "140px", xs: "157px" },
            mb: { sm: "43px", xs: 5.3 },
          }}
        />

        {/* התחל בחינם ו- פרסם מכרז */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            maxWidth: { lg: "177px", md: "160px", sm: "130px", xs: "315px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              mb: { sm: 0, xs: 5.3 },
            }}
          >
            <Button
              variant="contained"
              onClick={openModal}
              sx={{
                "&.MuiButton-root": {
                  borderRadius: "30px",
                  backgroundColor: "#FFCC32",
                  color: "#000",
                  fontWeight: "600",
                  mb: 2.3,
                  fontSize: { lg: "24px", md: "22px", xs: "18px" },
                  paddingBlock: { sm: "6px", xs: "8px" },
                },
              }}
            >
              התחל בחינם
            </Button>
            {/* <Button
              variant="outlined"
              sx={{
                "&.MuiButton-root": {
                  borderRadius: "30px",
                  fontWeight: "600",
                  borderColor: "#fff",
                  color: "#fff",
                  fontSize: { lg: "24px", md: "22px", xs: "18px" },
                  paddingBlock: { sm: "6px", xs: "8px" },
                },
              }}
            >
              פרסם מכרז
            </Button> */}
          </Box>
          <Box
            sx={{
              display: { sm: "flex", xs: "none" },
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <a href="https://www.facebook.com/kesherBids">
              <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }}>
                <Box
                  component="img"
                  src={require("../assets/icons/facebook-footer.png")}
                  sx={{
                    height: { md: "43px", sm: "38px" },
                    width: { md: "43px", sm: "38px" },
                  }}
                />
              </IconButton>
            </a>
            {/* <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }}>
              <Box
                component="img"
                src={require("../assets/icons/instagram-footer.png")}
                sx={{
                  height: { md: "43px", sm: "38px" },
                  width: { md: "43px", sm: "38px" },
                }}
              />
            </IconButton>
            <IconButton sx={{ "&.MuiButton-root": { borderRadius: "50%" } }}>
              <Box
                component="img"
                src={require("../assets/icons/linkedin-footer.png")}
                sx={{
                  height: { md: "43px", sm: "38px" },
                  width: { md: "43px", sm: "38px" },
                }}
              />
            </IconButton> */}
          </Box>
        </Box>

        {/* regular vertion */}
        <Box
          sx={{
            display: { sm: "flex", xs: "none" },
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          {list1.map(({ text, to }, index) => (
            <FooterListBtn
              key={text}
              text={text}
              onClick={() =>
              {
                if (text === "בקרו בבלוג שלנו")
                {
                  window.open(to, "_blank");
                }
                else
                {
                  nav(to);
                }
              }}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: { sm: "flex", xs: "none" },
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          {list2.map(({ text, to }) => (
            <FooterListBtn
              key={text}
              text={text}
              onClick={() =>
              {
                nav(to);
              }}
            />
          ))}
        </Box>

        {/* mobile vertion */}
        <Box
          sx={{
            display: { sm: "none", xs: "flex" },
            justifyContent: "space-between",
            maxWidth: "300px",
            width: "100%",
            mb: 5.3,
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
          >
            {list1.map(({ text, to }, index) => (
              <FooterListBtn
                key={text}
                text={text}
                onClick={() =>
                {
                  if (index === 0)
                  {
                    nav(to);
                  } else
                  {
                    window.open(to, "_blank");
                  }
                }}
              />
            ))}
          </Box>
          <Box
            sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
          >
            {list2.map(({ text, to }) => (
              <FooterListBtn
                key={text}
                text={text}
                onClick={() =>
                {
                  nav(to);
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <br />
      {/* <Box
        sx={{
          direction: 'rtl',
          margin: "auto",
          display: "flex",
          alignItems: { sm: "inherit", xs: "center" },
          justifyContent: "space-between",
          flexDirection: { sm: "row", xs: "column-reverse" },
          width: "100%",
          maxWidth: { xl: "1400px", lg: "1000px", md: "850px", sm: "600px" },
          height: "100%",
          background: 'white',
          color: 'black',
        }}> */}
      {/* <FooterListBtn
          key={'inshakedown'}
          text={'האתר בהרצה - במידה ונתקלתם בבעיה מוזמנים לספר לנו בצ\'ט.'}
          sx={{
            color: 'black',
          }}
          style={{color: "black"}}
          // onClick={() =>
          // {
          //   window.location.href='mailto:info@kesher-business.co.il?subject=נתקלנו בבעיה באתר';
          // }}
        /> */}
      <Typography sx={{ fontSize: "15px", color: "#fff", mx: '30px' }}>
        גירסה: {AppwideValues.appVersion}
      </Typography>

    </Box>
  );
}
