import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import { Button, IconButton, TableRow } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const StyledTableCell = styled(TableCell)(({ theme }) =>
  sx({
    [`&.${tableCellClasses.head}`]: {
      color: '#fff',
      paddingBlock: '0px',
      '& .MuiTableCell-root': {
        border: 'none',
        height: '39px',
        fontSize: { lg: '18px', md: '18px' },
      },
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: { xl: '18px', lg: '18px', md: '14px', sm: '13px' },
      paddingBlock: '0px',

      '&.MuiTableCell-root': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        // whiteSpace: "nowrap",
        unicodeBidi: 'plaintext',
        border: 'none',
      },
    },
  })
);
