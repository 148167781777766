import { userService } from "../../services/userService.service"
import jwt_decode from "jwt-decode";

export function regNextStep(userDetails) {
    return async (dispatch) => {


        try {
            // const { data, pageNum } = userDetails

            // let res = null
            // if (pageNum === 3) {
            //     const { cardNum, year, month, cvv, cardID, email, annually, acceptAgreement, acceptMailDelivery } = data
            //     const token = await userService.tokenizeCreditCard(cardNum, month, year, cvv, cardID)
            //     res = await userService.sendRegData({ email, token, isAnnually: annually, acceptAgreement, acceptMailDelivery }, pageNum)
            //     console.log("token", token);
            //     console.log("res-action-step3", res);
            // } else {
            //     res = await userService.sendRegData({ ...data }, pageNum)

            //     console.log("res-action-steps", res);
            // }


            // dispatch({ type: 'NEXT_STEP', payload: { ...res } })
            dispatch({ type: 'NEXT_STEP', payload: userDetails })

        } catch (err) {
            throw err
        }
    }
}

export function login(userInfo) {
    return async (dispatch) => {
        try {
            const accessToken = await userService.handleLogin(userInfo)
            // console.log(jwt_decode(accessToken));
            userService.setAuthToken(accessToken)
            const info = await userService.getPublicUserInfo(accessToken)
            dispatch(userService.setCurrentUser(info))
            return info
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function magicLinkLogin(accessToken) {
    return async (dispatch) => {
        try {
            userService.setToken(accessToken)
            userService.setAuthToken(accessToken)
            const info = await userService.getPublicUserInfo(accessToken)
            dispatch(userService.setCurrentUser(info))
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function uploadImage(imageData) {
    return async (dispatch) => {
        try {
            const updatedUser = await userService.updateImage(imageData)

            dispatch({ type: 'UPDATE_USER', payload: updatedUser })
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function logout() {
    return async (dispatch) => {
        try {
            userService.logout()
            dispatch({ type: "LOGOUT", payload: null })
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function forgot(email) {
    return async (dispatch) => {
        try {
            return await userService.forgotPassword(email)
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function getLoggedinUser() {
    return async (dispatch) => {
        //this will call a function to fetch the loggedin "user data" from the database 


        const user = userService.getLoggedinUser()
        // const user = await userService.getLoggedinUser()
        dispatch({ type: 'SET_LOGGEDIN_USER', user })
    }
}

export function updateUser(newUserDetails) {
    return async (dispatch) => {
        try {
            const updatedUser = await userService.update({ ...newUserDetails })
            dispatch({ type: 'UPDATE_USER', payload: updatedUser })
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}

export function updatePassword(newUserDetails) {
    return async (dispatch) => {
        try {
            await userService.updatePassword({ ...newUserDetails })
        } catch (err) {
            console.log("action: ", err);
            throw err
        }
    }
}

export function updateBillingDetails(newBillingDetails) {
    return async (dispatch) => {
        try {
            await userService.updateBilling({ ...newBillingDetails })
            // dispatch(userService.setCurrentUser(decoded))
            // return decoded
        } catch (err) {
            console.log(err);
            throw err
        }
    }
}
