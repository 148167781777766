import * as React from "react";
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { useEffect } from "react";
import Lottie from "react-lottie";
import Loader from "../../../assets/animations/loader.json";

export default function Pagination({ page, aidsForDisplay, handlePagination }) {
  const [maxPage, setMaxPage] = useState(5);
  const { startIdx, endIdx, items, totalLength } = aidsForDisplay;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    let max = totalLength;
    if (max > 50) max = parseInt(totalLength / 50);
    else max = 0;

    setMaxPage(max);
  }, [totalLength]);

  const handleChangePage = (event, newPage) => {
    if (newPage < 0 || newPage > maxPage) return;
    handlePagination(newPage);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: { sm: "fit-content", xs: "100%" },
        justifyContent: { sm: "auto", xs: "flex-end" },
        mt: { sm: "0px", xs: "13px" },
        mr: { sm: "0", xs: "20px" },
      }}
    >
      <Box
        sx={{ mr: { xl: 2, lg: 0.2 }, display: { sm: "block", xs: "none" } }}
      >
        <IconButton
          aria-label="left"
          onClick={(ev) => handleChangePage(ev, page + 1)}
          sx={{ mr: { xl: 2.5, lg: 1.5 } }}
        >
          <Box
            component="img"
            src={require(`../../../assets/icons/arrow-left-${
              page === maxPage ? "disabled" : "chosen"
            }.png`)}
            sx={{ height: "20px" }}
          />
        </IconButton>
        <IconButton
          aria-label="right"
          onClick={(ev) => handleChangePage(ev, page - 1)}
        >
          <Box
            component="img"
            src={require(`../../../assets/icons/arrow-right-${
              !page ? "disabled" : "chosen"
            }.png`)}
            sx={{ height: "20px" }}
          />
        </IconButton>
      </Box>
      <Typography
        sx={{
          unicodeBidi: "plaintext",
          fontWeight: "700",
          color: "#000",
          fontSize: { xl: "20px", lg: "18px", md: "16px" },
          display: !items?.length ? "none" : "block",
        }}
      >
        <span>{totalLength}</span>
        <span style={{ marginInlineEnd: "6px" }}>תוצאות</span>
      </Typography>
      <Lottie
        height={100}
        width={100}
        options={defaultOptions}
        style={{
          margin: "0px auto 0 px 0px",
          maxWidth: "600px",
          display: items == null ? "block" : "none",
        }}
      />

      <Typography
        sx={{
          unicodeBidi: "plaintext",
          color: "#000",
          fontSize: { xl: "20px", lg: "18px", md: "16px" },
          display: { sm: "block", xs: "none" },
        }}
      >
        {startIdx}-{endIdx}
        <span
          style={{
            color: "#000",
            marginInlineEnd: "5px",
            marginInlineStart: "15px",
          }}
        >
          מתוך:
        </span>
      </Typography>
    </Box>
  );
}
