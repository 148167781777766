import axios from "axios";
import { storageService } from "./storageService.service";
import { Store, ReactiveValue, makeCachedCollection } from "../util/store";
import { msecHour } from "../util/time";
import { AppwideValues, LOCALSTORAGE_KEYS } from "../env";

const URL = AppwideValues.serviceURL;

const offerGivers = new Store(null);
const loadStore = makeCachedCollection(offerGivers, "offerGiver/offerGivers", x => x, LOCALSTORAGE_KEYS.offerGivers, msecHour, d=>d instanceof Array)
const offerGiverToLogo = new ReactiveValue(()=>{
    if(offerGivers.value == null)
    {
        Promise.resolve( loadStore.loadStore());
    }
    return offerGivers.value ? Object.fromEntries(offerGivers.value.map(x => [x.name, x.logo])):[];
}, [offerGivers])

export const offerGiverService = {
    offerGivers,
    load: loadStore,
    offerGiverToLogo,
}
